export var GenericBlockType;

(function (GenericBlockType) {
  GenericBlockType["Affiliation"] = "affiliation";
  GenericBlockType["Beop"] = "beop";
  GenericBlockType["Blockquote"] = "blockquote";
  GenericBlockType["Embed"] = "embed";
  GenericBlockType["H2"] = "h2";
  GenericBlockType["H3"] = "h3";
  GenericBlockType["Highlighted"] = "highlighted";
  GenericBlockType["Image"] = "image";
  GenericBlockType["List"] = "list";
  GenericBlockType["Recipe"] = "recipe";
  GenericBlockType["SlideshowEmbed"] = "slideshowEmbed";
  GenericBlockType["Table"] = "table";
  GenericBlockType["Unstyled"] = "unstyled";
  GenericBlockType["Video"] = "video";
})(GenericBlockType || (GenericBlockType = {}));

export var ListItemType;

(function (ListItemType) {
  ListItemType["Ordered"] = "ordered-list-item";
  ListItemType["Unordered"] = "unordered-list-item";
})(ListItemType || (ListItemType = {}));

export var GenericInlineDataType;

(function (GenericInlineDataType) {
  GenericInlineDataType["AffiliationLink"] = "affiliation_link";
  GenericInlineDataType["ExternalLink"] = "external_link";
  GenericInlineDataType["InternalLink"] = "internal_link";
})(GenericInlineDataType || (GenericInlineDataType = {}));

export var GenericInlineStyleType;

(function (GenericInlineStyleType) {
  GenericInlineStyleType["Bold"] = "bold";
  GenericInlineStyleType["Italic"] = "italic";
  GenericInlineStyleType["Underline"] = "underline";
  GenericInlineStyleType["Superscript"] = "superscript";
})(GenericInlineStyleType || (GenericInlineStyleType = {}));

export var AffiliationBlockType;

(function (AffiliationBlockType) {
  AffiliationBlockType["Cta"] = "cta";
  AffiliationBlockType["Slideshow"] = "slideshow";
  AffiliationBlockType["PriceTable"] = "price_table";
})(AffiliationBlockType || (AffiliationBlockType = {}));