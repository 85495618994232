import { GENERIC_SEPARATOR } from "../types";
export var getOptionIdValueFromKeyword = function getOptionIdValueFromKeyword(keyword) {
  return "".concat(keyword.lang).concat(GENERIC_SEPARATOR).concat(keyword.keyword);
};
export var getKeywordValueFromOptionId = function getKeywordValueFromOptionId(option) {
  var _optionDetails$;

  var optionDetails = option.split(GENERIC_SEPARATOR);

  if (optionDetails.length !== 2) {
    return '';
  }

  return (_optionDetails$ = optionDetails[1]) !== null && _optionDetails$ !== void 0 ? _optionDetails$ : '';
};