var isSiteAllowed = function isSiteAllowed(site, group) {
  var userSiteRoles = group[site];

  if (undefined === userSiteRoles) {
    return false;
  }

  return userSiteRoles.length !== 0;
};

export default isSiteAllowed;