import _defineProperty from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { ContentModel } from '@uno/common-cms';
import { Services } from '@uno/common-shared';

// TODO: merge generateContentEnvelope in frontend and backend
var contentToEnvelope = function contentToEnvelope(content) {
  var created = content.created,
      modified = content.modified,
      id = content.id,
      contentData = content.contentData,
      contentMetadata = content.contentMetadata,
      contentType = content.contentType,
      dependencyMap = content.dependencyMap,
      status = content.status;
  return {
    service: Services.CONTENT,
    model: ContentModel.Content,
    pageChunk: _objectSpread({
      _createdAt: created,
      _id: id,
      _modifiedAt: modified,
      _contentTypeName: contentType,
      _dependencyMap: dependencyMap,
      _status: status,
      _contentMetadata: contentMetadata
    }, contentData)
  };
};

export default contentToEnvelope;