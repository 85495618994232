import React from "react";
var __jsx = React.createElement;
import { useIntl } from 'react-intl';
import { BlockType } from '@uno/common-cms';
import { H3 } from 'theme/icons';
import { useHandleBlockToggleCallback } from '../../../shared/hooks/useHandleBlockToggleCallback';
import { HeaderThreeBlockToggle } from './BlockToolbarComponent.style';

var BlockToolbarComponent = function BlockToolbarComponent(_ref) {
  var setEditorState = _ref.setEditorState;
  var handleBlockToggle = useHandleBlockToggleCallback(BlockType.H3, setEditorState);
  var intl = useIntl();
  var label = intl.formatMessage({
    id: 'content.labels.block-toolbar.h3'
  });
  return __jsx(HeaderThreeBlockToggle, {
    onMouseDown: handleBlockToggle(),
    title: label,
    "aria-label": label
  }, __jsx(H3, null));
};

export default BlockToolbarComponent;