import _defineProperty from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

var _blocksConfig;

import { GenericBlockType } from '@uno/common-cms';
import { transformAffiliationToDraftjsAndAppend, transformAffiliationToGenericAndAppend, transformBeopToDraftjsAndAppend, transformBeopToGenericAndAppend, transformBlockquoteToDraftjsAndAppend, transformBlockquoteToGenericAndAppend, transformEmbedToDraftjsAndAppend, transformEmbedToGenericAndAppend, transformH2ToDraftjsAndAppend, transformH2ToGenericAndAppend, transformH3ToDraftjsAndAppend, transformH3ToGenericAndAppend, transformHighlightedToDraftjsAndAppend, transformHighlightedToGenericAndAppend, transformImageToDraftjsAndAppend, transformImageToGenericAndAppend, transformListToDraftjsAndAppend, transformListToGenericAndAppend, transformRecipeToDraftjsAndAppend, transformRecipeToGenericAndAppend, transformSlideshowEmbedToDraftjsAndAppend, transformSlideshowEmbedToGenericAndAppend, transformTableToDraftjsAndAppend, transformTableToGenericAndAppend, transformUnstyledToDraftjsAndAppend, transformUnstyledToGenericAndAppend, transformVideoToDraftjsAndAppend, transformVideoToGenericAndAppend } from './blocks';
// Todo: merge/arrange with the futur RTE blocks config
export var blocksConfig = (_blocksConfig = {}, _defineProperty(_blocksConfig, GenericBlockType.Affiliation, {
  transformToDraftjsAndAppend: transformAffiliationToDraftjsAndAppend,
  transformToGenericAndAppend: transformAffiliationToGenericAndAppend
}), _defineProperty(_blocksConfig, GenericBlockType.Beop, {
  transformToDraftjsAndAppend: transformBeopToDraftjsAndAppend,
  transformToGenericAndAppend: transformBeopToGenericAndAppend
}), _defineProperty(_blocksConfig, GenericBlockType.Blockquote, {
  transformToDraftjsAndAppend: transformBlockquoteToDraftjsAndAppend,
  transformToGenericAndAppend: transformBlockquoteToGenericAndAppend
}), _defineProperty(_blocksConfig, GenericBlockType.Blockquote, {
  transformToDraftjsAndAppend: transformBlockquoteToDraftjsAndAppend,
  transformToGenericAndAppend: transformBlockquoteToGenericAndAppend
}), _defineProperty(_blocksConfig, GenericBlockType.Embed, {
  transformToDraftjsAndAppend: transformEmbedToDraftjsAndAppend,
  transformToGenericAndAppend: transformEmbedToGenericAndAppend
}), _defineProperty(_blocksConfig, GenericBlockType.H2, {
  transformToDraftjsAndAppend: transformH2ToDraftjsAndAppend,
  transformToGenericAndAppend: transformH2ToGenericAndAppend
}), _defineProperty(_blocksConfig, GenericBlockType.H3, {
  transformToDraftjsAndAppend: transformH3ToDraftjsAndAppend,
  transformToGenericAndAppend: transformH3ToGenericAndAppend
}), _defineProperty(_blocksConfig, GenericBlockType.Image, {
  transformToDraftjsAndAppend: transformImageToDraftjsAndAppend,
  transformToGenericAndAppend: transformImageToGenericAndAppend
}), _defineProperty(_blocksConfig, GenericBlockType.List, {
  transformToDraftjsAndAppend: transformListToDraftjsAndAppend,
  transformToGenericAndAppend: transformListToGenericAndAppend
}), _defineProperty(_blocksConfig, GenericBlockType.SlideshowEmbed, {
  transformToDraftjsAndAppend: transformSlideshowEmbedToDraftjsAndAppend,
  transformToGenericAndAppend: transformSlideshowEmbedToGenericAndAppend
}), _defineProperty(_blocksConfig, GenericBlockType.Table, {
  transformToDraftjsAndAppend: transformTableToDraftjsAndAppend,
  transformToGenericAndAppend: transformTableToGenericAndAppend
}), _defineProperty(_blocksConfig, GenericBlockType.Unstyled, {
  transformToDraftjsAndAppend: transformUnstyledToDraftjsAndAppend,
  transformToGenericAndAppend: transformUnstyledToGenericAndAppend
}), _defineProperty(_blocksConfig, GenericBlockType.Video, {
  transformToDraftjsAndAppend: transformVideoToDraftjsAndAppend,
  transformToGenericAndAppend: transformVideoToGenericAndAppend
}), _defineProperty(_blocksConfig, GenericBlockType.Highlighted, {
  transformToDraftjsAndAppend: transformHighlightedToDraftjsAndAppend,
  transformToGenericAndAppend: transformHighlightedToGenericAndAppend
}), _defineProperty(_blocksConfig, GenericBlockType.Recipe, {
  transformToDraftjsAndAppend: transformRecipeToDraftjsAndAppend,
  transformToGenericAndAppend: transformRecipeToGenericAndAppend
}), _blocksConfig);