import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { AUFEMININ_TAXONOMIES } from "./AUFEMININ/taxonomy";
import { AufemininTaxonomyName } from "./AUFEMININ/taxonomy/taxonomyName";
import { DOCTISSIMO_TAXONOMIES } from "./DOCTISSIMO_FR/taxonomy";
import { DoctissimoTaxonomyName } from "./DOCTISSIMO_FR/taxonomy/taxonomyName";
import { MARMITON_TAXONOMIES } from "./MARMITON_ORG/taxonomy";
import { MarmitonTaxonomyName } from "./MARMITON_ORG/taxonomy/taxonomyName";
import { TEST_TAXONOMIES, TestTaxonomyName } from "./TEST";
export var TAXONOMIES = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, AUFEMININ_TAXONOMIES), DOCTISSIMO_TAXONOMIES), MARMITON_TAXONOMIES), TEST_TAXONOMIES);
export var TAXONOMY_NAMES = [].concat(_toConsumableArray(Object.values(AufemininTaxonomyName)), _toConsumableArray(Object.values(DoctissimoTaxonomyName)), _toConsumableArray(Object.values(MarmitonTaxonomyName)), _toConsumableArray(Object.values(TestTaxonomyName)));