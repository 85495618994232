import { createStyles } from '@material-ui/core';
import { colorUsage, fontFamily, fontSize, fontWeight, lineHeight } from 'stylesheet';
var MuiTableCellOverride = createStyles({
  head: {
    textAlign: 'center',
    alignSelf: 'center',
    fontStyle: 'normal',
    fontFamily: fontFamily.main,
    fontWeight: fontWeight.bold,
    fontSize: fontSize.small,
    lineHeight: lineHeight.smaller,
    color: colorUsage.grayDark,
    margin: '0',
    padding: '0',
    top: "0",
    borderBottom: 'none'
  },
  body: {
    textAlign: 'center',
    alignSelf: 'center',
    fontStyle: 'normal',
    fontFamily: fontFamily.main,
    fontWeight: fontWeight.normal,
    fontSize: fontSize.small,
    color: colorUsage.grayDark,
    margin: '0',
    padding: '0',
    borderBottom: 'none',
    zIndex: 0,
    overflowY: 'auto',
    maxHeight: '40px',
    whiteSpace: 'pre-line',
    '&::-webkit-scrollbar': {
      height: '4px',
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: colorUsage.scrollbar
    }
  }
});
export default MuiTableCellOverride;