import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { getSiteConfig } from '@uno/common-cms';
import { Sites } from '@uno/common-shared';
import useAllowedSites from './useAllowedSites';
import useIsSiteSpecificRoute from './useIsSiteSpecificRoute';
var FALLBACK_SITE = Sites.DOCTISSIMO_FR;

var useSiteConfig = function useSiteConfig() {
  var _useRouter = useRouter(),
      push = _useRouter.push,
      siteFromQuery = _useRouter.query.site;

  var isSiteSpecificRoute = useIsSiteSpecificRoute();
  var allowedSites = useAllowedSites();
  var isSiteIdValid = useMemo(function () {
    return isSiteValid(siteFromQuery);
  }, [siteFromQuery]);
  var site = useMemo(function () {
    var _allowedSites$0$id, _allowedSites$;

    return isSiteIdValid ? siteFromQuery : (_allowedSites$0$id = (_allowedSites$ = allowedSites[0]) === null || _allowedSites$ === void 0 ? void 0 : _allowedSites$.id) !== null && _allowedSites$0$id !== void 0 ? _allowedSites$0$id : FALLBACK_SITE;
  }, [allowedSites, isSiteIdValid, siteFromQuery]);
  var siteConfig = useMemo(function () {
    return getSiteConfig(site);
  }, [site]);

  if (siteConfig === undefined) {
    throw new Error("Unable to get SiteConfig for current Site: ".concat(site));
  }

  var isServerSide = typeof window === 'undefined';

  if (!isServerSide && isSiteSpecificRoute && !isSiteIdValid) {
    void push('/');
  }

  return siteConfig;
};

var isSiteValid = function isSiteValid(siteId) {
  return !Array.isArray(siteId) && siteId !== undefined && Object.values(Sites).includes(siteId);
};

export default useSiteConfig;