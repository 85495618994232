import { genKey } from 'draft-js';
import { BlockType } from '@uno/common-cms';
import { getRawDraftEntityRangesAndUpdatedEntityMap, getRawDraftInlineStyleRangesFromGenericInlineStyleRanges } from '../../util';
export var transformBlockquoteToDraftjsAndAppend = function transformBlockquoteToDraftjsAndAppend(genericBlock, rawDraftContentState) {
  var _genericBlock$text;

  var _getRawDraftEntityRan = getRawDraftEntityRangesAndUpdatedEntityMap(genericBlock.inlineDataRanges, rawDraftContentState.entityMap),
      rawDraftEntityRanges = _getRawDraftEntityRan.rawDraftEntityRanges,
      entityMap = _getRawDraftEntityRan.entityMap;

  rawDraftContentState.blocks.push({
    key: genKey(),
    type: BlockType.BLOCKQUOTE,
    text: (_genericBlock$text = genericBlock.text) !== null && _genericBlock$text !== void 0 ? _genericBlock$text : '',
    depth: 0,
    inlineStyleRanges: getRawDraftInlineStyleRangesFromGenericInlineStyleRanges(genericBlock.inlineStyleRanges),
    entityRanges: rawDraftEntityRanges
  });
  rawDraftContentState.entityMap = entityMap;
  return rawDraftContentState;
};