import { GroupFields } from "../../../FieldNames";
import { getGroupLabelId } from "../../../../helpers";
import { FieldType } from "../../../../types";
import { coverImagesField } from "./coverImagesField";
export var imageGroupFields = function imageGroupFields() {
  return {
    type: FieldType.Group,
    name: GroupFields.IMAGES,
    labelId: getGroupLabelId(GroupFields.IMAGES),
    fields: [coverImagesField]
  };
};