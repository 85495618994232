import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

/* eslint-disable max-lines */
import get from 'lodash/get';
import { Sites, Stages } from '@uno/common-shared';
import { BaseContentTypeName, Fields } from "../../FieldNames";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { AufemininContentTypeName } from "./ContentTypeName";
import { slideshowPropertiesGroupFields, slideshowSeoGroupFields, slideshowV2Fields } from "./common";
var baseLabelId = BaseContentTypeName.SlideshowV2;
var fields = [].concat(_toConsumableArray(slideshowV2Fields), [slideshowSeoGroupFields, slideshowPropertiesGroupFields]);
export var slideshowV2 = {
  site: Sites.AUFEMININ_COM,
  name: AufemininContentTypeName.SlideshowV2FR,
  labelId: "content-type.".concat(baseLabelId, ".name"),
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Slideshow,
  getLabel: function getLabel(contentData) {
    return (// TODO: See to fix "Type instantiation is excessively deep and possibly infinite"
      // @ts-ignore Sorry too many fields
      (contentData === null || contentData === void 0 ? void 0 : contentData.title) !== undefined ? contentData === null || contentData === void 0 ? void 0 : contentData.title : ''
    );
  },
  getPreviewImage: function getPreviewImage(contentData) {
    var _ref;

    return (_ref = get(contentData, 'coverImages.default.imgUrl')) !== null && _ref !== void 0 ? _ref : null;
  },
  getSubLabel: function getSubLabel(contentData) {
    var _contentData$slides$l, _contentData$slides;

    return "".concat((_contentData$slides$l = contentData === null || contentData === void 0 ? void 0 : (_contentData$slides = contentData.slides) === null || _contentData$slides === void 0 ? void 0 : _contentData$slides.length) !== null && _contentData$slides$l !== void 0 ? _contentData$slides$l : 0, " Slide(s)");
  },
  getAuthorIds: function getAuthorIds(contentData) {
    var _ref2;

    return (_ref2 = [contentData.authorId]) !== null && _ref2 !== void 0 ? _ref2 : [];
  },
  getMainSectionId: function getMainSectionId(contentData) {
    var _contentData$mainSect, _contentData$mainSect2;

    return (_contentData$mainSect = contentData === null || contentData === void 0 ? void 0 : (_contentData$mainSect2 = contentData.mainSection) === null || _contentData$mainSect2 === void 0 ? void 0 : _contentData$mainSect2[0]) !== null && _contentData$mainSect !== void 0 ? _contentData$mainSect : '';
  },
  getManualModificationDate: function getManualModificationDate() {
    return undefined;
  },
  fields: fields,
  partialDependencyFields: [Fields.AUTHOR_ID, Fields.TITLE, Fields.CHAPO, Fields.COVER_IMAGES],
  isLinkable: true,
  isCreatable: process.env.STAGE !== Stages.PRODUCTION
};