import { BlockType as DraftjsBlockType, GenericBlockType, ListItemType } from '@uno/common-cms';
import { getGenericInlineDataRanges, getGenericInlineStyleRangesFromBlock } from '../../util';
import appendGenericBlock from '../../util/appendGenericBlock';
export var transformListToGenericAndAppend = function transformListToGenericAndAppend(block, genericBlocks, contentState) {
  var _genericBlocks;

  var newBlock = {
    type: GenericBlockType.List,
    items: []
  };

  if (((_genericBlocks = genericBlocks[genericBlocks.length - 1]) === null || _genericBlocks === void 0 ? void 0 : _genericBlocks.type) === GenericBlockType.List) {
    var lastGenericBlock = genericBlocks.pop();
    newBlock.items = lastGenericBlock.items;
  }

  newBlock.items.push({
    depth: block.getDepth(),
    text: block.getText(),
    type: block.getType() === DraftjsBlockType.OL ? ListItemType.Ordered : ListItemType.Unordered,
    inlineStyleRanges: getGenericInlineStyleRangesFromBlock(block),
    inlineDataRanges: getGenericInlineDataRanges(block, contentState)
  });
  return appendGenericBlock(genericBlocks, newBlock, block);
};