import { Sites } from '@uno/common-shared';
import { BaseContentTypeName, GroupFields } from "../../FieldNames";
import { titleTextField } from "../../common/fieldDefinitions/simpleText";
import { getGroupLabelId } from "../../../helpers";
import { FieldType } from "../../../types/ContentType";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { TestContentTypeName } from "./ContentTypeName";
var baseLabelId = BaseContentTypeName.Article;
var fields = [{
  type: FieldType.Group,
  name: GroupFields.ARTICLE,
  labelId: getGroupLabelId(GroupFields.ARTICLE),
  fields: [titleTextField]
}];
export var withNoBuildPage = {
  site: Sites.TEST,
  name: TestContentTypeName.WithNoBuildPage,
  labelId: 'content-type.article.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  getLabel: function getLabel() {
    return '';
  },
  getPreviewImage: function getPreviewImage() {
    return null;
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  getMainSectionId: function getMainSectionId() {
    return '';
  },
  getManualModificationDate: function getManualModificationDate() {
    return undefined;
  },
  fields: fields,
  partialDependencyFields: [],
  isLinkable: true,
  isCreatable: true
};