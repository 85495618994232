import { createStyles } from '@material-ui/core';
import { colorUsage, fontWeight } from 'stylesheet';
import horizontalScrollBarOverride from './horizontalScrollBarOverride';
var TagChipOverride = createStyles({
  root: {
    backgroundColor: function backgroundColor(_ref) {
      var multiple = _ref.multiple;
      return multiple ? colorUsage.muiOverride.chip.multiple.backgroundColor : colorUsage.muiOverride.chip.primary.backgroundColor;
    },
    color: function color(_ref2) {
      var multiple = _ref2.multiple;
      return multiple ? colorUsage.muiOverride.chip.multiple.color : colorUsage.muiOverride.chip.primary.color;
    },
    fontWeight: fontWeight.bold
  },
  deleteIcon: {
    '& path': {
      fill: function fill(_ref3) {
        var multiple = _ref3.multiple;
        return multiple ? colorUsage.muiOverride.chip.multiple.color : colorUsage.muiOverride.chip.primary.color;
      }
    }
  },
  label: function label(_ref4) {
    var _ref4$horizontalScrol = _ref4.horizontalScroll,
        horizontalScroll = _ref4$horizontalScrol === void 0 ? false : _ref4$horizontalScrol;
    return horizontalScroll ? horizontalScrollBarOverride : {};
  },
  sizeSmall: {
    height: '20px',
    borderRadius: '10px'
  }
});
export default TagChipOverride;