import { GroupFields } from "../../../FieldNames";
import { modificationDateField } from "../../../common/fieldDefinitions/date";
import { createdMetaDateField, modifiedMetaDateField, publicationMetaDateField } from "../../../common/fieldDefinitions/metaDate";
import { getGroupLabelId } from "../../../../helpers";
import { FieldType } from "../../../../types";
export var dateGroupFields = function dateGroupFields() {
  return {
    type: FieldType.Group,
    name: GroupFields.DATES,
    labelId: getGroupLabelId(GroupFields.DATES),
    fields: [modificationDateField, publicationMetaDateField, createdMetaDateField, modifiedMetaDateField]
  };
};