import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

var _HOSTS_BY_SITE, _HOST_IDS_BY_SITE;

import uniq from 'lodash/uniq';
import { Sites } from '@uno/common-shared';
import { AUFEMININ_HOST_IDS, AUFEMININ_HOSTS } from "./AUFEMININ";
import { DOCTISSIMO_HOST_IDS, DOCTISSIMO_HOSTS } from "./DOCTISSIMO_FR";
import { MARMITON_HOST_IDS, MARMITON_HOSTS } from "./MARMITON_ORG";
import { TEST_HOST_IDS, TEST_HOSTS } from "./TEST";
export var HOST_IDS = uniq([].concat(_toConsumableArray(Object.values(AUFEMININ_HOST_IDS)), _toConsumableArray(Object.values(DOCTISSIMO_HOST_IDS)), _toConsumableArray(Object.values(MARMITON_HOST_IDS)), _toConsumableArray(Object.values(TEST_HOST_IDS))));
export var HOSTS_BY_SITE = (_HOSTS_BY_SITE = {}, _defineProperty(_HOSTS_BY_SITE, Sites.AUFEMININ_COM, AUFEMININ_HOSTS), _defineProperty(_HOSTS_BY_SITE, Sites.DOCTISSIMO_FR, DOCTISSIMO_HOSTS), _defineProperty(_HOSTS_BY_SITE, Sites.ENFEMENINO_COM, AUFEMININ_HOSTS), _defineProperty(_HOSTS_BY_SITE, Sites.MARMITON_ORG, MARMITON_HOSTS), _defineProperty(_HOSTS_BY_SITE, Sites.TEST, TEST_HOSTS), _HOSTS_BY_SITE);
export var HOST_IDS_BY_SITE = (_HOST_IDS_BY_SITE = {}, _defineProperty(_HOST_IDS_BY_SITE, Sites.AUFEMININ_COM, AUFEMININ_HOST_IDS), _defineProperty(_HOST_IDS_BY_SITE, Sites.DOCTISSIMO_FR, DOCTISSIMO_HOST_IDS), _defineProperty(_HOST_IDS_BY_SITE, Sites.MARMITON_ORG, MARMITON_HOST_IDS), _defineProperty(_HOST_IDS_BY_SITE, Sites.ENFEMENINO_COM, AUFEMININ_HOST_IDS), _defineProperty(_HOST_IDS_BY_SITE, Sites.TEST, TEST_HOST_IDS), _HOST_IDS_BY_SITE);
export var areSiteAndHostCompatible = function areSiteAndHostCompatible(siteId, hostId) {
  var _HOST_IDS_BY_SITE$sit;

  var siteHostIds = (_HOST_IDS_BY_SITE$sit = HOST_IDS_BY_SITE[siteId]) !== null && _HOST_IDS_BY_SITE$sit !== void 0 ? _HOST_IDS_BY_SITE$sit : [];
  var hostIdInSiteHostIds = Object.values(siteHostIds).includes(hostId);
  return hostIdInSiteHostIds !== false;
};
export var isHostIdValid = function isHostIdValid(hostId) {
  return HOST_IDS.includes(hostId);
};