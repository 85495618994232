import { Sites } from '@uno/common-shared';
import { BaseContentTypeName, GroupFields } from "../../FieldNames";
import { firstNameTextField, lastNameTextField } from "../../common/fieldDefinitions/simpleText";
import { getGroupLabelId } from "../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../types/ContentType";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { TestContentTypeName } from "./ContentTypeName";
var baseLabelId = BaseContentTypeName.Person;
var fields = [{
  type: FieldType.Group,
  name: GroupFields.PERSON,
  labelId: getGroupLabelId(GroupFields.PERSON),
  fields: [firstNameTextField, lastNameTextField]
}, {
  type: FieldType.Group,
  name: GroupFields.SEO,
  labelId: getGroupLabelId(GroupFields.SEO),
  fields: []
}];
export var person = {
  site: Sites.TEST,
  name: TestContentTypeName.Person,
  labelId: 'content-type.person.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Person,
  getLabel: function getLabel(contentData) {
    var _contentData$firstNam, _contentData$lastName;

    return "".concat((_contentData$firstNam = contentData === null || contentData === void 0 ? void 0 : contentData.firstName) !== null && _contentData$firstNam !== void 0 ? _contentData$firstNam : '').concat((contentData === null || contentData === void 0 ? void 0 : contentData.firstName) !== undefined && (contentData === null || contentData === void 0 ? void 0 : contentData.lastName) !== undefined ? ' ' : '').concat((_contentData$lastName = contentData === null || contentData === void 0 ? void 0 : contentData.lastName) !== null && _contentData$lastName !== void 0 ? _contentData$lastName : '');
  },
  getPreviewImage: function getPreviewImage() {
    return null;
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  getMainSectionId: function getMainSectionId() {
    return '';
  },
  getManualModificationDate: function getManualModificationDate() {
    return undefined;
  },
  fields: fields,
  partialDependencyFields: [],
  isLinkable: true,
  isCreatable: true
};