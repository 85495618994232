import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
/**
 * Return the formatted error of the field referenced by `name`.
 * @param name path of the field in the form state
 */

var useFieldError = function useFieldError(name) {
  var intl = useIntl();

  var _useFormContext = useFormContext(),
      errors = _useFormContext.formState.errors;

  var fieldError = get(errors, name);

  if (fieldError === undefined) {
    return '';
  }

  if (!('message' in fieldError)) {
    var records = fieldError;
    return Object.keys(records).map(function (field) {
      var errorMessageId = records[field].message;
      return intl.formatMessage({
        id: errorMessageId
      });
    }).join(' ');
  }

  var errorMessageId = fieldError.message;

  if (errorMessageId === '') {
    return '';
  }

  return intl.formatMessage({
    id: errorMessageId
  });
};

export default useFieldError;