import { AtomicBlockType as DraftjsAtomicBlockType } from '@uno/common-cms';
import { addAtomicBlock } from '../../util';
export var transformAffiliationToDraftjsAndAppend = function transformAffiliationToDraftjsAndAppend(_ref, rawDraftContentState) {
  var affiliationId = _ref.affiliationId,
      affiliationType = _ref.affiliationType,
      title = _ref.title,
      products = _ref.products;
  return addAtomicBlock(rawDraftContentState, DraftjsAtomicBlockType.AFFILIATION, {
    blockData: {
      openModal: false
    },
    entityData: {
      affiliationId: affiliationId,
      title: title,
      type: affiliationType,
      products: products
    }
  }, 'MUTABLE');
};