import { Sites } from '@uno/common-shared';
import { Lang } from "../../../types";
import { IndexConfigId } from "../../../types/services/search";
var MARMITON = {
  id: Sites.MARMITON_ORG,
  label: 'Marmiton',
  lang: Lang.FR_FR,
  mainIndexConfigId: IndexConfigId.MarmitonOrgSearch
};
export default MARMITON;
export * from "./hosts";
export * from "./pageConfigNames";