import _defineProperty from "@babel/runtime/helpers/defineProperty";
export var Sites;

(function (Sites) {
  Sites["UNIFY"] = "0";
  Sites["AUFEMININ_COM"] = "1";
  Sites["ENFEMENINO_COM"] = "2";
  Sites["ALFEMMINILE_COM"] = "3";
  Sites["GOFEMININ_DE"] = "4";
  Sites["SOFEMININE_CO_UK"] = "5";
  Sites["WEWOMEN_BE"] = "36";
  Sites["TAOFEMININO_COM_BR"] = "41";
  Sites["MARMITON_ORG"] = "13";
  Sites["NETMUMS_COM"] = "35";
  Sites["DOCTISSIMO_FR"] = "51";
  Sites["BEAUTE_TEST_COM"] = "62";
  Sites["TEST"] = "9999";
})(Sites || (Sites = {}));

export var isSite = function isSite(site) {
  return Object.values(Sites).includes(site);
};
export var SiteNames;

(function (SiteNames) {
  SiteNames["NETMUMS"] = "netmums";
  SiteNames["DOCTISSIMO"] = "doctissimo";
  SiteNames["MARMITON"] = "marmiton";
  SiteNames["TEST"] = "test";
  SiteNames["STORYBOOK"] = "my-site";
  SiteNames["LES_NUMERIQUES"] = "les_numeriques";
  SiteNames["AUFEMININ"] = "aufeminin";
  SiteNames["BEAUTE_TEST_COM"] = "beaute_test";
})(SiteNames || (SiteNames = {}));

export var MarmitonSitesId;

(function (MarmitonSitesId) {
  MarmitonSitesId["MARMITON_ORG"] = "1";
})(MarmitonSitesId || (MarmitonSitesId = {}));

export var marmitonSitesIdToSitesId = _defineProperty({}, MarmitonSitesId.MARMITON_ORG, Sites.MARMITON_ORG);
export var extractSiteIdFromHttpEvent = function extractSiteIdFromHttpEvent(event) {
  return event.headers['x-site-id'];
};