import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as Yup from 'yup';
import { Fields, GroupFields } from "../../../FieldNames";
import { authorLinkedContentField } from "../../../common/fieldDefinitions/linkedContent";
import { metaUrlField } from "../../../common/fieldDefinitions/metaUrl";
import { faqIndexNumberField, indexNumberField, recipeIdNumberField } from "../../../common/fieldDefinitions/number";
import { cloneTitleReadOnlyField, cloneUrlReadOnlyField } from "../../../common/fieldDefinitions/readOnly";
import { answerRichTextField, bodyRichTextField, imageRichTextField, sourcesRichTextField } from "../../../common/fieldDefinitions/richText";
import { chapoTextField, ogDescriptionTextField, ogTitleTextField, questionTextField, titleTextField } from "../../../common/fieldDefinitions/simpleText";
import { sponsoField } from "../../../common/fieldDefinitions/sponso";
import { displayOnHomepageSwitchField, isGoogleNewsSwitchField, isSeoContentSwitchField } from "../../../common/fieldDefinitions/switch";
import { highlightedVideoField } from "../../../common/fieldDefinitions/video";
import { getFieldLabelId, getGroupLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType";
import { AufemininContentTypeName } from "../ContentTypeName";
import { coverImagesField } from "./coverImagesField";
import { dateGroupFields } from "./dateGroupFields";
import { legacyGroupFields } from "./legacyGroupFields";
import { semanticScoreGroupFields } from "./semanticScoreGroupFields";
import { seoGroupFields } from "./seoGroupFields";
import { taxonomyGroupFields } from "./taxonomyGroupFields";
export var articleMainGroupFields = {
  type: FieldType.Group,
  name: GroupFields.ARTICLE,
  labelId: getGroupLabelId(GroupFields.ARTICLE),
  fields: [titleTextField, chapoTextField, bodyRichTextField, sourcesRichTextField, {
    type: FieldType.Group,
    name: GroupFields.FAQ,
    labelId: getGroupLabelId(GroupFields.FAQ),
    fields: [faqIndexNumberField, {
      type: FieldType.Repeatable,
      name: Fields.FAQ,
      labelId: '',
      defaultValue: [{
        /**
         * Todo: CMS-1288 => make it works with : [Fields.QUESTION]: '',
         * temporary fix the following error during package build :
         * @uno/cms: Exported variable 'fields' has or is using name 'Fields' from external module "Y" but cannot be named.
         */
        question: '',

        /**
         * Todo: CMS-1288 => make it works with : [Fields.ANSWER]: '',
         * temporary fix the following error during package build :
         * @uno/cms: Exported variable 'fields' has or is using name 'Fields' from external module "Y" but cannot be named.
         */
        answer: {
          blocks: []
        }
      }],
      validation: Yup.array(),
      field: {
        type: FieldType.Group,
        name: GroupFields.QUESTIONANSWER,
        labelId: getGroupLabelId(GroupFields.QUESTIONANSWER),
        fields: [questionTextField, answerRichTextField]
      }
    }]
  }, {
    type: FieldType.Group,
    name: GroupFields.PHOTOS,
    labelId: getGroupLabelId(GroupFields.PHOTOS),
    fields: [{
      type: FieldType.Repeatable,
      name: Fields.PHOTOS,
      labelId: '',
      defaultValue: [_defineProperty({}, Fields.IMAGE, {
        blocks: []
      })],
      validation: Yup.array().required('form.required'),
      field: imageRichTextField
    }]
  }, {
    type: FieldType.Repeatable,
    name: Fields.RECIPE,
    labelId: '',
    defaultValue: [{
      /**
       * Todo: CMS-1288 => make it works with : [Fields.INDEX]: '',
       * temporary fix the following error during package build :
       * @uno/cms: Exported variable 'fields' has or is using name 'Fields' from external module "Y" but cannot be named.
       */
      index: '',

      /**
       * Todo: CMS-1288 => make it works with : [Fields.RECIPE_ID]: '',
       * temporary fix the following error during package build :
       * @uno/cms: Exported variable 'fields' has or is using name 'Fields' from external module "Y" but cannot be named.
       */
      recipeId: ''
    }],
    validation: Yup.array(),
    field: {
      type: FieldType.Group,
      name: GroupFields.RECIPE,
      labelId: getGroupLabelId(GroupFields.RECIPE),
      fields: [indexNumberField, recipeIdNumberField]
    }
  }]
};
var articleSeoFields = seoGroupFields();
export var articleSeoGroupFields = _objectSpread(_objectSpread({}, articleSeoFields), {}, {
  fields: [].concat(_toConsumableArray(articleSeoFields.fields), [semanticScoreGroupFields(GroupFields.ARTICLE)])
});
export var articlePropertiesGroupFields = {
  type: FieldType.Group,
  name: GroupFields.PROPERTIES,
  labelId: getGroupLabelId(GroupFields.PROPERTIES),
  fields: [{
    type: FieldType.Group,
    name: GroupFields.PARAMETERS,
    labelId: getGroupLabelId(GroupFields.PARAMETERS),
    fields: [metaUrlField, _objectSpread(_objectSpread({}, authorLinkedContentField), {}, {
      contentTypeNames: [AufemininContentTypeName.AuthorFR],
      labelId: getFieldLabelId(Fields.AUTHOR_ID),
      defaultValue: '',
      validation: Yup.string()
    }), isGoogleNewsSwitchField, isSeoContentSwitchField, displayOnHomepageSwitchField]
  }, {
    type: FieldType.Group,
    name: GroupFields.NETWORK,
    labelId: getGroupLabelId(GroupFields.NETWORK),
    fields: [ogTitleTextField, ogDescriptionTextField]
  }, taxonomyGroupFields(), dateGroupFields(), {
    type: FieldType.Group,
    name: GroupFields.IMAGES,
    labelId: getGroupLabelId(GroupFields.IMAGES),
    fields: [coverImagesField, highlightedVideoField]
  }, {
    type: FieldType.Group,
    name: GroupFields.SPONSO,
    labelId: getGroupLabelId(GroupFields.SPONSO),
    fields: [sponsoField]
  }, {
    type: FieldType.Group,
    name: GroupFields.CLONED_FROM,
    labelId: getGroupLabelId(GroupFields.CLONED_FROM),
    fields: [cloneTitleReadOnlyField, cloneUrlReadOnlyField]
  }, legacyGroupFields()]
};