import { EditorState } from 'draft-js';
import { Map } from 'immutable';

var updateBlockData = function updateBlockData(editorState, blockKey, data) {
  var contentState = editorState.getCurrentContent();
  var blockMap = contentState.getBlockMap();
  var block = contentState.getBlockForKey(blockKey); // Block may have just been deleted

  if (block === undefined) {
    return editorState;
  }

  var updatedBlock = block.merge({
    data: block.getData().merge(Map(data))
  });
  var newContentState = contentState.merge({
    blockMap: blockMap.merge(Map([[blockKey, updatedBlock]]))
  });
  var newEditorState = EditorState.push(editorState, newContentState, 'change-block-data'); // force selection to force re-render

  return EditorState.forceSelection(newEditorState, editorState.getSelection());
};

export default updateBlockData;