import _slicedToArray from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray";
import { useAsyncFn } from 'react-use';
import { getTaxonomyTerm } from 'services/networking/taxonomy';

var useTaxonomyTerm = function useTaxonomyTerm() {
  var _useAsyncFn = useAsyncFn(getTaxonomyTerm),
      _useAsyncFn2 = _slicedToArray(_useAsyncFn, 2),
      _useAsyncFn2$ = _useAsyncFn2[0],
      loading = _useAsyncFn2$.loading,
      error = _useAsyncFn2$.error,
      term = _useAsyncFn2$.value,
      fetchTerm = _useAsyncFn2[1];

  return {
    loading: loading,
    error: error,
    term: term,
    fetchTerm: fetchTerm
  };
};

export default useTaxonomyTerm;