import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var titleTextField = {
  type: FieldType.SimpleText,
  name: Fields.TITLE,
  labelId: getFieldLabelId(Fields.TITLE),
  defaultValue: '',
  validation: Yup.string().required('form.required')
};