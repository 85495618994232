import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var isCustomTitleSwitchField = {
  type: FieldType.Switch,
  name: Fields.IS_CUSTOM_TITLE,
  labelId: getFieldLabelId(Fields.IS_CUSTOM_TITLE),
  defaultValue: false,
  validation: Yup["boolean"]().required('form.required')
};