import { Sites } from '@uno/common-shared';
import { AufemininTaxonomyName } from "./taxonomyName";
var MAIN_SECTION_FR = {
  name: AufemininTaxonomyName.MainSectionFR,
  hierarchical: true,
  site: Sites.AUFEMININ_COM
};
var MAIN_SECTION_ES = {
  name: AufemininTaxonomyName.MainSectionES,
  hierarchical: true,
  site: Sites.ENFEMENINO_COM
};
var MAIN_SECTION_IT = {
  name: AufemininTaxonomyName.MainSectionIT,
  hierarchical: true,
  site: Sites.ALFEMMINILE_COM
};
var MAIN_SECTION_DE = {
  name: AufemininTaxonomyName.MainSectionDE,
  hierarchical: true,
  site: Sites.GOFEMININ_DE
};
var MAIN_SECTION_UK = {
  name: AufemininTaxonomyName.MainSectionUK,
  hierarchical: true,
  site: Sites.SOFEMININE_CO_UK
};
var MAIN_SECTION_BR = {
  name: AufemininTaxonomyName.MainSectionBR,
  hierarchical: true,
  site: Sites.TAOFEMININO_COM_BR
};
var MAIN_SECTION_BE = {
  name: AufemininTaxonomyName.MainSectionBE,
  hierarchical: true,
  site: Sites.WEWOMEN_BE
};
export { MAIN_SECTION_FR, MAIN_SECTION_ES, MAIN_SECTION_IT, MAIN_SECTION_DE, MAIN_SECTION_UK, MAIN_SECTION_BR, MAIN_SECTION_BE };