import React from "react";
var __jsx = React.createElement;
import { gradient } from 'stylesheet';
import { GradientSVG } from './SVGGradient.style';

var SVGGradient = function SVGGradient() {
  return __jsx(GradientSVG, {
    "aria-hidden": "true",
    focusable: "false"
  }, __jsx("linearGradient", {
    id: gradient.id
  }, __jsx("stop", {
    offset: "0%",
    stopColor: gradient.startColor
  }), __jsx("stop", {
    offset: "100%",
    stopColor: gradient.stopColor
  })));
};

export default SVGGradient;