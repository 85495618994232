import { createStyles } from '@material-ui/core';
import { getSpacing } from 'stylesheet';
var MuiTableBodyOverride = createStyles({
  root: {
    height: '100%',
    overflow: 'hidden',
    display: 'block',
    alignSelf: 'center',
    textAlign: 'center',
    borderRadius: "".concat(getSpacing(1)),
    position: 'static',
    margin: "0",
    padding: "".concat(getSpacing(1), " ").concat(getSpacing(2))
  }
});
export default MuiTableBodyOverride;