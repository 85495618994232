// Inspired by https://github.com/brijeshb42/medium-draft/blob/master/src/util/index.js
import { BlockType } from '@uno/common-cms';
import { getBlockRelatives } from 'components/common/RichTextEditor/util/block';
/**
 * Returns the `boundingClientRect` of the passed selection.
 */

export var getSelectionRect = function getSelectionRect(selection) {
  var boundingClientRect = selection.getRangeAt(0).getBoundingClientRect();
  var rect = boundingClientRect.top ? boundingClientRect : selection.getRangeAt(0).getClientRects()[0];
  return rect;
};
/**
 * Finds the DOM Element where the cursor is currently present.
 * Get it by its offset-key data attribute, to avoid issue with atomic block selection.
 * If not found, returns null.
 */

export var getSelectedBlockNode = function getSelectedBlockNode(block, blockMap) {
  if (block.getType() === BlockType.TABLE) {
    var _getBlockRelatives$;

    var key = (_getBlockRelatives$ = getBlockRelatives(block, blockMap)[0]) === null || _getBlockRelatives$ === void 0 ? void 0 : _getBlockRelatives$.getKey();

    if (key === undefined) {
      throw new Error('No key found in table first cell');
    }

    return document.querySelector("[data-offset-key='".concat(key, "-0-0']"));
  }

  return document.querySelector("[data-offset-key='".concat(block.getKey(), "-0-0']"));
};