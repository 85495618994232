import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _AUFEMININ_CONTENT_TY, _AUFEMININ_CONTENT_TY2;

import { Sites } from '@uno/common-shared';
export var AufemininContentTypeName;

(function (AufemininContentTypeName) {
  AufemininContentTypeName["ArticleES"] = "AUFEMININ_ES_ARTICLE";
  AufemininContentTypeName["ArticleFR"] = "AUFEMININ_COM_ARTICLE";
  AufemininContentTypeName["AuthorES"] = "AUFEMININ_ES_AUTHOR";
  AufemininContentTypeName["AuthorFR"] = "AUFEMININ_COM_AUTHOR";
  AufemininContentTypeName["HomeES"] = "AUFEMININ_ES_HOMEPAGE";
  AufemininContentTypeName["HomeFR"] = "AUFEMININ_COM_HOMEPAGE";
  AufemininContentTypeName["SectionHomeES"] = "AUFEMININ_ES_SECTION_HOME";
  AufemininContentTypeName["SectionHomeFR"] = "AUFEMININ_COM_SECTION_HOME";
  AufemininContentTypeName["SlideshowES"] = "AUFEMININ_ES_SLIDESHOW";
  AufemininContentTypeName["SlideshowFR"] = "AUFEMININ_COM_SLIDESHOW";
  AufemininContentTypeName["SlideshowV2ES"] = "AUFEMININ_ES_SLIDESHOW_V2";
  AufemininContentTypeName["SlideshowV2FR"] = "AUFEMININ_COM_SLIDESHOW_V2";
  AufemininContentTypeName["TagPageES"] = "AUFEMININ_ES_TAG_PAGE";
  AufemininContentTypeName["TagPageFR"] = "AUFEMININ_COM_TAG_PAGE";
})(AufemininContentTypeName || (AufemininContentTypeName = {}));

export var isAufemininContentTypeName = function isAufemininContentTypeName(contentTypeName) {
  return Object.values(AufemininContentTypeName).includes(contentTypeName);
};
export var AUFEMININ_CONTENT_TYPE_NAME_TO_SITE = (_AUFEMININ_CONTENT_TY = {}, _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.ArticleES, Sites.ENFEMENINO_COM), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.ArticleFR, Sites.AUFEMININ_COM), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.AuthorES, Sites.ENFEMENINO_COM), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.AuthorFR, Sites.AUFEMININ_COM), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.HomeES, Sites.ENFEMENINO_COM), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.HomeFR, Sites.AUFEMININ_COM), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.SectionHomeES, Sites.ENFEMENINO_COM), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.SectionHomeFR, Sites.AUFEMININ_COM), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.SlideshowES, Sites.ENFEMENINO_COM), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.SlideshowFR, Sites.AUFEMININ_COM), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.SlideshowV2ES, Sites.ENFEMENINO_COM), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.SlideshowV2FR, Sites.AUFEMININ_COM), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.TagPageES, Sites.ENFEMENINO_COM), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.TagPageFR, Sites.AUFEMININ_COM), _AUFEMININ_CONTENT_TY);
export var AUFEMININ_CONTENT_TYPE_NAME_BY_SITE = (_AUFEMININ_CONTENT_TY2 = {}, _defineProperty(_AUFEMININ_CONTENT_TY2, Sites.ENFEMENINO_COM, {
  Article: AufemininContentTypeName.ArticleES,
  Author: AufemininContentTypeName.AuthorES,
  Home: AufemininContentTypeName.HomeES,
  SectionHome: AufemininContentTypeName.SectionHomeES,
  Slideshow: AufemininContentTypeName.SlideshowES,
  SlideshowV2: AufemininContentTypeName.SlideshowV2ES,
  TagPage: AufemininContentTypeName.TagPageES
}), _defineProperty(_AUFEMININ_CONTENT_TY2, Sites.AUFEMININ_COM, {
  Article: AufemininContentTypeName.ArticleFR,
  Author: AufemininContentTypeName.AuthorFR,
  Home: AufemininContentTypeName.HomeFR,
  SectionHome: AufemininContentTypeName.SectionHomeFR,
  Slideshow: AufemininContentTypeName.SlideshowFR,
  SlideshowV2: AufemininContentTypeName.SlideshowV2FR,
  TagPage: AufemininContentTypeName.TagPageFR
}), _AUFEMININ_CONTENT_TY2);