import styled from 'styled-components';
import { colorUsage, getSpacing } from 'stylesheet';
import { Close, Validation, WarningTriangle } from 'theme/icons';
var CloseButton = styled(Close).withConfig({
  displayName: "ToasterProviderstyle__CloseButton",
  componentId: "sc-5dv9bp-0"
})(["& path{fill:", ";}"], colorUsage.textColor);
var SuccessIcon = styled(Validation).withConfig({
  displayName: "ToasterProviderstyle__SuccessIcon",
  componentId: "sc-5dv9bp-1"
})(["width:28px;margin-right:", ";& path{stroke:", ";}"], getSpacing(1), colorUsage.toaster.success.foreground);
var ErrorIcon = styled(WarningTriangle).withConfig({
  displayName: "ToasterProviderstyle__ErrorIcon",
  componentId: "sc-5dv9bp-2"
})(["width:32px;margin-right:", ";& path{fill:", ";}"], getSpacing(1), colorUsage.toaster.error.foreground);
export { CloseButton, SuccessIcon, ErrorIcon };