import { useIntl } from 'react-intl';

var useSortedContentTypes = function useSortedContentTypes(contentTypes) {
  var intl = useIntl();
  var sortedContentTypes = contentTypes.sort(function (contentTypeA, contentTypeB) {
    return intl.formatMessage({
      id: contentTypeA.labelId
    }) > intl.formatMessage({
      id: contentTypeB.labelId
    }) ? 1 : -1;
  });
  return sortedContentTypes;
};

export default useSortedContentTypes;