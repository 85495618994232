import { useCallback, useEffect, useState } from 'react';

var useDateRangeFilters = function useDateRangeFilters(currentRefinement, refine, min, max, containsDateFiltering // eslint-disable-next-line max-params
) {
  var initialMinDate = currentRefinement.min === min ? null : new Date(currentRefinement.min);
  var initialMaxDate = currentRefinement.max === max ? null : new Date(currentRefinement.max);

  var _useState = useState(initialMinDate),
      formerMinDate = _useState[0],
      setFormerMinDate = _useState[1];

  var _useState2 = useState(initialMaxDate),
      formerMaxDate = _useState2[0],
      setFormerMaxDate = _useState2[1];

  var _useState3 = useState(initialMinDate),
      minDate = _useState3[0],
      setMinDate = _useState3[1];

  var _useState4 = useState(initialMaxDate),
      maxDate = _useState4[0],
      setMaxDate = _useState4[1];

  var _useState5 = useState(currentRefinement.min),
      refinementMin = _useState5[0],
      setRefinementMin = _useState5[1];

  var _useState6 = useState(currentRefinement.max),
      refinementMax = _useState6[0],
      setRefinementMax = _useState6[1];

  var _useState7 = useState(false),
      hasError = _useState7[0],
      setHasError = _useState7[1];

  var handleReset = useCallback(function () {
    setRefinementMin(currentRefinement.min);
    setRefinementMax(currentRefinement.max);
    setMinDate(null);
    setMaxDate(null);
  }, [currentRefinement.min, currentRefinement.max]);
  var handleRefine = useCallback(function () {
    var minDateNumber = minDate === null || minDate === void 0 ? void 0 : minDate.getTime();
    var maxDateNumber = maxDate === null || maxDate === void 0 ? void 0 : maxDate.getTime();
    var newMin = minDateNumber !== undefined && minDateNumber > min ? minDateNumber : min;
    var newMax = maxDateNumber !== undefined && maxDateNumber < max ? maxDateNumber : max;

    if (newMin !== currentRefinement.min || newMax !== currentRefinement.max) {
      setRefinementMin(newMin);
      setRefinementMax(newMax);
      setFormerMinDate(minDate);
      setFormerMaxDate(maxDate);
      refine({
        min: newMin,
        max: newMax
      });
    }
  }, [minDate, maxDate, min, max, refine, currentRefinement.min, currentRefinement.max]); // eslint-disable-next-line complexity

  useEffect(function () {
    if (minDate && maxDate && minDate > maxDate) {
      setHasError(true);
      return;
    }

    hasError && setHasError(false);

    if (formerMinDate !== minDate || formerMaxDate !== maxDate) {
      // Meaning useEffect was triggered by a user changing the date inputs
      handleRefine();
    } // User has likely reset filters, or refined list which resulted
    // in the same items as if the date filters were not filled.
    // If user has reset filters, then isDateRefined = false and dates should be reset.
    // Check on min and max is meant to avoid side effects of a search resulting in no list items.


    if (!containsDateFiltering && min !== undefined && max !== undefined && (currentRefinement.min !== refinementMin || currentRefinement.max !== refinementMax)) {
      handleReset();
      return;
    }
  }, [minDate, maxDate, formerMinDate, formerMaxDate, min, max, refinementMin, refinementMax, currentRefinement.min, currentRefinement.max, refine, hasError, handleReset, handleRefine, containsDateFiltering]);
  var onMinChange = useCallback(function (value) {
    if (value === null) {
      setMinDate(value);
      return;
    }

    if (value !== null && !isNaN(value.getTime())) {
      value.setHours(0, 0, 0);
      setMinDate(value);
    }
  }, []);
  var onMaxChange = useCallback(function (value) {
    if (value === null) {
      setMaxDate(value);
      return;
    }

    if (value !== null && !isNaN(value.getTime())) {
      value.setHours(23, 59, 59);
      setMaxDate(value);
    }
  }, []);
  return {
    minDate: minDate,
    maxDate: maxDate,
    onMinChange: onMinChange,
    onMaxChange: onMaxChange,
    hasError: hasError
  };
};

export default useDateRangeFilters;