import _defineProperty from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useMemo } from 'react';
import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, createStore } from 'redux';
import reducer, { initialState as appInitialState } from './reducers';
var store = undefined;

var initStore = function initStore() {
  var preloadedState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : appInitialState;

  if (process.env.NODE_ENV === 'production') {
    return createStore(reducer, preloadedState, applyMiddleware());
  }

  return createStore(reducer, preloadedState, composeWithDevTools(applyMiddleware()));
};

export var getReduxStore = function getReduxStore(preloadedState) {
  var _store;

  var newStore = (_store = store) !== null && _store !== void 0 ? _store : initStore(preloadedState); // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store

  if (preloadedState !== undefined && store !== undefined) {
    newStore = initStore(_objectSpread(_objectSpread({}, store.getState()), preloadedState)); // Reset the current store

    store = undefined;
  } // For SSG and SSR always create a new store


  if (typeof window === 'undefined') {
    return newStore;
  } // Create the store once in the client


  if (store === undefined) {
    store = newStore;
  }

  return newStore;
};
export var useStore = function useStore(initialState) {
  return useMemo(function () {
    return getReduxStore(initialState);
  }, [initialState]);
};