import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import uniq from 'lodash/uniq';
export var ContentModel; // enum only for Dynamo model _type property

(function (ContentModel) {
  ContentModel["Content"] = "content";
  ContentModel["Dependency"] = "dependency";
})(ContentModel || (ContentModel = {}));

export var NavigationModel;

(function (NavigationModel) {
  NavigationModel["Page"] = "page";
  NavigationModel["Dependency"] = "dependency";
  NavigationModel["Symlink"] = "symlink";
  NavigationModel["Redirection"] = "redirection";
  NavigationModel["Preview"] = "preview";
})(NavigationModel || (NavigationModel = {}));

export var TaxonomyModel;

(function (TaxonomyModel) {
  TaxonomyModel["Term"] = "term";
})(TaxonomyModel || (TaxonomyModel = {}));

export var ModelValues = uniq([].concat(_toConsumableArray(Object.values(ContentModel)), _toConsumableArray(Object.values(NavigationModel)), _toConsumableArray(Object.values(TaxonomyModel))));