import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _ref;

import { Fields, GroupFields } from "../FieldNames";
import { getFieldLabelId, getGroupLabelId } from "../../helpers/contentTypeLabelId";
import { FieldType } from "../../types/ContentType/fields";
import { imageField } from "./fieldDefinitions/image";
import { descriptionRichTextField } from "./fieldDefinitions/richText";
import { titleTextField } from "./fieldDefinitions/simpleText";
export var slidesField = {
  type: FieldType.Repeatable,
  name: Fields.SLIDES,
  labelId: getFieldLabelId(Fields.SLIDES),
  isCarousel: true,
  fieldPreviewUrlPath: "".concat(Fields.IMAGE, ".imgUrl"),
  defaultValue: [(_ref = {}, _defineProperty(_ref, Fields.IMAGE, {
    imgUrl: undefined,
    legend: '',
    credits: ''
  }), _defineProperty(_ref, Fields.TITLE, ''), _defineProperty(_ref, Fields.DESCRIPTION, {
    blocks: []
  }), _ref)],
  field: {
    type: FieldType.Group,
    name: GroupFields.SLIDE,
    labelId: getGroupLabelId(GroupFields.SLIDE),
    fields: [imageField, titleTextField, descriptionRichTextField]
  }
};