import { FieldType } from "../types";
var NON_VALUE_FIELDS = [FieldType.MetaDate, FieldType.MetaPublicationDate, FieldType.MetaUrl, FieldType.SemanticScore];

var isValueField = function isValueField(field) {
  return !NON_VALUE_FIELDS.includes(field.type);
};

var flattenFields = function flattenFields(fields) {
  var flattenField = fields.flatMap(function (field) {
    return field.type === FieldType.Group ? flattenFields(field.fields) : field;
  });
  return flattenField.filter(isValueField);
};

export default flattenFields;