import { createStyles } from '@material-ui/core';
import merge from 'lodash/merge';
import { borderRadius, colorUsage, fontWeight, getSpacing } from 'stylesheet';
import MuiAutocompleteOverride from './MuiAutocompleteOverride';
var TagAutocompleteOverride = createStyles({
  inputRoot: {
    height: 'auto'
  },
  paper: {
    borderRadius: borderRadius.medium,
    border: "1px solid ".concat(colorUsage.inputBorder)
  },
  listbox: {
    padding: "".concat(getSpacing(1))
  },
  option: {
    backgroundColor: function backgroundColor(_ref) {
      var multipleTag = _ref.multipleTag;
      return multipleTag ? colorUsage.tagAutocomplete.option.multiple.backgroundColor : colorUsage.tagAutocomplete.option["default"].backgroundColor;
    },
    width: 'fit-content',
    padding: '0 15px',
    borderRadius: '10px',
    margin: "".concat(getSpacing(1), " 0"),
    fontSize: '13px',
    color: function color(_ref2) {
      var multipleTag = _ref2.multipleTag;
      return multipleTag ? colorUsage.tagAutocomplete.option.multiple.color : colorUsage.tagAutocomplete.option["default"].color;
    },
    fontWeight: fontWeight.bold
  }
}); // We merge in empty object to avoid mutating MuiAutocompleteOverride

export default merge({}, MuiAutocompleteOverride, TagAutocompleteOverride);