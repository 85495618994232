import { createStyles } from '@material-ui/core';
import { colorUsage } from 'stylesheet';
var HoverButtonOverride = createStyles(function () {
  return {
    root: {
      backgroundColor: colorUsage.centerParentButton.backgroundColor,
      '&:hover': {
        backgroundColor: colorUsage.centerParentButton.hoverBackgroundColor
      }
    }
  };
});
export default HoverButtonOverride;