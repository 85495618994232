import React from "react";
var __jsx = React.createElement;
import { useIntl } from 'react-intl';
import { Quote } from 'theme/icons';
import { useHandleBlockToggleCallback } from '../../hooks';
import { BlockQuoteBlockToggle } from './BlockToolbarComponent.style';

var BlockToolbarComponent = function BlockToolbarComponent(_ref) {
  var setEditorState = _ref.setEditorState;
  var handleBlockToggle = useHandleBlockToggleCallback(setEditorState);
  var intl = useIntl();
  var label = intl.formatMessage({
    id: 'content.labels.block-toolbar.block-quote'
  });
  return __jsx(BlockQuoteBlockToggle, {
    onMouseDown: handleBlockToggle(),
    title: label,
    "aria-label": label
  }, __jsx(Quote, null));
};

export default BlockToolbarComponent;