import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _MARMITON_HOSTS;

import { Stages } from '@uno/common-shared';
export var MARMITON_HOST_IDS;

(function (MARMITON_HOST_IDS) {
  MARMITON_HOST_IDS["WWW"] = "www";
})(MARMITON_HOST_IDS || (MARMITON_HOST_IDS = {}));

var commonMarmitonHosts = _defineProperty({}, MARMITON_HOST_IDS.WWW, 'www');

export var MARMITON_HOSTS = (_MARMITON_HOSTS = {}, _defineProperty(_MARMITON_HOSTS, Stages.DEV, commonMarmitonHosts), _defineProperty(_MARMITON_HOSTS, Stages.STAGING, commonMarmitonHosts), _defineProperty(_MARMITON_HOSTS, Stages.PRODUCTION, commonMarmitonHosts), _defineProperty(_MARMITON_HOSTS, Stages.PRODUCTION_LEGACY, commonMarmitonHosts), _MARMITON_HOSTS);