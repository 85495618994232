import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { DependencyScope, FieldType } from "../../../../types/ContentType/fields";
export var sizeNumberField = {
  type: FieldType.Number,
  name: Fields.SIZE,
  labelId: getFieldLabelId(Fields.SIZE),
  dependencyScope: DependencyScope.Partial,
  defaultValue: '',
  validation: Yup.string().ensure().required('form.required')
};