import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { DependencyScope, FieldType } from "../../../../types/ContentType/fields";
export var editorInChiefLinkedContentField = {
  type: FieldType.LinkedContent,
  name: Fields.EDITOR_IN_CHIEF,
  labelId: getFieldLabelId(Fields.EDITOR_IN_CHIEF),
  dependencyScope: DependencyScope.Partial,
  defaultValue: '',
  validation: Yup.string().required('form.required')
};