export var InlineToolbarTab;

(function (InlineToolbarTab) {
  InlineToolbarTab["BOLD"] = "bold";
  InlineToolbarTab["ITALIC"] = "italic";
  InlineToolbarTab["UNDERLINE"] = "underline";
  InlineToolbarTab["SUPERSCRIPT"] = "superscript";
  InlineToolbarTab["INTERNAL_LINK"] = "internalLink";
  InlineToolbarTab["EXTERNAL_LINK"] = "externalLink";
  InlineToolbarTab["AFFILIATION_LINK"] = "affiliationLink";
  InlineToolbarTab["RESET"] = "reset";
  InlineToolbarTab["HIGHLIGHT"] = "highlight";
})(InlineToolbarTab || (InlineToolbarTab = {}));

export var inlineToolbarTabs = [InlineToolbarTab.BOLD, InlineToolbarTab.ITALIC, InlineToolbarTab.UNDERLINE, InlineToolbarTab.SUPERSCRIPT, InlineToolbarTab.INTERNAL_LINK, InlineToolbarTab.EXTERNAL_LINK, InlineToolbarTab.AFFILIATION_LINK, InlineToolbarTab.RESET, InlineToolbarTab.HIGHLIGHT];