import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { GroupFields } from "../../../FieldNames";
import { descriptionTagTextField, keywordsTagTextField, titleTagTextField } from "../../../common/fieldDefinitions/simpleText";
import { getGroupLabelId } from "../../../../helpers";
import { FieldType } from "../../../../types";
export var seoGroupFields = function seoGroupFields() {
  return {
    type: FieldType.Group,
    name: GroupFields.SEO,
    labelId: getGroupLabelId(GroupFields.SEO),
    fields: [_objectSpread(_objectSpread({}, titleTagTextField), {}, {
      validation: titleTagTextField.validation.required('form.required')
    }), _objectSpread(_objectSpread({}, descriptionTagTextField), {}, {
      validation: descriptionTagTextField.validation.required('form.required')
    }), keywordsTagTextField]
  };
};