export var DoctissimoPageConfigName;

(function (DoctissimoPageConfigName) {
  DoctissimoPageConfigName["ContentTypeHome"] = "DOCTISSIMO_FR_CONTENT_TYPE_HOME_PAGE";
  DoctissimoPageConfigName["Feed"] = "DOCTISSIMO_FR_FEED";
  DoctissimoPageConfigName["Generic"] = "DOCTISSIMO_FR_ARTICLE_PAGE";
  DoctissimoPageConfigName["Header"] = "DOCTISSIMO_FR_HEADER";
  DoctissimoPageConfigName["Home"] = "DOCTISSIMO_FR_HOME_PAGE";
  DoctissimoPageConfigName["Newsletter"] = "DOCTISSIMO_FR_NEWSLETTER_PAGE";
  DoctissimoPageConfigName["Person"] = "DOCTISSIMO_FR_PERSON_PAGE";
  DoctissimoPageConfigName["PersonList"] = "DOCTISSIMO_FR_PERSON_LIST_PAGE";
  DoctissimoPageConfigName["PregnancyAppAppointmentPage"] = "DOCTISSIMO_FR_PREGNANCY_APP_APPOINTMENT_PAGE";
  DoctissimoPageConfigName["PregnancyAppWeekPage"] = "DOCTISSIMO_FR_PREGNANCY_APP_WEEK_PAGE";
  DoctissimoPageConfigName["SectionHome"] = "DOCTISSIMO_FR_SECTION_HOME_PAGE";
  DoctissimoPageConfigName["SlideshowESI"] = "DOCTISSIMO_FR_SLIDESHOW_ESI";
  DoctissimoPageConfigName["SlideshowFullPage"] = "DOCTISSIMO_FR_SLIDESHOW_FULLPAGE";
  DoctissimoPageConfigName["SlideshowV2"] = "DOCTISSIMO_FR_SLIDESHOW_V2";
  DoctissimoPageConfigName["SlideshowV2ESI"] = "DOCTISSIMO_FR_SLIDESHOW_V2_ESI";
})(DoctissimoPageConfigName || (DoctissimoPageConfigName = {}));