import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _objectSpread2, _objectSpread3;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { coverImagesSchema } from '@uno/common-shared';
import { Fields } from "../../../../../../config";
import { PartialContentPageWithContentTypeAndMainSectionChunkSchema, PartialPageChunkSchema } from "../../../../../page";
import { isSponsoredSchema } from "../../../../../sponsored";
import { highlightedVideoSchema } from "../../../../../video";
import { ARTICLE_CHUNK_CONTENT_TYPE_NAMES_AUFEMININ as ARTICLE_CHUNK_CONTENT_TYPE_NAMES } from "../../../../../../types";
var ArticleChunkSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, PartialContentPageWithContentTypeAndMainSectionChunkSchema.properties), {}, (_objectSpread2 = {
    // Override _contentTypeName from PartialContentPageWithContentTypeAndMainSectionChunkSchema to be more accurate
    _contentTypeName: {
      "enum": ARTICLE_CHUNK_CONTENT_TYPE_NAMES
    }
  }, _defineProperty(_objectSpread2, Fields.MODIFICATION_DATE, {
    type: ['string', 'null']
  }), _defineProperty(_objectSpread2, Fields.IS_GOOGLE_NEWS, {
    type: 'boolean'
  }), _defineProperty(_objectSpread2, Fields.DISPLAY_ON_HOMEPAGE, {
    type: 'boolean'
  }), _defineProperty(_objectSpread2, Fields.TITLE, {
    type: 'string'
  }), _defineProperty(_objectSpread2, Fields.COVER_IMAGES, coverImagesSchema), _defineProperty(_objectSpread2, Fields.TAG_SEO, {
    type: 'array',
    items: {
      type: 'string'
    }
  }), _defineProperty(_objectSpread2, Fields.TAG_PEOPLE, {
    type: 'array',
    items: {
      type: 'string'
    }
  }), _defineProperty(_objectSpread2, Fields.TAG_OPS, {
    type: 'array',
    items: {
      type: 'string'
    }
  }), _defineProperty(_objectSpread2, Fields.TAG_NEWS, {
    type: 'array',
    items: {
      type: 'string'
    }
  }), _defineProperty(_objectSpread2, Fields.HIGHLIGHTED_VIDEO, highlightedVideoSchema), _defineProperty(_objectSpread2, Fields.SPONSO, isSponsoredSchema), _objectSpread2)),
  required: [].concat(_toConsumableArray(PartialContentPageWithContentTypeAndMainSectionChunkSchema.required), [Fields.IS_GOOGLE_NEWS, Fields.DISPLAY_ON_HOMEPAGE, Fields.TITLE, Fields.COVER_IMAGES, Fields.TAG_SEO, Fields.TAG_PEOPLE, Fields.TAG_OPS, Fields.TAG_NEWS, Fields.HIGHLIGHTED_VIDEO])
};
var GenerateUrlArticleChunkSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, PartialPageChunkSchema.properties), {}, (_objectSpread3 = {
    _contentTypeName: {
      "enum": ARTICLE_CHUNK_CONTENT_TYPE_NAMES
    }
  }, _defineProperty(_objectSpread3, Fields.MAIN_SECTION, {
    type: 'array',
    items: {
      type: 'string'
    },
    minItems: 1
  }), _defineProperty(_objectSpread3, Fields.TITLE, {
    type: 'string'
  }), _objectSpread3)),
  required: [].concat(_toConsumableArray(PartialPageChunkSchema.required), ['_contentTypeName', Fields.MAIN_SECTION, Fields.TITLE])
};
export { ARTICLE_CHUNK_CONTENT_TYPE_NAMES as ARTICLE_CHUNK_CONTENT_TYPE_NAMES_AUFEMININ, ArticleChunkSchema as ArticleChunkSchemaAufeminin, GenerateUrlArticleChunkSchema as GenerateUrlArticleChunkSchemaAufeminin };