import { createStyles } from '@material-ui/core';
import { colorUsage } from 'stylesheet';
var MuiCheckboxOverride = createStyles({
  root: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    color: colorUsage.checkbox.selected,
    '&$checked': {
      color: colorUsage.checkbox.selected
    }
  }
});
export default MuiCheckboxOverride;