import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Sites } from '@uno/common-shared';
import { HOST_IDS, PAGE_CONFIGS_NAMES, PageType } from "../config";
import { PageStatus } from "../types";
import { PublicationStatusSchema } from "../types/services/navigation";
export var UrlConstructorSchema = {
  type: 'object',
  properties: {
    _siteId: {
      "enum": Object.values(Sites)
    },
    _hostId: {
      "enum": Object.values(HOST_IDS)
    },
    _path: {
      type: 'string'
    }
  },
  required: ['_siteId', '_hostId', '_path'],
  additionalProperties: false
};
export var UrlMetadataSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, UrlConstructorSchema.properties), {}, {
    _pageConfigName: {
      "enum": PAGE_CONFIGS_NAMES
    },
    _pageType: {
      "enum": Object.values(PageType)
    },
    _publicationStatus: PublicationStatusSchema,
    pageStatus: {
      "enum": Object.values(PageStatus)
    }
  }),
  required: [].concat(_toConsumableArray(UrlConstructorSchema.required), ['_pageConfigName', '_pageType', '_publicationStatus', 'pageStatus']),
  additionalProperties: false
};