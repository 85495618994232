import _slicedToArray from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray";
export var flattenMessages = function flattenMessages(nestedMessages) {
  var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return Object.entries(nestedMessages).reduce(function (messages, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    var prefixedKey = prefix !== '' ? "".concat(prefix, ".").concat(key) : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};
export default flattenMessages;