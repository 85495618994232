import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* eslint-disable max-lines */
import sortBy from 'lodash/sortBy';
import { GenericBlockType, GenericInlineDataType } from "../types";
var MAX_LINKS_PER_RICH_TEXT = 10;

var normalizeText = function normalizeText(text) {
  return text.toLowerCase().replace(/\u0153/g, 'o') // otherwise 1 char becomes 2 chars (oe) and it leads to positionning troubles in RichText
  .normalize('NFD') // "é" become "e'" for instance, "à" becomes "a`", ...
  .replace(/(?:[\^`\xA8\xAF\xB4\xB7\xB8\u02B0-\u034E\u0350-\u0357\u035D-\u0362\u0374\u0375\u037A\u0384\u0385\u0483-\u0487\u0559\u0591-\u05A1\u05A3-\u05BD\u05BF\u05C1\u05C2\u05C4\u064B-\u0652\u0657\u0658\u06DF\u06E0\u06E5\u06E6\u06EA-\u06EC\u0730-\u074A\u07A6-\u07B0\u07EB-\u07F5\u0818\u0819\u08E3-\u08FE\u093C\u094D\u0951-\u0954\u0971\u09BC\u09CD\u0A3C\u0A4D\u0ABC\u0ACD\u0AFD-\u0AFF\u0B3C\u0B4D\u0B55\u0BCD\u0C4D\u0CBC\u0CCD\u0D3B\u0D3C\u0D4D\u0DCA\u0E47-\u0E4C\u0E4E\u0EBA\u0EC8-\u0ECC\u0F18\u0F19\u0F35\u0F37\u0F39\u0F3E\u0F3F\u0F82-\u0F84\u0F86\u0F87\u0FC6\u1037\u1039\u103A\u1063\u1064\u1069-\u106D\u1087-\u108D\u108F\u109A\u109B\u135D-\u135F\u17C9-\u17D3\u17DD\u1939-\u193B\u1A75-\u1A7C\u1A7F\u1AB0-\u1ABD\u1B34\u1B44\u1B6B-\u1B73\u1BAA\u1BAB\u1C36\u1C37\u1C78-\u1C7D\u1CD0-\u1CE8\u1CED\u1CF4\u1CF7-\u1CF9\u1D2C-\u1D6A\u1DC4-\u1DCF\u1DF5-\u1DF9\u1DFD-\u1DFF\u1FBD\u1FBF-\u1FC1\u1FCD-\u1FCF\u1FDD-\u1FDF\u1FED-\u1FEF\u1FFD\u1FFE\u2CEF-\u2CF1\u2E2F\u302A-\u302F\u3099-\u309C\u30FC\uA66F\uA67C\uA67D\uA67F\uA69C\uA69D\uA6F0\uA6F1\uA700-\uA721\uA788-\uA78A\uA7F8\uA7F9\uA8C4\uA8E0-\uA8F1\uA92B-\uA92E\uA953\uA9B3\uA9C0\uA9E5\uAA7B-\uAA7D\uAABF-\uAAC2\uAAF6\uAB5B-\uAB5F\uAB69-\uAB6B\uABEC\uABED\uFB1E\uFE20-\uFE2F\uFF3E\uFF40\uFF70\uFF9E\uFF9F\uFFE3]|\uD800\uDEE0|\uD802[\uDEE5\uDEE6]|\uD803[\uDD22-\uDD27\uDF46-\uDF50]|\uD804[\uDCB9\uDCBA\uDD33\uDD34\uDD73\uDDC0\uDDCA-\uDDCC\uDE35\uDE36\uDEE9\uDEEA\uDF3C\uDF4D\uDF66-\uDF6C\uDF70-\uDF74]|\uD805[\uDC42\uDC46\uDCC2\uDCC3\uDDBF\uDDC0\uDE3F\uDEB6\uDEB7\uDF2B]|\uD806[\uDC39\uDC3A\uDD3D\uDD3E\uDD43\uDDE0\uDE34\uDE47\uDE99]|\uD807[\uDC3F\uDD42\uDD44\uDD45\uDD97]|\uD81A[\uDEF0-\uDEF4\uDF30-\uDF36]|\uD81B[\uDF8F-\uDF9F\uDFF0\uDFF1]|\uD834[\uDD67-\uDD69\uDD6D-\uDD72\uDD7B-\uDD82\uDD85-\uDD8B\uDDAA-\uDDAD]|\uD838[\uDD30-\uDD36\uDEEC-\uDEEF]|\uD83A[\uDCD0-\uDCD6\uDD44-\uDD46\uDD48-\uDD4A])/g, '');
};

var isLinkDataRange = function isLinkDataRange(dataRange) {
  return [GenericInlineDataType.AffiliationLink, GenericInlineDataType.ExternalLink, GenericInlineDataType.InternalLink].includes(dataRange.type);
};

var countLinksInDataRanges = function countLinksInDataRanges(dataRanges) {
  return dataRanges.reduce(function (nbLinks, dataRange) {
    return isLinkDataRange(dataRange) ? ++nbLinks : nbLinks;
  }, 0);
};

var countLinks = function countLinks(richText) {
  return richText.blocks.reduce(function (nbLinks, block) {
    switch (block.type) {
      case GenericBlockType.Blockquote:
      case GenericBlockType.Unstyled:
        return nbLinks + countLinksInDataRanges(block.inlineDataRanges);

      case GenericBlockType.List:
        return nbLinks + countLinksInDataRanges(block.items.flatMap(function (item) {
          return item.inlineDataRanges;
        }));

      default:
        return nbLinks;
    }
  }, 0);
};

var isLinkAtPosition = function isLinkAtPosition(dataRanges, position) {
  return dataRanges.some(function (dataRange) {
    return isLinkDataRange(dataRange) && position >= dataRange.offset && position <= dataRange.length;
  });
};

var buildSearchRegExp = function buildSearchRegExp(text) {
  return new RegExp(('\\b' + text.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&') // escape regexp special chars
  .replace(/([^a-z0-9'])/gi, '\\b$1\\b') + // fix word boundaries to match exact text
  '\\b').replace(/\\b\\b/gi, ''));
};

var getAddLinkPosition = function getAddLinkPosition(block, addLink) {
  var normalizedAddLinkKeyword = normalizeText(addLink.title);
  var normalizedBlockText = normalizeText(block.text);
  var addLinkPosition = normalizedBlockText.search(buildSearchRegExp(normalizedAddLinkKeyword));

  if (addLinkPosition < 0 || isLinkAtPosition(block.inlineDataRanges, addLinkPosition)) {
    return null;
  }

  return addLinkPosition;
};

var buildAddLinkDataRange = function buildAddLinkDataRange(addLink, position) {
  return {
    type: GenericInlineDataType.ExternalLink,
    offset: position,
    length: addLink.title.length,
    data: {
      attributes: {
        rel: '',
        target: ''
      },
      url: addLink.url
    }
  };
};

var insertAddLinkInLinkableBlock = function insertAddLinkInLinkableBlock(block, addLink) {
  var addLinkPosition = getAddLinkPosition(block, addLink);

  if (addLinkPosition === null) {
    return null;
  }

  var addLinkDataRange = buildAddLinkDataRange(addLink, addLinkPosition);
  return _objectSpread(_objectSpread({}, block), {}, {
    inlineDataRanges: [].concat(_toConsumableArray(block.inlineDataRanges), [addLinkDataRange])
  });
};

var insertAddLinkInListItemsOnce = function insertAddLinkInListItemsOnce(listItems, addLink) {
  var newListItems = [];
  var inserted = false;

  var _iterator = _createForOfIteratorHelper(listItems),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var listItem = _step.value;

      if (inserted) {
        newListItems.push(listItem);
        continue;
      }

      var newListItem = insertAddLinkInLinkableBlock(listItem, addLink);

      if (newListItem) {
        inserted = true;
        newListItems.push(newListItem);
      } else {
        newListItems.push(listItem);
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return inserted ? newListItems : null;
};

var insertAddLinkInBlock = function insertAddLinkInBlock(block, addLink) {
  if (block.type === GenericBlockType.List) {
    var items = insertAddLinkInListItemsOnce(block.items, addLink);

    if (items === null) {
      return null;
    }

    return _objectSpread(_objectSpread({}, block), {}, {
      items: items
    });
  }

  switch (block.type) {
    case GenericBlockType.Blockquote:
    case GenericBlockType.Unstyled:
      return insertAddLinkInLinkableBlock(block, addLink);

    default:
      return null;
  }
};

var insertAddLinkInBlocksOnce = function insertAddLinkInBlocksOnce(blocks, addLink) {
  var newBlocks = [];
  var inserted = false;

  var _iterator2 = _createForOfIteratorHelper(blocks),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var block = _step2.value;

      if (inserted) {
        newBlocks.push(block);
        continue;
      }

      var newBlock = insertAddLinkInBlock(block, addLink);

      if (newBlock !== null) {
        inserted = true;
        newBlocks.push(newBlock);
      } else {
        newBlocks.push(block);
      }
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }

  return newBlocks;
};

var insertAddLinkInRichTextReducer = function insertAddLinkInRichTextReducer(_ref, addLink) {
  var _ref2 = _slicedToArray(_ref, 2),
      richText = _ref2[0],
      linksNumber = _ref2[1];

  if (linksNumber >= MAX_LINKS_PER_RICH_TEXT) {
    return [richText, linksNumber];
  }

  return [_objectSpread(_objectSpread({}, richText), {}, {
    blocks: insertAddLinkInBlocksOnce(richText.blocks, addLink)
  }), ++linksNumber];
};

var addAddLinksToRichText = function addAddLinksToRichText(initialRichText, addLinks) {
  var initialLinksNumber = countLinks(initialRichText); // Early return only for performance reason

  if (initialLinksNumber >= MAX_LINKS_PER_RICH_TEXT) {
    return initialRichText;
  }

  var sortedAddLinks = sortBy(addLinks, ['linkOrder']);

  var _sortedAddLinks$reduc = sortedAddLinks.reduce(insertAddLinkInRichTextReducer, [initialRichText, initialLinksNumber]),
      _sortedAddLinks$reduc2 = _slicedToArray(_sortedAddLinks$reduc, 1),
      newRichText = _sortedAddLinks$reduc2[0];

  return newRichText;
};

export default addAddLinksToRichText;