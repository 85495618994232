import { createStyles } from '@material-ui/core';
import { colorUsage, getSpacing } from 'stylesheet';
var MuiSmallIconButtonOverride = createStyles({
  root: {
    color: colorUsage.textColor,
    padding: getSpacing(0.5),
    '&:hover': {
      backgroundColor: colorUsage.transparentBackground
    }
  }
});
export default MuiSmallIconButtonOverride;