import { AtomicBlockType as DraftjsAtomicBlockType } from '@uno/common-cms';
import { addAtomicBlock } from '../../util';
export var transformEmbedToDraftjsAndAppend = function transformEmbedToDraftjsAndAppend(_ref, rawDraftContentState) {
  var embedData = _ref.embedData;
  return addAtomicBlock(rawDraftContentState, DraftjsAtomicBlockType.EMBED, {
    blockData: {
      openModal: false
    },
    entityData: {
      embedData: embedData
    }
  }, 'IMMUTABLE');
};