import _defineProperty from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { blocksConfig } from '../../config';
export var transformHighlightedToDraftjsAndAppend = function transformHighlightedToDraftjsAndAppend(genericBlock, rawDraftContentState) {
  genericBlock.blocks.forEach(function (currentGenericBlock) {
    var _rawDraftContentState;

    var blockConfig = blocksConfig[currentGenericBlock.type];

    if (blockConfig === undefined) {
      return rawDraftContentState;
    }

    var start = rawDraftContentState.blocks.length;
    blockConfig.transformToDraftjsAndAppend(currentGenericBlock, rawDraftContentState);
    var end = rawDraftContentState.blocks.length;
    var blocks = [];

    for (var i = 0; i < end - start; i++) {
      var highlightedBlock = rawDraftContentState.blocks.pop();

      if (highlightedBlock !== undefined) {
        highlightedBlock.data = _objectSpread(_objectSpread({}, highlightedBlock.data), {}, {
          highlighted: true
        });
        blocks.unshift(highlightedBlock);
      }
    }

    (_rawDraftContentState = rawDraftContentState.blocks).push.apply(_rawDraftContentState, blocks);
  });
  return rawDraftContentState;
};