import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var mainSectionField = {
  type: FieldType.Taxonomy,
  name: Fields.MAIN_SECTION,
  labelId: getFieldLabelId(Fields.MAIN_SECTION),
  defaultValue: [],
  multiple: false,
  validation: Yup.array()
};