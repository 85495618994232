import { fontSize } from 'stylesheet';
export var StyleType;

(function (StyleType) {
  StyleType["BOLD"] = "BOLD";
  StyleType["ITALIC"] = "ITALIC";
  StyleType["UNDERLINE"] = "UNDERLINE";
  StyleType["SUPERSCRIPT"] = "SUPERSCRIPT";
})(StyleType || (StyleType = {}));

export var styleMap = {
  SUPERSCRIPT: {
    verticalAlign: 'super',
    fontSize: "".concat(fontSize.smaller)
  }
};