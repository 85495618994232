import _defineProperty from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { createStyles } from '@material-ui/core';
import { borderRadius, colorUsage, fontSize, getSpacing, lineHeight } from 'stylesheet';
var RootOverrides = createStyles({
  root: {
    width: '700px',
    borderRadius: borderRadius.medium,
    boxShadow: 'none',
    fontSize: fontSize.normal,
    flexWrap: 'unset',
    lineHeight: lineHeight.normal
  }
});
var NotistackOverride = createStyles({
  variantSuccess: _objectSpread(_objectSpread({}, RootOverrides.root), {}, {
    // * Info: `!important` is required because of a bug in the library: https://github.com/iamhosseindhv/notistack/issues/305
    backgroundColor: "".concat(colorUsage.toaster.success.background, " !important"),
    color: "".concat(colorUsage.toaster.success.foreground, " !important")
  }),
  variantError: _objectSpread(_objectSpread({}, RootOverrides.root), {}, {
    background: "".concat(colorUsage.toaster.error.background, " !important"),
    color: "".concat(colorUsage.toaster.error.foreground, " !important"),
    whiteSpace: 'pre',
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: '222px',
    //LineHeight (21) * x lines to show (10) + padding-top (6) + padding-bottom (6)
    alignItems: 'flex-start',
    '& > div': {
      alignItems: 'flex-start'
    },
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: getSpacing(1)
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: borderRadius.small,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, 0.5)'
    }
  })
});
export default NotistackOverride;