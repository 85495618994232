import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { DependencyScope, FieldType } from "../../../../types/ContentType/fields";
export var medicalExpertLinkedContentField = {
  type: FieldType.LinkedContent,
  name: Fields.MEDICAL_EXPERT,
  labelId: getFieldLabelId(Fields.MEDICAL_EXPERT),
  dependencyScope: DependencyScope.Partial,
  defaultValue: '',
  validation: Yup.string().required('form.required')
};