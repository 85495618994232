import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Sites } from '@uno/common-shared';
import { BaseContentTypeName, Fields, GroupFields } from "../../FieldNames";
import { sendingDateField } from "../../common/fieldDefinitions/date";
import { metaUrlField } from "../../common/fieldDefinitions/metaUrl";
import { bodyRichTextField } from "../../common/fieldDefinitions/richText";
import { templateSelectField } from "../../common/fieldDefinitions/select";
import { titleTextField } from "../../common/fieldDefinitions/simpleText";
import { isCustomTitleSwitchField } from "../../common/fieldDefinitions/switch";
import { getGroupLabelId, getOptionLabelId } from "../../../helpers";
import { BlockType, ContentTypeIconName, DependencyScope, FieldType } from "../../../types/ContentType";
import { DoctissimoContentTypeName } from "./ContentTypeName";
var baseLabelId = BaseContentTypeName.Newsletter;
var TemplateOptions;

(function (TemplateOptions) {
  TemplateOptions["Weekly"] = "weekly";
  TemplateOptions["Beauty"] = "beauty";
  TemplateOptions["Sexo"] = "sexo";
  TemplateOptions["Quiz"] = "quiz";
  TemplateOptions["Family"] = "family";
  TemplateOptions["Partnership"] = "partnership";
  TemplateOptions["Covid"] = "covid";
  TemplateOptions["Senior"] = "senior";
})(TemplateOptions || (TemplateOptions = {}));

var fields = [{
  type: FieldType.Group,
  name: GroupFields.NEWSLETTER,
  labelId: getGroupLabelId(GroupFields.NEWSLETTER),
  fields: [titleTextField, isCustomTitleSwitchField, _objectSpread(_objectSpread({}, templateSelectField), {}, {
    options: Object.values(TemplateOptions).map(function (option) {
      return {
        option: option,
        labelId: getOptionLabelId(baseLabelId, Fields.TEMPLATE, option)
      };
    })
  }), sendingDateField, _objectSpread(_objectSpread({}, bodyRichTextField), {}, {
    enabledBlocks: [BlockType.H2, BlockType.H3, BlockType.UL],
    internalLinkDependencyScope: DependencyScope.Partial
  })]
}, {
  type: FieldType.Group,
  name: GroupFields.PROPERTIES,
  labelId: getGroupLabelId(GroupFields.PROPERTIES),
  fields: [metaUrlField]
}];
export var newsletter = {
  site: Sites.DOCTISSIMO_FR,
  name: DoctissimoContentTypeName.Newsletter,
  labelId: 'content-type.newsletter.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  getLabel: function getLabel(contentData) {
    return (// TODO: See to fix "Type instantiation is excessively deep and possibly infinite"
      // @ts-ignore Sorry too many fields
      contentData.title !== undefined ? contentData.title : ''
    );
  },
  getPreviewImage: function getPreviewImage() {
    return null;
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  getMainSectionId: function getMainSectionId() {
    return '';
  },
  getManualModificationDate: function getManualModificationDate() {
    return undefined;
  },
  fields: fields,
  partialDependencyFields: [Fields.TITLE],
  isLinkable: false,
  isCreatable: true
};