import * as Yup from 'yup';
import { Sites } from '@uno/common-shared';
import { BaseContentTypeName, Fields, GroupFields } from "../../FieldNames";
import { createdMetaDateField, modifiedMetaDateField, publicationMetaDateField } from "../../common/fieldDefinitions/metaDate";
import { legacyBOUrlReadOnlyField, legacyIdReadOnlyField, legacyPathLabelReadOnlyField } from "../../common/fieldDefinitions/readOnly";
import { getFieldLabelId, getGroupLabelId } from "../../../helpers";
import { FieldType } from "../../../types";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { MarmitonContentTypeName } from "./ContentTypeName";
var baseLabelId = BaseContentTypeName.Unit;
var fields = [{
  type: FieldType.Group,
  name: GroupFields.UNIT,
  labelId: getGroupLabelId(GroupFields.UNIT),
  fields: [{
    type: FieldType.SimpleText,
    name: Fields.NAME,
    labelId: getFieldLabelId(Fields.NAME),
    defaultValue: '',
    validation: Yup.string().required('form.required')
  }, {
    type: FieldType.SimpleText,
    name: Fields.PLURAL_NAME,
    labelId: getFieldLabelId(Fields.PLURAL_NAME),
    defaultValue: '',
    validation: Yup.string()
  }, {
    type: FieldType.SimpleText,
    name: Fields.SHORT_UNIT_NAME,
    labelId: getFieldLabelId(Fields.SHORT_UNIT_NAME),
    defaultValue: '',
    validation: Yup.string().required('form.required')
  }]
}, {
  type: FieldType.Group,
  name: GroupFields.PROPERTIES,
  labelId: getGroupLabelId(GroupFields.PROPERTIES),
  fields: [{
    type: FieldType.Group,
    name: GroupFields.DATES,
    labelId: getGroupLabelId(GroupFields.DATES),
    fields: [publicationMetaDateField, createdMetaDateField, modifiedMetaDateField]
  }, {
    type: FieldType.Group,
    name: GroupFields.LEGACY,
    labelId: getGroupLabelId(GroupFields.LEGACY),
    fields: [legacyIdReadOnlyField, legacyPathLabelReadOnlyField, legacyBOUrlReadOnlyField]
  }]
}];
export var unit = {
  site: Sites.MARMITON_ORG,
  name: MarmitonContentTypeName.Unit,
  labelId: 'content-type.unit.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  getLabel: function getLabel(contentData) {
    return contentData.name !== undefined ? contentData.name : '';
  },
  getPreviewImage: function getPreviewImage() {
    return '';
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  getMainSectionId: function getMainSectionId() {
    return '';
  },
  getManualModificationDate: function getManualModificationDate() {
    return undefined;
  },
  fields: fields,
  partialDependencyFields: [Fields.NAME],
  isLinkable: false,
  isCreatable: true
};