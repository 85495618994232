import React from "react";
var __jsx = React.createElement;
import { Backdrop, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { PageBackdropOverride } from 'theme/overrides';
var PageBackdrop = withStyles(PageBackdropOverride)(Backdrop);

var PageLoader = function PageLoader(props) {
  return __jsx(PageBackdrop, props, __jsx(CircularProgress, null));
};

export default PageLoader;