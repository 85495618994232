/* eslint-disable max-lines */
export var Fields = {
  ACTIVE: 'active',
  AFFILIATION_CONTENTS: 'affiliationContents',
  ALT_SECTION: 'altSection',
  ANSWER: 'answer',
  APPOINTMENTS: 'appointments',
  AUTHOR_FIRST_NAME: 'authorFirstName',
  AUTHOR_ID: 'authorId',
  AUTHOR_LAST_NAME: 'authorLastName',
  AUTHORS: 'authors',
  BODY: 'body',
  CARTOUCHE: 'cartouche',
  CHAPO: 'chapo',
  CLONE_ID: 'cloneId',
  CLONE_TITLE: 'cloneTitle',
  CLONE_URL: 'cloneUrl',
  CONTENT: 'content',
  CONTENT_TYPE: 'contentType',
  COVER_IMAGES: 'coverImages',
  CREATED: 'created',
  DESCRIPTION: 'description',
  DESCRIPTION_TAG: 'descriptionTag',
  DISPLAY_ON_HOMEPAGE: 'displayOnHomepage',
  EDITO_HIGHLIGHT: 'editoHighlight',
  EDITO_HIGHLIGHT_TITLE: 'editoHighlightTitle',
  EDITO_HIGHLIGHT_TOP_CONTENT: 'editoHighlightTopContent',
  EDITOR_IN_CHIEF: 'editorInChief',
  EMAIL: 'email',
  END_DATE: 'endDate',
  EXPERT_ID: 'expertId',
  EXPERTS: 'experts',
  FAMILY_MACRO_SECTION_TOP_CONTENT: 'familyMacroSectionTopContent',
  FAMILY_MAIN_SECTIONS: 'familyMainSections',
  FAMILY_TITLE: 'familyTitle',
  FAQ: 'faq',
  FAQ_INDEX: 'faqIndex',
  FIRST_NAME: 'firstName',
  HAS_PAGE: 'hasPage',
  HEAD: 'head',
  HEALTH_MACRO_SECTION_TOP_CONTENT: 'healthMacroSectionTopContent',
  HEALTH_MAIN_SECTIONS: 'healthMainSections',
  HEALTH_TITLE: 'healthTitle',
  HIGHLIGHTED_VIDEO: 'highlightedVideo',
  IMAGE: 'image',
  IMAGE_FOETUS: 'imageFoetus',
  IMAGE_FRUIT: 'imageFruit',
  IMAGE_PANORAMIC: 'imagePanoramic',
  IMAGE_SQUARE: 'imageSquare',
  INDEX: 'index',
  IS_CHIEF_EDITOR: 'isChiefEditor',
  IS_CUSTOM_TITLE: 'isCustomTitle',
  IS_EGG: 'isEgg',
  IS_FISH: 'isFish',
  IS_GLUTEN: 'isGluten',
  IS_GOOGLE_NEWS: 'isGoogleNews',
  IS_LACTOSE: 'isLactose',
  IS_NUTS: 'isNuts',
  IS_PORK: 'isPork',
  IS_SEO_CONTENT: 'isSeoContent',
  IS_VEGAN: 'isVegan',
  IS_VEGETARIAN: 'isVegetarian',
  JOB: 'job',
  JOB_SUB_TITLE: 'jobSubTitle',
  JOB_TITLE: 'jobTitle',
  KEYWORD: 'keyword',
  KEYWORDS_TAG: 'keywordsTag',
  LAST_NAME: 'lastName',
  LEGACY_BO_URL: 'legacyBoUrl',
  LEGACY_ID: 'legacyId',
  LEGACY_PATH_LABEL: 'legacyPathLabel',
  LEGEND: 'legend',
  LINK_BODY: 'linkBody',
  LINKED_CONTENT_ID: 'linkedContentId',
  LINKED_CONTENTS: 'linkedContents',
  MACRO_SECTION_1_MAIN_SECTION: 'macroSection1MainSection',
  MACRO_SECTION_1_TOP_CONTENT: 'macroSection1TopContent',
  MACRO_SECTION_2_MAIN_SECTION: 'macroSection2MainSection',
  MACRO_SECTION_2_TOP_CONTENT: 'macroSection2TopContent',
  MACRO_SECTION_3_MAIN_SECTION: 'macroSection3MainSection',
  MACRO_SECTION_3_TOP_CONTENT: 'macroSection3TopContent',
  MACRO_SECTION_4_MAIN_SECTION: 'macroSection4MainSection',
  MACRO_SECTION_4_TOP_CONTENT: 'macroSection4TopContent',
  MACRO_SECTION_5_MAIN_SECTION: 'macroSection5MainSection',
  MACRO_SECTION_5_TOP_CONTENT: 'macroSection5TopContent',
  MAIN_SECTION: 'mainSection',
  MEDICAL_EXPERT: 'medicalExpert',
  MEDICAL_VALIDATION_DATE: 'medicalValidationDate',
  MEDICAL_VALIDATOR_ID: 'medicalValidatorId',
  MODIFICATION_DATE: 'modificationDate',
  MODIFIED: 'modified',
  MOST_VIEWED: 'mostViewed',
  MULTI_PREGNANCY_ARTICLE_HEALTH: 'multiPregnancyArticleHealth',
  MULTI_PREGNANCY_ARTICLE_WELLNESS: 'multiPregnancyArticleWellness',
  NAME: 'name',
  OG_DESCRIPTION: 'ogDescription',
  OG_TITLE: 'ogTitle',
  OTHER_GROUP_OF_CONTENTS: 'otherGroupOfContents',
  PARAGRAPH: 'paragraph',
  PARTNER_CONTENTS: 'partnerContents',
  PARTNER_IMAGE: 'partnerImage',
  PERSON_TYPE: 'personType',
  PHOTOS: 'photos',
  PLURAL_NAME: 'pluralName',
  PREGNANCY_ARTICLE_HEALTH: 'pregnancyArticleHealth',
  PREGNANCY_ARTICLE_WELLNESS: 'pregnancyArticleWellness',
  PREGNANCY_PERIOD: 'period',
  PUBLICATION_TIMESTAMP: 'publicationTimestamp',
  QUESTION: 'question',
  RECIPE: 'recipe',
  RECIPE_ID: 'recipeId',
  SCORE: 'score',
  SECTIONS: 'sections',
  SEE_MORE: 'seeMore',
  SEMANTIC_SCORE: 'ytgInput',
  SENDING_DATE: 'sendingDate',
  SENIOR_MACRO_SECTION_TOP_CONTENT: 'seniorMacroSectionTopContent',
  SENIOR_MAIN_SECTIONS: 'seniorMainSections',
  SENIOR_TITLE: 'seniorTitle',
  SEO_REVIEW_TODO: 'seoReviewTodo',
  SHORT_TITLE: 'shortTitle',
  SHORT_UNIT_NAME: 'shortUnitName',
  SHOW_NUTRISCORE: 'showNutriscore',
  SIZE: 'size',
  SLIDER_BODY: 'sliderBody',
  SLIDES: 'slides',
  SOURCES: 'sources',
  SPONSO: 'sponso',
  SPOTLIGHT_MACRO_SECTION_TOP_CONTENT: 'spotlightMacroSectionTopContent',
  START_DATE: 'startDate',
  STATUS: 'status',
  TAG_NEWS: 'newsTag',
  TAG_OPS: 'opsTag',
  TAG_PEOPLE: 'peopleTag',
  TAG_SEO: 'seoTag',
  TEMPLATE: 'template',
  TITLE: 'title',
  TITLE_TAG: 'titleTag',
  TOP_ARTICLE: 'topArticle',
  TOP_ARTICLES: 'topArticles',
  TOP_VIDEO: 'topVideo',
  TOP_VIDEOS: 'topVideos',
  UNMISSABLE: 'unmissable',
  URL: 'url',
  URL_FACEBOOK: 'urlFacebook',
  URL_INSTAGRAM: 'urlInstagram',
  URL_LINKEDIN: 'urlLinkedIn',
  URL_PINTEREST: 'urlPinterest',
  URL_TIKTOK: 'urlTikTok',
  URL_TWITTER: 'urlTwitter',
  URL_WEBSITE: 'urlWebsite',
  URL_WIKIPEDIA: 'urlWikipedia',
  VISUAL: 'visual',
  WEEK: 'week',
  WEIGHT: 'weight',
  WELLNESS_MACRO_SECTION_TOP_CONTENT: 'wellnessMacroSectionTopContent',
  WELLNESS_MAIN_SECTIONS: 'wellnessMainSections',
  WELLNESS_TITLE: 'wellnessTitle'
};
export var GroupFields;

(function (GroupFields) {
  GroupFields["AFFILIATION"] = "affiliation";
  GroupFields["AFFILIATION_CONTENT"] = "affiliationContent";
  GroupFields["ALLERGENS"] = "allergens";
  GroupFields["APPOINTMENTS"] = "appointments";
  GroupFields["ARTICLE"] = "article";
  GroupFields["AUTHOR"] = "author";
  GroupFields["MACRO_SECTION_1"] = "macroSection1";
  GroupFields["MACRO_SECTION_2"] = "macroSection2";
  GroupFields["MACRO_SECTION_3"] = "macroSection3";
  GroupFields["MACRO_SECTION_4"] = "macroSection4";
  GroupFields["MACRO_SECTION_5"] = "macroSection5";
  GroupFields["CONTENT_TYPE_HOME"] = "contentTypeHome";
  GroupFields["CLONED_FROM"] = "clonedFrom";
  GroupFields["DATES"] = "dates";
  GroupFields["DESCRIPTION"] = "description";
  GroupFields["DICTIONARY"] = "dictionary";
  GroupFields["DIET"] = "diet";
  GroupFields["EDITO_HIGHLIGHT"] = "editoHighlight";
  GroupFields["ENCYCLOPEDIA"] = "encyclopedia";
  GroupFields["FAMILY"] = "family";
  GroupFields["FAQ"] = "faq";
  GroupFields["HEALTH"] = "health";
  GroupFields["HOME"] = "home";
  GroupFields["HTML_PAGE"] = "htmlPage";
  GroupFields["IMAGES"] = "images";
  GroupFields["INGREDIENT"] = "ingredient";
  GroupFields["LEGACY"] = "legacy";
  GroupFields["MEDICAL_VALIDATION"] = "medicalValidation";
  GroupFields["MOST_VIEWED"] = "mostViewed";
  GroupFields["MULTI_PREGNANCY_ARTICLES_HEALTH"] = "multiPregnancyArticlesHealth";
  GroupFields["MULTI_PREGNANCY_ARTICLES_WELLNESS"] = "multiPregnancyArticlesWellness";
  GroupFields["OUR_TEAMS"] = "ourTeams";
  GroupFields["NEWS"] = "news";
  GroupFields["NEWSLETTER"] = "newsletter";
  GroupFields["NETWORK"] = "network";
  GroupFields["NUTRISCORE"] = "nutriscore";
  GroupFields["PARAMETERS"] = "parameters";
  GroupFields["PARTNER"] = "partner";
  GroupFields["PARTNER_CONTENT"] = "partnerContent";
  GroupFields["PERSON"] = "person";
  GroupFields["PHOTOS"] = "photos";
  GroupFields["PREGNANCY_APP_APPOINTMENT"] = "pregnancyAppAppointment";
  GroupFields["PREGNANCY_APP_WEEK"] = "pregnancyAppWeek";
  GroupFields["PREGNANCY_ARTICLES_HEALTH"] = "pregnancyArticlesHealth";
  GroupFields["PREGNANCY_ARTICLES_WELLNESS"] = "pregnancyArticlesWellness";
  GroupFields["PROPERTIES"] = "properties";
  GroupFields["QUESTIONANSWER"] = "questionanswer";
  GroupFields["RECIPE"] = "recipe";
  GroupFields["SEASON"] = "season";
  GroupFields["SECTION_HOME"] = "sectionHome";
  GroupFields["SEE_MORE"] = "seeMore";
  GroupFields["SENIOR"] = "senior";
  GroupFields["SEO"] = "seo";
  GroupFields["SLIDE"] = "slide";
  GroupFields["SLIDESHOW"] = "slideshow";
  GroupFields["SOURCES"] = "sources";
  GroupFields["SPONSO"] = "sponso";
  GroupFields["SPOTLIGHT"] = "spotlight";
  GroupFields["TAXONOMY"] = "taxonomy";
  GroupFields["SEMANTICSCORE"] = "semanticScore";
  GroupFields["TAG_PAGE"] = "tagPage";
  GroupFields["TAG_PAGES"] = "tagPages";
  GroupFields["TAG"] = "tag";
  GroupFields["TEST"] = "test";
  GroupFields["TOP_ARTICLES"] = "topArticles";
  GroupFields["TOP_VIDEOS"] = "topVideos";
  GroupFields["TRENDING_SECTION"] = "trendingSection";
  GroupFields["UNIT"] = "unit";
  GroupFields["UTENSIL"] = "utensil";
  GroupFields["VIDEO"] = "video";
  GroupFields["VISUAL"] = "visual";
  GroupFields["WELLNESS"] = "wellness";
})(GroupFields || (GroupFields = {}));

export var BaseContentTypeName;

(function (BaseContentTypeName) {
  BaseContentTypeName["Article"] = "article";
  BaseContentTypeName["Author"] = "author";
  BaseContentTypeName["ContentTypeHome"] = "contentTypeHome";
  BaseContentTypeName["Dictionary"] = "dictionary";
  BaseContentTypeName["Encyclopedia"] = "encyclopedia";
  BaseContentTypeName["Footer"] = "footer";
  BaseContentTypeName["Home"] = "home";
  BaseContentTypeName["HtmlPage"] = "htmlPage";
  BaseContentTypeName["Ingredient"] = "ingredient";
  BaseContentTypeName["News"] = "news";
  BaseContentTypeName["Newsletter"] = "newsletter";
  BaseContentTypeName["Person"] = "person";
  BaseContentTypeName["PregnancyAppAppointment"] = "pregnancyAppAppointment";
  BaseContentTypeName["PregnancyAppWeek"] = "pregnancyAppWeek";
  BaseContentTypeName["SectionHome"] = "sectionHome";
  BaseContentTypeName["Slideshow"] = "slideshow";
  BaseContentTypeName["SlideshowV2"] = "slideshowV2";
  BaseContentTypeName["TagPage"] = "tagPage";
  BaseContentTypeName["Test"] = "test";
  BaseContentTypeName["Unit"] = "unit";
  BaseContentTypeName["Utensil"] = "utensil";
  BaseContentTypeName["Video"] = "video";
})(BaseContentTypeName || (BaseContentTypeName = {}));