import React from "react";
var __jsx = React.createElement;
import { useIntl } from 'react-intl';
import { BlockType } from '@uno/common-cms';
import { useCurrentSelectionStyleType } from 'components/common/RichTextEditor/hooks/useCurrentSelectionStyleType';
import { H2 } from 'theme/icons';
import { useHandleBlockToggleCallback } from '../../../shared/hooks/useHandleBlockToggleCallback';
import { HeaderTwoBlockToggle } from './InlineToolbarComponent.style';

var InlineToolbarComponent = function InlineToolbarComponent(_ref) {
  var editorState = _ref.editorState,
      setEditorState = _ref.setEditorState;
  var handleBlockToggle = useHandleBlockToggleCallback(BlockType.H2, setEditorState);
  var selectionType = useCurrentSelectionStyleType(editorState);
  var intl = useIntl();
  var label = intl.formatMessage({
    id: 'content.labels.inline-toolbar.h2'
  });
  return __jsx(HeaderTwoBlockToggle, {
    onMouseDown: handleBlockToggle(),
    toggled: BlockType.H2 === selectionType,
    title: label,
    "aria-label": label
  }, __jsx(H2, null));
};

export default InlineToolbarComponent;