import _slicedToArray from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray";
import { useAsyncFn } from 'react-use/lib';
import { findVideos } from 'services/networking/video';

var useFindVideos = function useFindVideos(site) {
  var _useAsyncFn = useAsyncFn(function (searchText) {
    return findVideos(site, searchText);
  }, [site]),
      _useAsyncFn2 = _slicedToArray(_useAsyncFn, 2),
      _useAsyncFn2$ = _useAsyncFn2[0],
      loading = _useAsyncFn2$.loading,
      error = _useAsyncFn2$.error,
      videos = _useAsyncFn2$.value,
      doFindVideos = _useAsyncFn2[1];

  return {
    loading: loading,
    error: error,
    videos: videos,
    doFindVideos: doFindVideos
  };
};

export default useFindVideos;