import { EditorState, Modifier } from 'draft-js';
import { getEntitySelection } from '../entity';
/**
 * Update an existing entity's data
 *
 * @param keepExistingSelection If `true`, the entity will be applied to the already existing range referring to the entity. If `false`, the entity will be re-applied to the current editor selection.
 */

var updateEntity = function updateEntity(editorState, entityKey, data, keepExistingSelection) {
  var contentState = editorState.getCurrentContent();
  var initialSelectionState = editorState.getSelection();
  var updatedContentState = contentState.mergeEntityData(entityKey, data);
  var newEditorState = EditorState.push(editorState, updatedContentState, 'apply-entity');
  var selectionState = newEditorState.getSelection();

  try {
    selectionState = keepExistingSelection ? getEntitySelection(editorState, entityKey) : newEditorState.getSelection();
  } catch (error) {
    console.error(error.message);
  }

  var contentStateWithAppliedEntity = Modifier.applyEntity(newEditorState.getCurrentContent(), selectionState, entityKey);
  var editorStateWithAppliedEntity = EditorState.push(newEditorState, contentStateWithAppliedEntity, 'apply-entity'); // force selection to force re-render

  return EditorState.forceSelection(editorStateWithAppliedEntity, initialSelectionState);
};

export default updateEntity;