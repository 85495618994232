import { createStyles } from '@material-ui/core';
import { borderRadius } from 'stylesheet';
var MuiOutlinedInputOverride = createStyles({
  root: {
    height: '40px',
    borderRadius: borderRadius.medium
  },
  multiline: {
    minHeight: '40px',
    height: 'auto'
  }
});
export default MuiOutlinedInputOverride;