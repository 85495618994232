import { GenericBlockType } from '@uno/common-cms';
import appendGenericBlock from '../../util/appendGenericBlock';
export var transformRecipeToGenericAndAppend = function transformRecipeToGenericAndAppend(block, genericBlocks, contentState) {
  var _ref = contentState.getEntity(block.getEntityAt(0)).getData(),
      recipeId = _ref.recipeId;

  var newBlock = {
    type: GenericBlockType.Recipe,
    recipeId: recipeId
  };
  return appendGenericBlock(genericBlocks, newBlock, block);
};