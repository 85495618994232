import { useIntl } from 'react-intl';
import { CharactersCountStatus } from 'types/common';

var countCharactersWithoutLineBreaks = function countCharactersWithoutLineBreaks(text) {
  return text.replace(/[\r\n]+/gm, '').length;
};

var shouldHandleMin = function shouldHandleMin(charactersCount, _ref) {
  var minCharactersCount = _ref.minCharactersCount;
  return minCharactersCount !== undefined && charactersCount < minCharactersCount;
};

var shouldHandleRecommended = function shouldHandleRecommended(charactersCount, _ref2) {
  var recommendedCharactersCount = _ref2.recommendedCharactersCount,
      maxCharactersCount = _ref2.maxCharactersCount;
  return recommendedCharactersCount !== undefined && (maxCharactersCount === undefined || charactersCount <= recommendedCharactersCount);
};

var getCharactersCountStatus = function getCharactersCountStatus(charactersCount, _ref3) {
  var minCharactersCount = _ref3.minCharactersCount,
      recommendedCharactersCount = _ref3.recommendedCharactersCount,
      maxCharactersCount = _ref3.maxCharactersCount;

  switch (true) {
    case minCharactersCount !== undefined && charactersCount < minCharactersCount:
    case maxCharactersCount !== undefined && charactersCount > maxCharactersCount:
      return CharactersCountStatus.Error;

    case recommendedCharactersCount !== undefined && charactersCount > recommendedCharactersCount:
      return CharactersCountStatus.Warning;
  }

  return CharactersCountStatus.Valid;
};

var useCharactersCount = function useCharactersCount(charactersParams) {
  var intl = useIntl();
  var inputText = charactersParams.inputText,
      displayCharactersCount = charactersParams.displayCharactersCount,
      minCharactersCount = charactersParams.minCharactersCount,
      maxCharactersCount = charactersParams.maxCharactersCount,
      recommendedCharactersCount = charactersParams.recommendedCharactersCount;
  var charactersCount = countCharactersWithoutLineBreaks(inputText);

  switch (true) {
    case !displayCharactersCount:
      return {
        ratio: '',
        helperText: ''
      };

    case shouldHandleMin(charactersCount, charactersParams):
      return {
        ratio: intl.formatMessage({
          id: 'form.charactersCountRatio'
        }, {
          charactersCount: charactersCount,
          maxCharactersCount: minCharactersCount
        }),
        helperText: intl.formatMessage({
          id: 'form.minCharactersCount'
        }, {
          minCharactersCount: minCharactersCount
        }),
        status: getCharactersCountStatus(charactersCount, charactersParams)
      };

    case shouldHandleRecommended(charactersCount, charactersParams):
      return {
        ratio: intl.formatMessage({
          id: 'form.charactersCountRatio'
        }, {
          charactersCount: charactersCount,
          maxCharactersCount: recommendedCharactersCount
        }),
        helperText: intl.formatMessage({
          id: 'form.recommendedCharactersCount'
        }, {
          recommendedCharactersCount: recommendedCharactersCount
        }),
        status: getCharactersCountStatus(charactersCount, charactersParams)
      };
    // handle max

    case maxCharactersCount !== undefined:
      return {
        ratio: intl.formatMessage({
          id: 'form.charactersCountRatio'
        }, {
          charactersCount: charactersCount,
          maxCharactersCount: maxCharactersCount
        }),
        helperText: intl.formatMessage({
          id: 'form.maxCharactersCount'
        }, {
          maxCharactersCount: maxCharactersCount
        }),
        status: getCharactersCountStatus(charactersCount, charactersParams)
      };

    default:
      return {
        ratio: '',
        helperText: ''
      };
  }
};

export default useCharactersCount;