import { createStyles } from '@material-ui/core';
import { fontSize, fontWeight, lineHeight } from 'stylesheet';
var MuiInputLabelOverride = createStyles({
  root: {
    fontWeight: fontWeight.bold
  },
  outlined: {
    fontSize: fontSize.normal,
    fontWeight: fontWeight.normal,
    lineHeight: lineHeight.normal,
    transform: 'translate(14px, 10px) scale(1)',
    '&$shrink': {
      transform: 'translate(14px, -10px) scale(0.75)'
    }
  }
});
export default MuiInputLabelOverride;