import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as Yup from 'yup';
import { Sites } from '@uno/common-shared';
import { BaseContentTypeName, Fields, GroupFields } from "../../FieldNames";
import { bodyRichTextField } from "../../common/fieldDefinitions/richText";
import { titleTextField } from "../../common/fieldDefinitions/simpleText";
import { getGroupLabelId } from "../../../helpers";
import { FieldType } from "../../../types/ContentType";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { DoctissimoContentTypeName } from "./ContentTypeName";
import { getManualModificationDate } from "./common";
var baseLabelId = BaseContentTypeName.PregnancyAppAppointment;
var fields = [{
  type: FieldType.Group,
  name: GroupFields.PREGNANCY_APP_APPOINTMENT,
  labelId: getGroupLabelId(GroupFields.PREGNANCY_APP_APPOINTMENT),
  fields: [titleTextField, _objectSpread(_objectSpread({}, bodyRichTextField), {}, {
    validation: Yup.object().required('form.required')
  })]
}];
export var pregnancyAppAppointment = {
  site: Sites.DOCTISSIMO_FR,
  name: DoctissimoContentTypeName.PregnancyAppAppointment,
  labelId: 'content-type.pregnancyAppAppointment.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  getLabel: function getLabel(contentData) {
    return contentData.title;
  },
  getPreviewImage: function getPreviewImage() {
    return null;
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  getMainSectionId: function getMainSectionId() {
    return '';
  },
  getManualModificationDate: getManualModificationDate,
  fields: fields,
  partialDependencyFields: [Fields.DESCRIPTION, Fields.TITLE],
  isLinkable: true,
  isCreatable: true
};