import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _SECTION_HOME_ALLOWED;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as Yup from 'yup';
import { Sites } from '@uno/common-shared';
import { legacyGroupFields, seoGroupFields } from "./common";
import { AufemininTaxonomyName } from "../taxonomy";
import { BaseContentTypeName, Fields, GroupFields } from "../../FieldNames";
import { contentLinkedContentField } from "../../common/fieldDefinitions/linkedContent";
import { metaUrlField } from "../../common/fieldDefinitions/metaUrl";
import { titleTextField } from "../../common/fieldDefinitions/simpleText";
import { altSectionField, mainSectionField } from "../../common/fieldDefinitions/taxonomy";
import { highlightedVideoField } from "../../common/fieldDefinitions/video";
import { getFieldLabelId, getGroupLabelId } from "../../../helpers";
import { FieldType } from "../../../types/ContentType";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { AufemininContentTypeName } from "./ContentTypeName";
import { coverImagesField } from "./common/coverImagesField";
var baseLabelId = BaseContentTypeName.SectionHome;
var seoGroupField = seoGroupFields();
var legacyGroupField = legacyGroupFields();
export var buildSectionHomeFields = function buildSectionHomeFields(linkedContentContentTypeNames) {
  return [{
    type: FieldType.Group,
    name: GroupFields.SECTION_HOME,
    labelId: getGroupLabelId(GroupFields.SECTION_HOME),
    fields: [{
      type: FieldType.Group,
      name: GroupFields.TAXONOMY,
      labelId: getGroupLabelId(GroupFields.TAXONOMY),
      fields: [_objectSpread(_objectSpread({}, mainSectionField), {}, {
        taxonomyName: AufemininTaxonomyName.MainSectionFR,
        disabled: true
      }), _objectSpread(_objectSpread({}, altSectionField), {}, {
        taxonomyName: AufemininTaxonomyName.MainSectionFR
      })]
    }, titleTextField, {
      type: FieldType.Group,
      name: GroupFields.TOP_ARTICLES,
      labelId: getGroupLabelId(GroupFields.TOP_ARTICLES),
      fields: [{
        type: FieldType.Repeatable,
        name: Fields.TOP_ARTICLES,
        labelId: getFieldLabelId(Fields.TOP_ARTICLES),
        defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
        validation: Yup.array().max(8),
        max: 8,
        field: _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
          contentTypeNames: linkedContentContentTypeNames
        })
      }]
    }]
  }, seoGroupField, {
    type: FieldType.Group,
    name: GroupFields.PROPERTIES,
    labelId: getGroupLabelId(GroupFields.PROPERTIES),
    fields: [metaUrlField, {
      type: FieldType.Group,
      name: GroupFields.IMAGES,
      labelId: getGroupLabelId(GroupFields.IMAGES),
      fields: [coverImagesField, highlightedVideoField]
    }, legacyGroupField]
  }];
};
export var SECTION_HOME_ALLOWED_CONTENT_TYPES = (_SECTION_HOME_ALLOWED = {}, _defineProperty(_SECTION_HOME_ALLOWED, Sites.AUFEMININ_COM, [AufemininContentTypeName.ArticleFR, AufemininContentTypeName.SlideshowFR, AufemininContentTypeName.TagPageFR]), _defineProperty(_SECTION_HOME_ALLOWED, Sites.ENFEMENINO_COM, [AufemininContentTypeName.ArticleES, AufemininContentTypeName.SlideshowES, AufemininContentTypeName.TagPageES]), _SECTION_HOME_ALLOWED);
var fields = buildSectionHomeFields(SECTION_HOME_ALLOWED_CONTENT_TYPES[Sites.AUFEMININ_COM]);
export var sectionHome = {
  site: Sites.AUFEMININ_COM,
  name: AufemininContentTypeName.SectionHomeFR,
  labelId: 'content-type.sectionHome.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  getLabel: function getLabel() {
    return '';
  },
  getPreviewImage: function getPreviewImage() {
    return '';
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  getMainSectionId: function getMainSectionId(contentData) {
    var _contentData$mainSect, _contentData$mainSect2;

    return (_contentData$mainSect = contentData === null || contentData === void 0 ? void 0 : (_contentData$mainSect2 = contentData.mainSection) === null || _contentData$mainSect2 === void 0 ? void 0 : _contentData$mainSect2[0]) !== null && _contentData$mainSect !== void 0 ? _contentData$mainSect : '';
  },
  getManualModificationDate: function getManualModificationDate() {
    return undefined;
  },
  fields: fields,
  partialDependencyFields: [],
  isLinkable: false,
  isCreatable: false
};