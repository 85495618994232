import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { DependencyScope, FieldType } from "../../../../types/ContentType/fields";
export var weekNumberField = {
  type: FieldType.Number,
  name: Fields.WEEK,
  labelId: getFieldLabelId(Fields.WEEK),
  dependencyScope: DependencyScope.Partial,
  defaultValue: '',
  validation: Yup.string().ensure().required('form.required').min(1).max(43)
};