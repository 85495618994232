export var PublicationStatus;

(function (PublicationStatus) {
  PublicationStatus["OFFLINE"] = "offline";
  PublicationStatus["ONLINE"] = "online";
  PublicationStatus["SCHEDULED"] = "scheduled";
})(PublicationStatus || (PublicationStatus = {}));

export var PublicationStatusSchema = {
  "enum": Object.values(PublicationStatus)
};