import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _objectSpread2;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import uniq from 'lodash/uniq';
import { Sites } from '@uno/common-shared';
import { AUFEMININ_CONTENT_TYPE_NAME_BY_SITE, AufemininContentTypeName } from "./AUFEMININ";
import AUFEMININ_CONTENT_TYPES from "./AUFEMININ/contentTypes";
import { DOCTISSIMO_CONTENT_TYPES, DoctissimoContentTypeName } from "./DOCTISSIMO_FR";
import { MARMITON_CONTENT_TYPES, MarmitonContentTypeName } from "./MARMITON_ORG";
import { TestContentTypeName } from "./TEST";
import TEST_CONTENT_TYPES from "./TEST/contentTypes";
export var CONTENT_TYPES = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, DOCTISSIMO_CONTENT_TYPES), AUFEMININ_CONTENT_TYPES), MARMITON_CONTENT_TYPES), TEST_CONTENT_TYPES);
export var CONTENT_TYPES_NAMES_BY_SITE = _objectSpread(_objectSpread({}, AUFEMININ_CONTENT_TYPE_NAME_BY_SITE), {}, (_objectSpread2 = {}, _defineProperty(_objectSpread2, Sites.DOCTISSIMO_FR, {
  Article: DoctissimoContentTypeName.Article,
  Author: DoctissimoContentTypeName.Person,
  Dictionary: DoctissimoContentTypeName.Dictionary,
  Encyclopedia: DoctissimoContentTypeName.Encyclopedia,
  Home: DoctissimoContentTypeName.Home,
  News: DoctissimoContentTypeName.News,
  Newsletter: DoctissimoContentTypeName.Newsletter,
  PregnancyAppAppointment: DoctissimoContentTypeName.PregnancyAppAppointment,
  PregnancyAppWeek: DoctissimoContentTypeName.PregnancyAppWeek,
  SectionHome: DoctissimoContentTypeName.SectionHome,
  Slideshow: DoctissimoContentTypeName.Slideshow,
  SlideshowV2: DoctissimoContentTypeName.SlideshowV2,
  Video: DoctissimoContentTypeName.Video
}), _defineProperty(_objectSpread2, Sites.MARMITON_ORG, {
  Ingredient: MarmitonContentTypeName.Ingredient
}), _objectSpread2));
export var CONTENT_TYPES_NAMES = [].concat(_toConsumableArray(Object.values(DoctissimoContentTypeName)), _toConsumableArray(Object.values(AufemininContentTypeName)), _toConsumableArray(Object.values(MarmitonContentTypeName)), _toConsumableArray(Object.values(TestContentTypeName)));

if (CONTENT_TYPES_NAMES.length !== uniq(CONTENT_TYPES_NAMES).length) {
  throw new Error('CONTENT_TYPES_NAMES must contain unique names!');
} // Todo: add a new property to ContentType (to categorize) to find slideshow, based on the property value


export var SLIDESHOW_CONTENT_TYPES_NAMES = [DoctissimoContentTypeName.Slideshow, DoctissimoContentTypeName.SlideshowV2, AufemininContentTypeName.SlideshowES, AufemininContentTypeName.SlideshowFR, AufemininContentTypeName.SlideshowV2ES, AufemininContentTypeName.SlideshowV2FR];