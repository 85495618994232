import _toConsumableArray from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray";
import { EditorState } from 'draft-js';
import { BlockType } from '@uno/common-cms';
import getBlockRelatives from './getBlockRelatives';
import getBlocksAfter from './getBlocksAfter';
import getBlocksBefore from './getBlocksBefore';
import getTableKeyFromBlock from './getTableKeyFromBlock';
import isBlockInTableForTableKey from './isBlockInTableForTableKey';
import isBlockUnstyledAndEmpty from './isBlockUnstyledAndEmpty';

var moveBlock = function moveBlock(editorState, blockKey, direction) {
  var contentState = editorState.getCurrentContent();
  var currentSelection = editorState.getSelection();
  var blockMap = contentState.getBlockMap();
  var isBlockNotInBlockMap = !blockMap.some(function (currentBlock) {
    return (currentBlock === null || currentBlock === void 0 ? void 0 : currentBlock.getKey()) === blockKey;
  });
  var isFirstBlockMovedUp = direction === 'up' && blockMap.first().getKey() === blockKey;
  var isLastBlockMovedDown = direction === 'down' && isLastBlock(blockKey, blockMap);

  if (isBlockNotInBlockMap || isFirstBlockMovedUp || isLastBlockMovedDown) {
    return editorState;
  }

  var block = contentState.getBlockForKey(blockKey); // If it's a table, be sure to select the first block of this table

  if (block.getType() === BlockType.TABLE) {
    var tableKey = getTableKeyFromBlock(block);
    block = blockMap.skipUntil(function (vBlock) {
      return isBlockInTableForTableKey(vBlock, tableKey);
    }).first();
  }

  var newBlockMap = updateBlockMapWithBlockMove(blockMap, {
    block: block,
    direction: direction
  });
  var newContentState = contentState.merge({
    blockMap: newBlockMap
  });
  var newEditorState = EditorState.push(editorState, newContentState, 'insert-fragment');
  return EditorState.forceSelection(newEditorState, currentSelection);
};

/**
 * Update block map with block move
 *
 * Build new block map keeping blocks before/after and placing blocks to move and blocks to jump in correct order
 */
var updateBlockMapWithBlockMove = function updateBlockMapWithBlockMove(blockMap, _ref) {
  var block = _ref.block,
      direction = _ref.direction;
  var blocksToMove = getBlockRelatives(block, blockMap);
  var blocksToJump = getBlocksToJump(block, blockMap, direction);
  var blocksKeysToJump = blocksToJump.map(function (vBlock) {
    return vBlock.getKey();
  });
  var blocksBefore = getBlocksBefore(block, blockMap);
  var blocksAfter = getBlocksAfter(block, blockMap);
  var newBlockMap = direction === 'up' ? blocksBefore.filterNot(function (vBlock) {
    var _vBlock$getKey;

    return blocksKeysToJump.includes((_vBlock$getKey = vBlock === null || vBlock === void 0 ? void 0 : vBlock.getKey()) !== null && _vBlock$getKey !== void 0 ? _vBlock$getKey : '');
  }).concat([].concat(_toConsumableArray(blocksToMove.map(function (vBlock) {
    return [vBlock.getKey(), vBlock];
  })), _toConsumableArray(blocksToJump.map(function (vBlock) {
    return [vBlock.getKey(), vBlock];
  }))), blocksAfter) : blocksBefore.concat([].concat(_toConsumableArray(blocksToJump.map(function (vBlock) {
    return [vBlock.getKey(), vBlock];
  })), _toConsumableArray(blocksToMove.map(function (vBlock) {
    return [vBlock.getKey(), vBlock];
  }))), blocksAfter.filterNot(function (vBlock) {
    var _vBlock$getKey2;

    return blocksKeysToJump.includes((_vBlock$getKey2 = vBlock === null || vBlock === void 0 ? void 0 : vBlock.getKey()) !== null && _vBlock$getKey2 !== void 0 ? _vBlock$getKey2 : '');
  }));
  return newBlockMap;
};
/**
 * Get blocks to jump
 *
 * If there is a table after/before, we want to jump all table blocks.
 * All empty blocks are jumped.
 */


var getBlocksToJump = function getBlocksToJump(block, blockMap, direction) {
  var blocksBefore = getBlocksBefore(block, blockMap);
  var blocksAfter = getBlocksAfter(block, blockMap);
  var previousBlock = blocksBefore.last();
  var nextBlock = blocksAfter.first();

  switch (true) {
    case direction === 'up' && previousBlock.getType() === BlockType.TABLE:
      return blocksBefore.skipUntil(function (vBlock) {
        return isBlockInTableForTableKey(vBlock, getTableKeyFromBlock(previousBlock));
      }).toArray();

    case direction === 'down' && nextBlock.getType() === BlockType.TABLE:
      return blocksAfter.takeUntil(function (vBlock) {
        return !isBlockInTableForTableKey(vBlock, getTableKeyFromBlock(nextBlock));
      }).toArray();

    default:
      // Jump over all empty blocks
      return direction === 'up' ? blocksBefore.reverse().takeUntil(function (vBlock) {
        return !isBlockUnstyledAndEmpty(vBlock);
      }).reverse().toArray().concat([previousBlock]) : [nextBlock].concat(blocksAfter.takeUntil(function (vBlock) {
        return !isBlockUnstyledAndEmpty(vBlock);
      }).toArray());
  }
};
/**
 * Is last block
 *
 * If block is a table, check if last table block is the last block
 */


var isLastBlock = function isLastBlock(blockKey, blockMap) {
  var block = blockMap.get(blockKey);
  var lastBlock = blockMap.last();

  if (block.getType() !== BlockType.TABLE) {
    return lastBlock.getKey() === block.getKey();
  }

  var blockData = block.getData().toObject();
  var lastBlockData = lastBlock.getData().toObject();
  return lastBlock.getType() === BlockType.TABLE && blockData.tableKey === lastBlockData.tableKey;
};

export default moveBlock;