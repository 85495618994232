export var Context;

(function (Context) {
  Context["FORUM"] = "FORUM";
  Context["MAIN"] = "MAIN";
})(Context || (Context = {}));

export var UrlCategories;

(function (UrlCategories) {
  UrlCategories["DOMAIN_URL"] = "DOMAIN_URL";
  UrlCategories["SITE_URL"] = "SITE_URL";
  UrlCategories["STATIC_URL"] = "STATIC_URL";
  UrlCategories["ASSETS_URL"] = "ASSETS_URL";
})(UrlCategories || (UrlCategories = {}));