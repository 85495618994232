import { GroupFields } from "../../../FieldNames";
import { semanticScoreField } from "../../../common/fieldDefinitions/semanticScore";
import { getGroupLabelId } from "../../../../helpers";
import { FieldType } from "../../../../types";
export var semanticScoreFields = function semanticScoreFields(groupField) {
  return {
    type: FieldType.Group,
    name: GroupFields.SEMANTICSCORE,
    labelId: getGroupLabelId(GroupFields.SEMANTICSCORE),
    fields: [semanticScoreField(groupField)]
  };
};