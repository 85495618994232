// this enum corresponds to the SEO categories for each id
export var SeoLinksZonesIds;

(function (SeoLinksZonesIds) {
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_FALLBACK"] = 0] = "ZONE_FALLBACK";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_INLINE"] = 1] = "ZONE_INLINE";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_RIGHT_COLUMN"] = 2] = "ZONE_RIGHT_COLUMN";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_SLIDER_HOMEPAGE"] = 3] = "ZONE_SLIDER_HOMEPAGE";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_BOTTOM"] = 4] = "ZONE_BOTTOM";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_INLINE_IMAGE"] = 5] = "ZONE_INLINE_IMAGE";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_TOP"] = 6] = "ZONE_TOP";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_TAGS"] = 7] = "ZONE_TAGS";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_BOTTOM_SEARCH"] = 8] = "ZONE_BOTTOM_SEARCH";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_STORY_BOTTOM"] = 9] = "ZONE_STORY_BOTTOM";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_ADDLINK"] = 10] = "ZONE_ADDLINK";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_BOTTOM_RECIPES"] = 11] = "ZONE_BOTTOM_RECIPES";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_BOTTOM_AUFEMININ_PILLAR"] = 12] = "ZONE_BOTTOM_AUFEMININ_PILLAR";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_TOP_TAG_THREADS"] = 13] = "ZONE_TOP_TAG_THREADS";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_FRONT_TOOLS"] = 14] = "ZONE_FRONT_TOOLS";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_HP_FOOTER"] = 15] = "ZONE_HP_FOOTER";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_INSIDE_STORY"] = 16] = "ZONE_INSIDE_STORY";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_BOTTOM_REVIEW"] = 17] = "ZONE_BOTTOM_REVIEW";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_BOT_SEO_RECIPE"] = 19] = "ZONE_BOT_SEO_RECIPE";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_SECTION_TAGS"] = 21] = "ZONE_SECTION_TAGS";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_HOT_CONTENT_TAGS"] = 22] = "ZONE_HOT_CONTENT_TAGS";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_MOST_ACTIVE_CHAT"] = 23] = "ZONE_MOST_ACTIVE_CHAT";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_HOT_TOPICS"] = 24] = "ZONE_HOT_TOPICS";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_THEMED_RECIPES"] = 25] = "ZONE_THEMED_RECIPES";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_TOP_RECIPES"] = 26] = "ZONE_TOP_RECIPES";
  SeoLinksZonesIds[SeoLinksZonesIds["ZONE_HEADER"] = 27] = "ZONE_HEADER";
})(SeoLinksZonesIds || (SeoLinksZonesIds = {}));