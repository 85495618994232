import { GenericBlockType } from '@uno/common-cms';
import { getGenericInlineDataRanges, getGenericInlineStyleRangesFromBlock } from '../../util';
import appendGenericBlock from '../../util/appendGenericBlock';
export var transformUnstyledToGenericAndAppend = function transformUnstyledToGenericAndAppend(block, genericBlocks, contentState) {
  var newBlock = {
    type: GenericBlockType.Unstyled,
    text: block.getText(),
    inlineStyleRanges: getGenericInlineStyleRangesFromBlock(block),
    inlineDataRanges: getGenericInlineDataRanges(block, contentState)
  };
  return appendGenericBlock(genericBlocks, newBlock, block);
};