import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { ALL_BLOCKS, FieldType } from "../../../../types/ContentType/fields";
export var bodyRichTextField = {
  type: FieldType.RichText,
  name: Fields.BODY,
  enabledBlocks: ALL_BLOCKS,
  labelId: getFieldLabelId(Fields.BODY),
  defaultValue: {
    blocks: [],
    entityMap: {}
  },
  validation: Yup.object()
};