import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { ContentPage, getContentPath as getBakoContentPath } from 'config/routing';
import useSiteConfig from 'libs/common/hooks/useSiteConfig';
import { getContentType } from 'libs/content/utils';
import useContent from './useContent';

var useContentWithRedirect = function useContentWithRedirect(contentId) {
  var _useRouter = useRouter(),
      push = _useRouter.push;

  var _useSiteConfig = useSiteConfig(),
      site = _useSiteConfig.id;

  var _useContent = useContent(contentId),
      firstLoadContent = _useContent.firstLoadContent,
      loading = _useContent.loading,
      doFetchContent = _useContent.doFetchContent,
      error = _useContent.error; // Redirect on right site path if content's site != current site


  useEffect(function () {
    // Be sure useContent and router are synchronized before testing if redirect is needed:
    if (firstLoadContent !== undefined && firstLoadContent.id === contentId) {
      var siteFromContentType = getContentType(firstLoadContent.contentType).site;

      if (siteFromContentType !== site) {
        void push(getBakoContentPath(siteFromContentType, ContentPage.EDIT, firstLoadContent.id));
      }
    }
  }, [firstLoadContent, contentId, push, site]);
  return {
    firstLoadContent: firstLoadContent,
    doFetchContent: doFetchContent,
    error: error,
    loading: loading
  };
};

export default useContentWithRedirect;