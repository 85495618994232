import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _MARMITON_CONTENT_TYP;

import { MarmitonContentTypeName } from "./ContentTypeName";
import { ingredient } from "./Ingredient";
import { unit } from "./Unit";
import { utensil } from "./Utensil";
export * from "./ContentTypeName";
var MARMITON_CONTENT_TYPES = (_MARMITON_CONTENT_TYP = {}, _defineProperty(_MARMITON_CONTENT_TYP, MarmitonContentTypeName.Ingredient, ingredient), _defineProperty(_MARMITON_CONTENT_TYP, MarmitonContentTypeName.Unit, unit), _defineProperty(_MARMITON_CONTENT_TYP, MarmitonContentTypeName.Utensil, utensil), _MARMITON_CONTENT_TYP);
export default MARMITON_CONTENT_TYPES;