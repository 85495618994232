import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { ImageFieldNames } from '@uno/common-shared';
import { Fields } from "../../../FieldNames";
import { getCoverImageFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { coverImagesFieldValidation } from "../../../../helpers/coverImagesFieldValidation";
import { FieldType } from "../../../../types/ContentType/fields";
export var coverImagesField = {
  type: FieldType.CoverImages,
  name: Fields.COVER_IMAGES,
  labelId: getCoverImageFieldLabelId(ImageFieldNames.DEFAULT),
  defaultValue: {
    imgUrl: undefined,
    legend: '',
    credits: ''
  },
  ratio: {
    width: 3,
    height: 2
  },
  constraints: {
    minWidth: 1200,
    maxWidth: 3000
  },
  validation: coverImagesFieldValidation,
  configuration: _defineProperty({}, ImageFieldNames.SQUARE, {
    name: ImageFieldNames.SQUARE,
    labelId: getCoverImageFieldLabelId(ImageFieldNames.SQUARE),
    defaultValue: {
      imgUrl: undefined,
      legend: '',
      credits: ''
    },
    ratio: {
      width: 1,
      height: 1
    },
    constraints: {
      maxWidth: 3000
    }
  })
};