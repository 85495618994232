import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* eslint-disable max-lines */
import * as Yup from 'yup';
import { DoctissimoTaxonomyName } from "../../taxonomy";
import { Fields, GroupFields } from "../../../FieldNames";
import { medicalValidationDateField, modificationDateField } from "../../../common/fieldDefinitions/date";
import { coverImagesField } from "../../../common/fieldDefinitions/image";
import { authorLinkedContentField, expertLinkedContentField, medicalValidatorLinkedContentField } from "../../../common/fieldDefinitions/linkedContent";
import { createdMetaDateField, modifiedMetaDateField, publicationMetaDateField } from "../../../common/fieldDefinitions/metaDate";
import { metaUrlField } from "../../../common/fieldDefinitions/metaUrl";
import { bodyRichTextField, cartoucheRichTextField, descriptionRichTextField, sourcesRichTextField } from "../../../common/fieldDefinitions/richText";
import { chapoTextField, descriptionTagTextField, keywordsTagTextField, ogDescriptionTextField, ogTitleTextField, shortTitleTextField, titleTagTextField, titleTextField } from "../../../common/fieldDefinitions/simpleText";
import { sponsoField } from "../../../common/fieldDefinitions/sponso";
import { altSectionField, mainSectionField } from "../../../common/fieldDefinitions/taxonomy";
import { highlightedVideoField } from "../../../common/fieldDefinitions/video";
import { getFieldLabelId, getGroupLabelId } from "../../../../helpers";
import { BlockType, FieldType } from "../../../../types";
import { DoctissimoContentTypeName } from "../ContentTypeName";
import { legacyFields } from "./legacyFields";
export var mainGroupField = {
  type: FieldType.Group,
  name: GroupFields.ARTICLE,
  labelId: getGroupLabelId(GroupFields.ARTICLE),
  fields: [_objectSpread(_objectSpread({}, titleTextField), {}, {
    displayCharactersCount: true,
    maxCharactersCount: 110,
    validation: Yup.string().max(110, 'form.stringLength').required('form.required')
  }), chapoTextField, bodyRichTextField]
};
export var seoGroupField = {
  type: FieldType.Group,
  name: GroupFields.SEO,
  labelId: getGroupLabelId(GroupFields.SEO),
  fields: [_objectSpread(_objectSpread({}, titleTagTextField), {}, {
    validation: titleTagTextField.validation.required('form.required')
  }), _objectSpread(_objectSpread({}, descriptionTagTextField), {}, {
    validation: descriptionTagTextField.validation.required('form.required')
  }), keywordsTagTextField]
};
export var propertiesGroupField = {
  type: FieldType.Group,
  name: GroupFields.PROPERTIES,
  labelId: getGroupLabelId(GroupFields.PROPERTIES),
  fields: [metaUrlField, ogTitleTextField, shortTitleTextField, ogDescriptionTextField, {
    type: FieldType.Group,
    name: GroupFields.TAXONOMY,
    labelId: getGroupLabelId(GroupFields.TAXONOMY),
    fields: [_objectSpread(_objectSpread({}, mainSectionField), {}, {
      taxonomyName: DoctissimoTaxonomyName.MainSection,
      validation: Yup.array().test({
        message: 'form.required',
        test: function test(array) {
          return array !== undefined && array.length === 1;
        }
      })
    }), _objectSpread(_objectSpread({}, altSectionField), {}, {
      taxonomyName: DoctissimoTaxonomyName.MainSection
    })]
  }, {
    type: FieldType.Group,
    name: GroupFields.SOURCES,
    labelId: getGroupLabelId(GroupFields.SOURCES),
    fields: [{
      type: FieldType.Repeatable,
      name: Fields.AUTHORS,
      labelId: getFieldLabelId(Fields.AUTHORS),
      defaultValue: [{
        /**
         * Todo: CMS-1288 => make it works with : [Fields.AUTHOR_ID]: '',
         * temporary fix the following error during package build :
         * @uno/common-cms: config/DOCTISSIMO_FR/contentTypes/News.ts(21,7): error TS4023: Exported variable 'fields' has or is using name 'Fields' from external module "/common/cms/config/DOCTISSIMO_FR/contentTypes/common/baseArticleFields" but cannot be named.
         * Examples :
         * [Fields.AUTHOR_ID]: '', --> error
         * [Fields.AUTHOR_ID]: [], --> good
         * authorId: '' --> good
         */
        authorId: '' //[Fields.AUTHOR_ID]: '',

      }],
      validation: Yup.array(),
      field: _objectSpread(_objectSpread({}, authorLinkedContentField), {}, {
        contentTypeNames: [DoctissimoContentTypeName.Person]
      })
    }, {
      type: FieldType.Repeatable,
      name: Fields.EXPERTS,
      labelId: getFieldLabelId(Fields.EXPERTS),
      defaultValue: [{
        /**
         * Todo: CMS-1288 => make it works with : [Fields.EXPERT_ID]: '',
         * temporary fix the following error during package build :
         * @uno/common-cms: config/DOCTISSIMO_FR/contentTypes/News.ts(21,7): error TS4023: Exported variable 'fields' has or is using name 'Fields' from external module "/common/cms/config/DOCTISSIMO_FR/contentTypes/common/baseArticleFields" but cannot be named.
         * Examples :
         * [Fields.EXPERT_ID]: '', --> error
         * [Fields.EXPERT_ID]: [], --> good
         * authorId: '' --> good
         */
        expertId: '' //[Fields.EXPERT_ID]: '',

      }],
      validation: Yup.array(),
      field: _objectSpread(_objectSpread({}, expertLinkedContentField), {}, {
        contentTypeNames: [DoctissimoContentTypeName.Person]
      })
    }, sourcesRichTextField]
  }, {
    type: FieldType.Group,
    name: GroupFields.MEDICAL_VALIDATION,
    labelId: getGroupLabelId(GroupFields.MEDICAL_VALIDATION),
    fields: [_objectSpread(_objectSpread({}, medicalValidatorLinkedContentField), {}, {
      contentTypeNames: [DoctissimoContentTypeName.Person]
    }), medicalValidationDateField]
  }, {
    type: FieldType.Group,
    name: GroupFields.DATES,
    labelId: getGroupLabelId(GroupFields.DATES),
    fields: [modificationDateField, publicationMetaDateField, createdMetaDateField, modifiedMetaDateField]
  }, {
    type: FieldType.Group,
    name: GroupFields.IMAGES,
    labelId: getGroupLabelId(GroupFields.IMAGES),
    fields: [coverImagesField, highlightedVideoField]
  }, {
    type: FieldType.Group,
    name: GroupFields.SPONSO,
    labelId: getGroupLabelId(GroupFields.SPONSO),
    fields: [sponsoField]
  }, {
    type: FieldType.Repeatable,
    name: Fields.SEE_MORE,
    labelId: getFieldLabelId(Fields.SEE_MORE),
    defaultValue: [_defineProperty({
      /**
       * Todo: CMS-1288 => make it works with : [Fields.TITLE]: '',
       * temporary fix the following error during package build :
       * @uno/common-cms: config/DOCTISSIMO_FR/contentTypes/News.ts(21,7): error TS4023: Exported variable 'fields' has or is using name 'Fields' from external module "/common/cms/config/DOCTISSIMO_FR/contentTypes/common/baseArticleFields" but cannot be named.
       * Examples :
       * [Fields.TITLE]: '', --> error
       * [Fields.TITLE]: [], --> good
       * title: '' --> good
       */
      title: ''
    }, Fields.DESCRIPTION, {
      blocks: []
    })],
    validation: Yup.array(),
    field: {
      type: FieldType.Group,
      name: GroupFields.SEE_MORE,
      labelId: getGroupLabelId(GroupFields.SEE_MORE),
      fields: [_objectSpread(_objectSpread({}, titleTextField), {}, {
        validation: Yup.string()
      }), _objectSpread(_objectSpread({}, descriptionRichTextField), {}, {
        enabledBlocks: [BlockType.UL, BlockType.UNSTYLED]
      })]
    }
  }, cartoucheRichTextField, legacyFields]
};