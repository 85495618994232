import { EditorState, Modifier, RichUtils } from 'draft-js';
import { useCallback } from 'react';
import { BlockType } from '@uno/common-cms';
export var useHandleBlockToggleCallback = function useHandleBlockToggleCallback(setEditorState) {
  return useCallback(function () {
    return function (event) {
      event.preventDefault();
      setEditorState(function (editorState) {
        var stateAfterBlockToggle = RichUtils.toggleBlockType(editorState, BlockType.BLOCKQUOTE); // force selection after block toolbar block toggle

        var stateAfterCurrentBlockSelection = EditorState.forceSelection(stateAfterBlockToggle, stateAfterBlockToggle.getSelection());
        return insertInitialBlockquoteText(stateAfterCurrentBlockSelection);
      });
    };
  }, [setEditorState]);
};

var insertInitialBlockquoteText = function insertInitialBlockquoteText(editorState) {
  var contentStateTextReplaced = Modifier.replaceText(editorState.getCurrentContent(), editorState.getSelection(), '“ ”');
  var editorStateAfterTextInsertion = EditorState.push(editorState, contentStateTextReplaced, 'insert-characters');
  var offset = editorStateAfterTextInsertion.getSelection().getFocusOffset() - 1;
  var newSelection = editorStateAfterTextInsertion.getSelection().merge({
    focusOffset: offset,
    anchorOffset: offset - 1
  });
  return EditorState.acceptSelection(editorStateAfterTextInsertion, newSelection);
};