import _toConsumableArray from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray";
import { GenericBlockType } from '@uno/common-cms';
import { getGenericInlineDataRanges, getGenericInlineStyleRangesFromBlock } from '../../util';
import appendGenericBlock from '../../util/appendGenericBlock';
export var transformTableToGenericAndAppend = function transformTableToGenericAndAppend(block, genericBlocks, contentState) {
  var _genericBlocks, _newBlock$tableData$r;

  var newBlock = {
    type: GenericBlockType.Table,
    tableData: []
  };

  if (((_genericBlocks = genericBlocks[genericBlocks.length - 1]) === null || _genericBlocks === void 0 ? void 0 : _genericBlocks.type) === GenericBlockType.Table) {
    var lastGenericBlock = genericBlocks.pop();
    newBlock.tableData = lastGenericBlock.tableData;
  }

  var _ref = block.getData().toObject(),
      tablePosition = _ref.tablePosition; // table position format : "{tableKey}-{row}-{col}"


  var regex = RegExp(/^[a-z0-9]+-(\d)-\d$/);
  var matches = regex.exec(tablePosition);

  if (matches === null || matches[1] === undefined) {
    return genericBlocks;
  } // according to the regex, matches[0] = full match / matches[1] = 1st capturing group (the row)


  var rowIndex = parseInt(matches[1]);
  newBlock.tableData[rowIndex] = [].concat(_toConsumableArray((_newBlock$tableData$r = newBlock.tableData[rowIndex]) !== null && _newBlock$tableData$r !== void 0 ? _newBlock$tableData$r : []), [{
    text: block.getText(),
    inlineStyleRanges: getGenericInlineStyleRangesFromBlock(block),
    inlineDataRanges: getGenericInlineDataRanges(block, contentState)
  }]);
  return appendGenericBlock(genericBlocks, newBlock, block);
};