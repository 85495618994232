import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _urls, _defaultAvatarsUrls;

import { Sites } from "../../sites";
import { Stages } from "../../stages";
import { Context } from "../urlCategories";
import { forumUrls } from "./forumsConfig";
import { mainUrls } from "./mainConfig";
import { emptyConfig } from "./utils";

var generateUrlBySite = function generateUrlBySite(site) {
  var _Stages$DEV, _Stages$STAGING, _Stages$PRODUCTION, _Stages$PRODUCTION_LE, _ref;

  if (site === Sites.TEST || site === Sites.UNIFY) {
    return emptyConfig;
  }

  return _ref = {}, _defineProperty(_ref, Stages.DEV, (_Stages$DEV = {}, _defineProperty(_Stages$DEV, Context.FORUM, forumUrls[site].dev), _defineProperty(_Stages$DEV, Context.MAIN, mainUrls[site].dev), _Stages$DEV)), _defineProperty(_ref, Stages.STAGING, (_Stages$STAGING = {}, _defineProperty(_Stages$STAGING, Context.FORUM, forumUrls[site].staging), _defineProperty(_Stages$STAGING, Context.MAIN, mainUrls[site].staging), _Stages$STAGING)), _defineProperty(_ref, Stages.PRODUCTION, (_Stages$PRODUCTION = {}, _defineProperty(_Stages$PRODUCTION, Context.FORUM, forumUrls[site].production), _defineProperty(_Stages$PRODUCTION, Context.MAIN, mainUrls[site].production), _Stages$PRODUCTION)), _defineProperty(_ref, Stages.PRODUCTION_LEGACY, (_Stages$PRODUCTION_LE = {}, _defineProperty(_Stages$PRODUCTION_LE, Context.FORUM, forumUrls[site].productionLegacy), _defineProperty(_Stages$PRODUCTION_LE, Context.MAIN, mainUrls[site].productionLegacy), _Stages$PRODUCTION_LE)), _ref;
};

export var urls = (_urls = {}, _defineProperty(_urls, Sites.NETMUMS_COM, generateUrlBySite(Sites.NETMUMS_COM)), _defineProperty(_urls, Sites.AUFEMININ_COM, generateUrlBySite(Sites.AUFEMININ_COM)), _defineProperty(_urls, Sites.ENFEMENINO_COM, generateUrlBySite(Sites.ENFEMENINO_COM)), _defineProperty(_urls, Sites.ALFEMMINILE_COM, generateUrlBySite(Sites.ALFEMMINILE_COM)), _defineProperty(_urls, Sites.GOFEMININ_DE, generateUrlBySite(Sites.GOFEMININ_DE)), _defineProperty(_urls, Sites.SOFEMININE_CO_UK, generateUrlBySite(Sites.SOFEMININE_CO_UK)), _defineProperty(_urls, Sites.TAOFEMININO_COM_BR, generateUrlBySite(Sites.TAOFEMININO_COM_BR)), _defineProperty(_urls, Sites.WEWOMEN_BE, generateUrlBySite(Sites.WEWOMEN_BE)), _defineProperty(_urls, Sites.MARMITON_ORG, generateUrlBySite(Sites.MARMITON_ORG)), _defineProperty(_urls, Sites.DOCTISSIMO_FR, generateUrlBySite(Sites.DOCTISSIMO_FR)), _defineProperty(_urls, Sites.TEST, generateUrlBySite(Sites.TEST)), _defineProperty(_urls, Sites.BEAUTE_TEST_COM, generateUrlBySite(Sites.BEAUTE_TEST_COM)), _defineProperty(_urls, Sites.UNIFY, generateUrlBySite(Sites.UNIFY)), _urls);
export var defaultAvatarsUrls = (_defaultAvatarsUrls = {}, _defineProperty(_defaultAvatarsUrls, Sites.NETMUMS_COM, '/images/_next/avatar-default.png'), _defineProperty(_defaultAvatarsUrls, Sites.AUFEMININ_COM, ''), _defineProperty(_defaultAvatarsUrls, Sites.ENFEMENINO_COM, ''), _defineProperty(_defaultAvatarsUrls, Sites.ALFEMMINILE_COM, ''), _defineProperty(_defaultAvatarsUrls, Sites.GOFEMININ_DE, ''), _defineProperty(_defaultAvatarsUrls, Sites.SOFEMININE_CO_UK, ''), _defineProperty(_defaultAvatarsUrls, Sites.TAOFEMININO_COM_BR, ''), _defineProperty(_defaultAvatarsUrls, Sites.WEWOMEN_BE, ''), _defineProperty(_defaultAvatarsUrls, Sites.MARMITON_ORG, ''), _defineProperty(_defaultAvatarsUrls, Sites.DOCTISSIMO_FR, ''), _defineProperty(_defaultAvatarsUrls, Sites.TEST, ''), _defineProperty(_defaultAvatarsUrls, Sites.UNIFY, ''), _defineProperty(_defaultAvatarsUrls, Sites.BEAUTE_TEST_COM, ''), _defaultAvatarsUrls);