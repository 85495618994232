import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { sponsoFieldValidation } from "../../../../helpers/sponsoFieldValidation";
import { FieldType } from "../../../../types/ContentType/fields";
export var sponsoField = {
  type: FieldType.Sponso,
  name: Fields.SPONSO,
  labelId: getFieldLabelId(Fields.SPONSO),
  validation: sponsoFieldValidation,
  defaultValue: {
    ctaLabel: 'En savoir plus',
    partnerUrl: '',
    legalNotice: 'En partenariat avec',
    partnerName: '',
    isSponsored: false
  }
};