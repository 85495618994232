import { CONTENT_TYPES, getSiteConfigs } from "../config";

var getSiteConfigByContentType = function getSiteConfigByContentType(contentType) {
  if (CONTENT_TYPES[contentType] === undefined) {
    throw new Error("Unable to get SiteConfig for ".concat(contentType));
  }

  var siteConfigs = getSiteConfigs();
  var site = Object.values(siteConfigs).find(function (config) {
    return config.id === CONTENT_TYPES[contentType].site;
  });

  if (site === undefined) {
    throw new Error("Unable to get SiteConfig for ".concat(contentType));
  }

  return site;
};

export default getSiteConfigByContentType;