import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _objectSpread2;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { imageSchema } from '@uno/common-shared';
import { DoctissimoContentTypeName, Fields } from "../../../../../../config";
import { PartialContentPageChunkSchema, PartialPageChunkSchema } from "../../../../../page";
var PregnancyAppAppointmentChunkSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, PartialContentPageChunkSchema.properties), {}, (_objectSpread2 = {
    _contentMetadata: {
      type: 'object',
      properties: {
        editorialUpdateDate: {
          type: ['string']
        }
      },
      additionalProperties: false
    },
    _contentTypeName: {
      "const": DoctissimoContentTypeName.PregnancyAppAppointment
    }
  }, _defineProperty(_objectSpread2, Fields.TITLE, {
    type: 'string'
  }), _defineProperty(_objectSpread2, Fields.IMAGE_FOETUS, imageSchema), _defineProperty(_objectSpread2, Fields.IMAGE_FRUIT, imageSchema), _objectSpread2)),
  required: [].concat(_toConsumableArray(PartialContentPageChunkSchema.required), ['_contentMetadata', '_contentTypeName', Fields.TITLE])
};
var GenerateUrlPregnancyAppAppointmentChunkSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, PartialPageChunkSchema.properties), {}, _defineProperty({}, Fields.TITLE, {
    type: 'string'
  })),
  required: [].concat(_toConsumableArray(PartialPageChunkSchema.required), [Fields.TITLE])
};
export { PregnancyAppAppointmentChunkSchema as PregnancyAppAppointmentChunkSchemaDoctissimoFr, GenerateUrlPregnancyAppAppointmentChunkSchema as GenerateUrlPregnancyAppAppointmentChunkSchemaDoctissimoFr };