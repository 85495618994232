import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var recipeIdNumberField = {
  type: FieldType.Number,
  name: Fields.RECIPE_ID,
  labelId: getFieldLabelId(Fields.RECIPE_ID),
  defaultValue: '',
  validation: Yup.string()
};