import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

/* eslint-disable max-lines */
import compact from 'lodash/compact';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { Sites, Stages } from '@uno/common-shared';
import { BaseContentTypeName, Fields } from "../../FieldNames";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { DoctissimoContentTypeName } from "./ContentTypeName";
import { getManualModificationDate, slideshowPropertiesGroupFields, slideshowSeoFields, slideshowV2Fields } from "./common";
var baseLabelId = BaseContentTypeName.SlideshowV2;
var fields = [].concat(_toConsumableArray(slideshowV2Fields), [slideshowSeoFields, slideshowPropertiesGroupFields]);
export var slideshowV2 = {
  site: Sites.DOCTISSIMO_FR,
  name: DoctissimoContentTypeName.SlideshowV2,
  labelId: "content-type.".concat(baseLabelId, ".name"),
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Slideshow,
  getLabel: function getLabel(contentData) {
    return (// TODO: See to fix "Type instantiation is excessively deep and possibly infinite"
      // @ts-ignore Sorry too many fields
      (contentData === null || contentData === void 0 ? void 0 : contentData.title) !== undefined ? contentData === null || contentData === void 0 ? void 0 : contentData.title : ''
    );
  },
  getPreviewImage: function getPreviewImage(contentData) {
    var _ref;

    return (_ref = get(contentData, 'coverImages.default.imgUrl')) !== null && _ref !== void 0 ? _ref : null;
  },
  getSubLabel: function getSubLabel(contentData) {
    var _contentData$slides$l, _contentData$slides;

    return "".concat((_contentData$slides$l = contentData === null || contentData === void 0 ? void 0 : (_contentData$slides = contentData.slides) === null || _contentData$slides === void 0 ? void 0 : _contentData$slides.length) !== null && _contentData$slides$l !== void 0 ? _contentData$slides$l : 0, " Slide(s)");
  },
  getAuthorIds: function getAuthorIds(contentData) {
    var _contentData$authors;

    return uniq(compact((contentData === null || contentData === void 0 ? void 0 : (_contentData$authors = contentData.authors) === null || _contentData$authors === void 0 ? void 0 : _contentData$authors.length) !== undefined ? contentData.authors.map(function (_ref2) {
      var authorId = _ref2.authorId;
      return authorId;
    }) : []));
  },
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  getMainSectionId: function getMainSectionId(contentData) {
    var _contentData$mainSect, _contentData$mainSect2;

    return (_contentData$mainSect = contentData === null || contentData === void 0 ? void 0 : (_contentData$mainSect2 = contentData.mainSection) === null || _contentData$mainSect2 === void 0 ? void 0 : _contentData$mainSect2[0]) !== null && _contentData$mainSect !== void 0 ? _contentData$mainSect : '';
  },
  getManualModificationDate: getManualModificationDate,
  fields: fields,
  partialDependencyFields: [Fields.AUTHORS, Fields.CHAPO, Fields.COVER_IMAGES, Fields.TITLE],
  isLinkable: true,
  isCreatable: process.env.STAGE !== Stages.PRODUCTION
};