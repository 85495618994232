import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { DependencyScope, FieldType } from "../../../../types/ContentType/fields";
export var expertLinkedContentField = {
  type: FieldType.LinkedContent,
  name: Fields.EXPERT_ID,
  dependencyScope: DependencyScope.Partial,
  labelId: getFieldLabelId(Fields.EXPERT_ID),
  defaultValue: '',
  validation: Yup.string()
};