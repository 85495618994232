import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import uniq from 'lodash/uniq';
import { AufemininPageConfigName } from "./AUFEMININ";
import { DoctissimoPageConfigName } from "./DOCTISSIMO_FR";
import { MarmitonPageConfigName } from "./MARMITON_ORG";
import { TestPageConfigName } from "./TEST";
export var PAGE_CONFIGS_NAMES = [].concat(_toConsumableArray(Object.values(AufemininPageConfigName)), _toConsumableArray(Object.values(DoctissimoPageConfigName)), _toConsumableArray(Object.values(MarmitonPageConfigName)), _toConsumableArray(Object.values(TestPageConfigName)));

if (PAGE_CONFIGS_NAMES.length !== uniq(PAGE_CONFIGS_NAMES).length) {
  throw new Error('PAGE_CONFIGS_NAMES must contain unique names!');
}