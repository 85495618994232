import { combineReducers } from 'redux';
import { content, contentInitialState, dependencies, dependenciesInitialState, page, pageInitialState, preview, previewInitialState } from './content';
export var initialState = {
  content: contentInitialState,
  dependencies: dependenciesInitialState,
  page: pageInitialState,
  preview: previewInitialState
};
var rootReducers = combineReducers({
  content: content,
  dependencies: dependencies,
  page: page,
  preview: preview
});
export default rootReducers;