import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PublicationStatus } from '@uno/common-cms';
import { setValidateSchema } from 'redux/content';
import useSubmitContent from './useSubmitContent';

var usePublicationStatus = function usePublicationStatus(pageMetaData, pageMetaDataLoading) {
  var dispatch = useDispatch();

  var _useState = useState(),
      currentPublicationStatus = _useState[0],
      setCurrentPublicationStatus = _useState[1];

  var _useState2 = useState(),
      newPublicationStatus = _useState2[0],
      setNewPublicationStatus = _useState2[1]; // WARNING: I have a memory leak if I store a Date into the state: I couldn't understand why, so I store it as string and build Date obj just before returning it


  var _useState3 = useState(),
      scheduledPublicationDate = _useState3[0],
      setScheduledPublicationDate = _useState3[1];

  var _useSubmitContent = useSubmitContent({
    publicationStatus: newPublicationStatus,
    scheduledPublicationDate: scheduledPublicationDate === undefined ? undefined : new Date(scheduledPublicationDate)
  }, currentPublicationStatus === null || currentPublicationStatus === void 0 ? void 0 : currentPublicationStatus.publicationStatus, currentPublicationStatus === null || currentPublicationStatus === void 0 ? void 0 : currentPublicationStatus.publishAt),
      handleSubmit = _useSubmitContent.handleSubmit,
      isPublishing = _useSubmitContent.isPublishing; // Update states when receiving pageMetaData


  useEffect(function () {
    if (pageMetaData !== undefined && !pageMetaDataLoading) {
      var _pageMetaData$publica;

      var initialStatus = (_pageMetaData$publica = pageMetaData === null || pageMetaData === void 0 ? void 0 : pageMetaData.publicationStatus) !== null && _pageMetaData$publica !== void 0 ? _pageMetaData$publica : PublicationStatus.OFFLINE;
      setCurrentPublicationStatus({
        publicationStatus: initialStatus,
        publishAt: pageMetaData === null || pageMetaData === void 0 ? void 0 : pageMetaData.publishedAt
      });
      setNewPublicationStatus(initialStatus);
      setScheduledPublicationDate((pageMetaData === null || pageMetaData === void 0 ? void 0 : pageMetaData.publicationStatus) === PublicationStatus.SCHEDULED && (pageMetaData === null || pageMetaData === void 0 ? void 0 : pageMetaData.publishedAt) !== undefined ? pageMetaData.publishedAt : undefined);
    }
  }, [pageMetaData, pageMetaDataLoading]);

  var isStateReady = function isStateReady(state) {
    return state !== undefined && stateIsReady;
  };

  var publicationState = {
    currentPublicationStatus: currentPublicationStatus,
    newPublicationStatus: newPublicationStatus,
    scheduledPublicationDate: scheduledPublicationDate
  };
  var stateIsReady = currentPublicationStatus !== undefined && newPublicationStatus !== undefined;
  var validateSchema = stateIsReady && newPublicationStatus !== PublicationStatus.OFFLINE;

  var updatePublicationStatus = function updatePublicationStatus(newStatus) {
    var newScheduledDate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
    setNewPublicationStatus(newStatus);
    setScheduledPublicationDate(newScheduledDate === undefined ? undefined : newScheduledDate.toISOString());
  };

  useEffect(function () {
    dispatch(setValidateSchema(validateSchema));
  }, [dispatch, validateSchema]);
  return {
    isStateReady: isStateReady,
    handleSubmit: handleSubmit,
    isPublishing: isPublishing,
    publicationState: isStateReady(publicationState) ? publicationState : undefined,
    setCurrentPublicationStatus: setCurrentPublicationStatus,
    updatePublicationStatus: updatePublicationStatus
  };
};

export default usePublicationStatus;