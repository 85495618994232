import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var activeSwitchField = {
  type: FieldType.Switch,
  name: Fields.ACTIVE,
  labelId: getFieldLabelId(Fields.ACTIVE),
  defaultValue: false,
  validation: Yup["boolean"]().required('form.required')
};