import { Sites } from '@uno/common-shared';
import { AufemininTaxonomyName } from "./taxonomyName";
var TAG_NEWS_FR = {
  name: AufemininTaxonomyName.TagNewsFR,
  hierarchical: true,
  site: Sites.AUFEMININ_COM
};
var TAG_NEWS_ES = {
  name: AufemininTaxonomyName.TagNewsES,
  hierarchical: true,
  site: Sites.ENFEMENINO_COM
};
var TAG_NEWS_IT = {
  name: AufemininTaxonomyName.TagNewsIT,
  hierarchical: true,
  site: Sites.ALFEMMINILE_COM
};
var TAG_NEWS_DE = {
  name: AufemininTaxonomyName.TagNewsDE,
  hierarchical: true,
  site: Sites.GOFEMININ_DE
};
var TAG_NEWS_UK = {
  name: AufemininTaxonomyName.TagNewsUK,
  hierarchical: true,
  site: Sites.SOFEMININE_CO_UK
};
var TAG_NEWS_BR = {
  name: AufemininTaxonomyName.TagNewsBR,
  hierarchical: true,
  site: Sites.TAOFEMININO_COM_BR
};
var TAG_NEWS_BE = {
  name: AufemininTaxonomyName.TagNewsBE,
  hierarchical: true,
  site: Sites.WEWOMEN_BE
};
export { TAG_NEWS_FR, TAG_NEWS_ES, TAG_NEWS_IT, TAG_NEWS_DE, TAG_NEWS_UK, TAG_NEWS_BR, TAG_NEWS_BE };