import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { DependencyScope, FieldType } from "../../../../types/ContentType/fields";
export var contentLinkedContentField = {
  type: FieldType.LinkedContent,
  name: Fields.CONTENT,
  dependencyScope: DependencyScope.Partial,
  publishedContentOnly: true,
  labelId: getFieldLabelId(Fields.CONTENT),
  defaultValue: '',
  validation: Yup.string()
};