import { Fields, GroupFields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";

var getTextPropertiesPath = function getTextPropertiesPath(groupField) {
  return ["".concat(groupField, ".").concat(Fields.TITLE), "".concat(groupField, ".").concat(Fields.CHAPO), groupField === GroupFields.SLIDESHOW ? "".concat(groupField, ".").concat(Fields.SLIDES, ".").concat(GroupFields.SLIDE, "[0].").concat(Fields.DESCRIPTION) // slide[]: all slides, slide[X]: (X + 1)th slide only
  : "".concat(groupField, ".").concat(Fields.BODY)];
};

export var semanticScoreField = function semanticScoreField(groupField) {
  return {
    type: FieldType.SemanticScore,
    name: Fields.SEMANTIC_SCORE,
    labelId: getFieldLabelId(Fields.SEMANTIC_SCORE),
    defaultValue: [],
    textPropertiesPath: getTextPropertiesPath(groupField)
  };
};