var __jsx = React.createElement;
import Link from 'next/link';
import React from 'react';
import UserMenu from 'components/common/Header/UserMenu';
import SelectSite from 'components/common/SelectSite';
import { useIsSiteSpecificRoute } from 'libs/common/hooks';
import { HeaderContainer, HeaderItem, LeftSide, Logo, LogoLink, RightSide } from './Header.style';
import NavigationHeader from './NavigationHeader';

var Header = function Header() {
  var isSiteSpecificRoute = useIsSiteSpecificRoute();
  return __jsx(HeaderContainer, null, __jsx(HeaderItem, null, __jsx(LeftSide, null, __jsx(Link, {
    href: "/",
    passHref: true
  }, __jsx(LogoLink, null, __jsx(Logo, {
    alt: "Logo Unify",
    src: "/logo.png"
  }))), isSiteSpecificRoute && __jsx(SelectSite, null))), __jsx(HeaderItem, null, __jsx(NavigationHeader, null)), __jsx(HeaderItem, null, __jsx(RightSide, null, __jsx(UserMenu, null))));
};

export default Header;