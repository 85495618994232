import { ContentBlock, EditorState, genKey } from 'draft-js';
import { List, Map } from 'immutable';
import { BlockType } from '@uno/common-cms';
export var continuousBlocks = [BlockType.UNSTYLED, BlockType.OL, BlockType.UL, BlockType.CODE];
export var getCurrentBlock = function getCurrentBlock(editorState) {
  var selectionState = editorState.getSelection();
  var contentState = editorState.getCurrentContent();
  var block = contentState.getBlockForKey(selectionState.getStartKey());
  return block;
};
export var resetBlockType = function resetBlockType(editorState) {
  var newType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : BlockType.UNSTYLED;
  var initialData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var content = editorState.getCurrentContent();
  var selection = editorState.getSelection();
  var key = selection.getStartKey();
  var blockMap = content.getBlockMap();
  var block = blockMap.get(key);
  var newBlock = block.mergeDeep({
    type: newType,
    data: initialData
  });
  var newContent = content.merge({
    blockMap: blockMap.set(key, newBlock),
    selectionAfter: selection.merge({
      anchorOffset: 0,
      focusOffset: 0
    })
  });
  return EditorState.push(editorState, newContent, 'change-block-type');
};
export var addNewBlock = function addNewBlock(relatedPosition, editorState, block) {
  var properties = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {
    initialData: {},
    characterList: List(),
    blockType: BlockType.UNSTYLED,
    depth: 0
  };
  var content = editorState.getCurrentContent();
  var blockMap = content.getBlockMap();
  var blockKey = block.getKey();
  var blocksBefore = blockMap.toSeq().takeUntil(function (v) {
    return v === block;
  });
  var blocksAfter = blockMap.toSeq().skipUntil(function (v) {
    return v === block;
  }).rest();
  var selection = editorState.getSelection();
  var newBlockKey = genKey();
  var newBlock = new ContentBlock({
    key: newBlockKey,
    type: properties.blockType,
    text: '',
    characterList: properties.characterList,
    depth: properties.depth,
    data: Map(properties.initialData)
  });
  var newBlockMap = blocksBefore.concat(relatedPosition === 'before' ? [[newBlockKey, newBlock], [blockKey, block]] : [[blockKey, block], [newBlockKey, newBlock]], blocksAfter).toOrderedMap();
  var newContent = content.merge({
    blockMap: newBlockMap,
    selectionBefore: selection,
    selectionAfter: selection.merge({
      anchorKey: newBlockKey,
      anchorOffset: 0,
      focusKey: newBlockKey,
      focusOffset: 0,
      isBackward: false
    })
  });
  return EditorState.push(editorState, newContent, 'split-block');
};
export var getAtomicBlockType = function getAtomicBlockType(block, contentState) {
  return getEntityTypeAt(0, block, contentState);
};
export var getIsBlockSelected = function getIsBlockSelected(block) {
  return Boolean(block.getData().get('selected'));
};

var getEntityTypeAt = function getEntityTypeAt(offset, block, contentState) {
  var entityKey = block.getEntityAt(offset);

  if (entityKey === null) {
    return null;
  }

  var entity = contentState.getEntity(entityKey);
  return entity.getType();
};

export { default as adjustBlockDepthForContentState } from './adjustBlockDepthForContentState';
export { default as getBlockEntities } from './getBlockEntities';
export { default as getBlockHighlightPosition } from './getBlockHighlightPosition';
export { default as getBlockRelatives } from './getBlockRelatives';
export { default as getBlocksAfter } from './getBlocksAfter';
export { default as getBlocksBefore } from './getBlocksBefore';
export { default as getNodeOffsetTop } from './getNodeOffsetTop';
export { default as getTableKeyFromBlock } from './getTableKeyFromBlock';
export { default as isBlockEmpty } from './isBlockEmpty';
export { default as isBlockInTableForTableKey } from './isBlockInTableForTableKey';
export { default as isBlockListItem } from './isBlockListItem';
export { default as isBlockUnstyledAndEmpty } from './isBlockUnstyledAndEmpty';
export { default as isHighlightedBlock } from './isHighlightedBlock';
export { default as moveBlock } from './moveBlock';
export { default as removeBlockFromState } from './removeBlockFromState';
export { default as removeEntity } from './removeEntity';
export { default as setBlockSelection } from './setBlockSelection';
export { default as updateBlockData } from './updateBlockData';
export { default as updateEntity } from './updateEntity';