import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _netmumsUrls, _aufemininUrls, _enFemeninoUrls, _alFemminileUrls, _goFemininDeUrls, _soFeminineUrls, _taoFemininoUrls, _weWomenBeUrls, _marmitonUrls, _doctissimoUrls, _beauteTestUrls, _mainUrls;

/* eslint-disable max-lines */
import { Sites } from "../../sites";
import { Stages } from "../../stages";
import { getUrlsForAContext } from "./utils";
var netmumsUrls = (_netmumsUrls = {}, _defineProperty(_netmumsUrls, Stages.DEV, getUrlsForAContext({
  domainUrl: 'localhost',
  siteUrl: 'http://localhost/coffeehouse',
  staticUrl: 'https://cdn.staging.nmums.doc-media.fr/netmums_awsstaging',
  assetsUrl: ''
})), _defineProperty(_netmumsUrls, Stages.STAGING, getUrlsForAContext({
  domainUrl: 'netmums.com',
  siteUrl: 'https://staging.netmums.com/coffeehouse',
  staticUrl: 'https://cdn.staging.nmums.doc-media.fr/netmums_awsstaging',
  assetsUrl: 'https://assets.netmums.com.unfy.dev'
})), _defineProperty(_netmumsUrls, Stages.PRODUCTION, getUrlsForAContext({
  domainUrl: 'netmums.com',
  siteUrl: 'https://www.netmums.com/coffeehouse',
  staticUrl: 'https://cdn.prod.nmums.doc-media.fr/netmums_awsprod',
  assetsUrl: 'https://assets.netmums.com'
})), _defineProperty(_netmumsUrls, Stages.PRODUCTION_LEGACY, getUrlsForAContext({
  domainUrl: 'netmums.com',
  siteUrl: 'https://www.netmums.com/coffeehouse',
  staticUrl: '',
  assetsUrl: ''
})), _netmumsUrls);
var aufemininUrls = (_aufemininUrls = {}, _defineProperty(_aufemininUrls, Stages.DEV, getUrlsForAContext({
  domainUrl: 'localhost',
  siteUrl: 'http://localhost/',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_aufemininUrls, Stages.STAGING, getUrlsForAContext({
  domainUrl: 'aufeminin.com.s.unfy.cloud',
  siteUrl: 'https://www.aufeminin.com.s.unfy.cloud/',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_aufemininUrls, Stages.PRODUCTION, getUrlsForAContext({
  domainUrl: 'aufeminin.com',
  siteUrl: 'https://www.aufeminin.com',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_aufemininUrls, Stages.PRODUCTION_LEGACY, getUrlsForAContext({
  domainUrl: 'aufeminin.com',
  siteUrl: 'https://www.aufeminin.com',
  staticUrl: '',
  assetsUrl: ''
})), _aufemininUrls);
var enFemeninoUrls = (_enFemeninoUrls = {}, _defineProperty(_enFemeninoUrls, Stages.DEV, getUrlsForAContext({
  domainUrl: 'localhost:3000',
  siteUrl: 'http://localhost:3000/',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_enFemeninoUrls, Stages.STAGING, getUrlsForAContext({
  domainUrl: 'enfemenino.com',
  siteUrl: 'https://www.enfemenino.com',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_enFemeninoUrls, Stages.PRODUCTION, getUrlsForAContext({
  domainUrl: 'enfemenino.com',
  siteUrl: 'https://www.enfemenino.com',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_enFemeninoUrls, Stages.PRODUCTION_LEGACY, getUrlsForAContext({
  domainUrl: 'enfemenino.com',
  siteUrl: 'https://www.enfemenino.com',
  staticUrl: '',
  assetsUrl: ''
})), _enFemeninoUrls);
var alFemminileUrls = (_alFemminileUrls = {}, _defineProperty(_alFemminileUrls, Stages.DEV, getUrlsForAContext({
  domainUrl: 'localhost',
  siteUrl: 'http://localhost/',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_alFemminileUrls, Stages.STAGING, getUrlsForAContext({
  domainUrl: 'alfemminile.com',
  siteUrl: 'https://www.alfemminile.com',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_alFemminileUrls, Stages.PRODUCTION, getUrlsForAContext({
  domainUrl: 'alfemminile.com',
  siteUrl: 'https://www.alfemminile.com',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_alFemminileUrls, Stages.PRODUCTION_LEGACY, getUrlsForAContext({
  domainUrl: 'alfemminile.com',
  siteUrl: 'https://www.alfemminile.com',
  staticUrl: '',
  assetsUrl: ''
})), _alFemminileUrls);
var goFemininDeUrls = (_goFemininDeUrls = {}, _defineProperty(_goFemininDeUrls, Stages.DEV, getUrlsForAContext({
  domainUrl: 'localhost',
  siteUrl: 'http://localhost/',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_goFemininDeUrls, Stages.STAGING, getUrlsForAContext({
  domainUrl: 'gofeminin.de',
  siteUrl: 'https://www.gofeminin.de',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_goFemininDeUrls, Stages.PRODUCTION, getUrlsForAContext({
  domainUrl: 'gofeminin.de',
  siteUrl: 'https://www.gofeminin.de',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_goFemininDeUrls, Stages.PRODUCTION_LEGACY, getUrlsForAContext({
  domainUrl: 'gofeminin.de',
  siteUrl: 'https://www.gofeminin.de',
  staticUrl: '',
  assetsUrl: ''
})), _goFemininDeUrls);
var soFeminineUrls = (_soFeminineUrls = {}, _defineProperty(_soFeminineUrls, Stages.DEV, getUrlsForAContext({
  domainUrl: 'localhost',
  siteUrl: 'http://localhost/',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_soFeminineUrls, Stages.STAGING, getUrlsForAContext({
  domainUrl: 'sofeminine.co.uk',
  siteUrl: 'https://www.sofeminine.co.uk',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_soFeminineUrls, Stages.PRODUCTION, getUrlsForAContext({
  domainUrl: 'sofeminine.co.uk',
  siteUrl: 'https://www.sofeminine.co.uk',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_soFeminineUrls, Stages.PRODUCTION_LEGACY, getUrlsForAContext({
  domainUrl: 'sofeminine.co.uk',
  siteUrl: 'https://www.sofeminine.co.uk',
  staticUrl: '',
  assetsUrl: ''
})), _soFeminineUrls);
var taoFemininoUrls = (_taoFemininoUrls = {}, _defineProperty(_taoFemininoUrls, Stages.DEV, getUrlsForAContext({
  domainUrl: 'localhost',
  siteUrl: 'http://localhost/',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_taoFemininoUrls, Stages.STAGING, getUrlsForAContext({
  domainUrl: 'taofeminino.com.br',
  siteUrl: 'https://www.taofeminino.com.br',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_taoFemininoUrls, Stages.PRODUCTION, getUrlsForAContext({
  domainUrl: 'taofeminino.com.br',
  siteUrl: 'https://www.taofeminino.com.br',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_taoFemininoUrls, Stages.PRODUCTION_LEGACY, getUrlsForAContext({
  domainUrl: 'taofeminino.com.br',
  siteUrl: 'https://www.taofeminino.com.br',
  staticUrl: '',
  assetsUrl: ''
})), _taoFemininoUrls);
var weWomenBeUrls = (_weWomenBeUrls = {}, _defineProperty(_weWomenBeUrls, Stages.DEV, getUrlsForAContext({
  domainUrl: 'localhost',
  siteUrl: 'http://localhost/',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_weWomenBeUrls, Stages.STAGING, getUrlsForAContext({
  domainUrl: 'alfemminile.com',
  siteUrl: 'https://www.enfemenino.com',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_weWomenBeUrls, Stages.PRODUCTION, getUrlsForAContext({
  domainUrl: 'alfemminile.com',
  siteUrl: 'https://www.alfemminile.com',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_weWomenBeUrls, Stages.PRODUCTION_LEGACY, getUrlsForAContext({
  domainUrl: 'alfemminile.com',
  siteUrl: 'https://www.alfemminile.com',
  staticUrl: '',
  assetsUrl: ''
})), _weWomenBeUrls);
var marmitonUrls = (_marmitonUrls = {}, _defineProperty(_marmitonUrls, Stages.DEV, getUrlsForAContext({
  domainUrl: 'localhost:3000',
  siteUrl: 'http://localhost:3000/',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_marmitonUrls, Stages.STAGING, getUrlsForAContext({
  domainUrl: 'marmiton.org',
  siteUrl: 'https://www.marmiton.org',
  staticUrl: '',
  assetsUrl: 'https://assets.marmiton.org.unfy.dev'
})), _defineProperty(_marmitonUrls, Stages.PRODUCTION, getUrlsForAContext({
  domainUrl: 'marmiton.org',
  siteUrl: 'https://www.marmiton.org',
  staticUrl: '',
  assetsUrl: 'https://assets.marmiton.org'
})), _defineProperty(_marmitonUrls, Stages.PRODUCTION_LEGACY, getUrlsForAContext({
  domainUrl: 'marmiton.org',
  siteUrl: 'https://www.marmiton.org',
  staticUrl: '',
  assetsUrl: ''
})), _marmitonUrls);
var doctissimoUrls = (_doctissimoUrls = {}, _defineProperty(_doctissimoUrls, Stages.DEV, getUrlsForAContext({
  domainUrl: 'doctissimo.fr.l.unfy.cloud',
  siteUrl: 'http://localhost:3000/',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_doctissimoUrls, Stages.STAGING, getUrlsForAContext({
  domainUrl: 'doctissimo.fr.s.unfy.cloud',
  siteUrl: 'https://www.doctissimo.fr.s.unfy.cloud',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_doctissimoUrls, Stages.PRODUCTION, getUrlsForAContext({
  domainUrl: 'doctissimo.fr',
  siteUrl: 'https://www.doctissimo.fr',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_doctissimoUrls, Stages.PRODUCTION_LEGACY, getUrlsForAContext({
  domainUrl: 'doctissimo.fr',
  siteUrl: 'https://www.doctissimo.fr',
  staticUrl: '',
  assetsUrl: ''
})), _doctissimoUrls);
var beauteTestUrls = (_beauteTestUrls = {}, _defineProperty(_beauteTestUrls, Stages.DEV, getUrlsForAContext({
  domainUrl: 'beaute-test.com.l.unfy.cloud',
  siteUrl: 'http://localhost:3000/',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_beauteTestUrls, Stages.STAGING, getUrlsForAContext({
  domainUrl: 'beaute-test.com.s.unfy.cloud',
  siteUrl: 'https://www.beaute-test.com.s.unfy.cloud',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_beauteTestUrls, Stages.PRODUCTION, getUrlsForAContext({
  domainUrl: 'beaute-test.com',
  siteUrl: 'https://www.beaute-test.com',
  staticUrl: '',
  assetsUrl: ''
})), _defineProperty(_beauteTestUrls, Stages.PRODUCTION_LEGACY, getUrlsForAContext({
  domainUrl: 'beaute-test.com',
  siteUrl: 'https://www.beaute-test.com',
  staticUrl: '',
  assetsUrl: ''
})), _beauteTestUrls);
export var mainUrls = (_mainUrls = {}, _defineProperty(_mainUrls, Sites.NETMUMS_COM, netmumsUrls), _defineProperty(_mainUrls, Sites.AUFEMININ_COM, aufemininUrls), _defineProperty(_mainUrls, Sites.ENFEMENINO_COM, enFemeninoUrls), _defineProperty(_mainUrls, Sites.ALFEMMINILE_COM, alFemminileUrls), _defineProperty(_mainUrls, Sites.GOFEMININ_DE, goFemininDeUrls), _defineProperty(_mainUrls, Sites.SOFEMININE_CO_UK, soFeminineUrls), _defineProperty(_mainUrls, Sites.TAOFEMININO_COM_BR, taoFemininoUrls), _defineProperty(_mainUrls, Sites.WEWOMEN_BE, weWomenBeUrls), _defineProperty(_mainUrls, Sites.MARMITON_ORG, marmitonUrls), _defineProperty(_mainUrls, Sites.DOCTISSIMO_FR, doctissimoUrls), _defineProperty(_mainUrls, Sites.BEAUTE_TEST_COM, beauteTestUrls), _mainUrls);