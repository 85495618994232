export var PAGE_TEST_START_PATH = '/test-';
export var PageBuildState;

(function (PageBuildState) {
  PageBuildState["Done"] = "done";
  PageBuildState["InProgress"] = "in_progress";
  PageBuildState["Queued"] = "queued";
})(PageBuildState || (PageBuildState = {}));

export var PageStatus;

(function (PageStatus) {
  PageStatus["Alive"] = "ALIVE";
  PageStatus["Killed"] = "KILLED";
  PageStatus["Redirected"] = "REDIRECTED";
})(PageStatus || (PageStatus = {}));