/**
 * Return the entities defined in `block`
 */
var getBlockEntities = function getBlockEntities(editorState, block) {
  var contentState = editorState.getCurrentContent();
  var blockEntities = []; // fill `blockEntities` with the entities used in the block

  block.findEntityRanges(function (character) {
    return character.getEntity() !== null;
  }, function (start) {
    var entityKey = block.getEntityAt(start);
    var entity = contentState.getEntity(entityKey);
    return blockEntities.push({
      key: entityKey,
      type: entity.getType(),
      blockKey: block.getKey()
    });
  });
  return blockEntities;
};

export default getBlockEntities;