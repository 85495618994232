import { EditorState, Modifier } from 'draft-js';
import { entitiesConfig } from 'components/common/RichTextEditor/types';
import { getEntitySelection } from '../entity';

var removeEntity = function removeEntity(editorState, entityKey) {
  var contentState = editorState.getCurrentContent();
  var linkEntity = contentState.getEntity(entityKey);
  var entityConfig = entitiesConfig[linkEntity.getType()];

  if ((entityConfig === null || entityConfig === void 0 ? void 0 : entityConfig.removeEntity) !== undefined) {
    entityConfig.removeEntity(linkEntity.getData());
  }

  var contentStateWithoutEntity = contentState;

  try {
    var entitySelectionState = getEntitySelection(editorState, entityKey);
    contentStateWithoutEntity = Modifier.applyEntity(contentState, entitySelectionState, null);
  } catch (error) {
    console.error(error.message);
  }

  return EditorState.push(editorState, contentStateWithoutEntity, 'apply-entity');
};

export default removeEntity;