import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import compact from 'lodash/compact';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { Sites } from '@uno/common-shared';
import { BaseContentTypeName, Fields, GroupFields } from "../../FieldNames";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { DoctissimoContentTypeName } from "./ContentTypeName";
import { getManualModificationDate, mainGroupField, propertiesGroupField, semanticScoreFields, seoGroupField } from "./common";
var baseLabelId = BaseContentTypeName.Article;
var fields = [mainGroupField, _objectSpread(_objectSpread({}, seoGroupField), {}, {
  fields: [].concat(_toConsumableArray(seoGroupField.fields), [semanticScoreFields(GroupFields.ARTICLE)])
}), propertiesGroupField];
export var article = {
  site: Sites.DOCTISSIMO_FR,
  name: DoctissimoContentTypeName.Article,
  labelId: 'content-type.article.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  getLabel: function getLabel(contentData) {
    return (// TODO: See to fix "Type instantiation is excessively deep and possibly infinite"
      // @ts-ignore Sorry too many fields
      contentData.title !== undefined ? contentData.title : ''
    );
  },
  getPreviewImage: function getPreviewImage(contentData) {
    var _ref;

    return (_ref = get(contentData, 'coverImages.default.imgUrl')) !== null && _ref !== void 0 ? _ref : null;
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds(contentData) {
    var _contentData$authors;

    return uniq(compact((contentData === null || contentData === void 0 ? void 0 : (_contentData$authors = contentData.authors) === null || _contentData$authors === void 0 ? void 0 : _contentData$authors.length) !== undefined ? contentData.authors.map(function (_ref2) {
      var authorId = _ref2.authorId;
      return authorId;
    }) : []));
  },
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  getMainSectionId: function getMainSectionId(contentData) {
    var _contentData$mainSect, _contentData$mainSect2;

    return (_contentData$mainSect = contentData === null || contentData === void 0 ? void 0 : (_contentData$mainSect2 = contentData.mainSection) === null || _contentData$mainSect2 === void 0 ? void 0 : _contentData$mainSect2[0]) !== null && _contentData$mainSect !== void 0 ? _contentData$mainSect : '';
  },
  getManualModificationDate: getManualModificationDate,
  fields: fields,
  partialDependencyFields: [Fields.AUTHORS, Fields.CHAPO, Fields.COVER_IMAGES, Fields.TITLE],
  isLinkable: true,
  isCreatable: true
};