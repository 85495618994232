import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Fields, GroupFields } from "../../../FieldNames";
import { createdMetaDateField, modifiedMetaDateField, publicationMetaDateField } from "../../../common/fieldDefinitions/metaDate";
import { legacyBOUrlReadOnlyField, legacyIdReadOnlyField, legacyPathLabelReadOnlyField } from "../../../common/fieldDefinitions/readOnly";
import { descriptionRichTextField } from "../../../common/fieldDefinitions/richText";
import { nameTextField, pluralNameTextField } from "../../../common/fieldDefinitions/simpleText";
import { getGroupLabelId, imageFieldValidation } from "../../../../helpers";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { BlockType, FieldType } from "../../../../types/ContentType";
export var NutriscoreOptions;

(function (NutriscoreOptions) {
  NutriscoreOptions["A"] = "A";
  NutriscoreOptions["B"] = "B";
  NutriscoreOptions["C"] = "C";
  NutriscoreOptions["D"] = "D";
  NutriscoreOptions["E"] = "E";
})(NutriscoreOptions || (NutriscoreOptions = {}));

export var ingredientGroupFields = {
  type: FieldType.Group,
  name: GroupFields.INGREDIENT,
  labelId: getGroupLabelId(GroupFields.INGREDIENT),
  fields: [_objectSpread(_objectSpread({}, nameTextField), {}, {
    validation: nameTextField.validation.required('form.required')
  }), _objectSpread(_objectSpread({}, pluralNameTextField), {}, {
    validation: pluralNameTextField.validation.required('form.required')
  }), {
    type: FieldType.Group,
    name: GroupFields.NUTRISCORE,
    labelId: getGroupLabelId(GroupFields.NUTRISCORE),
    fields: [{
      type: FieldType.Select,
      name: Fields.SCORE,
      labelId: getFieldLabelId(Fields.SCORE),
      defaultValue: null,
      allowEmpty: false,
      options: Object.values(NutriscoreOptions).map(function (option) {
        return {
          option: option,
          labelId: option
        };
      })
    }, {
      type: FieldType.Switch,
      name: Fields.SHOW_NUTRISCORE,
      labelId: getFieldLabelId(Fields.SHOW_NUTRISCORE),
      defaultValue: false
    }]
  }, {
    type: FieldType.Group,
    name: GroupFields.ALLERGENS,
    labelId: getGroupLabelId(GroupFields.ALLERGENS),
    fields: [{
      type: FieldType.Switch,
      name: Fields.IS_EGG,
      labelId: getFieldLabelId(Fields.IS_EGG),
      defaultValue: false
    }, {
      type: FieldType.Switch,
      name: Fields.IS_LACTOSE,
      labelId: getFieldLabelId(Fields.IS_LACTOSE),
      defaultValue: false
    }, {
      type: FieldType.Switch,
      name: Fields.IS_GLUTEN,
      labelId: getFieldLabelId(Fields.IS_GLUTEN),
      defaultValue: false
    }, {
      type: FieldType.Switch,
      name: Fields.IS_NUTS,
      labelId: getFieldLabelId(Fields.IS_NUTS),
      defaultValue: false
    }]
  }, {
    type: FieldType.Group,
    name: GroupFields.DIET,
    labelId: getGroupLabelId(GroupFields.DIET),
    fields: [{
      type: FieldType.Switch,
      name: Fields.IS_VEGETARIAN,
      labelId: getFieldLabelId(Fields.IS_VEGETARIAN),
      defaultValue: false
    }, {
      type: FieldType.Switch,
      name: Fields.IS_VEGAN,
      labelId: getFieldLabelId(Fields.IS_VEGAN),
      defaultValue: false
    }, {
      type: FieldType.Switch,
      name: Fields.IS_PORK,
      labelId: getFieldLabelId(Fields.IS_PORK),
      defaultValue: false
    }, {
      type: FieldType.Switch,
      name: Fields.IS_FISH,
      labelId: getFieldLabelId(Fields.IS_FISH),
      defaultValue: false
    }]
  }, {
    type: FieldType.Group,
    name: GroupFields.SEASON,
    labelId: getGroupLabelId(GroupFields.SEASON),
    fields: [{
      type: FieldType.Date,
      name: Fields.START_DATE,
      labelId: getFieldLabelId(Fields.START_DATE),
      defaultValue: null
    }, {
      type: FieldType.Date,
      name: Fields.END_DATE,
      labelId: getFieldLabelId(Fields.END_DATE),
      defaultValue: null
    }]
  }, {
    type: FieldType.Group,
    name: GroupFields.DESCRIPTION,
    labelId: getGroupLabelId(GroupFields.DESCRIPTION),
    fields: [_objectSpread(_objectSpread({}, descriptionRichTextField), {}, {
      labelId: ' ',
      enabledBlocks: [BlockType.UNSTYLED]
    })]
  }]
};
export var ingredientPropertiesGroupFields = {
  type: FieldType.Group,
  name: GroupFields.PROPERTIES,
  labelId: getGroupLabelId(GroupFields.PROPERTIES),
  fields: [{
    type: FieldType.Group,
    name: GroupFields.VISUAL,
    labelId: getGroupLabelId(GroupFields.VISUAL),
    fields: [{
      type: FieldType.Image,
      name: Fields.IMAGE,
      labelId: getFieldLabelId(Fields.IMAGE),
      defaultValue: {
        imgUrl: undefined,
        legend: '',
        credits: ''
      },
      ratio: {
        width: 3,
        height: 2
      },
      constraints: {
        maxWidth: 3000
      },
      validation: imageFieldValidation
    }]
  }, {
    type: FieldType.Group,
    name: GroupFields.DATES,
    labelId: getGroupLabelId(GroupFields.DATES),
    fields: [publicationMetaDateField, createdMetaDateField, modifiedMetaDateField]
  }, {
    type: FieldType.Group,
    name: GroupFields.LEGACY,
    labelId: getGroupLabelId(GroupFields.LEGACY),
    fields: [legacyIdReadOnlyField, legacyPathLabelReadOnlyField, legacyBOUrlReadOnlyField]
  }]
};