import { AtomicBlockType as DraftjsAtomicBlockType } from '@uno/common-cms';
import { addAtomicBlock } from '../../util';
export var transformImageToDraftjsAndAppend = function transformImageToDraftjsAndAppend(_ref, rawDraftContentState) {
  var imgUrl = _ref.imgUrl,
      legend = _ref.legend,
      credits = _ref.credits,
      _originalImage = _ref._originalImage,
      imageAnchor = _ref.imageAnchor;
  return addAtomicBlock(rawDraftContentState, DraftjsAtomicBlockType.IMAGE, {
    blockData: {
      openModal: false
    },
    entityData: {
      imgUrl: imgUrl,
      legend: legend,
      credits: credits,
      _originalImage: _originalImage,
      imageAnchor: imageAnchor
    }
  }, 'IMMUTABLE');
};