import { Sites } from '@uno/common-shared';
import { BaseContentTypeName, Fields } from "../../FieldNames";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { MarmitonContentTypeName } from "./ContentTypeName";
import { ingredientGroupFields, ingredientPropertiesGroupFields } from "./common";
var baseLabelId = BaseContentTypeName.Ingredient;
var fields = [ingredientGroupFields, ingredientPropertiesGroupFields];
export var ingredient = {
  site: Sites.MARMITON_ORG,
  name: MarmitonContentTypeName.Ingredient,
  labelId: 'content-type.ingredient.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  getLabel: function getLabel(contentData) {
    return contentData.name !== undefined ? contentData.name : '';
  },
  getPreviewImage: function getPreviewImage() {
    return null;
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  getMainSectionId: function getMainSectionId() {
    return '';
  },
  getManualModificationDate: function getManualModificationDate() {
    return undefined;
  },
  fields: fields,
  partialDependencyFields: [Fields.NAME],
  isLinkable: true,
  isCreatable: true
};