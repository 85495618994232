import { BlockType } from '@uno/common-cms';
import isBlockInTableForTableKey from './isBlockInTableForTableKey';

var setBlockSelection = function setBlockSelection(editorState, blockKey, updateBlockData, selected) {
  var contentState = editorState.getCurrentContent();
  var block = contentState.getBlockForKey(blockKey);
  var blockMap = contentState.getBlockMap();

  if (block.getType() === BlockType.TABLE) {
    var _ref = block.getData().toObject(),
        tableKey = _ref.tableKey;

    block = blockMap.skipUntil(function (vBlock) {
      return isBlockInTableForTableKey(vBlock, tableKey);
    }).first();
  }

  updateBlockData(block.getKey(), {
    selected: selected
  });
};

export default setBlockSelection;