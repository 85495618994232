export var AufemininPageConfigName;

(function (AufemininPageConfigName) {
  AufemininPageConfigName["Article"] = "AUFEMININ_ARTICLE_PAGE";
  AufemininPageConfigName["Author"] = "AUFEMININ_AUTHOR_PAGE";
  AufemininPageConfigName["Feed"] = "AUFEMININ_FEED_PAGE";
  AufemininPageConfigName["Home"] = "AUFEMININ_HOME_PAGE";
  AufemininPageConfigName["SectionHome"] = "AUFEMININ_SECTION_HOME_PAGE";
  AufemininPageConfigName["SlideshowFullPage"] = "AUFEMININ_SLIDESHOW_FULLPAGE";
  AufemininPageConfigName["SlideshowV2"] = "AUFEMININ_SLIDESHOW_V2";
  AufemininPageConfigName["TagPage"] = "AUFEMININ_TAGPAGE_PAGE";
})(AufemininPageConfigName || (AufemininPageConfigName = {}));