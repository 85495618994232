import { GenericBlockType } from '@uno/common-cms';
import appendGenericBlock from '../../util/appendGenericBlock';
export var transformVideoToGenericAndAppend = function transformVideoToGenericAndAppend(block, genericBlocks, contentState) {
  var _ref = contentState.getEntity(block.getEntityAt(0)).getData(),
      imgUrl = _ref.imgUrl,
      title = _ref.title,
      videoId = _ref.videoId,
      videoIframeUrl = _ref.videoIframeUrl;

  var newBlock = {
    type: GenericBlockType.Video,
    imgUrl: imgUrl,
    title: title,
    videoId: videoId,
    videoIframeUrl: videoIframeUrl
  };
  return appendGenericBlock(genericBlocks, newBlock, block);
};