/* eslint-disable max-lines */
import { AffiliationBlockType, GenericBlockType, GenericInlineDataType, GenericInlineStyleType, ListItemType } from "../../../types";
var genericContentStateFixture = {
  blocks: [{
    type: GenericBlockType.H2,
    text: 'mon super h2'
  }, {
    type: GenericBlockType.Unstyled,
    text: 'mon paragraph avec du texte en gras et en italique, avec un lien externe et un lien interne.',
    inlineStyleRanges: [{
      type: GenericInlineStyleType.Bold,
      offset: 32,
      length: 4
    }, {
      type: GenericInlineStyleType.Underline,
      offset: 43,
      length: 8
    }],
    inlineDataRanges: [{
      type: GenericInlineDataType.ExternalLink,
      offset: 61,
      length: 12,
      data: {
        attributes: {
          target: '_blank',
          rel: 'nofollow'
        },
        url: 'https://www.google.fr'
      }
    }, {
      type: GenericInlineDataType.InternalLink,
      offset: 80,
      length: 12,
      data: {
        attributes: {
          target: '_blank',
          rel: 'nofollow'
        },
        contentId: 'a377a789-cb56-44b2-85ff-5ddc20c8abdb',
        title: 'Mon titre'
      }
    }]
  }, {
    type: GenericBlockType.H3,
    text: 'mon super h3'
  }, {
    type: GenericBlockType.Image,
    imgUrl: 'https://resize2.prod.docfr.doc-media.fr/rcrop/650,380,center-middle/img/var/doctissimo/storage/images/fr/www/sante/epidemie/coronavirus-chinois/vaccin-covid-19-preparation-ehpad/8518996-1-fre-FR/vaccin-covid-19-preparation-ehpad.jpg',
    legend: 'My legend',
    credits: 'My credits'
  }, {
    type: GenericBlockType.Video,
    imgUrl: 'https://resize2.prod.docfr.doc-media.fr/rcrop/650,380,center-middle/img/var/doctissimo/storage/images/fr/www/sante/epidemie/coronavirus-chinois/vaccin-covid-19-preparation-ehpad/8518996-1-fre-FR/vaccin-covid-19-preparation-ehpad.jpg',
    title: 'Ma super vidéo',
    videoId: '48970',
    videoIframeUrl: 'https://dfp.doctissimo.fr/reloaded/esi/videoplayer.php?videoid=634920'
  }, {
    type: GenericBlockType.Embed,
    embedData: '<iframe src="https://www.google.fr"></iframe>'
  }, {
    type: GenericBlockType.List,
    items: [{
      depth: 0,
      type: ListItemType.Unordered,
      text: 'item list 1',
      inlineStyleRanges: [],
      inlineDataRanges: []
    }, {
      depth: 0,
      type: ListItemType.Unordered,
      text: 'item list 2',
      inlineStyleRanges: [],
      inlineDataRanges: []
    }, {
      depth: 1,
      type: ListItemType.Unordered,
      text: 'item list 21',
      inlineStyleRanges: [],
      inlineDataRanges: []
    }, {
      depth: 1,
      type: ListItemType.Unordered,
      text: 'item list 22',
      inlineStyleRanges: [],
      inlineDataRanges: []
    }, {
      depth: 0,
      type: ListItemType.Unordered,
      text: 'item list 3',
      inlineStyleRanges: [],
      inlineDataRanges: []
    }]
  }, {
    type: GenericBlockType.Table,
    tableData: [[{
      text: 'ma case 00',
      inlineStyleRanges: [],
      inlineDataRanges: []
    }, {
      text: 'ma case 01',
      inlineStyleRanges: [],
      inlineDataRanges: []
    }, {
      text: 'ma case 02',
      inlineStyleRanges: [],
      inlineDataRanges: []
    }], [{
      text: 'ma case 10',
      inlineStyleRanges: [],
      inlineDataRanges: []
    }, {
      text: 'ma case 11',
      inlineStyleRanges: [],
      inlineDataRanges: []
    }, {
      text: 'ma case 12',
      inlineStyleRanges: [],
      inlineDataRanges: []
    }]]
  }, {
    type: GenericBlockType.SlideshowEmbed,
    contentId: 'a377a789-cb56-44b2-85ff-5ddc20c8abdb'
  }, {
    type: GenericBlockType.Unstyled,
    text: 'du texte simple :)',
    inlineStyleRanges: [],
    inlineDataRanges: []
  }, {
    type: GenericBlockType.Highlighted,
    blocks: [{
      type: GenericBlockType.H2,
      text: 'mon super h2 highlighted'
    }]
  }, {
    type: GenericBlockType.H2,
    text: 'mon h2 entre highlighted'
  }, {
    type: GenericBlockType.Highlighted,
    blocks: [{
      type: GenericBlockType.H2,
      text: 'h2 group highlighted'
    }, {
      type: GenericBlockType.Unstyled,
      text: 'du texte simple highlighted',
      inlineStyleRanges: [],
      inlineDataRanges: []
    }]
  }, {
    type: GenericBlockType.H2,
    text: 'mon h2 avant list highlighted'
  }, {
    type: GenericBlockType.Highlighted,
    blocks: [{
      type: GenericBlockType.List,
      items: [{
        depth: 0,
        type: ListItemType.Unordered,
        text: 'highlighted item list 1',
        inlineStyleRanges: [],
        inlineDataRanges: []
      }, {
        depth: 0,
        type: ListItemType.Unordered,
        text: 'highlighted item list 2',
        inlineStyleRanges: [],
        inlineDataRanges: []
      }, {
        depth: 1,
        type: ListItemType.Unordered,
        text: 'highlighted item list 21',
        inlineStyleRanges: [],
        inlineDataRanges: []
      }, {
        depth: 1,
        type: ListItemType.Unordered,
        text: 'highlighted item list 22',
        inlineStyleRanges: [],
        inlineDataRanges: []
      }, {
        depth: 0,
        type: ListItemType.Unordered,
        text: 'highlighted item list 3',
        inlineStyleRanges: [],
        inlineDataRanges: []
      }]
    }]
  }, {
    type: GenericBlockType.Affiliation,
    affiliationId: 'a377a789-cb56-44b2-85ff-5ddc20c8abdb',
    title: "Mon premier bloc d'affiliation",
    affiliationType: AffiliationBlockType.Cta,
    products: [{
      image: undefined,
      retailerNumber: undefined
    }]
  }, {
    type: GenericBlockType.Affiliation,
    affiliationId: 'a377a789-cb56-44b2-85ff-5ddc20c8abdb',
    title: "Mon second bloc d'affiliation",
    affiliationType: AffiliationBlockType.PriceTable,
    products: [{
      image: {
        imgUrl: 'image.url',
        legend: '',
        credits: ''
      },
      retailerNumber: 4
    }]
  }, {
    type: GenericBlockType.Affiliation,
    affiliationId: 'a377a789-cb56-44b2-85ff-5ddc20c8abdb',
    title: "Mon troisième bloc d'affiliation",
    affiliationType: AffiliationBlockType.Slideshow,
    products: [{
      title: 'produit 1',
      image: {
        imgUrl: 'image1.url',
        legend: '',
        credits: ''
      },
      retailerNumber: undefined
    }, {
      title: 'produit 2',
      image: {
        imgUrl: 'image2.url',
        legend: '',
        credits: ''
      },
      retailerNumber: undefined
    }]
  }, {
    type: GenericBlockType.Beop
  }, {
    type: GenericBlockType.Recipe,
    recipeId: 'recipe-id'
  }, {
    type: GenericBlockType.Blockquote,
    text: 'ma citation',
    inlineStyleRanges: [],
    inlineDataRanges: []
  }]
};
export default genericContentStateFixture;