import styled from 'styled-components';
import { colorUsage, getSpacing, transitionDuration } from 'stylesheet';
var GenericBlockToggle = styled.span.withConfig({
  displayName: "GenericBlockTogglestyle__GenericBlockToggle",
  componentId: "sc-q6i7ow-0"
})(["position:relative;display:flex;align-items:center;justify-content:center;width:24px;height:32px;&:not(:first-child){margin-left:", ";}color:", ";cursor:pointer;transition:color ", ";& path{transition:fill ", ";fill:", ";}&:hover{color:", ";& path{fill:", ";}}"], getSpacing(0.5), colorUsage.blockToolbarBlockForeground, transitionDuration["default"], transitionDuration["default"], colorUsage.blockToolbarBlockForeground, colorUsage.blockToolbarBlockHoverForeground, colorUsage.blockToolbarBlockHoverForeground);
var AddButtonBlock = styled(GenericBlockToggle).withConfig({
  displayName: "GenericBlockTogglestyle__AddButtonBlock",
  componentId: "sc-q6i7ow-1"
})(["transition:stroke ", ";stroke:", ";&:hover{stroke:", ";}"], transitionDuration["default"], colorUsage.blockToolbarBlockForeground, colorUsage.blockToolbarBlockHoverForeground);
export { AddButtonBlock, GenericBlockToggle };