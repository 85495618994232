import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* eslint-disable max-lines */
import * as Yup from 'yup';
import { Sites } from '@uno/common-shared';
import { BaseContentTypeName, Fields, GroupFields } from "../../FieldNames";
import { contentLinkedContentField } from "../../common/fieldDefinitions/linkedContent";
import { sizeNumberField, weekNumberField, weightNumberField } from "../../common/fieldDefinitions/number";
import { pregnancyPeriodSelectField } from "../../common/fieldDefinitions/select";
import { titleTextField } from "../../common/fieldDefinitions/simpleText";
import { getFieldLabelId, getGroupLabelId, getOptionLabelId, imageFieldValidation } from "../../../helpers";
import { FieldType } from "../../../types/ContentType";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { DoctissimoContentTypeName } from "./ContentTypeName";
import { getManualModificationDate } from "./common";
var baseLabelId = BaseContentTypeName.PregnancyAppWeek;
export var PregnancyPeriodOptions;

(function (PregnancyPeriodOptions) {
  PregnancyPeriodOptions["PRENATAL"] = "prenatal";
  PregnancyPeriodOptions["POSTNATAL"] = "postnatal";
})(PregnancyPeriodOptions || (PregnancyPeriodOptions = {}));

var fields = [{
  type: FieldType.Group,
  name: GroupFields.PREGNANCY_APP_WEEK,
  labelId: getGroupLabelId(GroupFields.PREGNANCY_APP_WEEK),
  fields: [titleTextField, _objectSpread(_objectSpread({}, pregnancyPeriodSelectField), {}, {
    defaultValue: PregnancyPeriodOptions.PRENATAL,
    options: Object.values(PregnancyPeriodOptions).map(function (option) {
      return {
        option: option,
        labelId: getOptionLabelId(baseLabelId, Fields.PREGNANCY_PERIOD, option)
      };
    })
  }), weekNumberField, weightNumberField, sizeNumberField, {
    type: FieldType.Group,
    name: GroupFields.IMAGES,
    labelId: getGroupLabelId(GroupFields.IMAGES),
    fields: [{
      type: FieldType.Image,
      name: Fields.IMAGE_FOETUS,
      labelId: getFieldLabelId(Fields.IMAGE_FOETUS),
      defaultValue: {
        imgUrl: undefined,
        legend: '',
        credits: ''
      },
      ratio: {
        width: 3,
        height: 2
      },
      constraints: {
        maxWidth: 3000
      },
      validation: imageFieldValidation
    }, {
      type: FieldType.Image,
      name: Fields.IMAGE_FRUIT,
      labelId: getFieldLabelId(Fields.IMAGE_FRUIT),
      defaultValue: {
        imgUrl: undefined,
        legend: '',
        credits: ''
      },
      ratio: {
        width: 3,
        height: 2
      },
      constraints: {
        maxWidth: 3000
      },
      validation: imageFieldValidation
    }]
  }, {
    type: FieldType.Group,
    name: GroupFields.TOP_ARTICLES,
    labelId: getGroupLabelId(GroupFields.TOP_ARTICLES),
    fields: [{
      type: FieldType.Repeatable,
      name: Fields.TOP_ARTICLES,
      labelId: getFieldLabelId(Fields.TOP_ARTICLES),
      defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
      validation: Yup.array(),
      field: _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
        contentTypeNames: [DoctissimoContentTypeName.Article, DoctissimoContentTypeName.News]
      })
    }]
  }, {
    type: FieldType.Group,
    name: GroupFields.TOP_VIDEOS,
    labelId: getGroupLabelId(GroupFields.TOP_VIDEOS),
    fields: [{
      type: FieldType.Repeatable,
      name: Fields.TOP_VIDEOS,
      labelId: getFieldLabelId(Fields.TOP_VIDEOS),
      defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
      validation: Yup.array(),
      field: _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
        contentTypeNames: [DoctissimoContentTypeName.Video, DoctissimoContentTypeName.News]
      })
    }]
  }, {
    type: FieldType.Group,
    name: GroupFields.APPOINTMENTS,
    labelId: getGroupLabelId(GroupFields.APPOINTMENTS),
    fields: [{
      type: FieldType.Repeatable,
      name: Fields.APPOINTMENTS,
      labelId: getFieldLabelId(Fields.APPOINTMENTS),
      defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
      validation: Yup.array(),
      field: _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
        contentTypeNames: [DoctissimoContentTypeName.News, DoctissimoContentTypeName.PregnancyAppAppointment]
      })
    }]
  }]
}, {
  type: FieldType.Group,
  name: GroupFields.HEALTH,
  labelId: getGroupLabelId(GroupFields.HEALTH),
  fields: [{
    type: FieldType.Group,
    name: GroupFields.PREGNANCY_ARTICLES_HEALTH,
    labelId: getGroupLabelId(GroupFields.PREGNANCY_ARTICLES_HEALTH),
    fields: [{
      type: FieldType.Repeatable,
      name: Fields.PREGNANCY_ARTICLE_HEALTH,
      labelId: getFieldLabelId(Fields.PREGNANCY_ARTICLE_HEALTH),
      defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
      validation: Yup.array(),
      field: _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
        contentTypeNames: [DoctissimoContentTypeName.Article, DoctissimoContentTypeName.News, DoctissimoContentTypeName.Slideshow]
      })
    }]
  }, {
    type: FieldType.Group,
    name: GroupFields.MULTI_PREGNANCY_ARTICLES_HEALTH,
    labelId: getGroupLabelId(GroupFields.MULTI_PREGNANCY_ARTICLES_HEALTH),
    fields: [{
      type: FieldType.Repeatable,
      name: Fields.MULTI_PREGNANCY_ARTICLE_HEALTH,
      labelId: getFieldLabelId(Fields.MULTI_PREGNANCY_ARTICLE_HEALTH),
      defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
      validation: Yup.array(),
      field: _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
        contentTypeNames: [DoctissimoContentTypeName.Article, DoctissimoContentTypeName.News, DoctissimoContentTypeName.Slideshow]
      })
    }]
  }]
}, {
  type: FieldType.Group,
  name: GroupFields.WELLNESS,
  labelId: getGroupLabelId(GroupFields.WELLNESS),
  fields: [{
    type: FieldType.Group,
    name: GroupFields.PREGNANCY_ARTICLES_WELLNESS,
    labelId: getGroupLabelId(GroupFields.PREGNANCY_ARTICLES_WELLNESS),
    fields: [{
      type: FieldType.Repeatable,
      name: Fields.PREGNANCY_ARTICLE_WELLNESS,
      labelId: getFieldLabelId(Fields.PREGNANCY_ARTICLE_WELLNESS),
      defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
      validation: Yup.array(),
      field: _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
        contentTypeNames: [DoctissimoContentTypeName.Article, DoctissimoContentTypeName.News, DoctissimoContentTypeName.Slideshow]
      })
    }]
  }, {
    type: FieldType.Group,
    name: GroupFields.MULTI_PREGNANCY_ARTICLES_WELLNESS,
    labelId: getGroupLabelId(GroupFields.MULTI_PREGNANCY_ARTICLES_WELLNESS),
    fields: [{
      type: FieldType.Repeatable,
      name: Fields.MULTI_PREGNANCY_ARTICLE_WELLNESS,
      labelId: getFieldLabelId(Fields.MULTI_PREGNANCY_ARTICLE_WELLNESS),
      defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
      validation: Yup.array(),
      field: _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
        contentTypeNames: [DoctissimoContentTypeName.Article, DoctissimoContentTypeName.News, DoctissimoContentTypeName.Slideshow]
      })
    }]
  }]
}];
export var pregnancyAppWeek = {
  site: Sites.DOCTISSIMO_FR,
  name: DoctissimoContentTypeName.PregnancyAppWeek,
  labelId: 'content-type.pregnancyAppWeek.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  // TODO: See to fix "Type instantiation is excessively deep and possibly infinite"
  // @ts-ignore Sorry too many fields
  getLabel: function getLabel(contentData) {
    return contentData.title;
  },
  // TODO: See to fix "Type instantiation is excessively deep and possibly infinite"
  getPreviewImage: function getPreviewImage(contentData) {
    var _ref8, _contentData$imageFoe, _contentData$imageFoe2, _contentData$imageFru;

    return (_ref8 = (_contentData$imageFoe = (_contentData$imageFoe2 = contentData.imageFoetus) === null || _contentData$imageFoe2 === void 0 ? void 0 : _contentData$imageFoe2.imgUrl) !== null && _contentData$imageFoe !== void 0 ? _contentData$imageFoe : (_contentData$imageFru = contentData.imageFruit) === null || _contentData$imageFru === void 0 ? void 0 : _contentData$imageFru.imgUrl) !== null && _ref8 !== void 0 ? _ref8 : null;
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  getMainSectionId: function getMainSectionId() {
    return '';
  },
  getManualModificationDate: getManualModificationDate,
  fields: fields,
  partialDependencyFields: [Fields.TITLE],
  isLinkable: true,
  isCreatable: true
};