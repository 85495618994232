import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

var isUpdatedField = function isUpdatedField(field) {
  return field !== undefined;
};

var updateFieldPropertyHelper = function updateFieldPropertyHelper(_ref) {
  var fields = _ref.fields,
      fieldPath = _ref.fieldPath,
      values = _ref.values;
  var pathLevels = fieldPath.split('.');
  var fieldName = pathLevels.shift(); // pathLevels contains remaining path to destination

  var field = find(fields, {
    name: fieldName
  });

  if (field === undefined) {
    return;
  }

  if (isUpdatedField(field) && isEmpty(pathLevels)) {
    // Empty pathLevels means we've reached destination fieldName: we can update it
    var _iterator = _createForOfIteratorHelper(values),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _step$value = _step.value,
            key = _step$value.key,
            value = _step$value.value;
        field[key] = value;
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return;
  }

  if ('fields' in field) {
    updateFieldPropertyHelper({
      fields: field.fields,
      fieldPath: pathLevels.join('.'),
      values: values
    });
  }

  if ('field' in field) {
    updateFieldPropertyHelper({
      fields: [field.field],
      fieldPath: pathLevels.join('.'),
      values: values
    });
  }
};

export var updateFieldsProperties = function updateFieldsProperties(_ref2) {
  var fields = _ref2.fields,
      fieldUpdates = _ref2.fieldUpdates;
  var clonedFields = cloneDeep(fields);
  Object.entries(fieldUpdates).forEach(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
        fieldPath = _ref4[0],
        values = _ref4[1];

    updateFieldPropertyHelper({
      fields: clonedFields,
      fieldPath: fieldPath,
      values: values
    });
  });
  return clonedFields;
};