import { EditorState, RichUtils } from 'draft-js';
import { useCallback } from 'react';
export var useHandleBlockToggleCallback = function useHandleBlockToggleCallback(blockType, setEditorState) {
  return useCallback(function () {
    return function (event) {
      event.preventDefault();
      setEditorState(function (editorState) {
        var stateAfterBlockToggle = RichUtils.toggleBlockType(editorState, blockType); // force selection after block toolbar block toggle

        var stateAfterCurrentBlockSelection = EditorState.forceSelection(stateAfterBlockToggle, stateAfterBlockToggle.getSelection());
        return stateAfterCurrentBlockSelection;
      });
    };
  }, [blockType, setEditorState]);
};