import styled from 'styled-components';
import { getSpacing, typography } from 'stylesheet';
export var Container = styled.div.withConfig({
  displayName: "AppCrashFallbackstyle__Container",
  componentId: "sc-14e0xve-0"
})(["display:flex;justify-content:center;"]);
export var PageContent = styled.div.withConfig({
  displayName: "AppCrashFallbackstyle__PageContent",
  componentId: "sc-14e0xve-1"
})(["padding:", " ", ";"], getSpacing(8), getSpacing(4));
export var Title = styled.h1.withConfig({
  displayName: "AppCrashFallbackstyle__Title",
  componentId: "sc-14e0xve-2"
})(["", ""], typography.h1);
export var HelperList = styled.ul.withConfig({
  displayName: "AppCrashFallbackstyle__HelperList",
  componentId: "sc-14e0xve-3"
})(["margin-top:", ";list-style:disc inside;"], getSpacing(2));