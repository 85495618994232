import { createStyles } from '@material-ui/core';
import { borderRadius, colorUsage, fontSize } from 'stylesheet';
var MuiButtonOverrides = createStyles({
  root: {
    borderRadius: borderRadius.medium,
    textTransform: 'unset',
    fontSize: fontSize.normal
  },
  outlinedPrimary: {
    '&:hover': {
      backgroundColor: colorUsage.muiOverride.button.primary.hoverBackground
    }
  },
  iconSizeMedium: {
    '& svg': {
      height: '20px',
      width: '20px'
    }
  }
});
export default MuiButtonOverrides;