import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _PersonList$AUTHORS, _PersonList$EXPERTS, _PERSON_LIST;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import invert from 'lodash/invert';
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import { Sites } from '@uno/common-shared';
import { DOCTISSIMO_HOST_IDS, Fields } from "../../../../../../config";
import { PartialPageChunkSchema } from "../../../../../page";
import { PersonListDoctissimoFr as PersonList, PersonTypeDoctissimoFr as PersonType } from "../../../../../../types";
var PERSON_LIST = (_PERSON_LIST = {}, _defineProperty(_PERSON_LIST, PersonList.AUTHORS, (_PersonList$AUTHORS = {
  chunkId: 'personList-authors-989c-a48351d34ceb'
}, _defineProperty(_PersonList$AUTHORS, Fields.PERSON_TYPE, PersonType.Author), _defineProperty(_PersonList$AUTHORS, "page", {
  siteId: Sites.DOCTISSIMO_FR,
  hostId: DOCTISSIMO_HOST_IDS.WWW,
  path: 'equipe/auteurs'
}), _PersonList$AUTHORS)), _defineProperty(_PERSON_LIST, PersonList.EXPERTS, (_PersonList$EXPERTS = {
  chunkId: 'personList-experts-aea3-34094f6baf9a'
}, _defineProperty(_PersonList$EXPERTS, Fields.PERSON_TYPE, PersonType.Expert), _defineProperty(_PersonList$EXPERTS, "page", {
  siteId: Sites.DOCTISSIMO_FR,
  hostId: DOCTISSIMO_HOST_IDS.WWW,
  path: 'equipe/les-experts-de-doctissimo'
}), _PersonList$EXPERTS)), _PERSON_LIST);

/** Associate a chunk id to a PersonList. key: chunk id ; value: PersonList */
var PERSON_LIST_CHUNK_IDS = invert(mapValues(PERSON_LIST, function (config) {
  return config.chunkId;
}));

if (!isEqual(Object.values(PERSON_LIST_CHUNK_IDS), Object.keys(PERSON_LIST))) {
  throw new Error('Invalid configuration: each PersonList configuration must have a unique chunk id');
}

var PersonListChunkSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, PartialPageChunkSchema.properties), {}, {
    _id: {
      "enum": Object.keys(PERSON_LIST_CHUNK_IDS)
    }
  }),
  required: _toConsumableArray(PartialPageChunkSchema.required)
};
export { PERSON_LIST as PERSON_LIST_DOCTISSIMO_FR, PERSON_LIST_CHUNK_IDS as PERSON_LIST_CHUNK_IDS_DOCTISSIMO_FR, PersonListChunkSchema as PersonListChunkSchemaDoctissimoFr };