/* eslint-disable complexity */
import isWithinInterval from 'date-fns/isWithinInterval';
import subDays from 'date-fns/subDays';
import isEmpty from 'lodash/isEmpty';
import { PublicationStatus } from '@uno/common-cms';

var dateValidator = function dateValidator(minDate, maxDate) {
  return function (current) {
    return !isWithinInterval(current, {
      start: subDays(minDate, 1),
      end: maxDate
    });
  };
};

var validateAgainstPublishDateOptions = function validateAgainstPublishDateOptions(pageMetaData, value) {
  // allow modification for loaded fields to be able to empty it
  if ((pageMetaData === null || pageMetaData === void 0 ? void 0 : pageMetaData.publicationStatus) === PublicationStatus.OFFLINE && !isEmpty(value)) {
    return {
      keyboardIcon: null
    };
  } // content is not published nor scheduled


  if ((pageMetaData === null || pageMetaData === void 0 ? void 0 : pageMetaData.publishedAt) === undefined || (pageMetaData === null || pageMetaData === void 0 ? void 0 : pageMetaData.publicationStatus) === PublicationStatus.SCHEDULED) {
    return {
      disabled: true
    };
  }

  var today = new Date();
  var publishDate = new Date(pageMetaData.publishedAt); // content published

  return {
    shouldDisableDate: dateValidator(publishDate, today)
  };
};

export default validateAgainstPublishDateOptions;