// Copied from Draft.js
var adjustBlockDepthForContentState = function adjustBlockDepthForContentState(contentState, selectionState, adjustment, maxDepth) {
  var startKey = selectionState.getStartKey();
  var endKey = selectionState.getEndKey();
  var blockMap = contentState.getBlockMap();
  var blocks = blockMap.toSeq().skipUntil(function (_, k) {
    return k === startKey;
  }).takeUntil(function (_, k) {
    return k === endKey;
  }).concat([[endKey, blockMap.get(endKey)]]).map(function (block) {
    var _block$getDepth;

    var depth = ((_block$getDepth = block === null || block === void 0 ? void 0 : block.getDepth()) !== null && _block$getDepth !== void 0 ? _block$getDepth : 0) + adjustment;
    depth = Math.max(0, depth);

    if (maxDepth != null) {
      depth = Math.min(depth, maxDepth);
    }

    return block === null || block === void 0 ? void 0 : block.set('depth', depth);
  });
  blockMap = blockMap.merge(blocks);
  return contentState.merge({
    blockMap: blockMap,
    selectionBefore: selectionState,
    selectionAfter: selectionState
  });
};

export default adjustBlockDepthForContentState;