export var AufemininTaxonomyName;

(function (AufemininTaxonomyName) {
  AufemininTaxonomyName["MainSectionFR"] = "AUFEMININ_COM_MAIN_SECTION";
  AufemininTaxonomyName["MainSectionES"] = "ENFEMENINO_COM_MAIN_SECTION";
  AufemininTaxonomyName["MainSectionIT"] = "ALFEMMINILE_COM_MAIN_SECTION";
  AufemininTaxonomyName["MainSectionDE"] = "GOFEMININ_DE_MAIN_SECTION";
  AufemininTaxonomyName["MainSectionUK"] = "SOFEMININE_CO_UK_MAIN_SECTION";
  AufemininTaxonomyName["MainSectionBR"] = "TAOFEMININO_COM_BR_MAIN_SECTION";
  AufemininTaxonomyName["MainSectionBE"] = "WEWOMEN_BE_MAIN_SECTION";
  AufemininTaxonomyName["TagSeoFR"] = "AUFEMININ_COM_TAG_SEO";
  AufemininTaxonomyName["TagSeoES"] = "ENFEMENINO_COM_TAG_SEO";
  AufemininTaxonomyName["TagSeoIT"] = "ALFEMMINILE_COM_TAG_SEO";
  AufemininTaxonomyName["TagSeoDE"] = "GOFEMININ_DE_MAIN_TAG_SEO";
  AufemininTaxonomyName["TagSeoUK"] = "SOFEMININE_CO_UK_TAG_SEO";
  AufemininTaxonomyName["TagSeoBR"] = "TAOFEMININO_COM_BR_TAG_SEO";
  AufemininTaxonomyName["TagSeoBE"] = "WEWOMEN_BE_TAG_SEO";
  AufemininTaxonomyName["TagPeopleFR"] = "AUFEMININ_COM_TAG_PEOPLE";
  AufemininTaxonomyName["TagPeopleES"] = "ENFEMENINO_COM_TAG_PEOPLE";
  AufemininTaxonomyName["TagPeopleIT"] = "ALFEMMINILE_COM_TAG_PEOPLE";
  AufemininTaxonomyName["TagPeopleDE"] = "GOFEMININ_DE_MAIN_TAG_PEOPLE";
  AufemininTaxonomyName["TagPeopleUK"] = "SOFEMININE_CO_UK_TAG_PEOPLE";
  AufemininTaxonomyName["TagPeopleBR"] = "TAOFEMININO_COM_BR_TAG_PEOPLE";
  AufemininTaxonomyName["TagOpsFR"] = "AUFEMININ_COM_TAG_OPS";
  AufemininTaxonomyName["TagOpsES"] = "ENFEMENINO_COM_TAG_OPS";
  AufemininTaxonomyName["TagOpsIT"] = "ALFEMMINILE_COM_TAG_OPS";
  AufemininTaxonomyName["TagOpsDE"] = "GOFEMININ_DE_MAIN_TAG_OPS";
  AufemininTaxonomyName["TagOpsUK"] = "SOFEMININE_CO_UK_TAG_OPS";
  AufemininTaxonomyName["TagOpsBR"] = "TAOFEMININO_COM_BR_TAG_OPS";
  AufemininTaxonomyName["TagOpsBE"] = "WEWOMEN_BE_TAG_OPS";
  AufemininTaxonomyName["TagNewsFR"] = "AUFEMININ_COM_TAG_NEWS";
  AufemininTaxonomyName["TagNewsES"] = "ENFEMENINO_COM_TAG_NEWS";
  AufemininTaxonomyName["TagNewsIT"] = "ALFEMMINILE_COM_TAG_NEWS";
  AufemininTaxonomyName["TagNewsDE"] = "GOFEMININ_DE_MAIN_TAG_NEWS";
  AufemininTaxonomyName["TagNewsUK"] = "SOFEMININE_CO_UK_TAG_NEWS";
  AufemininTaxonomyName["TagNewsBR"] = "TAOFEMININO_COM_BR_TAG_NEWS";
  AufemininTaxonomyName["TagNewsBE"] = "WEWOMEN_BE_TAG_NEWS";
})(AufemininTaxonomyName || (AufemininTaxonomyName = {}));