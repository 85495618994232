import { AtomicBlockType as DraftjsAtomicBlockType } from '@uno/common-cms';
import { addAtomicBlock } from '../../util';
export var transformRecipeToDraftjsAndAppend = function transformRecipeToDraftjsAndAppend(_ref, rawDraftContentState) {
  var recipeId = _ref.recipeId;
  return addAtomicBlock(rawDraftContentState, DraftjsAtomicBlockType.RECIPE, {
    blockData: {
      openModal: false
    },
    entityData: {
      recipeId: recipeId
    }
  }, 'IMMUTABLE');
};