import React from "react";
var __jsx = React.createElement;
import { useIntl } from 'react-intl';
import { BlockType } from '@uno/common-cms';
import { BulletNumber } from 'theme/icons';
import { useHandleBlockToggleCallback } from '../../../shared/hooks/useHandleBlockToggleCallback';
import { OrderedListBlockToggle } from './BlockToolbarComponent.style';

var BlockToolbarComponent = function BlockToolbarComponent(_ref) {
  var setEditorState = _ref.setEditorState;
  var handleBlockToggle = useHandleBlockToggleCallback(BlockType.OL, setEditorState);
  var intl = useIntl();
  var label = intl.formatMessage({
    id: 'content.labels.block-toolbar.ordered-list'
  });
  return __jsx(OrderedListBlockToggle, {
    onMouseDown: handleBlockToggle(),
    title: label,
    "aria-label": label
  }, __jsx(BulletNumber, null));
};

export default BlockToolbarComponent;