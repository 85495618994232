import { GenericBlockType, ListItemType } from "../types";

var extractTextFromTableDataReducer = function extractTextFromTableDataReducer(acc, tableData) {
  if (Array.isArray(tableData)) {
    return tableData.reduce(extractTextFromTableDataReducer, acc);
  }

  acc.push(tableData.text);
  return acc;
};

var extractTextFromComplexBlock = function extractTextFromComplexBlock(block) {
  switch (block.type) {
    case GenericBlockType.Highlighted:
      return block.blocks.reduce(extractTextFromBlocksReducer, []);

    case GenericBlockType.List:
      return block.items.reduce(extractTextFromBlocksReducer, []);

    case GenericBlockType.Table:
      return block.tableData.reduce(extractTextFromTableDataReducer, []);

    default:
      return [];
  }
};

var extractTextFromBlocksReducer = function extractTextFromBlocksReducer(acc, block) {
  switch (block.type) {
    case GenericBlockType.Blockquote:
    case GenericBlockType.H2:
    case GenericBlockType.H3:
    case GenericBlockType.Unstyled:
    case ListItemType.Ordered:
    case ListItemType.Unordered:
      acc.push(block.text);
      return acc;

    default:
      return acc.concat(extractTextFromComplexBlock(block));
  }
};

var extractTextFromRichText = function extractTextFromRichText(initialRichText) {
  return initialRichText.blocks.reduce(extractTextFromBlocksReducer, []).join('\n');
};

export default extractTextFromRichText;