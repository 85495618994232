import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as Yup from 'yup';
import { AufemininTaxonomyName } from "../../taxonomy/taxonomyName";
import { GroupFields } from "../../../FieldNames";
import { altSectionField, mainSectionField, tagNewsTaxonomyField, tagOpsTaxonomyField, tagPeopleTaxonomyField, tagSeoTaxonomyField } from "../../../common/fieldDefinitions/taxonomy";
import { getGroupLabelId } from "../../../../helpers";
import { FieldType } from "../../../../types";
export var taxonomyGroupFields = function taxonomyGroupFields() {
  return {
    type: FieldType.Group,
    name: GroupFields.PROPERTIES,
    labelId: getGroupLabelId(GroupFields.PROPERTIES),
    fields: [{
      type: FieldType.Group,
      name: GroupFields.TAXONOMY,
      labelId: getGroupLabelId(GroupFields.TAXONOMY),
      fields: [_objectSpread(_objectSpread({}, mainSectionField), {}, {
        taxonomyName: AufemininTaxonomyName.MainSectionFR,
        validation: Yup.array().test({
          message: 'form.required',
          test: function test(array) {
            return array !== undefined && array.length === 1;
          }
        })
      }), _objectSpread(_objectSpread({}, altSectionField), {}, {
        taxonomyName: AufemininTaxonomyName.MainSectionFR
      }), _objectSpread(_objectSpread({}, tagSeoTaxonomyField), {}, {
        taxonomyName: AufemininTaxonomyName.TagSeoFR
      }), _objectSpread(_objectSpread({}, tagPeopleTaxonomyField), {}, {
        taxonomyName: AufemininTaxonomyName.TagPeopleFR
      }), _objectSpread(_objectSpread({}, tagNewsTaxonomyField), {}, {
        taxonomyName: AufemininTaxonomyName.TagNewsFR
      }), _objectSpread(_objectSpread({}, tagOpsTaxonomyField), {}, {
        taxonomyName: AufemininTaxonomyName.TagOpsFR
      })]
    }]
  };
};