import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var seoReviewTodoSwitchField = {
  type: FieldType.Switch,
  name: Fields.SEO_REVIEW_TODO,
  labelId: getFieldLabelId(Fields.SEO_REVIEW_TODO),
  defaultValue: false,
  validation: Yup["boolean"]().required('form.required')
};