/* eslint-disable max-lines */
import { css } from 'styled-components';
/**
 * This file is here to ensure UI consistency
 * You **MUST** sync with your designer at the start of the project to check
 * what colors/typos/grid unit you are going to use, and stick to it.
 */
// This file is where the variables are defined, so we can disable stylelint here
// stylelint-disable

/**
 * App spacing measurement convention
 * Use the getSpacing function below to compute padding and margin
 * and elements with fixed width/height
 */

var SPACING_UNIT = 8;
var MEASUREMENT_UNIT = 'px';
export var getSpacing = function getSpacing() {
  for (var _len = arguments.length, multipliers = new Array(_len), _key = 0; _key < _len; _key++) {
    multipliers[_key] = arguments[_key];
  }

  return multipliers.map(function (multiplier) {
    return "".concat(multiplier * SPACING_UNIT).concat(MEASUREMENT_UNIT);
  }).join(' ');
};
export var pageWidth = {
  small: 920,
  large: 1380
};
export var zIndex = {
  behind: -1,
  "default": 0,
  level2: 2,
  buttonHover: 1000,
  header: 1100,
  // See https://material-ui.com/customization/z-index/
  WYSIWYGToolbar: 1000,
  pageBackdrop: 1200
};
/**
 * Do not use these colors directly in your components but use colorUsage below
 * If a new color is in the mockups, check with the designer
 * that the project really need a new color and add it here.
 * As to naming, the best name is the name used by the designer
 */

export var colorPalette = {
  primary: {
    50: '#F7F3FF',
    200: '#B892F8',
    500: '#7725F1',
    600: '#4906AD',
    900: '#2B0160'
  },
  secondary: {
    50: '#FFEEF5',
    200: '#F794C3',
    500: '#F02988',
    600: '#AC0A57',
    900: '#520025'
  },
  gray: {
    100: '#FCFDFF',
    200: '#E5F0FF',
    300: '#E9EFF9',
    400: '#EDEDED',
    500: '#A5B1C3',
    900: '#3E4651',
    990: '#160931'
  },
  green: {
    50: '#FDFFF8',
    200: '#ECFFC4',
    500: '#9FD134',
    800: '#48660A'
  },
  orange: {
    50: '#FFF9F4',
    200: '#FFDDBF',
    500: '#FF9839'
  },
  red: {
    50: '#FFF4F3',
    200: '#FFC8C4',
    500: '#F35248',
    800: '#C53127'
  },
  white: '#FFFFFF',
  gradientStart: '#F02988',
  gradientStop: '#7225F1',
  gradient: 'linear-gradient(88.35deg, #F02988 0%, #7225F1 100%)',
  invertedGradient: 'linear-gradient(88.35deg, #7225F1 0%, #F02988 100%)',
  primaryOpaque: 'rgba(119, 37, 241, 0.1)',
  transparentWhite: 'rgba(255,255,255,0.7)',
  blue: '#258FF1',
  transparentLightGray: 'rgba(229, 240, 255, 0.2)',
  transparentGray: 'rgba(165, 177, 195, 0.2)'
};
export var gradient = {
  id: 'gradient',
  startColor: colorPalette.gradientStart,
  stopColor: colorPalette.gradientStop
};
export var colorUsage = {
  witheColor: colorPalette.white,
  grayDark: colorPalette.gray[900],
  pageBackground: colorPalette.gray[100],
  pageForeground: colorPalette.white,
  headerBackground: colorPalette.white,
  textColor: colorPalette.gray[900],
  textColorLight: colorPalette.gray[500],
  inputBorder: colorPalette.gray[300],
  imageBorder: colorPalette.gray[300],
  filtersBarBorder: colorPalette.gray[400],
  shadowIconButtonBackground: colorPalette.white,
  highlightedTextBackground: colorPalette.gray[200],
  inlineToolbarToggleHoverForeground: colorPalette.secondary[500],
  inlineToolbarBorderBorder: colorPalette.gray[500],
  blockToolbarToggleHoverForeground: "url(#".concat(gradient.id, ")"),
  blockToolbarBackground: colorPalette.secondary[500],
  gradientButtonIcon: colorPalette.white,
  borderGradientButtonHoverContent: colorPalette.secondary[500],
  borderGradientButtonBorder: colorPalette.gray[500],
  blockToolbarBlockForeground: colorPalette.white,
  blockToolbarBlockHoverForeground: colorPalette.primary[500],
  embedBlockBackground: "".concat(colorPalette.gray[200], "33"),
  embedBorder: "".concat(colorPalette.gray[500], "33"),
  embedBlockForeground: colorPalette.gray[500],
  defaultIconColor: colorPalette.gray[500],
  whiteIconColor: colorPalette.white,
  iconRemove: colorPalette.red[500],
  iconHover: colorPalette.primary[500],
  linkHover: colorPalette.gray[500],
  dotColor: colorPalette.gray[500],
  scrollbar: colorPalette.gray[500],
  defaultBorder: colorPalette.gray[500],
  cropBorders: colorPalette.secondary[500],
  anchor: {
    backgroundTextColor: colorPalette.primary[50],
    textColor: colorPalette.primary[500]
  },
  beopBlock: {
    foreground: colorPalette.gray[500],
    background: colorPalette.transparentLightGray,
    border: colorPalette.transparentGray
  },
  muiOverride: {
    tab: {
      root: {
        hover: {
          wrapper: colorPalette.gray[900]
        },
        selected: {
          background: colorPalette.primary[500],
          wrapper: colorPalette.white
        }
      },
      wrapper: colorPalette.gray[500],
      gradient: {
        textAccentColor: colorPalette.gray[900],
        beforeBackground: colorPalette.white,
        afterBackground: colorPalette.gradient,
        selectedBackground: colorPalette.gradient
      }
    },
    tableHead: {
      backgroundColor: colorPalette.white,
      bordorBottomColor: colorPalette.gray[100],
      highlightColor: colorPalette.secondary[500]
    },
    shadowIcon: {
      background: colorPalette.white,
      border: colorPalette.gray[300],
      disabledBackground: colorPalette.primaryOpaque
    },
    button: {
      primary: {
        color: colorPalette.primary[500],
        hoverBackground: colorPalette.primaryOpaque
      },
      gradient: {
        color: colorPalette.white,
        disabledBackground: colorPalette.gray[300],
        background: colorPalette.gradient,
        hoveredBackground: colorPalette.invertedGradient
      }
    },
    chip: {
      primary: {
        backgroundColor: colorPalette.primary[50],
        color: colorPalette.primary[900]
      },
      multiple: {
        backgroundColor: colorPalette.secondary[50],
        color: colorPalette.secondary[900]
      }
    }
  },
  status: {
    icon: {
      fill: {
        "default": colorPalette.white,
        edit: colorPalette.gray[990]
      },
      color: {
        draft: colorPalette.gray[500],
        online: colorPalette.green[500],
        offline: colorPalette.red[500],
        review: colorPalette.red[500],
        scheduled: colorPalette.orange[500],
        optimize: colorPalette.orange[500]
      }
    },
    color: {
      draft: colorPalette.gray[100],
      online: colorPalette.green[50],
      offline: colorPalette.red[50],
      review: colorPalette.red[50],
      scheduled: colorPalette.orange[50],
      optimize: colorPalette.orange[50],
      edit: colorPalette.white
    }
  },
  dropzone: {
    base: {
      backgroundColor: colorPalette.primary[50],
      borderColor: colorPalette.primary[500],
      color: colorPalette.primary[600]
    },
    active: {
      borderColor: colorPalette.primary[500]
    },
    accept: {
      borderColor: colorPalette.primary[500]
    },
    reject: {
      borderColor: colorPalette.red[500]
    }
  },
  coverImageVariant: {
    backgroundColor: colorPalette.gray[300],
    borderColor: colorPalette.gray[500],
    color: colorPalette.gray[500]
  },
  paletteOverride: {
    primary: {
      main: colorPalette.primary[500]
    }
  },
  CTAText: colorPalette.primary[500],
  buttonGradient: colorPalette.gradient,
  iconForeground: colorPalette.gray[900],
  iconGradientHoverForeground: "url(#".concat(gradient.id, ")"),
  message: {
    success: colorPalette.green[500],
    warning: colorPalette.orange[500],
    error: colorPalette.red[500]
  },
  // manually add opacity to existing color using RRGGBBAA format
  modalBackground: "".concat(colorPalette.gray[990], "66"),
  toaster: {
    success: {
      foreground: colorPalette.green[800],
      background: colorPalette.green[200]
    },
    error: {
      foreground: colorPalette.red[800],
      background: colorPalette.red[50]
    }
  },
  transparentBackground: colorPalette.transparentWhite,
  selectedBlockWrapper: {
    background: "".concat(colorPalette.primary[50], "33"),
    border: "".concat(colorPalette.primary[500], "33")
  },
  linkedTextForeground: colorPalette.blue,
  affiliation: colorPalette.secondary[500],
  placeHolderTextForeground: colorPalette.gray[500],
  imageDeleteButtonBackground: colorPalette.gray[990],
  imageDeleteButtonBorder: colorPalette.white,
  externalLinkForeground: colorPalette.gray[500],
  linkToolbarBorder: colorPalette.gray[300],
  checkbox: {
    selected: colorPalette.primary[500]
  },
  tableGrid: {
    background: colorPalette.white,
    borderColor: colorPalette.blue,
    text: colorPalette.gray[500],
    cell: {
      borderColor: {
        "default": colorPalette.gray[500],
        selected: colorPalette.secondary[500]
      },
      background: {
        "default": colorPalette.gray[200],
        selected: colorPalette.secondary[50]
      }
    }
  },
  textFieldStatus: {
    error: colorPalette.red[500],
    valid: colorPalette.primary[500],
    warning: colorPalette.orange[500]
  },
  searchItemList: {
    borderColor: colorPalette.gray[500],
    scrollbarColor: colorPalette.gray[300],
    titleColor: colorPalette.gray[900],
    subTitleColor: colorPalette.gray[500],
    hoverItemBackgroundColor: colorPalette.primary[50],
    hoverItemIconColor: colorPalette.white
  },
  tagAutocomplete: {
    option: {
      "default": {
        backgroundColor: colorPalette.primary[50],
        color: colorPalette.gray[500]
      },
      multiple: {
        backgroundColor: colorPalette.secondary[50],
        color: colorPalette.secondary[200]
      }
    }
  },
  tagInput: {
    options: {
      highlight: {
        "default": {
          color: colorPalette.primary[900]
        },
        multiple: {
          color: colorPalette.secondary[900]
        }
      }
    }
  },
  carousel: {
    slide: {
      border: colorPalette.gray[400]
    },
    preview: {
      hover: colorPalette.primary[500],
      missing: {
        background: colorPalette.red[500],
        icon: colorPalette.white
      }
    },
    append: colorPalette.primary[500],
    remove: {
      border: colorPalette.gray[400]
    }
  },
  centerParentButton: {
    backgroundColor: colorPalette.white,
    hoverBackgroundColor: colorPalette.primary[50]
  }
};
export var fontFamily = {
  main: 'Abitare Sans',
  code: 'Monospace'
};
export var fontSize = {
  smaller: '12px',
  small: '14px',
  normal: '16px',
  large: '20px',
  larger: '24px',
  huge: '32px'
};
export var lineHeight = {
  smaller: '16px',
  small: '18px',
  normal: '21px',
  large: '26px',
  larger: '31px',
  huge: '42px'
};
export var fontWeight = {
  lighter: 100,
  light: 300,
  normal: 400,
  bold: 500,
  bolder: 800
};
export var typography = {
  main: css(["color:", ";font-weight:", ";font-size:", ";font-family:", ";line-height:", ";"], colorUsage.textColor, fontWeight.normal, fontSize.normal, fontFamily.main, lineHeight.normal),
  bold: css(["font-weight:", ";"], fontWeight.bold),
  light: css(["font-weight:", ";"], fontWeight.light),
  small: css(["font-size:", ";line-height:", ";"], fontSize.small, lineHeight.small),
  h1: css(["font-weight:", ";font-size:", ";font-family:", ";line-height:", ";"], fontWeight.bolder, fontSize.huge, fontFamily.main, lineHeight.huge),
  h2: css(["font-weight:", ";font-size:", ";font-family:", ";line-height:", ";"], fontWeight.bolder, fontSize.larger, fontFamily.main, lineHeight.larger),
  h3: css(["font-weight:", ";font-size:", ";font-family:", ";line-height:", ";"], fontWeight.bold, fontSize.large, fontFamily.main, lineHeight.large),
  h4: css(["font-weight:", ";font-size:", ";font-family:", ";line-height:", ";"], fontWeight.bold, fontSize.normal, fontFamily.main, lineHeight.normal),
  code: css(["color:", ";font-family:", ";"], colorUsage.grayDark, fontFamily.code)
};
export var borderRadius = {
  small: '4px',
  medium: '8px',
  large: '16px'
};
export var shadow = {
  bottom: {
    dark: '0px 2px 10px rgba(22, 9, 49, 0.12);',
    light: '0px 4px 10px rgba(22, 9, 49, 0.06)'
  },
  light: {
    dark: '0px -4px 10px rgba(22, 9, 49, 0.12)',
    light: '0px -2px 10px rgba(22, 9, 49, 0.06)'
  }
};
export var transitionDuration = {
  "default": '250ms'
};
export var headerHeight = '72px';
export var atomicBlockContainerWidth = '700px';
export var imagePreviewHeight = '300px';
export var animation = {
  scaleInHorLeft: css(["animation:scale-in-hor-left 0.1s cubic-bezier(0.25,0.46,0.45,0.94) both;@keyframes scale-in-hor-left{0%{transform:scaleX(0);transform-origin:0 0;opacity:1;}100%{transform:scaleX(1);transform-origin:0 0;opacity:1;}}"]),
  slideInLeft: function slideInLeft(offset) {
    return css(["animation:slide-in-left ", " cubic-bezier(0.25,0.46,0.45,0.94) both;@keyframes slide-in-left{0%{transform:translateX(-", "px);opacity:0;}100%{transform:translateX(0);opacity:1;}}"], transitionDuration["default"], offset);
  },
  slideOutLeft: function slideOutLeft(offset) {
    return css(["animation:slide-out-left ", " cubic-bezier(0.55,0.085,0.68,0.53) both;@keyframes slide-out-left{0%{transform:translateX(0);opacity:1;}100%{transform:translateX(-", "px);opacity:0;}}"], transitionDuration["default"], offset);
  },
  slideInRight: function slideInRight(offset) {
    return css(["animation:slide-in-right ", " cubic-bezier(0.25,0.46,0.45,0.94) both;@keyframes slide-in-right{0%{transform:translateX(", "px);opacity:0;}100%{transform:translateX(0);opacity:1;}}"], transitionDuration["default"], offset);
  },
  slideOutRight: function slideOutRight(offset) {
    return css(["animation:slide-out-right ", " cubic-bezier(0.55,0.085,0.68,0.53) both;@keyframes slide-out-right{0%{transform:translateX(0);opacity:1;}100%{transform:translateX(", "px);opacity:0;}}"], transitionDuration["default"], offset);
  },
  scaleUpTop: css(["animation:scale-up-top ", " cubic-bezier(0.39,0.575,0.565,1) both;@keyframes scale-up-top{0%{transform:scale(0);transform-origin:50% 0%;}100%{transform:scale(1);transform-origin:50% 0%;}}"], transitionDuration["default"])
};
export var statusIconBase = css(["width:24px;height:24px;padding:4px;border-radius:20px;path{fill:", ";}"], colorUsage.status.icon.fill["default"]);
export var gradientText = css(["background:", ";-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:transparent;"], colorPalette.gradient); // Force scrollbar to be displayed Chrome/OSX when present (not only on scroll)
// https://stackoverflow.com/questions/31154440/css-show-horizontal-scroll-bars/31154654

export var horizontalScrollBar = css(["overflow-x:auto;&::-webkit-scrollbar{-webkit-appearance:none;}&::-webkit-scrollbar-thumb{background-color:rgba(0,0,0,0.5);border:2px solid ", ";border-radius:8px;}&::-webkit-scrollbar-track{background-color:", ";border-radius:8px;}&::-webkit-scrollbar:horizontal{height:11px;}"], colorPalette.white, colorPalette.white);