import isBefore from 'date-fns/isBefore';
import { ValidationError } from 'yup';
var MIN_DATE_ERROR_LABEL_ID = 'form.beforePublicationDatesUnaccepted';
var MAX_DATE_ERROR_LABEL_ID = 'form.futureDatesUnaccepted';

var dateRangeValidator = function dateRangeValidator(minDate, maxDate, value) {
  var path = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';

  if (isBefore(value, minDate)) {
    return new ValidationError(MIN_DATE_ERROR_LABEL_ID, value, path);
  }

  if (isBefore(maxDate, value)) {
    return new ValidationError(MAX_DATE_ERROR_LABEL_ID, value, path);
  }

  return true;
};

export default dateRangeValidator;