import { SelectionState } from 'draft-js';
/**
 * Find the existing range referring to the entity
 */

var getEntitySelection = function getEntitySelection(editorState, entityKey) {
  var selectionState;
  var blockMap = editorState.getCurrentContent().getBlockMap();
  blockMap.forEach(function (block) {
    block === null || block === void 0 ? void 0 : block.findEntityRanges(function (character) {
      return character.getEntity() === entityKey;
    }, function (start, end) {
      selectionState = new SelectionState({
        anchorKey: block.getKey(),
        anchorOffset: start,
        focusKey: block.getKey(),
        focusOffset: end
      });
    });
  });

  if (selectionState === undefined) {
    throw new Error("Entity ".concat(entityKey, " is not present in the editor"));
  }

  return selectionState;
};

export default getEntitySelection;