import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var cloneUrlReadOnlyField = {
  type: FieldType.ReadOnly,
  name: Fields.CLONE_URL,
  labelId: getFieldLabelId(Fields.CLONE_URL),
  defaultValue: '',
  validation: Yup.string()
};