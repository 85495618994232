import { useCallback } from 'react';
import insertAtomicBlock from 'components/common/RichTextEditor/util/block/insertAtomicBlock';

var useInsertAtomicBlockCallback = function useInsertAtomicBlockCallback(setEditorState) {
  return useCallback(function (type, blockData, entityData) {
    return setEditorState(function (editorState) {
      return insertAtomicBlock(editorState, type, blockData, entityData);
    });
  }, [setEditorState]);
};

export default useInsertAtomicBlockCallback;