import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useInterval } from 'react-use';
var MILLISECONDS_PER_SECOND = 1000;
var ONE_MINUTE_IN_MILLISECONDS = 60 * MILLISECONDS_PER_SECOND;
var SECONDS_PER_MINUTE = 60;
var SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE;
var SECONDS_PER_DAY = 24 * SECONDS_PER_HOUR;

var useLastModifiedDuration = function useLastModifiedDuration(lastModifiedDate) {
  var intl = useIntl();

  var _useState = useState(new Date()),
      updatedNowDate = _useState[0],
      setUpdatedNowDate = _useState[1];

  useInterval(function () {
    setUpdatedNowDate(new Date());
  }, ONE_MINUTE_IN_MILLISECONDS);
  useEffect(function () {
    // force reset updatedNowDate when lastModifiedDate is updated
    setUpdatedNowDate(new Date());
  }, [lastModifiedDate]); // total seconds since last modified

  var deltaSeconds = useMemo(function () {
    return (updatedNowDate.getTime() - lastModifiedDate.getTime()) / MILLISECONDS_PER_SECOND;
  }, [lastModifiedDate, updatedNowDate]);
  var days = Math.floor(deltaSeconds / SECONDS_PER_DAY);
  deltaSeconds -= days * SECONDS_PER_DAY;
  var hours = Math.floor(deltaSeconds / SECONDS_PER_HOUR);
  deltaSeconds -= hours * SECONDS_PER_HOUR;
  var minutes = Math.floor(deltaSeconds / SECONDS_PER_MINUTE);
  var durationText = useMemo(function () {
    switch (true) {
      case days > 0:
        return "".concat(days, " ").concat(intl.formatMessage({
          id: 'date.day-or-days'
        }));

      case hours > 0:
        return "".concat(hours, "h").concat(minutes < 10 ? "0".concat(minutes) : minutes);

      case minutes > 0:
        return "".concat(minutes, "min");

      default:
        return '0min';
    }
  }, [days, hours, intl, minutes]);
  return {
    days: days,
    durationText: durationText,
    hours: hours,
    minutes: minutes
  };
};

export default useLastModifiedDuration;