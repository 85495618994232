import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import get from 'lodash/get';
import { CONTENT_TYPES } from "../config";
import { FieldType } from "../types";
import extractTextFromRichText from "./extractTextFromRichText";

var getField = function getField(name, fields) {
  var path = name.indexOf('.') >= 0 ? name.split('.')[0] : name;
  var field = fields.find(function (_ref) {
    var _name = _ref.name;
    return _name === path;
  });

  if (path === name || field === undefined) {
    // No more sub path or invalid path
    return field;
  }

  var subPath = name.substr("".concat(path, ".").length);

  switch (field.type) {
    case FieldType.Group:
      return getField(subPath, _toConsumableArray(field.fields));

    case FieldType.Repeatable:
      return getField(subPath, [field.field]);

    default:
      // Invalid sub path
      return undefined;
  }
};

var getText = function getText(field, fieldValue, repeatedField) {
  switch (field.type) {
    case FieldType.RichText:
      return extractTextFromRichText(fieldValue);

    case FieldType.SimpleText:
      return fieldValue;

    case FieldType.Repeatable:
      return repeatedField !== undefined ? fieldValue.map(function (fieldVal) {
        return getText(repeatedField, get(fieldVal, repeatedField.name));
      }).join(' ') : '';

    default:
      return '';
  }
};

var getPropertyPath = function getPropertyPath(textPropertyPath) {
  return textPropertyPath.replace(/\[[0-9]+\]/, '') // Supports repeatableField index: directly select final field
  .replace(/\.[^.]+\[\]\..+/, '');
}; // Supports repeatableField


var getRepeatedPropertyPath = function getRepeatedPropertyPath(textPropertyPath) {
  return textPropertyPath.replace(/\[([0-9]+)?\]/, '');
};

var getFieldName = function getFieldName(textPropertyPath, defaultFieldName) {
  var fieldPath = textPropertyPath.split('.');
  var repeatableFieldName = textPropertyPath.replace("".concat(fieldPath[0], "."), '').replace(/\..+\[([0-9]+)\]\./, '.$1.') // Supports repeatableField index: directly select final field
  .replace(/\..+\[\]\..+/, ''); // Supports repeatableField

  return getRepeatedPropertyPath(textPropertyPath) !== textPropertyPath ? repeatableFieldName : defaultFieldName;
};

var getTextToProcess = function getTextToProcess(content, textPropertiesPath) {
  var contentData = content.contentData,
      contentTypeName = content.contentType;
  var fields = CONTENT_TYPES[contentTypeName].fields;
  return textPropertiesPath.reduce(function (acc, textPropertyPath) {
    var field = getField(getPropertyPath(textPropertyPath), _toConsumableArray(fields));
    var repeatedField = getField(getRepeatedPropertyPath(textPropertyPath), _toConsumableArray(fields));

    if (field === undefined) {
      return acc;
    }

    var fieldName = getFieldName(textPropertyPath, field.name);
    var fieldValue = get(contentData, fieldName);

    if (fieldValue === undefined) {
      return acc;
    }

    var text = getText(field, fieldValue, repeatedField);
    return acc.length > 0 ? "".concat(acc, " ").concat(text) : text;
  }, '');
};

export default getTextToProcess;