import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { dateFieldAgainstPublishedAtValidation } from "../../../../helpers/dateFieldAgainstPublishedAtValidation";
import { FieldType } from "../../../../types/ContentType/fields";
var withTime = true;
export var modificationDateField = {
  type: FieldType.Date,
  name: Fields.MODIFICATION_DATE,
  labelId: getFieldLabelId(Fields.MODIFICATION_DATE),
  defaultValue: null,
  withTime: withTime,
  validateAgainstPublishedAt: true,
  validation: dateFieldAgainstPublishedAtValidation(Fields.MODIFICATION_DATE, withTime)
};