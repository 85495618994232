import { genKey } from 'draft-js';
import { BlockType as DraftjsBlockType } from '@uno/common-cms';
import { getRawDraftEntityRangesAndUpdatedEntityMap, getRawDraftInlineStyleRangesFromGenericInlineStyleRanges } from '../../util';
export var transformTableToDraftjsAndAppend = function transformTableToDraftjsAndAppend(genericBlock, rawDraftContentState) {
  var _genericBlock$tableDa;

  var tableKey = genKey();
  var tableShape = Array(genericBlock.tableData.length).fill(Array((_genericBlock$tableDa = genericBlock.tableData[0]) === null || _genericBlock$tableDa === void 0 ? void 0 : _genericBlock$tableDa.length).fill(1)).map(function (row) {
    return row.map(function () {
      return {
        element: 'td'
      };
    });
  });
  genericBlock.tableData.forEach(function (row, rowIndex) {
    row.forEach(function (_ref, colIndex) {
      var inlineDataRanges = _ref.inlineDataRanges,
          inlineStyleRanges = _ref.inlineStyleRanges,
          text = _ref.text;
      var isFirstCell = rowIndex === 0 && colIndex === 0;

      var _getRawDraftEntityRan = getRawDraftEntityRangesAndUpdatedEntityMap(inlineDataRanges, rawDraftContentState.entityMap),
          rawDraftEntityRanges = _getRawDraftEntityRan.rawDraftEntityRanges,
          entityMap = _getRawDraftEntityRan.entityMap;

      var blockData = {
        tableKey: tableKey,
        tablePosition: "".concat(tableKey, "-").concat(rowIndex, "-").concat(colIndex)
      };

      if (isFirstCell) {
        blockData.tableShape = tableShape;
      }

      rawDraftContentState.blocks.push({
        key: genKey(),
        type: DraftjsBlockType.TABLE,
        text: text !== null && text !== void 0 ? text : '',
        depth: 0,
        inlineStyleRanges: getRawDraftInlineStyleRangesFromGenericInlineStyleRanges(inlineStyleRanges),
        entityRanges: rawDraftEntityRanges,
        data: blockData
      });
      rawDraftContentState.entityMap = entityMap;
    });
  });
  return rawDraftContentState;
};