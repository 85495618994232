import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import compact from 'lodash/compact';
import { isSite } from "../../../sites";
import { BAKO_SITES } from "../config";
import { isCrossSiteRole, isSiteRole, ROLE_SEPARATOR } from "../roles";

var bakoCognitoGroupToSitesWithRoles = function bakoCognitoGroupToSitesWithRoles(group) {
  var allBakoSites = BAKO_SITES;

  if (isCrossSiteRole(group)) {
    return {
      sites: allBakoSites,
      role: group
    };
  }

  var _group$split = group.split(ROLE_SEPARATOR),
      _group$split2 = _slicedToArray(_group$split, 2),
      site = _group$split2[0],
      siteRole = _group$split2[1];

  if (site === undefined || siteRole === undefined) {
    return undefined;
  }

  if (isSite(site) && isSiteRole(siteRole)) {
    return {
      sites: [site],
      role: siteRole
    };
  }

  return undefined;
};

var bakoGroupsToRoles = function bakoGroupsToRoles(groups) {
  var sitesAndGroup = compact(groups.map(bakoCognitoGroupToSitesWithRoles));
  var res = {};
  sitesAndGroup.forEach(function (_ref) {
    var sites = _ref.sites,
        role = _ref.role;
    sites.forEach(function (site) {
      var _res$site;

      if (res[site] === undefined) {
        res[site] = [];
      }

      (_res$site = res[site]) === null || _res$site === void 0 ? void 0 : _res$site.push(role);
    });
  });
  return res;
};

export default bakoGroupsToRoles;