import { GroupFields } from "../../../FieldNames";
import { legacyBOUrlReadOnlyField, legacyIdReadOnlyField, legacyPathLabelReadOnlyField } from "../../../common/fieldDefinitions/readOnly";
import { getGroupLabelId } from "../../../../helpers";
import { FieldType } from "../../../../types";
export var legacyGroupFields = function legacyGroupFields() {
  return {
    type: FieldType.Group,
    name: GroupFields.LEGACY,
    labelId: getGroupLabelId(GroupFields.LEGACY),
    fields: [legacyIdReadOnlyField, legacyPathLabelReadOnlyField, legacyBOUrlReadOnlyField]
  };
};