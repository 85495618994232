import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { DoctissimoContentTypeName } from "../../config";
import { basePartialDocumentSchema, baseSearchParamsSchema } from "../schemas";
export var partialDocumentSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, basePartialDocumentSchema.properties), {}, {
    contentTypeName: {
      "enum": Object.values(DoctissimoContentTypeName)
    },
    // override basePartialDocumentSchema schema
    altSection: {
      type: ['array', 'null'],
      items: {
        type: 'string'
      }
    },
    authors: {
      type: ['array', 'null'],
      items: {
        type: 'string'
      }
    },
    contentTypeHome: {
      type: 'object',
      properties: {
        contentType: {
          type: 'string'
        }
      },
      required: ['contentType']
    },
    content: {
      type: 'object',
      properties: {
        highlightedVideo: {
          type: 'object',
          properties: {
            imgUrl: {
              type: 'string'
            },
            videoId: {
              type: 'string'
            },
            title: {
              type: 'string'
            },
            videoIframeUrl: {
              type: 'string'
            }
          }
        }
      }
    },
    person: {
      type: 'object',
      properties: {
        active: {
          type: 'boolean'
        }
      },
      required: ['active']
    }
  }),
  required: []
};
export var partialDocumentSchemaWithRequiredUrl = {
  type: 'object',
  properties: partialDocumentSchema.properties,
  required: ['absoluteUrl']
};
export var searchParamsSchema = baseSearchParamsSchema;