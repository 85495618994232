import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { imageFieldValidation } from "../../../../helpers/imageFieldValidation";
import { FieldType } from "../../../../types/ContentType/fields";
export var imageField = {
  type: FieldType.Image,
  name: Fields.IMAGE,
  labelId: getFieldLabelId(Fields.IMAGE),
  defaultValue: {
    imgUrl: undefined,
    legend: '',
    credits: ''
  },
  ratio: {
    width: 3,
    height: 2
  },
  constraints: {
    maxWidth: 3000
  },
  validation: imageFieldValidation
};