import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import get from 'lodash/get';
import * as Yup from 'yup';
import { Sites } from '@uno/common-shared';
import { BaseContentTypeName, Fields, GroupFields } from "../../FieldNames";
import { modificationDateField } from "../../common/fieldDefinitions/date";
import { coverImagesField } from "../../common/fieldDefinitions/image";
import { createdMetaDateField, modifiedMetaDateField, publicationMetaDateField } from "../../common/fieldDefinitions/metaDate";
import { metaUrlField } from "../../common/fieldDefinitions/metaUrl";
import { bodyTextField, headTextField, ogDescriptionTextField, ogTitleTextField, shortTitleTextField, titleTextField } from "../../common/fieldDefinitions/simpleText";
import { altSectionField, mainSectionField } from "../../common/fieldDefinitions/taxonomy";
import { getGroupLabelId } from "../../../helpers";
import { ContentTypeIconName, FieldType } from "../../../types/ContentType";
import { DoctissimoTaxonomyName } from "../taxonomy";
import { DoctissimoContentTypeName } from "./ContentTypeName";
import { getManualModificationDate, legacyFields, seoGroupField } from "./common";
var baseLabelId = BaseContentTypeName.HtmlPage;
var fields = [{
  type: FieldType.Group,
  name: GroupFields.HTML_PAGE,
  labelId: getGroupLabelId(GroupFields.HTML_PAGE),
  fields: [titleTextField, headTextField, bodyTextField]
}, seoGroupField, {
  type: FieldType.Group,
  name: GroupFields.PROPERTIES,
  labelId: getGroupLabelId(GroupFields.PROPERTIES),
  fields: [metaUrlField, shortTitleTextField, ogTitleTextField, ogDescriptionTextField, {
    type: FieldType.Group,
    name: GroupFields.TAXONOMY,
    labelId: getGroupLabelId(GroupFields.TAXONOMY),
    fields: [_objectSpread(_objectSpread({}, mainSectionField), {}, {
      taxonomyName: DoctissimoTaxonomyName.MainSection,
      validation: Yup.array().test({
        message: 'form.required',
        test: function test(array) {
          return array !== undefined && array.length === 1;
        }
      })
    }), _objectSpread(_objectSpread({}, altSectionField), {}, {
      taxonomyName: DoctissimoTaxonomyName.MainSection
    })]
  }, {
    type: FieldType.Group,
    name: GroupFields.DATES,
    labelId: getGroupLabelId(GroupFields.DATES),
    fields: [modificationDateField, publicationMetaDateField, createdMetaDateField, modifiedMetaDateField]
  }, {
    type: FieldType.Group,
    name: GroupFields.IMAGES,
    labelId: getGroupLabelId(GroupFields.IMAGES),
    fields: [coverImagesField]
  }, legacyFields]
}];
export var htmlPage = {
  site: Sites.DOCTISSIMO_FR,
  name: DoctissimoContentTypeName.HtmlPage,
  labelId: 'content-type.htmlPage.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  getLabel: function getLabel(contentData) {
    return (// TODO: See to fix "Type instantiation is excessively deep and possibly infinite"
      // @ts-ignore Sorry too many fields
      contentData.title !== undefined ? contentData.title : ''
    );
  },
  getPreviewImage: function getPreviewImage(contentData) {
    var _ref;

    return (_ref = get(contentData, 'coverImages.default.imgUrl')) !== null && _ref !== void 0 ? _ref : null;
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  getMainSectionId: function getMainSectionId(contentData) {
    var _contentData$mainSect, _contentData$mainSect2;

    return (_contentData$mainSect = contentData === null || contentData === void 0 ? void 0 : (_contentData$mainSect2 = contentData.mainSection) === null || _contentData$mainSect2 === void 0 ? void 0 : _contentData$mainSect2[0]) !== null && _contentData$mainSect !== void 0 ? _contentData$mainSect : '';
  },
  getManualModificationDate: getManualModificationDate,
  fields: fields,
  partialDependencyFields: [Fields.TITLE],
  isLinkable: true,
  isCreatable: true
};