import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as Yup from 'yup';
import { GroupFields } from "../../../FieldNames";
import { authorLinkedContentField } from "../../../common/fieldDefinitions/linkedContent";
import { metaUrlField } from "../../../common/fieldDefinitions/metaUrl";
import { bodyRichTextField } from "../../../common/fieldDefinitions/richText";
import { chapoTextField, titleTextField } from "../../../common/fieldDefinitions/simpleText";
import { displayOnHomepageSwitchField } from "../../../common/fieldDefinitions/switch";
import { slidesField } from "../../../common/slidesField";
import { getGroupLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType";
import { AufemininContentTypeName } from "../ContentTypeName";
import { dateGroupFields } from "./dateGroupFields";
import { imageGroupFields } from "./imageGroupFields";
import { legacyGroupFields } from "./legacyGroupFields";
import { semanticScoreGroupFields } from "./semanticScoreGroupFields";
import { seoGroupFields } from "./seoGroupFields";
import { taxonomyGroupFields } from "./taxonomyGroupFields";
export var slideshowV1Fields = {
  type: FieldType.Group,
  name: GroupFields.SLIDESHOW,
  labelId: getGroupLabelId(GroupFields.SLIDESHOW),
  fields: [_objectSpread(_objectSpread({}, titleTextField), {}, {
    displayCharactersCount: true,
    maxCharactersCount: 110,
    validation: Yup.string().max(110, 'form.stringLength').required('form.required')
  }), _objectSpread(_objectSpread({}, chapoTextField), {}, {
    validation: Yup.string()
  }), slidesField]
};
export var slideshowV2Fields = [{
  type: FieldType.Group,
  name: GroupFields.SLIDESHOW,
  labelId: getGroupLabelId(GroupFields.SLIDESHOW),
  fields: [_objectSpread(_objectSpread({}, titleTextField), {}, {
    displayCharactersCount: true,
    maxCharactersCount: 110,
    validation: Yup.string().max(110, 'form.stringLength').required('form.required')
  }), _objectSpread(_objectSpread({}, chapoTextField), {}, {
    validation: Yup.string()
  }), bodyRichTextField]
}, {
  type: FieldType.Group,
  name: GroupFields.SLIDE,
  labelId: getGroupLabelId(GroupFields.SLIDE),
  fields: [slidesField]
}];
var slideshowSeoFields = seoGroupFields();
export var slideshowSeoGroupFields = _objectSpread(_objectSpread({}, slideshowSeoFields), {}, {
  fields: [].concat(_toConsumableArray(slideshowSeoFields.fields), [semanticScoreGroupFields(GroupFields.SLIDESHOW)])
});
export var slideshowPropertiesGroupFields = {
  type: FieldType.Group,
  name: GroupFields.PROPERTIES,
  labelId: getGroupLabelId(GroupFields.PROPERTIES),
  fields: [{
    type: FieldType.Group,
    name: GroupFields.PARAMETERS,
    labelId: getGroupLabelId(GroupFields.PARAMETERS),
    fields: [metaUrlField, _objectSpread(_objectSpread({}, authorLinkedContentField), {}, {
      contentTypeNames: [AufemininContentTypeName.AuthorFR]
    }), displayOnHomepageSwitchField]
  }, taxonomyGroupFields(), dateGroupFields(), imageGroupFields(), legacyGroupFields()]
};