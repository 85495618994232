export var contentIndexName = process.env.NEXT_PUBLIC_ALGOLIA_CONTENT_INDEX_NAME;
var indexNamePrefix = "".concat(contentIndexName !== null && contentIndexName !== void 0 ? contentIndexName : 'undefinedContentIndexName', "-");
export var contentSortingRulesToIndex = {
  modificationDate: {
    desc: "".concat(indexNamePrefix, "modificationTimestamp-desc"),
    asc: "".concat(indexNamePrefix, "modificationTimestamp-asc")
  },
  publicationDate: {
    desc: "".concat(indexNamePrefix, "publicationTimestamp-desc"),
    asc: "".concat(indexNamePrefix, "publicationTimestamp-asc")
  }
};