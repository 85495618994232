import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var pregnancyPeriodSelectField = {
  type: FieldType.Select,
  name: Fields.PREGNANCY_PERIOD,
  labelId: getFieldLabelId(Fields.PREGNANCY_PERIOD),
  allowEmpty: false,
  validation: Yup.string().required('form.required')
};