import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _objectSpread2;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { coverImagesSchema } from '@uno/common-shared';
import { Fields } from "../../../../../../config";
import { PartialContentPageWithContentTypeAndMainSectionChunkSchema } from "../../../../../page";
import { SLIDESHOW_V2_CHUNK_CONTENT_TYPE_NAMES_AUFEMININ as SLIDESHOW_V2_CHUNK_CONTENT_TYPE_NAMES } from "../../../../../../types";
var SlideshowV2tagSchema = {
  type: 'array',
  items: {
    type: 'string'
  },
  maxItems: 1
};
var SlideshowV2ChunkSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, PartialContentPageWithContentTypeAndMainSectionChunkSchema.properties), {}, (_objectSpread2 = {
    _contentTypeName: {
      "enum": SLIDESHOW_V2_CHUNK_CONTENT_TYPE_NAMES
    },
    _contentMetadata: {
      type: 'object',
      properties: {
        editorialUpdateDate: {
          type: ['string']
        }
      },
      additionalProperties: false
    }
  }, _defineProperty(_objectSpread2, Fields.TITLE, {
    type: 'string'
  }), _defineProperty(_objectSpread2, Fields.CHAPO, {
    type: 'string'
  }), _defineProperty(_objectSpread2, Fields.COVER_IMAGES, coverImagesSchema), _defineProperty(_objectSpread2, Fields.LEGACY_ID, {
    type: 'string'
  }), _defineProperty(_objectSpread2, Fields.DISPLAY_ON_HOMEPAGE, {
    type: 'boolean'
  }), _defineProperty(_objectSpread2, Fields.TAG_SEO, SlideshowV2tagSchema), _defineProperty(_objectSpread2, Fields.TAG_PEOPLE, SlideshowV2tagSchema), _defineProperty(_objectSpread2, Fields.TAG_OPS, SlideshowV2tagSchema), _objectSpread2)),
  required: [].concat(_toConsumableArray(PartialContentPageWithContentTypeAndMainSectionChunkSchema.required), ['_contentMetadata', Fields.TITLE, Fields.CHAPO, Fields.SLIDES, Fields.COVER_IMAGES, Fields.DISPLAY_ON_HOMEPAGE, Fields.TAG_SEO, Fields.TAG_PEOPLE, Fields.TAG_OPS])
};
export { SlideshowV2tagSchema as SlideshowV2tagSchemaAufeminin, SlideshowV2ChunkSchema as SlideshowV2ChunkSchemaAufeminin };