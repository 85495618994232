import { BlockType } from '@uno/common-cms';
import getBlockRelatives from './getBlockRelatives';

var isBlockEmpty = function isBlockEmpty(block, blockMap) {
  if (block.getType() !== BlockType.TABLE) {
    return block.getLength() === 0;
  }

  var blocks = getBlockRelatives(block, blockMap);
  var hasNonEmptyBlock = !!blocks.some(function (tableBlock) {
    return tableBlock.getLength() !== 0;
  });
  return !hasNonEmptyBlock;
};

export default isBlockEmpty;