import { getSiteConfigs } from "../config";

var getSiteConfig = function getSiteConfig(site) {
  var configs = getSiteConfigs();
  var siteConfig = Object.values(configs).find(function (config) {
    return config.id === site;
  });

  if (siteConfig === undefined) {
    throw new Error("Unable to get SiteConfig for site = ".concat(site));
  }

  return siteConfig;
};

export default getSiteConfig;