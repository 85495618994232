export var ImageFieldNames;

(function (ImageFieldNames) {
  ImageFieldNames["DEFAULT"] = "default";
  ImageFieldNames["FACEBOOK"] = "facebook";
  ImageFieldNames["HOME_SITE"] = "homeSite";
  ImageFieldNames["PINTEREST"] = "pinterest";
  ImageFieldNames["SQUARE"] = "square";
})(ImageFieldNames || (ImageFieldNames = {}));

export var ImageFieldProperties;

(function (ImageFieldProperties) {
  ImageFieldProperties["imgUrl"] = "imgUrl";
  ImageFieldProperties["credits"] = "credits";
  ImageFieldProperties["legend"] = "legend";
  ImageFieldProperties["_originalImage"] = "_originalImage";
  ImageFieldProperties["cropArea"] = "cropArea";
  ImageFieldProperties["x"] = "x";
  ImageFieldProperties["y"] = "y";
  ImageFieldProperties["width"] = "width";
  ImageFieldProperties["height"] = "height";
  ImageFieldProperties["configuration"] = "configuration";
  ImageFieldProperties["imageAnchor"] = "imageAnchor";
  ImageFieldProperties["positionX"] = "positionX";
  ImageFieldProperties["positionY"] = "positionY";
})(ImageFieldProperties || (ImageFieldProperties = {}));