import algoliasearch from 'algoliasearch/lite';
import { useMemo } from 'react';
import { contentIndexName, contentSortingRulesToIndex } from 'config/algolia/sortingRulesToIndex';
import useAlgoliaSearchKey from './useAlgoliaSearchKey';

var useAlgoliaSearchClient = function useAlgoliaSearchClient() {
  if (process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID === undefined) {
    throw new Error('Cannot find Algolia config');
  }

  var _useAlgoliaSearchKey = useAlgoliaSearchKey(),
      ALGOLIA_SEARCH_KEY = _useAlgoliaSearchKey.ALGOLIA_SEARCH_KEY;

  var searchClient = useMemo(function () {
    var _process$env$NEXT_PUB;

    return ALGOLIA_SEARCH_KEY === undefined ? undefined : algoliasearch((_process$env$NEXT_PUB = process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID) !== null && _process$env$NEXT_PUB !== void 0 ? _process$env$NEXT_PUB : '', ALGOLIA_SEARCH_KEY);
  }, [ALGOLIA_SEARCH_KEY]);
  return {
    searchClient: searchClient,
    indexName: contentIndexName !== null && contentIndexName !== void 0 ? contentIndexName : '',
    sortingIndexNames: contentSortingRulesToIndex
  };
};

export default useAlgoliaSearchClient;