import { createStyles } from '@material-ui/core';
import { borderRadius, colorUsage, fontSize, fontWeight, getSpacing, lineHeight, shadow, transitionDuration } from 'stylesheet';
var MuiTabOverride = createStyles({
  root: {
    margin: "0 ".concat(getSpacing(1)),
    padding: "".concat(getSpacing(2), " ").concat(getSpacing(5)),
    borderRadius: borderRadius.large,
    backgroundColor: 'transparent',
    transition: "background-color ".concat(transitionDuration["default"]),
    '&:hover $wrapper': {
      color: colorUsage.muiOverride.tab.root.hover.wrapper
    },
    '&$selected': {
      backgroundColor: colorUsage.muiOverride.tab.root.selected.background,
      boxShadow: shadow.bottom.dark
    },
    '&$selected $wrapper': {
      color: colorUsage.muiOverride.tab.root.selected.wrapper
    }
  },
  wrapper: {
    width: 'auto',
    fontSize: fontSize.small,
    fontWeight: fontWeight.bold,
    lineHeight: lineHeight.small,
    textTransform: 'capitalize',
    color: colorUsage.muiOverride.tab.wrapper,
    transition: "color ".concat(transitionDuration["default"])
  }
});
export default MuiTabOverride;