import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as Yup from 'yup';
import { Sites } from '@uno/common-shared';
import { BaseContentTypeName, Fields, GroupFields } from "../../FieldNames";
import { authorLinkedContentField } from "../../common/fieldDefinitions/linkedContent";
import { titleTextField } from "../../common/fieldDefinitions/simpleText";
import { mainSectionField } from "../../common/fieldDefinitions/taxonomy";
import { getFieldLabelId, getGroupLabelId } from "../../../helpers";
import { FieldType } from "../../../types/ContentType";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { TestTaxonomyName } from "../taxonomy/taxonomyName";
import { TestContentTypeName } from "./ContentTypeName";
var baseLabelId = BaseContentTypeName.Article;
var fields = [{
  type: FieldType.Group,
  name: GroupFields.ARTICLE,
  labelId: getGroupLabelId(GroupFields.ARTICLE),
  fields: [titleTextField]
}, {
  type: FieldType.Group,
  name: GroupFields.SEO,
  labelId: getGroupLabelId(GroupFields.SEO),
  fields: []
}, {
  type: FieldType.Group,
  name: GroupFields.PROPERTIES,
  labelId: getGroupLabelId(GroupFields.PROPERTIES),
  fields: [{
    type: FieldType.Group,
    name: GroupFields.TAXONOMY,
    labelId: getGroupLabelId(GroupFields.TAXONOMY),
    fields: [_objectSpread(_objectSpread({}, mainSectionField), {}, {
      taxonomyName: TestTaxonomyName.MainSection
    })]
  }, {
    type: FieldType.Group,
    name: GroupFields.SOURCES,
    labelId: getGroupLabelId(GroupFields.SOURCES),
    fields: [{
      type: FieldType.Repeatable,
      name: Fields.AUTHORS,
      labelId: getFieldLabelId(Fields.AUTHORS),
      defaultValue: [_defineProperty({}, Fields.AUTHOR_ID, '')],
      validation: Yup.array(),
      field: _objectSpread(_objectSpread({}, authorLinkedContentField), {}, {
        contentTypeName: TestContentTypeName.Person
      })
    }]
  }]
}];
export var article = {
  site: Sites.TEST,
  name: TestContentTypeName.Article,
  labelId: 'content-type.article.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  getLabel: function getLabel() {
    return '';
  },
  getPreviewImage: function getPreviewImage() {
    return null;
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds(contentData) {
    var _contentData$authors$, _contentData$authors;

    return (_contentData$authors$ = contentData === null || contentData === void 0 ? void 0 : (_contentData$authors = contentData.authors) === null || _contentData$authors === void 0 ? void 0 : _contentData$authors.map(function (_ref2) {
      var authorId = _ref2.authorId;
      return authorId;
    })) !== null && _contentData$authors$ !== void 0 ? _contentData$authors$ : [];
  },
  getMainSectionId: function getMainSectionId(contentData) {
    var _contentData$mainSect, _contentData$mainSect2;

    return (_contentData$mainSect = contentData === null || contentData === void 0 ? void 0 : (_contentData$mainSect2 = contentData.mainSection) === null || _contentData$mainSect2 === void 0 ? void 0 : _contentData$mainSect2[0]) !== null && _contentData$mainSect !== void 0 ? _contentData$mainSect : '';
  },
  getManualModificationDate: function getManualModificationDate() {
    return undefined;
  },
  fields: fields,
  partialDependencyFields: [],
  isLinkable: true,
  isCreatable: true
};