import { Services } from '@uno/common-shared';
import { ContentModel, NavigationModel } from "./models";
/** Service on which depends a child of a dependency */

export var DependencyService;
/** Model of the child of a dependency */

(function (DependencyService) {
  DependencyService[DependencyService["Content"] = Services.CONTENT] = "Content";
  DependencyService[DependencyService["Navigation"] = Services.NAVIGATION] = "Navigation";
})(DependencyService || (DependencyService = {}));

export var DependencyModel;

(function (DependencyModel) {
  DependencyModel[DependencyModel["Content"] = ContentModel.Content] = "Content";
  DependencyModel[DependencyModel["PageList"] = NavigationModel.Page] = "PageList";
})(DependencyModel || (DependencyModel = {}));