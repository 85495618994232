import _slicedToArray from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

var _Page$CONTENT, _Page$TAXONOMY, _allPages, _navBarLinks, _defaultPageByPage;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var Page;

(function (Page) {
  Page["CONTENT"] = "CONTENT";
  Page["TAXONOMY"] = "TAXONOMY";
})(Page || (Page = {}));

export var ContentPage;

(function (ContentPage) {
  ContentPage["EDIT"] = "EDIT";
  ContentPage["LIST"] = "LIST";
})(ContentPage || (ContentPage = {}));

export var TaxonomyPage;

(function (TaxonomyPage) {
  TaxonomyPage["EDIT"] = "EDIT";
  TaxonomyPage["LIST"] = "LIST";
})(TaxonomyPage || (TaxonomyPage = {}));

var allPages = (_allPages = {}, _defineProperty(_allPages, Page.CONTENT, (_Page$CONTENT = {}, _defineProperty(_Page$CONTENT, ContentPage.EDIT, {
  path: 'content/edit',
  route: '/[site]/content/edit/[id]'
}), _defineProperty(_Page$CONTENT, ContentPage.LIST, {
  path: 'content',
  route: '/[site]/content'
}), _Page$CONTENT)), _defineProperty(_allPages, Page.TAXONOMY, (_Page$TAXONOMY = {}, _defineProperty(_Page$TAXONOMY, TaxonomyPage.EDIT, {
  path: 'taxonomy/edit',
  route: '/[site]/taxonomy/edit/[id]'
}), _defineProperty(_Page$TAXONOMY, TaxonomyPage.LIST, {
  path: 'taxonomy',
  route: '/[site]/taxonomy'
}), _Page$TAXONOMY)), _allPages);
export var navBarLinks = (_navBarLinks = {}, _defineProperty(_navBarLinks, Page.CONTENT, {
  path: allPages[Page.CONTENT][ContentPage.LIST].path,
  label: 'header.nav.content'
}), _defineProperty(_navBarLinks, Page.TAXONOMY, {
  path: allPages[Page.TAXONOMY][TaxonomyPage.LIST].path,
  label: 'header.nav.taxonomy'
}), _navBarLinks);
export var allNavPaths = Object.values(navBarLinks).map(function (pageInfo) {
  return pageInfo.path;
});
var defaultPageByPage = (_defaultPageByPage = {}, _defineProperty(_defaultPageByPage, Page.CONTENT, allPages[Page.CONTENT][ContentPage.LIST]), _defineProperty(_defaultPageByPage, Page.TAXONOMY, allPages[Page.TAXONOMY][TaxonomyPage.LIST]), _defaultPageByPage);
var defaultPage = defaultPageByPage[Page.CONTENT];
var routes = Object.entries(allPages).flatMap(function (_ref) {
  var _ref2 = _slicedToArray(_ref, 2),
      page = _ref2[0],
      subPages = _ref2[1];

  return Object.values(subPages).map(function (pageInfo) {
    return _objectSpread(_objectSpread({}, pageInfo), {}, {
      page: page
    });
  });
});
export var getContentPath = function getContentPath(site, contentPage, contentId) {
  return "/".concat(site, "/").concat(allPages[Page.CONTENT][contentPage].path, "/").concat(contentId);
};
export var redirectBySite = function redirectBySite(site, route) {
  var _routes$find;

  var page = (_routes$find = routes.find(function (pageInfo) {
    return pageInfo.route === route;
  })) === null || _routes$find === void 0 ? void 0 : _routes$find.page;
  var redirect = page === undefined ? defaultPage // couldn't retrieve the page corresponding to given route: use defaultPage
  : defaultPageByPage[page]; // use current page's default destination

  return redirect.route.replace('[site]', site);
};