import { GroupFields } from "../../../FieldNames";
import { metaUrlField } from "../../../common/fieldDefinitions/metaUrl";
import { bodyRichTextField } from "../../../common/fieldDefinitions/richText";
import { emailTextField, jobSubTitleTextField, jobTitleTextField, nameTextField, urlFacebookTextField, urlInstagramTextField, urlLinkedInTextField, urlPinterestTextField, urlTiktokTextField, urlTwitterTextField, urlWebsiteTextField, urlWikipediaTextField } from "../../../common/fieldDefinitions/simpleText";
import { activeSwitchField, isChiefEditorSwitchField } from "../../../common/fieldDefinitions/switch";
import { getGroupLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType";
import { dateGroupFields } from "./dateGroupFields";
import { imageGroupFields } from "./imageGroupFields";
import { legacyGroupFields } from "./legacyGroupFields";
import { seoGroupFields } from "./seoGroupFields";
export var authorMainGroupFields = {
  type: FieldType.Group,
  name: GroupFields.AUTHOR,
  labelId: getGroupLabelId(GroupFields.AUTHOR),
  fields: [nameTextField, jobTitleTextField, jobSubTitleTextField, isChiefEditorSwitchField, bodyRichTextField, {
    type: FieldType.Group,
    name: GroupFields.NETWORK,
    labelId: getGroupLabelId(GroupFields.NETWORK),
    fields: [emailTextField, urlFacebookTextField, urlInstagramTextField, urlPinterestTextField, urlTwitterTextField, urlLinkedInTextField, urlTiktokTextField, urlWikipediaTextField, urlWebsiteTextField]
  }]
};
export var authorSeoGroupFields = seoGroupFields();
export var authorPropertiesGroupFields = {
  type: FieldType.Group,
  name: GroupFields.PROPERTIES,
  labelId: getGroupLabelId(GroupFields.PROPERTIES),
  fields: [metaUrlField, activeSwitchField, imageGroupFields(), dateGroupFields(), legacyGroupFields()]
};