import { createStyles } from '@material-ui/core';
import { borderRadius, colorUsage, fontSize, fontWeight, getSpacing, lineHeight, transitionDuration } from 'stylesheet';
var MuiGradientButtonOverride = createStyles({
  root: {
    padding: "".concat(getSpacing(1), " ").concat(getSpacing(2)),
    borderRadius: borderRadius.medium,
    border: 0,
    color: colorUsage.muiOverride.button.gradient.color,
    textTransform: 'unset',
    background: colorUsage.muiOverride.button.gradient.background,
    zIndex: 1,
    '&:before': {
      position: 'absolute',
      content: '""',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderRadius: borderRadius.medium,
      background: colorUsage.muiOverride.button.gradient.hoveredBackground,
      zIndex: -1,
      transition: "opacity ".concat(transitionDuration["default"], " linear"),
      opacity: 0
    },
    '&:hover:before': {
      opacity: 1
    }
  },
  label: {
    fontSize: fontSize.normal,
    fontWeight: fontWeight.bold,
    lineHeight: lineHeight.normal
  },
  startIcon: {
    marginRight: getSpacing(1)
  },
  disabled: {
    background: colorUsage.muiOverride.button.gradient.disabledBackground
  }
});
export default MuiGradientButtonOverride;