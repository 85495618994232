import { BlockType } from '@uno/common-cms';

var getTableKeyFromBlock = function getTableKeyFromBlock(block) {
  if (block.getType() !== BlockType.TABLE) {
    throw new Error("Block for key \"".concat(block.getKey(), "\" is not a table block"));
  }

  var _ref = block.getData().toObject(),
      tableKey = _ref.tableKey;

  return tableKey;
};

export default getTableKeyFromBlock;