import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dependencyToLinkedContent } from 'libs/content/transformers/dependencyToLinkedContent';
import { getFirstLoadContent } from 'redux/content';

var useLinkedContent = function useLinkedContent(linkedContentId) {
  var _useState = useState(linkedContentId),
      initialLinkedContentId = _useState[0];

  var _useState2 = useState(null),
      linkedContent = _useState2[0],
      setLinkedContent = _useState2[1];

  var _useSelector = useSelector(getFirstLoadContent),
      firstLoadContent = _useSelector.value;

  var dependencyMapping = firstLoadContent === null || firstLoadContent === void 0 ? void 0 : firstLoadContent.dependencyMap;
  var linkedContentDependencyObject = get(dependencyMapping, initialLinkedContentId);
  useEffect(function () {
    linkedContentDependencyObject && setLinkedContent(dependencyToLinkedContent(initialLinkedContentId, linkedContentDependencyObject));
  }, [initialLinkedContentId, linkedContentDependencyObject]);
  return [linkedContent, setLinkedContent];
};

export default useLinkedContent;