import { createSlice } from '@reduxjs/toolkit';
import assignIn from 'lodash/assignIn';
export var dependenciesInitialState = {};
var contentSlice = createSlice({
  name: 'dependencies',
  initialState: dependenciesInitialState,
  reducers: {
    updateDependencies: function updateDependencies(state, action) {
      assignIn(state, action.payload);
    },
    flushDependencies: function flushDependencies() {
      return dependenciesInitialState;
    }
  }
});
var _contentSlice$actions = contentSlice.actions,
    updateDependencies = _contentSlice$actions.updateDependencies,
    flushDependencies = _contentSlice$actions.flushDependencies;
export { updateDependencies, flushDependencies };
export default contentSlice.reducer;