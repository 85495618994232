import { Sites } from '@uno/common-shared';
import { BaseContentTypeName, Fields } from "../../FieldNames";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { AufemininContentTypeName } from "./ContentTypeName";
import { articleMainGroupFields, articlePropertiesGroupFields, articleSeoGroupFields } from "./common";
var baseLabelId = BaseContentTypeName.Article;
var fields = [articleMainGroupFields, articleSeoGroupFields, articlePropertiesGroupFields];
export var article = {
  site: Sites.AUFEMININ_COM,
  name: AufemininContentTypeName.ArticleFR,
  labelId: 'content-type.article.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  // TODO: See to fix "Type instantiation is excessively deep and possibly infinite"
  // @ts-ignore Sorry too many fields
  getLabel: function getLabel(contentData) {
    return contentData.title !== undefined ? contentData.title : '';
  },
  getPreviewImage: function getPreviewImage() {
    return null;
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  getMainSectionId: function getMainSectionId(contentData) {
    var _contentData$mainSect, _contentData$mainSect2;

    return (_contentData$mainSect = contentData === null || contentData === void 0 ? void 0 : (_contentData$mainSect2 = contentData.mainSection) === null || _contentData$mainSect2 === void 0 ? void 0 : _contentData$mainSect2[0]) !== null && _contentData$mainSect !== void 0 ? _contentData$mainSect : '';
  },
  getManualModificationDate: function getManualModificationDate() {
    return undefined;
  },
  fields: fields,
  partialDependencyFields: [Fields.CHAPO, Fields.COVER_IMAGES, Fields.TITLE],
  isLinkable: true,
  isCreatable: true
};