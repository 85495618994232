import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var sendingDateField = {
  type: FieldType.Date,
  name: Fields.SENDING_DATE,
  labelId: getFieldLabelId(Fields.SENDING_DATE),
  defaultValue: null,
  validation: Yup.string().required('form.required')
};