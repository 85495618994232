import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Sites } from '@uno/common-shared';
import { AufemininContentTypeName } from "../../../contentTypes/ContentTypeName";
import { AufemininTaxonomyName } from "../../../taxonomy";
import { buildTagPageFields, tagPage } from "../../../contentTypes/TagPage";
var fields = buildTagPageFields(AufemininTaxonomyName.TagSeoES, AufemininTaxonomyName.TagPeopleES, AufemininTaxonomyName.TagNewsES, AufemininTaxonomyName.MainSectionES, AufemininContentTypeName.TagPageES);
export var tagPageES = _objectSpread(_objectSpread({}, tagPage), {}, {
  site: Sites.ENFEMENINO_COM,
  name: AufemininContentTypeName.TagPageES,
  fields: fields
});