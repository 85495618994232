/* eslint-disable max-lines */
import { Sites } from '@uno/common-shared';
import { BaseContentTypeName, Fields } from "../../FieldNames";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { AufemininContentTypeName } from "./ContentTypeName";
import { authorMainGroupFields, authorPropertiesGroupFields, authorSeoGroupFields } from "./common/baseAuthorFields";
var baseLabelId = BaseContentTypeName.Author;
var fields = [authorMainGroupFields, authorSeoGroupFields, authorPropertiesGroupFields];
export var author = {
  site: Sites.AUFEMININ_COM,
  name: AufemininContentTypeName.AuthorFR,
  labelId: 'content-type.author.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Person,
  // TODO: See to fix "Type instantiation is excessively deep and possibly infinite"
  // @ts-ignore Sorry too many fields
  getLabel: function getLabel(contentData) {
    var _contentData$name;

    return (_contentData$name = contentData.name) !== null && _contentData$name !== void 0 ? _contentData$name : '';
  },
  getPreviewImage: function getPreviewImage() {
    return null;
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  getMainSectionId: function getMainSectionId() {
    return '';
  },
  getManualModificationDate: function getManualModificationDate() {
    return undefined;
  },
  fields: fields,
  partialDependencyFields: [Fields.NAME, Fields.COVER_IMAGES, Fields.BODY],
  isLinkable: true,
  isCreatable: true
};