import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _objectSpread2;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Fields } from "../../../../../../config";
import { PartialContentPageChunkSchema } from "../../../../../page";
import { RepeatableLinkedContentSchema, RepeatableLinkedContentWithTitleSchema } from "../../../../../repeatableLinkedContent";
import { HOME_CHUNK_CONTENT_TYPE_NAMES_AUFEMININ as HOME_CHUNK_CONTENT_TYPE_NAMES } from "../../../../../../types";
var HomeChunkBaseSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, PartialContentPageChunkSchema.properties), {}, {
    _contentTypeName: {
      "enum": HOME_CHUNK_CONTENT_TYPE_NAMES
    }
  }),
  required: [].concat(_toConsumableArray(PartialContentPageChunkSchema.required), ['_contentTypeName'])
};
var mainSectionSchema = {
  type: 'array',
  items: {
    type: 'string'
  },
  minItems: 1
};
var HomeChunkSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, HomeChunkBaseSchema.properties), {}, (_objectSpread2 = {}, _defineProperty(_objectSpread2, Fields.AFFILIATION_CONTENTS, RepeatableLinkedContentWithTitleSchema), _defineProperty(_objectSpread2, Fields.MACRO_SECTION_1_MAIN_SECTION, mainSectionSchema), _defineProperty(_objectSpread2, Fields.MACRO_SECTION_1_TOP_CONTENT, RepeatableLinkedContentSchema), _defineProperty(_objectSpread2, Fields.MACRO_SECTION_2_MAIN_SECTION, mainSectionSchema), _defineProperty(_objectSpread2, Fields.MACRO_SECTION_2_TOP_CONTENT, RepeatableLinkedContentSchema), _defineProperty(_objectSpread2, Fields.MACRO_SECTION_3_MAIN_SECTION, mainSectionSchema), _defineProperty(_objectSpread2, Fields.MACRO_SECTION_3_TOP_CONTENT, RepeatableLinkedContentSchema), _defineProperty(_objectSpread2, Fields.MACRO_SECTION_4_MAIN_SECTION, mainSectionSchema), _defineProperty(_objectSpread2, Fields.MACRO_SECTION_4_TOP_CONTENT, RepeatableLinkedContentSchema), _defineProperty(_objectSpread2, Fields.MACRO_SECTION_5_MAIN_SECTION, mainSectionSchema), _defineProperty(_objectSpread2, Fields.MACRO_SECTION_5_TOP_CONTENT, RepeatableLinkedContentSchema), _defineProperty(_objectSpread2, Fields.PARTNER_CONTENTS, RepeatableLinkedContentWithTitleSchema), _defineProperty(_objectSpread2, Fields.SPOTLIGHT_MACRO_SECTION_TOP_CONTENT, RepeatableLinkedContentSchema), _defineProperty(_objectSpread2, Fields.TOP_ARTICLES, RepeatableLinkedContentSchema), _defineProperty(_objectSpread2, Fields.TOP_VIDEOS, RepeatableLinkedContentSchema), _objectSpread2)),
  required: [].concat(_toConsumableArray(HomeChunkBaseSchema.required), [Fields.AFFILIATION_CONTENTS, Fields.MACRO_SECTION_1_MAIN_SECTION, Fields.MACRO_SECTION_1_TOP_CONTENT, Fields.MACRO_SECTION_2_MAIN_SECTION, Fields.MACRO_SECTION_2_TOP_CONTENT, Fields.MACRO_SECTION_3_MAIN_SECTION, Fields.MACRO_SECTION_3_TOP_CONTENT, Fields.MACRO_SECTION_4_MAIN_SECTION, Fields.MACRO_SECTION_4_TOP_CONTENT, Fields.MACRO_SECTION_5_MAIN_SECTION, Fields.MACRO_SECTION_5_TOP_CONTENT, Fields.PARTNER_CONTENTS, Fields.SPOTLIGHT_MACRO_SECTION_TOP_CONTENT, Fields.TOP_ARTICLES, Fields.TOP_VIDEOS]),
  additionalProperties: true
};
export { HomeChunkBaseSchema as HomeChunkBaseSchemaAufeminin, HomeChunkSchema as HomeChunkSchemaAufeminin };