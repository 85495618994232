import { blocksConfig } from './config';
import { getGenericBlockTypeFromDraftjsBlock } from './util';
export var transformDraftjsToGenericState = function transformDraftjsToGenericState(contentState) {
  var draftBlocks = contentState.getBlocksAsArray();
  return {
    blocks: draftBlocks.reduce(function (genericBlocks, currentDraftjsBlock) {
      var _getGenericBlockTypeF;

      var genericBlockType = (_getGenericBlockTypeF = getGenericBlockTypeFromDraftjsBlock(currentDraftjsBlock, contentState)) !== null && _getGenericBlockTypeF !== void 0 ? _getGenericBlockTypeF : 'undefinedGenericBlockType';
      var blockConfig = blocksConfig[genericBlockType];

      if (blockConfig === undefined) {
        return genericBlocks;
      }

      return blockConfig.transformToGenericAndAppend(currentDraftjsBlock, genericBlocks, contentState);
    }, [])
  };
};