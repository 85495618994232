import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { ALL_BLOCKS, FieldType } from "../../../../types/ContentType/fields";
export var paragraphRichTextField = {
  type: FieldType.RichText,
  name: Fields.PARAGRAPH,
  enabledBlocks: ALL_BLOCKS,
  labelId: getFieldLabelId(Fields.PARAGRAPH),
  defaultValue: {
    blocks: []
  },
  validation: Yup.object()
};