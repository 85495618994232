import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { BlockType, FieldType } from "../../../../types/ContentType/fields";
export var sourcesRichTextField = {
  type: FieldType.RichText,
  name: Fields.SOURCES,
  enabledBlocks: [BlockType.UL, BlockType.UNSTYLED],
  labelId: getFieldLabelId(Fields.SOURCES),
  defaultValue: {
    blocks: [],
    entityMap: {}
  },
  validation: Yup.object()
};