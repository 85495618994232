import isHighlightedBlock from './isHighlightedBlock';

var getBlockHighlightPosition = function getBlockHighlightPosition(editorState, block) {
  if (!block || !isHighlightedBlock(block)) {
    return;
  }

  var contentState = editorState.getCurrentContent();
  var prevBlock = contentState.getBlockBefore(block.getKey());
  var nextBlock = contentState.getBlockAfter(block.getKey());
  var isFirst = !isHighlightedBlock(prevBlock);
  var isLast = !isHighlightedBlock(nextBlock);
  return {
    isFirst: isFirst,
    isLast: isLast
  };
};

export default getBlockHighlightPosition;