import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { array as YupArray, object as YupObject } from 'yup';
import { CONTENT_TYPES } from "../../config";
import { flattenFields } from "../../helpers";
import { FieldType } from "../../types";

var addCommonValidation = function addCommonValidation(validation) {
  if (validation !== undefined && 'type' in validation && validation.type === 'string') {
    return validation.ensure();
  }

  return validation;
};

var getRepeatableFieldValidationSchema = function getRepeatableFieldValidationSchema(field) {
  var subValidation = field.validation && 'type' in field.validation && field.validation.type === 'array' ? field.validation : YupArray();
  var fieldToFlat = field.field.type === FieldType.Group ? field.field.fields : [field.field];
  var subfields = flattenFields(fieldToFlat);
  var subSchema = fieldsToValidationSchema(subfields);
  return subValidation.of(subSchema);
};

var fieldsToValidationSchema = function fieldsToValidationSchema(fields) {
  var validators = fields.reduce(function (acc, field) {
    switch (field.type) {
      case FieldType.Repeatable:
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, field.name, getRepeatableFieldValidationSchema(field)));
    }

    return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, field.name, addCommonValidation(field.validation)));
  }, {});
  return YupObject().shape(validators);
};

export var getBusinessValidationSchemaFromContentType = function getBusinessValidationSchemaFromContentType(contentTypeName) {
  var contentType = CONTENT_TYPES[contentTypeName];
  var allFields = flattenFields(contentType.fields);
  return fieldsToValidationSchema(allFields);
};