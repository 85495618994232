import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var contentTypeSelectField = {
  type: FieldType.Select,
  name: Fields.CONTENT_TYPE,
  labelId: getFieldLabelId(Fields.CONTENT_TYPE),
  defaultValue: undefined,
  allowEmpty: false,
  validation: Yup.string().required('form.required')
};