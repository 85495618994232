export var isSponsoredSchema = {
  type: 'object',
  properties: {
    isSponsored: {
      type: 'boolean'
    },
    ctaLabel: {
      type: 'string'
    },
    partnerUrl: {
      type: 'string'
    },
    legalNotice: {
      type: 'string'
    },
    partnerName: {
      type: 'string'
    }
  },
  required: ['isSponsored'],
  additionalProperties: false
};