import React from "react";
var __jsx = React.createElement;
import { ContentTypeIconName } from '@uno/common-cms';
import { Pen, Slideshow, User } from 'theme/icons';

var getContentTypeIcon = function getContentTypeIcon(icon) {
  switch (icon) {
    case ContentTypeIconName.Article:
      return __jsx(Pen, null);

    case ContentTypeIconName.Slideshow:
      return __jsx(Slideshow, null);

    case ContentTypeIconName.Person:
      return __jsx(User, null);
  }
};

export default getContentTypeIcon;