import _defineProperty from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

var _entitiesConfig;

export var Entities;

(function (Entities) {
  Entities["AffiliationLink"] = "AFFILIATION_LINK";
  Entities["ExternalLink"] = "EXTERNAL_LINK";
  Entities["InternalLink"] = "INTERNAL_LINK";
})(Entities || (Entities = {}));

export var entitiesConfig = (_entitiesConfig = {}, _defineProperty(_entitiesConfig, Entities.AffiliationLink, {}), _defineProperty(_entitiesConfig, Entities.ExternalLink, {}), _defineProperty(_entitiesConfig, Entities.InternalLink, {}), _entitiesConfig);