import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import omit from 'lodash/omit';
import { simplifiedPageLinkSchema } from '@uno/common-shared';
import { CONTENT_TYPES_NAMES, PAGE_CONFIGS_NAMES } from "../config";
import { ContentStatus, PageStatus, PublicationStatus } from "../types";
export var basePartialDocumentSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({
    absoluteUrl: {
      type: 'string'
    }
  }, omit(simplifiedPageLinkSchema.properties, 'id')), {}, {
    contentTypeName: {
      "enum": CONTENT_TYPES_NAMES
    },
    // override pageLink simplified schema
    pageConfigName: {
      "enum": PAGE_CONFIGS_NAMES
    },
    contentStatus: {
      "enum": Object.values(ContentStatus)
    },
    pageStatus: {
      "enum": Object.values(PageStatus)
    },
    publicationStatus: {
      "enum": Object.values(PublicationStatus)
    },
    publishedAt: {
      type: 'string'
    },
    mainSection: {
      type: ['string', 'null']
    },
    sectionHierarchy: {
      type: ['array', 'null'],
      items: {
        type: 'object',
        properties: {
          termId: {
            type: 'string'
          },
          label: {
            type: 'string'
          }
        },
        required: ['termId', 'label']
      }
    },
    seoScore: {
      type: 'number'
    },
    parentMainSection: {
      type: 'string'
    }
  }),
  required: [],
  additionalProperties: false
};
export var basePartialDocumentSchemaWithRequiredUrl = {
  type: 'object',
  properties: basePartialDocumentSchema.properties,
  required: ['absoluteUrl'],
  additionalProperties: false
};
export var filterSchema = {
  anyOf: [{
    type: 'object',
    properties: {
      bool: {
        type: 'object'
      },
      term: {
        type: 'object'
      },
      terms: {
        type: 'object'
      },
      range: {
        type: 'object'
      },
      exists: {
        type: 'object'
      }
    },
    required: [],
    additionalProperties: false
  }, {
    type: 'array',
    items: {
      type: 'object',
      properties: {
        bool: {
          type: 'object'
        },
        term: {
          type: 'object'
        },
        terms: {
          type: 'object'
        },
        range: {
          type: 'object'
        },
        exists: {
          type: 'object'
        }
      },
      required: [],
      additionalProperties: false
    }
  }]
};
export var baseSearchParamsSchema = {
  type: 'object',
  properties: {
    // Similar to ElasticSearch occurrences types: https://www.elastic.co/guide/en/elasticsearch/reference/7.x/query-dsl-bool-query.html#query-dsl-bool-query
    bool: {
      type: 'object',
      properties: {
        filter: filterSchema,
        should: filterSchema,
        must: filterSchema,
        must_not: filterSchema,
        minimum_should_match: {
          type: 'number'
        }
      },
      required: [],
      additionalProperties: false
    }
  },
  required: [],
  additionalProperties: false
};