import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _Stages$DEV, _Stages$STAGING, _Stages$PRODUCTION, _Stages$PRODUCTION_LE, _emptyConfig;

import { Stages } from "../../stages";
import { Context, UrlCategories } from "../urlCategories";
export var getUrlsForAContext = function getUrlsForAContext(_ref) {
  var _ref2;

  var domainUrl = _ref.domainUrl,
      siteUrl = _ref.siteUrl,
      staticUrl = _ref.staticUrl,
      assetsUrl = _ref.assetsUrl;
  return _ref2 = {}, _defineProperty(_ref2, UrlCategories.DOMAIN_URL, domainUrl), _defineProperty(_ref2, UrlCategories.SITE_URL, siteUrl), _defineProperty(_ref2, UrlCategories.STATIC_URL, staticUrl), _defineProperty(_ref2, UrlCategories.ASSETS_URL, assetsUrl), _ref2;
};
export var emptyUrls = getUrlsForAContext({
  domainUrl: '',
  siteUrl: '',
  staticUrl: '',
  assetsUrl: ''
});
export var emptyConfig = (_emptyConfig = {}, _defineProperty(_emptyConfig, Stages.DEV, (_Stages$DEV = {}, _defineProperty(_Stages$DEV, Context.FORUM, emptyUrls), _defineProperty(_Stages$DEV, Context.MAIN, emptyUrls), _Stages$DEV)), _defineProperty(_emptyConfig, Stages.STAGING, (_Stages$STAGING = {}, _defineProperty(_Stages$STAGING, Context.FORUM, emptyUrls), _defineProperty(_Stages$STAGING, Context.MAIN, emptyUrls), _Stages$STAGING)), _defineProperty(_emptyConfig, Stages.PRODUCTION, (_Stages$PRODUCTION = {}, _defineProperty(_Stages$PRODUCTION, Context.FORUM, emptyUrls), _defineProperty(_Stages$PRODUCTION, Context.MAIN, emptyUrls), _Stages$PRODUCTION)), _defineProperty(_emptyConfig, Stages.PRODUCTION_LEGACY, (_Stages$PRODUCTION_LE = {}, _defineProperty(_Stages$PRODUCTION_LE, Context.FORUM, emptyUrls), _defineProperty(_Stages$PRODUCTION_LE, Context.MAIN, emptyUrls), _Stages$PRODUCTION_LE)), _emptyConfig);