import React from "react";
var __jsx = React.createElement;
import { useIntl } from 'react-intl';
import { BlockType } from '@uno/common-cms';
import { H2 } from 'theme/icons';
import { useHandleBlockToggleCallback } from '../../../shared/hooks/useHandleBlockToggleCallback';
import { HeaderTwoBlockToggle } from './BlockToolbarComponent.style';

var BlockToolbarComponent = function BlockToolbarComponent(_ref) {
  var setEditorState = _ref.setEditorState;
  var handleBlockToggle = useHandleBlockToggleCallback(BlockType.H2, setEditorState);
  var intl = useIntl();
  var label = intl.formatMessage({
    id: 'content.labels.block-toolbar.h2'
  });
  return __jsx(HeaderTwoBlockToggle, {
    onMouseDown: handleBlockToggle(),
    title: label,
    "aria-label": label
  }, __jsx(H2, null));
};

export default BlockToolbarComponent;