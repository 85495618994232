import { colorUsage } from 'stylesheet';
var horizontalScrollBarOverride = {
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    height: '4px',
    width: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: colorUsage.scrollbar
  },
  textOverflow: 'initial',
  whiteSpace: 'nowrap'
};
export default horizontalScrollBarOverride;