import _defineProperty from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

var _Blocks;

import { AtomicBlockType, BlockType } from '@uno/common-cms';
import { affiliationBlock } from './blocks/affiliation';
import { beopBlock } from './blocks/beop';
import { blockQuoteBlock } from './blocks/blockQuote';
import { embedBlock } from './blocks/embed';
import { h2Block } from './blocks/h2';
import { h3Block } from './blocks/h3';
import { imageBlock } from './blocks/image';
import { orderedListBlock } from './blocks/orderedList';
import { paragraphBlock } from './blocks/paragraph';
import { recipeBlock } from './blocks/recipe';
import { slideshowEmbedBlock } from './blocks/slideshowEmbed';
import { tableBlock } from './blocks/table';
import { unorderedListBlock } from './blocks/unorderedList';
import { unstyledBlock } from './blocks/unstyled';
import { videoBlock } from './blocks/video';
export var Blocks = (_Blocks = {}, _defineProperty(_Blocks, AtomicBlockType.AFFILIATION, affiliationBlock), _defineProperty(_Blocks, AtomicBlockType.BEOP, beopBlock), _defineProperty(_Blocks, AtomicBlockType.EMBED, embedBlock), _defineProperty(_Blocks, AtomicBlockType.IMAGE, imageBlock), _defineProperty(_Blocks, AtomicBlockType.RECIPE, recipeBlock), _defineProperty(_Blocks, AtomicBlockType.SLIDESHOW_EMBED, slideshowEmbedBlock), _defineProperty(_Blocks, AtomicBlockType.VIDEO, videoBlock), _defineProperty(_Blocks, BlockType.BLOCKQUOTE, blockQuoteBlock), _defineProperty(_Blocks, BlockType.H2, h2Block), _defineProperty(_Blocks, BlockType.H3, h3Block), _defineProperty(_Blocks, BlockType.OL, orderedListBlock), _defineProperty(_Blocks, BlockType.PARAGRAPH, paragraphBlock), _defineProperty(_Blocks, BlockType.TABLE, tableBlock), _defineProperty(_Blocks, BlockType.UL, unorderedListBlock), _defineProperty(_Blocks, BlockType.UNSTYLED, unstyledBlock), _Blocks);