import { createStyles } from '@material-ui/core';
import { colorUsage, getSpacing } from 'stylesheet';
var MuiTableHeadOverride = createStyles({
  root: {
    height: '100%',
    overflow: 'hidden',
    display: 'block',
    alignSelf: 'center',
    textAlign: 'center',
    borderRadius: "".concat(getSpacing(1)),
    position: 'static',
    margin: "0",
    padding: "0 ".concat(getSpacing(2)),
    backgroundColor: "".concat(colorUsage.muiOverride.tableHead.backgroundColor)
  }
});
export default MuiTableHeadOverride;