var __jsx = React.createElement;
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { IntlProvider } from 'react-intl';
import Header from 'components/common/Header';
import { ToasterProvider } from 'components/common/ToasterProvider';
import { flattenMessages } from 'services/i18n/intl';
import { theme } from 'theme';
import enMessages from 'translations/en.json';
import frMessages from 'translations/fr.json';
import { PageContent, RootContainer } from './Root.style';
var locales = {
  fr: flattenMessages(frMessages),
  en: flattenMessages(enMessages)
};

var Root = function Root(_ref) {
  var children = _ref.children;
  return __jsx(ThemeProvider, {
    theme: theme
  }, __jsx(IntlProvider, {
    locale: "fr",
    messages: locales.fr
  }, __jsx(ToasterProvider, null, __jsx(RootContainer, null, __jsx(Header, null), __jsx(PageContent, null, children)))));
};

export default Root;