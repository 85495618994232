import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { getSiteConfigs } from '@uno/common-cms';
import { useUserRoles } from 'libs/content/hooks';

var useAllowedSites = function useAllowedSites() {
  var _useUserRoles = useUserRoles(),
      isSiteAllowed = _useUserRoles.isSiteAllowed;

  var allowedSites = useMemo(function () {
    var siteConfigs = getSiteConfigs();
    return orderBy(siteConfigs, 'label', 'asc').filter(function (siteConfig) {
      return isSiteAllowed(siteConfig.id);
    });
  }, [isSiteAllowed]);
  return allowedSites;
};

export default useAllowedSites;