import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var faqIndexNumberField = {
  type: FieldType.Number,
  name: Fields.FAQ_INDEX,
  labelId: getFieldLabelId(Fields.FAQ_INDEX),
  defaultValue: '',
  validation: Yup.string()
};