import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var emailTextField = {
  type: FieldType.SimpleText,
  name: Fields.EMAIL,
  labelId: getFieldLabelId(Fields.EMAIL),
  defaultValue: '',
  validation: Yup.string().email('form.invalidFormat')
};