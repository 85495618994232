import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var altSectionField = {
  type: FieldType.Taxonomy,
  name: Fields.ALT_SECTION,
  labelId: getFieldLabelId(Fields.ALT_SECTION),
  defaultValue: [],
  multiple: true,
  validation: Yup.array()
};