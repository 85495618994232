import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { DependencyScope, FieldType } from "../../../../types/ContentType/fields";
export var weightNumberField = {
  type: FieldType.Number,
  name: Fields.WEIGHT,
  labelId: getFieldLabelId(Fields.WEIGHT),
  dependencyScope: DependencyScope.Partial,
  defaultValue: '',
  validation: Yup.string().ensure().required('form.required')
};