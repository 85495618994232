import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var displayOnHomepageSwitchField = {
  type: FieldType.Switch,
  name: Fields.DISPLAY_ON_HOMEPAGE,
  labelId: getFieldLabelId(Fields.DISPLAY_ON_HOMEPAGE),
  defaultValue: true,
  validation: Yup["boolean"]().required('form.required')
};