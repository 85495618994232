import { getContentType } from 'libs/content/utils';
export var contentToLinkedContent = function contentToLinkedContent(_ref) {
  var id = _ref.id,
      contentType = _ref.contentType,
      contentData = _ref.contentData;
  return {
    id: id,
    label: getContentType(contentType).getLabel(contentData),
    previewImage: getContentType(contentType).getPreviewImage(contentData)
  };
};