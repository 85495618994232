import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _Yup$object$shape, _Yup$object$shape2, _Yup$object$shape3, _Yup$object$shape4, _Yup$object$shape5, _Yup$object$shape6, _Yup$object$shape7, _Yup$object$shape8;

import * as Yup from 'yup';
import { ImageFieldProperties } from '@uno/common-shared';
/** used to generate the validation schema in validateContent.ts */

export var imageFieldValidationRequired = Yup.object().shape((_Yup$object$shape4 = {}, _defineProperty(_Yup$object$shape4, ImageFieldProperties.imgUrl, Yup.string().ensure().required('form.imageFieldRequired')), _defineProperty(_Yup$object$shape4, ImageFieldProperties.credits, Yup.string().ensure()), _defineProperty(_Yup$object$shape4, ImageFieldProperties.legend, Yup.string().ensure()), _defineProperty(_Yup$object$shape4, ImageFieldProperties._originalImage, Yup.object().shape((_Yup$object$shape2 = {}, _defineProperty(_Yup$object$shape2, ImageFieldProperties.imgUrl, Yup.string().ensure()), _defineProperty(_Yup$object$shape2, ImageFieldProperties.cropArea, Yup.object().shape((_Yup$object$shape = {}, _defineProperty(_Yup$object$shape, ImageFieldProperties.x, Yup.number()), _defineProperty(_Yup$object$shape, ImageFieldProperties.y, Yup.number()), _defineProperty(_Yup$object$shape, ImageFieldProperties.width, Yup.number()), _defineProperty(_Yup$object$shape, ImageFieldProperties.height, Yup.number()), _Yup$object$shape))), _Yup$object$shape2))), _defineProperty(_Yup$object$shape4, ImageFieldProperties.imageAnchor, Yup.object().shape((_Yup$object$shape3 = {}, _defineProperty(_Yup$object$shape3, ImageFieldProperties.positionX, Yup.number()), _defineProperty(_Yup$object$shape3, ImageFieldProperties.positionY, Yup.number()), _Yup$object$shape3))), _Yup$object$shape4));
/** used to generate the validation schema in validateContent.ts */

export var imageFieldValidation = Yup.object().shape((_Yup$object$shape8 = {}, _defineProperty(_Yup$object$shape8, ImageFieldProperties.imgUrl, Yup.string().ensure()), _defineProperty(_Yup$object$shape8, ImageFieldProperties.credits, Yup.string().ensure()), _defineProperty(_Yup$object$shape8, ImageFieldProperties.legend, Yup.string().ensure()), _defineProperty(_Yup$object$shape8, ImageFieldProperties._originalImage, Yup.object().shape((_Yup$object$shape6 = {}, _defineProperty(_Yup$object$shape6, ImageFieldProperties.imgUrl, Yup.string().ensure()), _defineProperty(_Yup$object$shape6, ImageFieldProperties.cropArea, Yup.object().shape((_Yup$object$shape5 = {}, _defineProperty(_Yup$object$shape5, ImageFieldProperties.x, Yup.number()), _defineProperty(_Yup$object$shape5, ImageFieldProperties.y, Yup.number()), _defineProperty(_Yup$object$shape5, ImageFieldProperties.width, Yup.number()), _defineProperty(_Yup$object$shape5, ImageFieldProperties.height, Yup.number()), _Yup$object$shape5))), _Yup$object$shape6))), _defineProperty(_Yup$object$shape8, ImageFieldProperties.imageAnchor, Yup.object().shape((_Yup$object$shape7 = {}, _defineProperty(_Yup$object$shape7, ImageFieldProperties.positionX, Yup.number()), _defineProperty(_Yup$object$shape7, ImageFieldProperties.positionY, Yup.number()), _Yup$object$shape7))), _Yup$object$shape8));