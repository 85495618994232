export var getFieldLabelId = function getFieldLabelId(field) {
  return "content-type.fields.".concat(field, ".name");
};
export var getGroupLabelId = function getGroupLabelId(fieldGroup) {
  return "content-type.groups.".concat(fieldGroup);
};
export var getCoverImageFieldLabelId = function getCoverImageFieldLabelId(coverImageField) {
  return "content-type.fields.coverImages.".concat(coverImageField);
};
export var getOptionLabelId = function getOptionLabelId(contentTypeName, field, option) {
  return "content-type.".concat(contentTypeName, ".fields.").concat(field, ".options.").concat(option);
};