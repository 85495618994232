import { createStyles } from '@material-ui/core';
import { colorUsage } from 'stylesheet';
var MuiTableSortLabelOverride = createStyles({
  root: {
    '&:hover': {
      color: colorUsage.muiOverride.tableHead.highlightColor,
      '& path': {
        fill: colorUsage.muiOverride.tableHead.highlightColor
      }
    },
    '&$active': {
      color: colorUsage.muiOverride.tableHead.highlightColor,
      '& path': {
        fill: colorUsage.muiOverride.tableHead.highlightColor
      }
    }
  },
  icon: {
    width: '12px',
    opacity: '1 !important',
    '& path': {
      fill: colorUsage.grayDark
    }
  }
});
export default MuiTableSortLabelOverride;