import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var tagNewsTaxonomyField = {
  type: FieldType.Taxonomy,
  name: Fields.TAG_NEWS,
  labelId: getFieldLabelId(Fields.TAG_NEWS),
  defaultValue: [],
  multiple: true,
  validation: Yup.array()
};