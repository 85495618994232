import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _properties2, _properties3;

import { Fields } from "../config";
export var RepeatableLinkedContentSchema = {
  type: 'array',
  items: {
    type: 'object',
    properties: _defineProperty({}, Fields.CONTENT, {
      type: 'string'
    }),
    required: [Fields.CONTENT],
    additionalProperties: false
  }
};
export var RepeatableLinkedContentWithTitleSchema = {
  type: 'array',
  items: {
    type: 'object',
    properties: (_properties2 = {}, _defineProperty(_properties2, Fields.TITLE, {
      type: 'string'
    }), _defineProperty(_properties2, Fields.CONTENT, {
      type: 'string'
    }), _properties2),
    required: [Fields.CONTENT]
  }
};
export var RepeatableLinkedContentWithParagraphSchema = {
  type: 'array',
  items: {
    type: 'object',
    properties: (_properties3 = {}, _defineProperty(_properties3, Fields.PARAGRAPH, {
      type: 'object'
    }), _defineProperty(_properties3, Fields.CONTENT, {
      type: 'string'
    }), _properties3),
    required: [Fields.PARAGRAPH, Fields.CONTENT]
  }
};