import { useRouter } from 'next/router';
import { useMemo } from 'react';

var useIsSiteSpecificRoute = function useIsSiteSpecificRoute() {
  var _useRouter = useRouter(),
      route = _useRouter.route;

  var isSiteSpecificRoute = useMemo(function () {
    return route.startsWith('/[site]');
  }, [route]);
  return isSiteSpecificRoute;
};

export default useIsSiteSpecificRoute;