import { getBlockEntities } from '../block';
/**
 * Return the entities defined in all the blocks of the current state
 */

var getEntities = function getEntities(editorState) {
  var contentState = editorState.getCurrentContent();
  var blockMap = contentState.getBlockMap();
  var entities = blockMap.reduce(function (currentEntities, block, blockKey) {
    if (block === undefined || blockKey === undefined) {
      return currentEntities;
    }

    var blockEntities = getBlockEntities(editorState, block);
    return currentEntities.concat(blockEntities);
  }, []);
  return entities;
};

export default getEntities;