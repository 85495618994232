import { buildUrlWithHost, Context } from '@uno/common-shared';
import { HOSTS_BY_SITE } from "../../config";
export var transformToAbsoluteUrl = function transformToAbsoluteUrl(_ref) {
  var _HOSTS_BY_SITE$siteId, _HOSTS_BY_SITE$siteId2;

  var siteId = _ref.siteId,
      hostId = _ref.hostId,
      path = _ref.path,
      stage = _ref.stage;
  var host = (_HOSTS_BY_SITE$siteId = HOSTS_BY_SITE[siteId]) === null || _HOSTS_BY_SITE$siteId === void 0 ? void 0 : (_HOSTS_BY_SITE$siteId2 = _HOSTS_BY_SITE$siteId[stage]) === null || _HOSTS_BY_SITE$siteId2 === void 0 ? void 0 : _HOSTS_BY_SITE$siteId2[hostId];

  if (host === undefined) {
    throw new Error("Host id: ".concat(hostId, " does not exist for site: ").concat(siteId));
  }

  return buildUrlWithHost({
    siteId: siteId,
    host: host,
    path: path,
    stage: stage,
    context: Context.MAIN
  });
};