import styled from 'styled-components';
import { getSpacing } from 'stylesheet';
export var SiteLogo = styled.img.withConfig({
  displayName: "SelectSitestyle__SiteLogo",
  componentId: "sc-v8xg2p-0"
})(["width:24px;height:24px;"]);
export var ItemContainer = styled.div.withConfig({
  displayName: "SelectSitestyle__ItemContainer",
  componentId: "sc-v8xg2p-1"
})(["display:flex;align-items:center;"]);
export var IconContainer = styled.div.withConfig({
  displayName: "SelectSitestyle__IconContainer",
  componentId: "sc-v8xg2p-2"
})(["display:inline-flex;margin-right:", ";"], getSpacing(1));