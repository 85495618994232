import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* eslint-disable max-lines */
import * as Yup from 'yup';
import { Sites } from '@uno/common-shared';
import { BaseContentTypeName, Fields, GroupFields } from "../../FieldNames";
import { contentLinkedContentField, editorInChiefLinkedContentField, medicalExpertLinkedContentField } from "../../common/fieldDefinitions/linkedContent";
import { titleTextField } from "../../common/fieldDefinitions/simpleText";
import { mainSectionField } from "../../common/fieldDefinitions/taxonomy";
import { getFieldLabelId, getGroupLabelId } from "../../../helpers";
import { FieldType } from "../../../types/ContentType";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { editoHighlightTitleTextField } from "../../common/fieldDefinitions/simpleText";
import { DoctissimoTaxonomyName } from "../taxonomy";
import { DoctissimoContentTypeName } from "./ContentTypeName";
import { legacyFields, seoGroupField } from "./common";
var baseLabelId = BaseContentTypeName.Home;

var taxonomiesField = _objectSpread(_objectSpread({}, mainSectionField), {}, {
  labelId: getFieldLabelId(Fields.SECTIONS),
  multiple: true,
  taxonomyName: DoctissimoTaxonomyName.MainSection,
  validation: Yup.array().test({
    message: 'form.required',
    test: function test(array) {
      return array !== undefined && array.length > 0;
    }
  })
});

var fields = [{
  type: FieldType.Group,
  name: GroupFields.HOME,
  labelId: getGroupLabelId(GroupFields.HOME),
  fields: [{
    type: FieldType.Group,
    name: GroupFields.TOP_ARTICLES,
    labelId: getGroupLabelId(GroupFields.TOP_ARTICLES),
    fields: [{
      type: FieldType.Repeatable,
      name: Fields.TOP_ARTICLES,
      labelId: getFieldLabelId(Fields.TOP_ARTICLES),
      defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
      validation: Yup.array().max(6),
      max: 6,
      field: contentLinkedContentField
    }]
  }, {
    type: FieldType.Group,
    name: GroupFields.SPOTLIGHT,
    labelId: getGroupLabelId(GroupFields.SPOTLIGHT),
    fields: [{
      type: FieldType.Group,
      name: GroupFields.HEALTH,
      labelId: getGroupLabelId(GroupFields.HEALTH),
      fields: [_objectSpread(_objectSpread({}, titleTextField), {}, {
        name: Fields.HEALTH_TITLE
      }), _objectSpread(_objectSpread({}, taxonomiesField), {}, {
        name: Fields.HEALTH_MAIN_SECTIONS
      }), {
        type: FieldType.Repeatable,
        name: Fields.HEALTH_MACRO_SECTION_TOP_CONTENT,
        labelId: getFieldLabelId(Fields.HEALTH_MACRO_SECTION_TOP_CONTENT),
        defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
        validation: Yup.array().max(5),
        max: 5,
        field: contentLinkedContentField
      }]
    }, {
      type: FieldType.Group,
      name: GroupFields.FAMILY,
      labelId: getGroupLabelId(GroupFields.FAMILY),
      fields: [_objectSpread(_objectSpread({}, titleTextField), {}, {
        name: Fields.FAMILY_TITLE
      }), _objectSpread(_objectSpread({}, taxonomiesField), {}, {
        name: Fields.FAMILY_MAIN_SECTIONS
      }), {
        type: FieldType.Repeatable,
        name: Fields.FAMILY_MACRO_SECTION_TOP_CONTENT,
        labelId: getFieldLabelId(Fields.FAMILY_MACRO_SECTION_TOP_CONTENT),
        defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
        validation: Yup.array().max(5),
        max: 5,
        field: contentLinkedContentField
      }]
    }, {
      type: FieldType.Group,
      name: GroupFields.WELLNESS,
      labelId: getGroupLabelId(GroupFields.WELLNESS),
      fields: [_objectSpread(_objectSpread({}, titleTextField), {}, {
        name: Fields.WELLNESS_TITLE
      }), _objectSpread(_objectSpread({}, taxonomiesField), {}, {
        name: Fields.WELLNESS_MAIN_SECTIONS
      }), {
        type: FieldType.Repeatable,
        name: Fields.WELLNESS_MACRO_SECTION_TOP_CONTENT,
        labelId: getFieldLabelId(Fields.WELLNESS_MACRO_SECTION_TOP_CONTENT),
        defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
        validation: Yup.array().max(5),
        max: 5,
        field: contentLinkedContentField
      }]
    }, {
      type: FieldType.Group,
      name: GroupFields.SENIOR,
      labelId: getGroupLabelId(GroupFields.SENIOR),
      fields: [_objectSpread(_objectSpread({}, titleTextField), {}, {
        name: Fields.SENIOR_TITLE
      }), _objectSpread(_objectSpread({}, taxonomiesField), {}, {
        name: Fields.SENIOR_MAIN_SECTIONS
      }), {
        type: FieldType.Repeatable,
        name: Fields.SENIOR_MACRO_SECTION_TOP_CONTENT,
        labelId: getFieldLabelId(Fields.SENIOR_MACRO_SECTION_TOP_CONTENT),
        defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
        validation: Yup.array().max(5),
        max: 5,
        field: contentLinkedContentField
      }]
    }]
  }, {
    type: FieldType.Group,
    name: GroupFields.MOST_VIEWED,
    labelId: getGroupLabelId(GroupFields.MOST_VIEWED),
    fields: [{
      type: FieldType.Repeatable,
      name: Fields.MOST_VIEWED,
      labelId: getFieldLabelId(Fields.MOST_VIEWED),
      defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
      validation: Yup.array(),
      field: contentLinkedContentField
    }]
  }, {
    type: FieldType.Group,
    name: GroupFields.EDITO_HIGHLIGHT,
    labelId: getGroupLabelId(GroupFields.EDITO_HIGHLIGHT),
    fields: [editoHighlightTitleTextField, {
      type: FieldType.Repeatable,
      name: Fields.EDITO_HIGHLIGHT_TOP_CONTENT,
      labelId: getFieldLabelId(Fields.EDITO_HIGHLIGHT_TOP_CONTENT),
      defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
      validation: Yup.array().max(10),
      max: 10,
      field: contentLinkedContentField
    }]
  }, {
    type: FieldType.Group,
    name: GroupFields.OUR_TEAMS,
    labelId: getGroupLabelId(GroupFields.OUR_TEAMS),
    fields: [_objectSpread(_objectSpread({}, editorInChiefLinkedContentField), {}, {
      contentTypeNames: [DoctissimoContentTypeName.Person]
    }), _objectSpread(_objectSpread({}, medicalExpertLinkedContentField), {}, {
      contentTypeNames: [DoctissimoContentTypeName.Person]
    })]
  }]
}, seoGroupField, {
  type: FieldType.Group,
  name: GroupFields.PROPERTIES,
  labelId: getGroupLabelId(GroupFields.PROPERTIES),
  fields: [legacyFields]
}];
export var home = {
  site: Sites.DOCTISSIMO_FR,
  name: DoctissimoContentTypeName.Home,
  labelId: 'content-type.home.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  getLabel: function getLabel() {
    return '';
  },
  getPreviewImage: function getPreviewImage() {
    return '';
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  getMainSectionId: function getMainSectionId() {
    return '';
  },
  getManualModificationDate: function getManualModificationDate() {
    return undefined;
  },
  fields: fields,
  partialDependencyFields: [],
  isLinkable: false,
  isCreatable: false
}; // TODO: See to fix "Type instantiation is excessively deep and possibly infinite"
// @ts-ignore Sorry too many fields