import { GenericBlockType } from '@uno/common-cms';
import appendGenericBlock from '../../util/appendGenericBlock';
export var transformEmbedToGenericAndAppend = function transformEmbedToGenericAndAppend(block, genericBlocks, contentState) {
  var _ref = contentState.getEntity(block.getEntityAt(0)).getData(),
      embedData = _ref.embedData;

  var newBlock = {
    type: GenericBlockType.Embed,
    embedData: embedData
  };
  return appendGenericBlock(genericBlocks, newBlock, block);
};