import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var personTaxonomyField = {
  type: FieldType.Taxonomy,
  name: Fields.PERSON_TYPE,
  labelId: getFieldLabelId(Fields.PERSON_TYPE),
  defaultValue: [],
  multiple: true,
  validation: Yup.array().test({
    message: 'form.required',
    test: function test(array) {
      return array.length >= 1;
    }
  })
};