import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Sites } from '@uno/common-shared';
import { BaseContentTypeName, Fields, GroupFields } from "../../FieldNames";
import { coverImagesField } from "../../common/fieldDefinitions/image";
import { createdMetaDateField, modifiedMetaDateField, publicationMetaDateField } from "../../common/fieldDefinitions/metaDate";
import { metaUrlField } from "../../common/fieldDefinitions/metaUrl";
import { contentTypeSelectField } from "../../common/fieldDefinitions/select";
import { chapoTextField, titleTextField } from "../../common/fieldDefinitions/simpleText";
import { mainSectionField } from "../../common/fieldDefinitions/taxonomy";
import { coverImagesFieldValidationRequired, getGroupLabelId, getOptionLabelId } from "../../../helpers";
import { FieldType } from "../../../types/ContentType";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { DoctissimoTaxonomyName } from "../taxonomy";
import { DoctissimoContentTypeName } from "./ContentTypeName";
import { seoGroupField } from "./common";
var baseLabelId = BaseContentTypeName.ContentTypeHome;
export var ALLOWED_CONTENT_TYPES = [DoctissimoContentTypeName.Dictionary, DoctissimoContentTypeName.News, // TODO: when removing Slideshow here, do not forget to re-save all ContentTypeHome in Bako with SlideshowV2 value + remove backend/services/navigation/config/DOCTISSIMO_FR/helpers/chunks/buildLatestContents.ts:32
DoctissimoContentTypeName.Slideshow, DoctissimoContentTypeName.SlideshowV2, DoctissimoContentTypeName.Test, DoctissimoContentTypeName.Video];
export var isContentTypeHomeContentType = function isContentTypeHomeContentType(contentType) {
  return ALLOWED_CONTENT_TYPES.includes(contentType);
};
var fields = [{
  type: FieldType.Group,
  name: GroupFields.CONTENT_TYPE_HOME,
  labelId: getGroupLabelId(GroupFields.CONTENT_TYPE_HOME),
  fields: [_objectSpread(_objectSpread({}, contentTypeSelectField), {}, {
    options: ALLOWED_CONTENT_TYPES.map(function (option) {
      return {
        option: option,
        labelId: getOptionLabelId(baseLabelId, Fields.CONTENT_TYPE, option)
      };
    })
  }), titleTextField, chapoTextField]
}, seoGroupField, {
  type: FieldType.Group,
  name: GroupFields.PROPERTIES,
  labelId: getGroupLabelId(GroupFields.PROPERTIES),
  fields: [metaUrlField, {
    type: FieldType.Group,
    name: GroupFields.TAXONOMY,
    labelId: getGroupLabelId(GroupFields.TAXONOMY),
    fields: [_objectSpread(_objectSpread({}, mainSectionField), {}, {
      taxonomyName: DoctissimoTaxonomyName.MainSection
    })]
  }, {
    type: FieldType.Group,
    name: GroupFields.DATES,
    labelId: getGroupLabelId(GroupFields.DATES),
    fields: [publicationMetaDateField, createdMetaDateField, modifiedMetaDateField]
  }, {
    type: FieldType.Group,
    name: GroupFields.IMAGES,
    labelId: getGroupLabelId(GroupFields.IMAGES),
    fields: [_objectSpread(_objectSpread({}, coverImagesField), {}, {
      validation: coverImagesFieldValidationRequired
    })]
  }]
}];
export var contentTypeHome = {
  site: Sites.DOCTISSIMO_FR,
  name: DoctissimoContentTypeName.ContentTypeHome,
  labelId: 'content-type.contentTypeHome.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  getLabel: function getLabel(contentData) {
    var _contentData$title;

    return (_contentData$title = contentData === null || contentData === void 0 ? void 0 : contentData.title) !== null && _contentData$title !== void 0 ? _contentData$title : '';
  },
  getPreviewImage: function getPreviewImage(contentData) {
    var _contentData$coverIma, _contentData$coverIma2, _contentData$coverIma3;

    return (_contentData$coverIma = contentData === null || contentData === void 0 ? void 0 : (_contentData$coverIma2 = contentData.coverImages) === null || _contentData$coverIma2 === void 0 ? void 0 : (_contentData$coverIma3 = _contentData$coverIma2["default"]) === null || _contentData$coverIma3 === void 0 ? void 0 : _contentData$coverIma3.imgUrl) !== null && _contentData$coverIma !== void 0 ? _contentData$coverIma : null;
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  getMainSectionId: function getMainSectionId(contentData) {
    var _contentData$mainSect, _contentData$mainSect2;

    return (_contentData$mainSect = contentData === null || contentData === void 0 ? void 0 : (_contentData$mainSect2 = contentData.mainSection) === null || _contentData$mainSect2 === void 0 ? void 0 : _contentData$mainSect2[0]) !== null && _contentData$mainSect !== void 0 ? _contentData$mainSect : '';
  },
  getManualModificationDate: function getManualModificationDate() {
    return undefined;
  },
  fields: fields,
  partialDependencyFields: [Fields.TITLE],
  isLinkable: false,
  isCreatable: false
};