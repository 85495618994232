import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _commonDoctissimoHost, _DOCTISSIMO_HOSTS;

import { Stages } from '@uno/common-shared';
export var DOCTISSIMO_HOST_IDS;

(function (DOCTISSIMO_HOST_IDS) {
  DOCTISSIMO_HOST_IDS["APP"] = "app";
  DOCTISSIMO_HOST_IDS["DIAPORAMAS"] = "diaporamas";
  DOCTISSIMO_HOST_IDS["FORUM"] = "forum";
  DOCTISSIMO_HOST_IDS["GET_HEALTHY"] = "gethealthy";
  DOCTISSIMO_HOST_IDS["GRIPPE_A"] = "grippe-a";
  DOCTISSIMO_HOST_IDS["MATERNITES"] = "maternites";
  DOCTISSIMO_HOST_IDS["MEDECINES_DOUCES"] = "medecines-douces";
  DOCTISSIMO_HOST_IDS["NEWS"] = "news";
  DOCTISSIMO_HOST_IDS["PRENOMS"] = "prenoms";
  DOCTISSIMO_HOST_IDS["RECETTES"] = "recettes";
  DOCTISSIMO_HOST_IDS["TEST"] = "test";
  DOCTISSIMO_HOST_IDS["VIDEOS"] = "videos";
  DOCTISSIMO_HOST_IDS["WWW"] = "www";
})(DOCTISSIMO_HOST_IDS || (DOCTISSIMO_HOST_IDS = {}));

var commonDoctissimoHosts = (_commonDoctissimoHost = {}, _defineProperty(_commonDoctissimoHost, DOCTISSIMO_HOST_IDS.APP, 'app'), _defineProperty(_commonDoctissimoHost, DOCTISSIMO_HOST_IDS.DIAPORAMAS, 'diaporamas'), _defineProperty(_commonDoctissimoHost, DOCTISSIMO_HOST_IDS.FORUM, 'forum'), _defineProperty(_commonDoctissimoHost, DOCTISSIMO_HOST_IDS.GET_HEALTHY, 'gethealthy'), _defineProperty(_commonDoctissimoHost, DOCTISSIMO_HOST_IDS.GRIPPE_A, 'grippe-a'), _defineProperty(_commonDoctissimoHost, DOCTISSIMO_HOST_IDS.MATERNITES, 'maternites'), _defineProperty(_commonDoctissimoHost, DOCTISSIMO_HOST_IDS.MEDECINES_DOUCES, 'medecines-douces'), _defineProperty(_commonDoctissimoHost, DOCTISSIMO_HOST_IDS.NEWS, 'news'), _defineProperty(_commonDoctissimoHost, DOCTISSIMO_HOST_IDS.PRENOMS, 'prenoms'), _defineProperty(_commonDoctissimoHost, DOCTISSIMO_HOST_IDS.RECETTES, 'recettes'), _defineProperty(_commonDoctissimoHost, DOCTISSIMO_HOST_IDS.TEST, 'test'), _defineProperty(_commonDoctissimoHost, DOCTISSIMO_HOST_IDS.VIDEOS, 'videos'), _defineProperty(_commonDoctissimoHost, DOCTISSIMO_HOST_IDS.WWW, 'www'), _commonDoctissimoHost);
export var DOCTISSIMO_HOSTS = (_DOCTISSIMO_HOSTS = {}, _defineProperty(_DOCTISSIMO_HOSTS, Stages.DEV, commonDoctissimoHosts), _defineProperty(_DOCTISSIMO_HOSTS, Stages.STAGING, commonDoctissimoHosts), _defineProperty(_DOCTISSIMO_HOSTS, Stages.PRODUCTION, commonDoctissimoHosts), _defineProperty(_DOCTISSIMO_HOSTS, Stages.PRODUCTION_LEGACY, commonDoctissimoHosts), _DOCTISSIMO_HOSTS);