import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _DOCTISSIMO_CONTENT_T;

import { article } from "./Article";
import { contentTypeHome } from "./ContentTypeHome";
import { DoctissimoContentTypeName } from "./ContentTypeName";
import { dictionary } from "./Dictionary";
import { encyclopedia } from "./Encyclopedia";
import { home } from "./Home";
import { htmlPage } from "./HtmlPage";
import { news } from "./News";
import { newsletter } from "./Newsletter";
import { person } from "./Person";
import { pregnancyAppAppointment } from "./PregnancyAppAppointment";
import { pregnancyAppWeek } from "./PregnancyAppWeek";
import { sectionHome } from "./SectionHome";
import { slideshow } from "./Slideshow";
import { slideshowV2 } from "./SlideshowV2";
import { test } from "./Test";
import { video } from "./Video";
var DOCTISSIMO_CONTENT_TYPES = (_DOCTISSIMO_CONTENT_T = {}, _defineProperty(_DOCTISSIMO_CONTENT_T, DoctissimoContentTypeName.Article, article), _defineProperty(_DOCTISSIMO_CONTENT_T, DoctissimoContentTypeName.ContentTypeHome, contentTypeHome), _defineProperty(_DOCTISSIMO_CONTENT_T, DoctissimoContentTypeName.Dictionary, dictionary), _defineProperty(_DOCTISSIMO_CONTENT_T, DoctissimoContentTypeName.Encyclopedia, encyclopedia), _defineProperty(_DOCTISSIMO_CONTENT_T, DoctissimoContentTypeName.Home, home), _defineProperty(_DOCTISSIMO_CONTENT_T, DoctissimoContentTypeName.HtmlPage, htmlPage), _defineProperty(_DOCTISSIMO_CONTENT_T, DoctissimoContentTypeName.News, news), _defineProperty(_DOCTISSIMO_CONTENT_T, DoctissimoContentTypeName.Newsletter, newsletter), _defineProperty(_DOCTISSIMO_CONTENT_T, DoctissimoContentTypeName.Slideshow, slideshow), _defineProperty(_DOCTISSIMO_CONTENT_T, DoctissimoContentTypeName.SlideshowV2, slideshowV2), _defineProperty(_DOCTISSIMO_CONTENT_T, DoctissimoContentTypeName.Test, test), _defineProperty(_DOCTISSIMO_CONTENT_T, DoctissimoContentTypeName.Person, person), _defineProperty(_DOCTISSIMO_CONTENT_T, DoctissimoContentTypeName.PregnancyAppAppointment, pregnancyAppAppointment), _defineProperty(_DOCTISSIMO_CONTENT_T, DoctissimoContentTypeName.PregnancyAppWeek, pregnancyAppWeek), _defineProperty(_DOCTISSIMO_CONTENT_T, DoctissimoContentTypeName.SectionHome, sectionHome), _defineProperty(_DOCTISSIMO_CONTENT_T, DoctissimoContentTypeName.Video, video), _DOCTISSIMO_CONTENT_T);
export default DOCTISSIMO_CONTENT_TYPES;
export { ALLOWED_CONTENT_TYPES as DOCTISSIMO_FR_CONTENT_TYPE_HOME_CONTENT_TYPES, isContentTypeHomeContentType as isDoctissimoContentTypeHomeContentType } from "./ContentTypeHome";
export * from "./ContentTypeName";
export * from "./common";
export { PregnancyPeriodOptions } from "./PregnancyAppWeek";