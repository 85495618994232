import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { DoctissimoContentTypeName, Fields } from "../../../../../../config";
import { PartialContentPageChunkSchema } from "../../../../../page";
var NewsletterChunkSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, PartialContentPageChunkSchema.properties), {}, _defineProperty({
    _contentTypeName: {
      "const": DoctissimoContentTypeName.Newsletter
    }
  }, Fields.TITLE, {
    type: 'string'
  })),
  required: [].concat(_toConsumableArray(PartialContentPageChunkSchema.required), ['_contentTypeName', Fields.TITLE])
};
export { NewsletterChunkSchema as NewsletterChunkSchemaDoctissimoFr };