import React from "react";
var __jsx = React.createElement;
import { makeStyles } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { useCallback, useRef } from 'react';
import SmallIconButton from 'components/common/material-ui/custom-components/SmallIconButton.style';
import { NotistackOverride } from 'theme/overrides';
import { CloseButton, ErrorIcon, SuccessIcon } from './ToasterProvider.style';

var CloseIcon = function CloseIcon(_ref) {
  var onClick = _ref.onClick;
  return __jsx(SmallIconButton, {
    onClick: onClick
  }, __jsx(CloseButton, null));
};

var useStyles = makeStyles(NotistackOverride);

var ToasterProvider = function ToasterProvider(_ref2) {
  var children = _ref2.children;
  var classes = useStyles();
  var providerRef = useRef(null);
  var onClickDismiss = useCallback(function (key) {
    return function () {
      var _providerRef$current;

      (_providerRef$current = providerRef.current) === null || _providerRef$current === void 0 ? void 0 : _providerRef$current.closeSnackbar(key);
    };
  }, []);
  return __jsx(SnackbarProvider, {
    ref: providerRef,
    classes: classes,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    },
    action: function action(key) {
      return __jsx(CloseIcon, {
        onClick: onClickDismiss(key)
      });
    },
    iconVariant: {
      success: __jsx(SuccessIcon, null),
      error: __jsx(ErrorIcon, null)
    }
  }, children);
};

export default ToasterProvider;