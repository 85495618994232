import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { Context, getUrls, removePrecedingSlash } from '@uno/common-shared';
import { HOSTS_BY_SITE, isHostIdValid } from "../../config";
export var transformToUrlDescription = function transformToUrlDescription(_ref) {
  var _HOSTS_BY_SITE$siteId;

  var siteId = _ref.siteId,
      url = _ref.url,
      stage = _ref.stage;
  var possibleHosts = (_HOSTS_BY_SITE$siteId = HOSTS_BY_SITE[siteId]) === null || _HOSTS_BY_SITE$siteId === void 0 ? void 0 : _HOSTS_BY_SITE$siteId[stage];

  if (possibleHosts === undefined) {
    return undefined;
  }

  var domainUrl = getUrls({
    siteId: siteId,
    stage: stage,
    context: Context.MAIN
  }).DOMAIN_URL;

  for (var _i = 0, _Object$entries = Object.entries(possibleHosts); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
        hostId = _Object$entries$_i[0],
        host = _Object$entries$_i[1];

    // this error will never happen, but it ensures type safety
    if (!isHostIdValid(hostId)) {
      throw new Error("Host id: ".concat(hostId, " does not exist for site: ").concat(siteId));
    }

    var baseUrl = host + '.' + domainUrl;
    var path = url.split(baseUrl).pop();

    if (path === undefined || path === url) {
      continue;
    }

    return {
      siteId: siteId,
      hostId: hostId,
      path: removePrecedingSlash(path)
    };
  }

  return;
};