import { coverImagesSchema } from "./image";
/** Simplified schema of PageLink type
 * This is necessary to simplify it, because Typescript is not able to resolve types with too much complexity.
 */

export var simplifiedPageLinkSchema = {
  type: 'object',
  properties: {
    id: {
      type: 'string'
    },
    title: {
      type: 'string'
    },
    subtitle: {
      type: 'string'
    },
    chapo: {
      type: 'string'
    },
    pageConfigName: {
      type: 'string'
    },
    contentTypeName: {
      type: 'string'
    },
    imagePanoramic: {
      type: 'string'
    },
    imageSquare: {
      type: 'string'
    },
    coverImages: coverImagesSchema,
    url: {
      type: 'object',
      properties: {
        siteId: {
          type: 'string'
        },
        hostId: {
          type: 'string'
        },
        path: {
          type: 'string'
        }
      },
      required: ['siteId', 'hostId', 'path'],
      additionalProperties: false
    },
    topSectionLabel: {
      type: 'string'
    },
    updatedAt: {
      type: 'string'
    },
    isSponsored: {
      type: 'boolean'
    }
  },
  required: ['id', 'title', 'subtitle', 'chapo', 'pageConfigName', 'contentTypeName', 'imagePanoramic', 'imageSquare', 'coverImages', 'url', 'topSectionLabel', 'updatedAt', 'isSponsored'],
  additionalProperties: false
};