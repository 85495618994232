import { createSlice } from '@reduxjs/toolkit';
import { addAbsoluteUrlsToUrlDescriptions } from 'libs/content/utils';
export var pageInitialState = {
  metaData: {
    value: undefined,
    loading: false
  },
  urls: {
    value: undefined,
    loading: false
  }
};
var pageSlice = createSlice({
  name: 'page',
  initialState: pageInitialState,
  reducers: {
    updatePageMetaData: function updatePageMetaData(state, action) {
      var _action$payload$loadi;

      state.metaData.loading = (_action$payload$loadi = action.payload.loading) !== null && _action$payload$loadi !== void 0 ? _action$payload$loadi : false;

      if (action.payload.value !== undefined) {
        var _action$payload$value;

        state.metaData.value = (_action$payload$value = action.payload.value[0]) !== null && _action$payload$value !== void 0 ? _action$payload$value : null;
      }
    },
    updateUrls: function updateUrls(state, action) {
      var _action$payload$loadi2;

      state.urls.loading = (_action$payload$loadi2 = action.payload.loading) !== null && _action$payload$loadi2 !== void 0 ? _action$payload$loadi2 : false;

      if (action.payload.value !== undefined) {
        state.urls.value = addAbsoluteUrlsToUrlDescriptions(action.payload.value);
      }
    }
  }
});
var _pageSlice$actions = pageSlice.actions,
    updatePageMetaData = _pageSlice$actions.updatePageMetaData,
    updateUrls = _pageSlice$actions.updateUrls;
export { updatePageMetaData, updateUrls };
export default pageSlice.reducer;