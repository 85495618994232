import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _ref2;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as Yup from 'yup';
import { DoctissimoContentTypeName, DoctissimoTaxonomyName } from "../../../DOCTISSIMO_FR";
import { BaseContentTypeName, Fields, GroupFields } from "../../../FieldNames";
import { modificationDateField } from "../../../common/fieldDefinitions/date";
import { coverImagesField } from "../../../common/fieldDefinitions/image";
import { authorLinkedContentField, contentLinkedContentField } from "../../../common/fieldDefinitions/linkedContent";
import { bodyRichTextField } from "../../../common/fieldDefinitions/richText";
import { jobSelectField } from "../../../common/fieldDefinitions/select";
import { descriptionTextField, legendTextField, titleTextField } from "../../../common/fieldDefinitions/simpleText";
import { activeSwitchField } from "../../../common/fieldDefinitions/switch";
import { altSectionField } from "../../../common/fieldDefinitions/taxonomy";
import { highlightedVideoField } from "../../../common/fieldDefinitions/video";
import { imageFieldValidation } from "../../../../helpers";
import { BlockType, ContentTypeIconName, FieldType } from "../../../../types";
import { MockFrContentTypeName } from "./ContentTypeName";
var baseLabelId = BaseContentTypeName.Article;
var fields = [{
  type: FieldType.Group,
  name: GroupFields.SLIDESHOW,
  labelId: '',
  fields: [titleTextField, _objectSpread(_objectSpread({}, bodyRichTextField), {}, {
    enabledBlocks: Object.values(BlockType)
  }), _objectSpread(_objectSpread({}, altSectionField), {}, {
    taxonomyName: DoctissimoTaxonomyName.MainSection
  }), {
    type: FieldType.Image,
    name: Fields.IMAGE_PANORAMIC,
    labelId: '',
    defaultValue: {
      imgUrl: undefined,
      legend: '',
      credits: ''
    },
    ratio: {
      width: 3,
      height: 2
    },
    constraints: {
      maxWidth: 3000
    },
    validation: imageFieldValidation
  }, _objectSpread(_objectSpread({}, coverImagesField), {}, {
    constraints: {
      maxWidth: 3000
    }
  }), highlightedVideoField, modificationDateField, _objectSpread(_objectSpread({}, authorLinkedContentField), {}, {
    contentTypeNames: [DoctissimoContentTypeName.Person]
  }), _objectSpread(_objectSpread({}, jobSelectField), {}, {
    options: [{
      option: 'option1',
      labelId: 'label1'
    }, {
      option: 'option2',
      labelId: 'label2'
    }]
  }), activeSwitchField, {
    type: FieldType.Repeatable,
    name: Fields.LINKED_CONTENTS,
    labelId: '',
    defaultValue: [_defineProperty({}, Fields.LINKED_CONTENT_ID, '')],
    validation: Yup.array(),
    field: contentLinkedContentField
  }, {
    type: FieldType.Repeatable,
    name: Fields.SLIDES,
    labelId: '',
    defaultValue: [(_ref2 = {}, _defineProperty(_ref2, Fields.LEGEND, ''), _defineProperty(_ref2, Fields.DESCRIPTION, ''), _ref2)],
    validation: Yup.array().min(2).required('form.required'),
    field: {
      type: FieldType.Group,
      name: GroupFields.SLIDE,
      labelId: '',
      fields: [legendTextField, descriptionTextField]
    }
  }]
}];
export var fieldTypes = {
  site: 123,
  name: MockFrContentTypeName.FieldTypes,
  labelId: 'content-type.article.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  fields: fields
};