/* eslint-disable complexity */
import * as Yup from 'yup';
import { ValidationError } from 'yup';
import dateRangeValidator from "./dateRangeValidator";
var INVALID_FORMAT_LABEL_ID = 'form.invalidFormat';
var PUBLICATION_REQUIRED_LABEL_ID = 'form.publicationRequired';

var unifyTime = function unifyTime(date) {
  date.setHours(0, 0, 0, 0);
};

export var dateValidator = function dateValidator(_ref) {
  var _ctx$pageMetaData, _ctx$pageMetaData2, _ctx$pageMetaData3;

  var ctx = _ref.ctx,
      withTime = _ref.withTime,
      value = _ref.value,
      field = _ref.field;

  if (!value) {
    return true;
  }

  if (ctx === undefined) {
    return true;
  }

  if (((_ctx$pageMetaData = ctx.pageMetaData) === null || _ctx$pageMetaData === void 0 ? void 0 : _ctx$pageMetaData.publishedAt) === undefined || ((_ctx$pageMetaData2 = ctx.pageMetaData) === null || _ctx$pageMetaData2 === void 0 ? void 0 : _ctx$pageMetaData2.publishedAt) === '') {
    return new ValidationError(PUBLICATION_REQUIRED_LABEL_ID, value, field !== null && field !== void 0 ? field : '');
  }

  var publishDate = new Date((_ctx$pageMetaData3 = ctx.pageMetaData) === null || _ctx$pageMetaData3 === void 0 ? void 0 : _ctx$pageMetaData3.publishedAt);
  var now = new Date();

  if (withTime !== undefined && !withTime) {
    unifyTime(now);
    unifyTime(value);
    unifyTime(publishDate);
  }

  return dateRangeValidator(publishDate, now, value, field !== null && field !== void 0 ? field : '');
};
export var dateFieldAgainstPublishedAtValidation = function dateFieldAgainstPublishedAtValidation(field) {
  var withTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return Yup.date().nullable().typeError(INVALID_FORMAT_LABEL_ID).test('testing-date-range', 'date-range-error', function (value) {
    return dateValidator({
      ctx: this.options.context,
      withTime: withTime,
      value: value,
      field: field
    });
  });
};