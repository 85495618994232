import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _ref;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* eslint-disable max-lines */
import * as Yup from 'yup';
import { Fields, GroupFields } from "../../../FieldNames";
import { medicalValidationDateField, modificationDateField } from "../../../common/fieldDefinitions/date";
import { coverImagesField, imageField } from "../../../common/fieldDefinitions/image";
import { authorLinkedContentField, expertLinkedContentField, medicalValidatorLinkedContentField } from "../../../common/fieldDefinitions/linkedContent";
import { createdMetaDateField, modifiedMetaDateField, publicationMetaDateField } from "../../../common/fieldDefinitions/metaDate";
import { metaUrlField } from "../../../common/fieldDefinitions/metaUrl";
import { bodyRichTextField, descriptionRichTextField, sourcesRichTextField } from "../../../common/fieldDefinitions/richText";
import { chapoTextField, descriptionTagTextField, ogDescriptionTextField, ogTitleTextField, shortTitleTextField, titleTagTextField, titleTextField } from "../../../common/fieldDefinitions/simpleText";
import { altSectionField, mainSectionField } from "../../../common/fieldDefinitions/taxonomy";
import { slidesField } from "../../../common/slidesField";
import { coverImagesFieldValidationRequired, getFieldLabelId, getGroupLabelId } from "../../../../helpers";
import { FieldType } from "../../../../types";
import { DoctissimoTaxonomyName } from "../../taxonomy";
import { DoctissimoContentTypeName } from "../ContentTypeName";
import { seoGroupField } from "./baseArticleFields";
import { legacyFields } from "./legacyFields";
import { semanticScoreFields } from "./semanticScoreFields";
export var slideshowV1Fields = {
  type: FieldType.Group,
  name: GroupFields.SLIDESHOW,
  labelId: getGroupLabelId(GroupFields.SLIDESHOW),
  fields: [_objectSpread(_objectSpread({}, titleTextField), {}, {
    displayCharactersCount: true,
    maxCharactersCount: 110,
    validation: Yup.string().max(110, 'form.stringLength').required('form.required')
  }), _objectSpread(_objectSpread({}, chapoTextField), {}, {
    validation: Yup.string()
  }), {
    type: FieldType.Repeatable,
    name: Fields.SLIDES,
    labelId: getFieldLabelId(Fields.SLIDES),
    isCarousel: true,
    fieldPreviewUrlPath: "".concat(Fields.IMAGE, ".imgUrl"),
    defaultValue: [(_ref = {}, _defineProperty(_ref, Fields.IMAGE, {
      imgUrl: undefined,
      legend: '',
      credits: ''
    }), _defineProperty(_ref, Fields.TITLE, ''), _defineProperty(_ref, Fields.DESCRIPTION, {
      blocks: []
    }), _defineProperty(_ref, Fields.DESCRIPTION_TAG, ''), _defineProperty(_ref, Fields.TITLE_TAG, ''), _ref)],
    field: {
      type: FieldType.Group,
      name: GroupFields.SLIDE,
      labelId: getGroupLabelId(GroupFields.SLIDE),
      fields: [imageField, titleTextField, descriptionRichTextField, titleTagTextField, descriptionTagTextField]
    }
  }]
};
export var slideshowV2Fields = [{
  type: FieldType.Group,
  name: GroupFields.SLIDESHOW,
  labelId: getGroupLabelId(GroupFields.SLIDESHOW),
  fields: [_objectSpread(_objectSpread({}, titleTextField), {}, {
    displayCharactersCount: true,
    maxCharactersCount: 110,
    validation: Yup.string().max(110, 'form.stringLength').required('form.required')
  }), _objectSpread(_objectSpread({}, chapoTextField), {}, {
    validation: Yup.string()
  }), bodyRichTextField]
}, {
  type: FieldType.Group,
  name: GroupFields.SLIDE,
  labelId: getGroupLabelId(GroupFields.SLIDE),
  fields: [slidesField]
}];
export var slideshowSeoFields = _objectSpread(_objectSpread({}, seoGroupField), {}, {
  fields: [].concat(_toConsumableArray(seoGroupField.fields), [semanticScoreFields(GroupFields.SLIDESHOW)])
});
export var slideshowPropertiesGroupFields = {
  type: FieldType.Group,
  name: GroupFields.PROPERTIES,
  labelId: getGroupLabelId(GroupFields.PROPERTIES),
  fields: [metaUrlField, shortTitleTextField, ogTitleTextField, ogDescriptionTextField, {
    type: FieldType.Group,
    name: GroupFields.TAXONOMY,
    labelId: getGroupLabelId(GroupFields.TAXONOMY),
    fields: [_objectSpread(_objectSpread({}, mainSectionField), {}, {
      taxonomyName: DoctissimoTaxonomyName.MainSection,
      validation: Yup.array().test({
        message: 'form.required',
        test: function test(array) {
          return array !== undefined && array.length === 1;
        }
      })
    }), _objectSpread(_objectSpread({}, altSectionField), {}, {
      taxonomyName: DoctissimoTaxonomyName.MainSection
    })]
  }, {
    type: FieldType.Group,
    name: GroupFields.SOURCES,
    labelId: getGroupLabelId(GroupFields.SOURCES),
    fields: [{
      type: FieldType.Repeatable,
      name: Fields.AUTHORS,
      labelId: getFieldLabelId(Fields.AUTHORS),
      defaultValue: [_defineProperty({}, Fields.AUTHOR_ID, '')],
      validation: Yup.array(),
      field: _objectSpread(_objectSpread({}, authorLinkedContentField), {}, {
        contentTypeNames: [DoctissimoContentTypeName.Person]
      })
    }, {
      type: FieldType.Repeatable,
      name: Fields.EXPERTS,
      labelId: getFieldLabelId(Fields.EXPERTS),
      defaultValue: [_defineProperty({}, Fields.EXPERT_ID, '')],
      validation: Yup.array(),
      field: _objectSpread(_objectSpread({}, expertLinkedContentField), {}, {
        contentTypeNames: [DoctissimoContentTypeName.Person]
      })
    }, sourcesRichTextField]
  }, {
    type: FieldType.Group,
    name: GroupFields.MEDICAL_VALIDATION,
    labelId: getGroupLabelId(GroupFields.MEDICAL_VALIDATION),
    fields: [_objectSpread(_objectSpread({}, medicalValidatorLinkedContentField), {}, {
      contentTypeNames: [DoctissimoContentTypeName.Person]
    }), medicalValidationDateField]
  }, {
    type: FieldType.Group,
    name: GroupFields.DATES,
    labelId: getGroupLabelId(GroupFields.DATES),
    fields: [modificationDateField, publicationMetaDateField, createdMetaDateField, modifiedMetaDateField]
  }, {
    type: FieldType.Group,
    name: GroupFields.IMAGES,
    labelId: getGroupLabelId(GroupFields.IMAGES),
    fields: [_objectSpread(_objectSpread({}, coverImagesField), {}, {
      validation: coverImagesFieldValidationRequired
    })]
  }, legacyFields]
};