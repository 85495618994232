/**
 * @param url absolut url
 * @param queryObject containing the query string params
 */
export var openWindow = function openWindow(url, queryObject) {
  var urlToOpen = new URL(url);

  if (queryObject) {
    for (var key in queryObject) {
      var _queryObject$key;

      urlToOpen.searchParams.append(key, (_queryObject$key = queryObject[key]) !== null && _queryObject$key !== void 0 ? _queryObject$key : '');
    }
  }

  window.open(urlToOpen.toString(), '_blank');
};