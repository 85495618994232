import { EditorState, Modifier, SelectionState } from 'draft-js';
import { BlockType } from '@uno/common-cms';
import { blocksConfig } from 'components/common/RichTextEditor/transformer';
import { getGenericBlockTypeFromDraftjsBlock } from 'components/common/RichTextEditor/transformer/util';
import isBlockInTableForTableKey from './isBlockInTableForTableKey';

var removeBlockFromState = function removeBlockFromState(editorState, blockKey) {
  var _getGenericBlockTypeF;

  var contentState = editorState.getCurrentContent();
  var block = contentState.getBlockForKey(blockKey);
  var blockType = block.getType();
  var blockBeforeKey = contentState.getKeyBefore(blockKey);
  var blockAfterKey = contentState.getKeyAfter(blockKey);
  var focusBlock = block; // if block is a table, be sure to get the first table block

  if (blockType === BlockType.TABLE) {
    var _ref = block.getData().toObject(),
        tableKey = _ref.tableKey;

    block = contentState.getBlockMap().skipUntil(function (vBlock) {
      return isBlockInTableForTableKey(vBlock, tableKey);
    }).first();
    var lastTableBlock = contentState.getBlockMap().skipUntil(function (vBlock) {
      return isBlockInTableForTableKey(vBlock, tableKey);
    }).takeUntil(function (vBlock) {
      return !isBlockInTableForTableKey(vBlock, tableKey);
    }).last();
    blockBeforeKey = contentState.getKeyBefore(block.getKey());
    blockAfterKey = contentState.getKeyAfter(lastTableBlock.getKey());
    focusBlock = lastTableBlock;
  }

  var genericBlockType = (_getGenericBlockTypeF = getGenericBlockTypeFromDraftjsBlock(block, contentState)) !== null && _getGenericBlockTypeF !== void 0 ? _getGenericBlockTypeF : 'undefinedGenericBlockType';
  var blockConfig = blocksConfig[genericBlockType];

  if ((blockConfig === null || blockConfig === void 0 ? void 0 : blockConfig.removeBlock) !== undefined) {
    var entityKey = block.getEntityAt(0);
    blockConfig.removeBlock(contentState.getEntity(entityKey).getData());
  } // the block before exists, we can remove the range from the end of it the end the current block


  if (blockBeforeKey !== undefined) {
    var anchorBlock = contentState.getBlockForKey(blockBeforeKey);

    var _targetRange = new SelectionState({
      anchorKey: anchorBlock.getKey(),
      anchorOffset: anchorBlock.getLength(),
      focusKey: focusBlock.getKey(),
      focusOffset: focusBlock.getLength()
    });

    var _contentStateWithoutBlock = Modifier.removeRange(contentState, _targetRange, 'backward');

    return EditorState.push(editorState, _contentStateWithoutBlock, 'remove-range');
  } // the block before does not exist, we have to remove the range from the start of the current block to the start of the block after


  var blockAfter = contentState.getBlockForKey(blockAfterKey);
  var targetRange = new SelectionState({
    anchorKey: blockKey,
    anchorOffset: 0,
    focusKey: blockAfterKey,
    focusOffset: 0
  });
  var contentStateWithoutBlock = Modifier.removeRange(contentState, targetRange, 'backward'); // we have to manually set the remaining block type and data back to their original values

  var contentStateWithCorrectBlockType = Modifier.setBlockType(contentStateWithoutBlock, editorState.getSelection(), blockAfter.getType());
  var contentStateWithCorrectBlockData = Modifier.setBlockData(contentStateWithCorrectBlockType, editorState.getSelection(), blockAfter.getData());
  var newEditorState = EditorState.push(editorState, contentStateWithCorrectBlockData, 'remove-range');
  return newEditorState;
};

export default removeBlockFromState;