import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _objectSpread2;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { ImageFieldNames } from '@uno/common-shared';
import { coverImagesField as coverImagesFieldDefinition } from "../../../common/fieldDefinitions/image";
import { getCoverImageFieldLabelId } from "../../../../helpers";
export var coverImagesField = _objectSpread(_objectSpread({}, coverImagesFieldDefinition), {}, {
  configuration: _objectSpread(_objectSpread({}, coverImagesFieldDefinition.configuration), {}, (_objectSpread2 = {}, _defineProperty(_objectSpread2, ImageFieldNames.HOME_SITE, {
    name: ImageFieldNames.HOME_SITE,
    labelId: getCoverImageFieldLabelId(ImageFieldNames.HOME_SITE),
    defaultValue: {
      imgUrl: undefined,
      legend: '',
      credits: ''
    },
    ratio: {
      width: 686,
      height: 400
    },
    constraints: {
      minWidth: 686,
      maxWidth: 3000
    }
  }), _defineProperty(_objectSpread2, ImageFieldNames.FACEBOOK, {
    name: ImageFieldNames.FACEBOOK,
    labelId: getCoverImageFieldLabelId(ImageFieldNames.FACEBOOK),
    defaultValue: {
      imgUrl: undefined,
      legend: '',
      credits: ''
    },
    ratio: {
      width: 470,
      height: 246
    },
    constraints: {
      minWidth: 470,
      maxWidth: 3000
    }
  }), _defineProperty(_objectSpread2, ImageFieldNames.PINTEREST, {
    name: ImageFieldNames.PINTEREST,
    labelId: getCoverImageFieldLabelId(ImageFieldNames.PINTEREST),
    defaultValue: {
      imgUrl: undefined,
      legend: '',
      credits: ''
    },
    ratio: {
      width: 470,
      height: 246
    },
    constraints: {
      minWidth: 470,
      maxWidth: 3000
    }
  }), _objectSpread2))
});