import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _objectSpread2, _objectSpread3;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { DoctissimoContentTypeName, Fields } from "../../../../../../config";
import { PartialContentPageChunkSchema } from "../../../../../page";
import { RepeatableLinkedContentSchema } from "../../../../../repeatableLinkedContent";
var HomeChunkBaseSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, PartialContentPageChunkSchema.properties), {}, (_objectSpread2 = {
    _contentTypeName: {
      "const": DoctissimoContentTypeName.Home
    }
  }, _defineProperty(_objectSpread2, Fields.EDITOR_IN_CHIEF, {
    type: 'string'
  }), _defineProperty(_objectSpread2, Fields.MEDICAL_EXPERT, {
    type: 'string'
  }), _objectSpread2)),
  required: [].concat(_toConsumableArray(PartialContentPageChunkSchema.required), ['_contentTypeName', Fields.EDITOR_IN_CHIEF, Fields.MEDICAL_EXPERT])
};
var MainSectionSchema = {
  type: 'array',
  minItems: 1,
  items: {
    type: 'string'
  }
};
var HomeChunkSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, HomeChunkBaseSchema.properties), {}, (_objectSpread3 = {}, _defineProperty(_objectSpread3, Fields.FAMILY_MAIN_SECTIONS, MainSectionSchema), _defineProperty(_objectSpread3, Fields.HEALTH_MAIN_SECTIONS, MainSectionSchema), _defineProperty(_objectSpread3, Fields.SENIOR_MAIN_SECTIONS, MainSectionSchema), _defineProperty(_objectSpread3, Fields.WELLNESS_MAIN_SECTIONS, MainSectionSchema), _defineProperty(_objectSpread3, Fields.FAMILY_MACRO_SECTION_TOP_CONTENT, RepeatableLinkedContentSchema), _defineProperty(_objectSpread3, Fields.HEALTH_MACRO_SECTION_TOP_CONTENT, RepeatableLinkedContentSchema), _defineProperty(_objectSpread3, Fields.MOST_VIEWED, RepeatableLinkedContentSchema), _defineProperty(_objectSpread3, Fields.TOP_ARTICLES, RepeatableLinkedContentSchema), _defineProperty(_objectSpread3, Fields.WELLNESS_MACRO_SECTION_TOP_CONTENT, RepeatableLinkedContentSchema), _defineProperty(_objectSpread3, Fields.EDITO_HIGHLIGHT_TOP_CONTENT, RepeatableLinkedContentSchema), _defineProperty(_objectSpread3, Fields.SENIOR_MACRO_SECTION_TOP_CONTENT, RepeatableLinkedContentSchema), _objectSpread3)),
  required: [].concat(_toConsumableArray(HomeChunkBaseSchema.required), [Fields.FAMILY_MAIN_SECTIONS, Fields.HEALTH_MAIN_SECTIONS, Fields.SENIOR_MAIN_SECTIONS, Fields.WELLNESS_MAIN_SECTIONS, Fields.FAMILY_MACRO_SECTION_TOP_CONTENT, Fields.HEALTH_MACRO_SECTION_TOP_CONTENT, Fields.MOST_VIEWED, Fields.TOP_ARTICLES, Fields.WELLNESS_MACRO_SECTION_TOP_CONTENT, Fields.EDITO_HIGHLIGHT_TOP_CONTENT, Fields.SENIOR_MACRO_SECTION_TOP_CONTENT])
};
export { HomeChunkBaseSchema as HomeChunkBaseSchemaDoctissimoFr, HomeChunkSchema as HomeChunkSchemaDoctissimoFr };