import { BlockType } from '@uno/common-cms';
export var isBlockInTableForTableKey = function isBlockInTableForTableKey(block, tableKey) {
  if ((block === null || block === void 0 ? void 0 : block.getType()) !== BlockType.TABLE) {
    return false;
  }

  var _ref = block.getData().toObject(),
      blockTableKey = _ref.tableKey;

  return tableKey === blockTableKey;
};
export default isBlockInTableForTableKey;