import { createSlice } from '@reduxjs/toolkit';
import assignIn from 'lodash/assignIn';
export var previewInitialState = {
  loading: false
};
var previewSlice = createSlice({
  name: 'preview',
  initialState: previewInitialState,
  reducers: {
    updatePreview: function updatePreview(state, action) {
      assignIn(state, action.payload);
    }
  }
});
var updatePreview = previewSlice.actions.updatePreview;
export { updatePreview };
export default previewSlice.reducer;