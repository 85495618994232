import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { videoFieldValidation } from "../../../../helpers/videoFieldValidation";
import { FieldType } from "../../../../types/ContentType/fields";
export var highlightedVideoField = {
  type: FieldType.Video,
  name: Fields.HIGHLIGHTED_VIDEO,
  labelId: getFieldLabelId(Fields.HIGHLIGHTED_VIDEO),
  defaultValue: {
    imgUrl: '',
    title: '',
    videoId: '',
    videoIframeUrl: ''
  },
  validation: videoFieldValidation
};