var SiteMapNewsFeedsNames;

(function (SiteMapNewsFeedsNames) {
  SiteMapNewsFeedsNames["NewsES"] = "newsEs";
  SiteMapNewsFeedsNames["NewsFR"] = "newsFr";
})(SiteMapNewsFeedsNames || (SiteMapNewsFeedsNames = {}));

var SiteMapNewsFeedsIds;

(function (SiteMapNewsFeedsIds) {
  SiteMapNewsFeedsIds["NewsES"] = "sitemapNewsAufemEs-0000-4b0a-9142-d089116b5bdf";
  SiteMapNewsFeedsIds["NewsFR"] = "sitemapNewsAufemCom-0000-4b0a-9142-d089116b5bdf";
})(SiteMapNewsFeedsIds || (SiteMapNewsFeedsIds = {}));

var FeedType;

(function (FeedType) {
  FeedType["Sitemap"] = "sitemap";
})(FeedType || (FeedType = {}));

export { SiteMapNewsFeedsNames as SiteMapNewsFeedsNamesAufeminin, SiteMapNewsFeedsIds as SiteMapNewsFeedsIdsAufeminin, FeedType as FeedTypeAufeminin };