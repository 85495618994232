import _defineProperty from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import sortBy from 'lodash/sortBy';
import { FieldType, getFieldLabelId, getGroupLabelId } from '@uno/common-cms';
import getContentType from './getContentType';

var formatErrors = function formatErrors(errors, baseLabelId, groupMappingByField) {
  var position = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var formattedErrors = Object.keys(errors).map(function (field) {
    var _ref;

    var error = errors[field];
    var fieldLabelId = getFieldLabelId(field);
    var groupLabelId = getGroupLabelId(groupMappingByField[field]);

    if (Array.isArray(error) && error !== undefined) {
      var subErrors = error.map(function (subError, i) {
        return formatErrors(subError, baseLabelId, groupMappingByField, i);
      }).flat();
      return {
        fieldLabelId: fieldLabelId,
        groupLabelId: groupLabelId,
        error: subErrors
      };
    }

    if (error && !('type' in error)) {
      var _subErrors = formatErrors(error, baseLabelId, groupMappingByField);

      return {
        fieldLabelId: fieldLabelId,
        groupLabelId: groupLabelId,
        error: _subErrors[0] !== undefined ? _subErrors[0].error : ''
      };
    }

    return _objectSpread({
      fieldLabelId: fieldLabelId,
      groupLabelId: groupLabelId,
      error: (_ref = error === null || error === void 0 ? void 0 : error.message) !== null && _ref !== void 0 ? _ref : ''
    }, position !== null ? {
      position: position
    } : {});
  });
  return formattedErrors.flat();
};

var getGroupMappingByField = function getGroupMappingByField(fields) {
  var group = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var depth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  return fields.reduce(function (acc, field) {
    if (field.type === FieldType.Repeatable && field.field.type === FieldType.Group) {
      return _objectSpread(_objectSpread({}, getGroupMappingByField(field.field.fields, group)), {}, _defineProperty({}, field.name, group), acc);
    }

    if (field.type === FieldType.Group) {
      if (depth > 1) {
        return _objectSpread(_objectSpread({}, getGroupMappingByField(field.fields, group, depth + 1)), acc);
      }

      return _objectSpread(_objectSpread({}, getGroupMappingByField(field.fields, field.name, depth + 1)), acc);
    }

    return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, field.name, group));
  }, {});
};

var formatFormErrors = function formatFormErrors(errors, contentTypeName) {
  var contentType = getContentType(contentTypeName);
  var groupMappingByField = getGroupMappingByField(contentType.fields);
  var formattedErrors = formatErrors(errors, contentType.baseLabelId, groupMappingByField);
  return sortBy(formattedErrors, 'groupLabelId');
};

export default formatFormErrors;