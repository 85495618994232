import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _netmumsUrls, _doctissimoUrls, _aufemininUrls, _defaultUrls, _forumUrls;

import { Sites } from "../../sites";
import { Stages } from "../../stages";
import { emptyUrls, getUrlsForAContext } from "./utils";
var netmumsUrls = (_netmumsUrls = {}, _defineProperty(_netmumsUrls, Stages.DEV, getUrlsForAContext({
  domainUrl: 'localhost',
  siteUrl: 'http://localhost/coffeehouse',
  staticUrl: 'https://cdn.staging.nmums.doc-media.fr/netmums_awsstaging',
  assetsUrl: '/coffeehouse'
})), _defineProperty(_netmumsUrls, Stages.STAGING, getUrlsForAContext({
  domainUrl: 'netmums.com',
  siteUrl: 'https://staging.netmums.com/coffeehouse',
  staticUrl: 'https://cdn.staging.nmums.doc-media.fr/netmums_awsstaging',
  assetsUrl: 'https://assets.netmums.com.unfy.dev'
})), _defineProperty(_netmumsUrls, Stages.PRODUCTION, getUrlsForAContext({
  domainUrl: 'netmums.com',
  siteUrl: 'https://www.netmums.com/coffeehouse',
  staticUrl: 'https://cdn.prod.nmums.doc-media.fr/netmums_awsprod',
  assetsUrl: 'https://assets.netmums.com'
})), _defineProperty(_netmumsUrls, Stages.PRODUCTION_LEGACY, getUrlsForAContext({
  domainUrl: 'netmums.com',
  siteUrl: 'https://www.netmums.com/coffeehouse',
  staticUrl: '',
  assetsUrl: ''
})), _netmumsUrls);
var doctissimoUrls = (_doctissimoUrls = {}, _defineProperty(_doctissimoUrls, Stages.DEV, getUrlsForAContext({
  domainUrl: 'doctissimo.local',
  siteUrl: 'http://forum.doctissimo.local',
  staticUrl: 'https://cdn.staging.cfdoc.doc-media.fr/doctissimo_awsstaging',
  assetsUrl: ''
})), _defineProperty(_doctissimoUrls, Stages.STAGING, getUrlsForAContext({
  domainUrl: 'doctissimo.fr.s.unfy.cloud',
  siteUrl: 'https://forum.doctissimo.fr.s.unfy.cloud',
  staticUrl: 'https://cdn.staging.cfdoc.doc-media.fr/doctissimo_awsstaging',
  assetsUrl: 'https://assets.doctissimo.fr.unfy.dev'
})), _defineProperty(_doctissimoUrls, Stages.PRODUCTION, getUrlsForAContext({
  domainUrl: 'doctissimo.fr',
  siteUrl: 'https://forum.doctissimo.fr',
  staticUrl: 'https://aws-cf.imdoc.fr/prod',
  assetsUrl: 'https://assets.doctissimo.fr'
})), _defineProperty(_doctissimoUrls, Stages.PRODUCTION_LEGACY, getUrlsForAContext({
  domainUrl: 'doctissimo.fr',
  siteUrl: 'https://forum.doctissimo.fr',
  staticUrl: '',
  assetsUrl: ''
})), _doctissimoUrls);
var aufemininUrls = (_aufemininUrls = {}, _defineProperty(_aufemininUrls, Stages.DEV, getUrlsForAContext({
  domainUrl: 'localhost',
  siteUrl: 'http://localhost/forum',
  staticUrl: 'https://assets.afcdn.com/',
  assetsUrl: '/forum'
})), _defineProperty(_aufemininUrls, Stages.STAGING, getUrlsForAContext({
  domainUrl: 'aufeminin.com.s.unfy.cloud',
  siteUrl: 'https://forum.aufeminin.com.s.unfy.cloud/forum',
  staticUrl: 'https://assets.afcdn.com/',
  assetsUrl: 'https://assets.afcdn.com/'
})), _defineProperty(_aufemininUrls, Stages.PRODUCTION, getUrlsForAContext({
  domainUrl: 'aufeminin.com',
  siteUrl: 'https://forum.aufeminin.com/forum',
  staticUrl: 'https://assets.afcdn.com/forum',
  assetsUrl: 'https://assets.afcdn.com/'
})), _defineProperty(_aufemininUrls, Stages.PRODUCTION_LEGACY, getUrlsForAContext({
  domainUrl: 'aufeminin.com',
  siteUrl: 'https://forum.aufeminin.com/forum',
  staticUrl: '',
  assetsUrl: ''
})), _aufemininUrls);
var defaultUrls = (_defaultUrls = {}, _defineProperty(_defaultUrls, Stages.DEV, emptyUrls), _defineProperty(_defaultUrls, Stages.STAGING, emptyUrls), _defineProperty(_defaultUrls, Stages.PRODUCTION, emptyUrls), _defineProperty(_defaultUrls, Stages.PRODUCTION_LEGACY, emptyUrls), _defaultUrls);
export var forumUrls = (_forumUrls = {}, _defineProperty(_forumUrls, Sites.NETMUMS_COM, netmumsUrls), _defineProperty(_forumUrls, Sites.DOCTISSIMO_FR, doctissimoUrls), _defineProperty(_forumUrls, Sites.AUFEMININ_COM, aufemininUrls), _defineProperty(_forumUrls, Sites.ENFEMENINO_COM, defaultUrls), _defineProperty(_forumUrls, Sites.ALFEMMINILE_COM, defaultUrls), _defineProperty(_forumUrls, Sites.GOFEMININ_DE, defaultUrls), _defineProperty(_forumUrls, Sites.SOFEMININE_CO_UK, defaultUrls), _defineProperty(_forumUrls, Sites.TAOFEMININO_COM_BR, defaultUrls), _defineProperty(_forumUrls, Sites.WEWOMEN_BE, defaultUrls), _defineProperty(_forumUrls, Sites.MARMITON_ORG, defaultUrls), _defineProperty(_forumUrls, Sites.BEAUTE_TEST_COM, defaultUrls), _forumUrls);