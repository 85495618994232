import { createStyles } from '@material-ui/core';
import { colorUsage, fontSize, fontWeight, getSpacing, lineHeight, transitionDuration } from 'stylesheet';
var MuiGradientTabOverride = createStyles({
  root: {
    minWidth: 'auto',
    minHeight: '0',
    height: 'auto',
    margin: "0 ".concat(getSpacing(1)),
    padding: "".concat(getSpacing(1.5), " ").concat(getSpacing(2)),
    borderRadius: '13px',
    color: colorUsage.textColor,
    opacity: 1,
    transition: "color ".concat(transitionDuration["default"]),
    '&:hover': {
      color: colorUsage.muiOverride.tab.gradient.textAccentColor
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 3,
      right: 3,
      bottom: 3,
      left: 3,
      zIndex: '-1',
      borderRadius: '10px',
      background: colorUsage.muiOverride.tab.gradient.beforeBackground
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: '-2',
      borderRadius: 'inherit',
      background: colorUsage.muiOverride.tab.gradient.afterBackground,
      opacity: 0,
      transition: "opacity ".concat(transitionDuration["default"])
    }
  },
  wrapper: {
    fontSize: fontSize.large,
    fontWeight: fontWeight.bold,
    lineHeight: lineHeight.normal,
    textTransform: 'capitalize'
  },
  selected: {
    background: colorUsage.muiOverride.tab.gradient.selectedBackground,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'padding-box',
    '&:after': {
      opacity: 1
    }
  }
});
export default MuiGradientTabOverride;