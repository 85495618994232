import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import get from 'lodash/get';
import * as Yup from 'yup';
import { Sites } from '@uno/common-shared';
import { BaseContentTypeName, Fields, GroupFields } from "../../FieldNames";
import { coverImagesField } from "../../common/fieldDefinitions/image";
import { contentLinkedContentField } from "../../common/fieldDefinitions/linkedContent";
import { createdMetaDateField, modifiedMetaDateField, publicationMetaDateField } from "../../common/fieldDefinitions/metaDate";
import { metaUrlField } from "../../common/fieldDefinitions/metaUrl";
import { chapoTextField, shortTitleTextField, titleTextField } from "../../common/fieldDefinitions/simpleText";
import { altSectionField, mainSectionField } from "../../common/fieldDefinitions/taxonomy";
import { highlightedVideoField } from "../../common/fieldDefinitions/video";
import { getFieldLabelId, getGroupLabelId } from "../../../helpers";
import { FieldType } from "../../../types/ContentType";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { DoctissimoTaxonomyName } from "../taxonomy";
import { DoctissimoContentTypeName } from "./ContentTypeName";
import { legacyFields, seoGroupField } from "./common";
var baseLabelId = BaseContentTypeName.SectionHome;
var fields = [{
  type: FieldType.Group,
  name: GroupFields.SECTION_HOME,
  labelId: getGroupLabelId(GroupFields.SECTION_HOME),
  fields: [{
    type: FieldType.Group,
    name: GroupFields.TAXONOMY,
    labelId: getGroupLabelId(GroupFields.TAXONOMY),
    fields: [_objectSpread(_objectSpread({}, mainSectionField), {}, {
      taxonomyName: DoctissimoTaxonomyName.MainSection,
      disabled: true
    }), _objectSpread(_objectSpread({}, altSectionField), {}, {
      taxonomyName: DoctissimoTaxonomyName.MainSection
    })]
  }, titleTextField, _objectSpread(_objectSpread({}, shortTitleTextField), {}, {
    validation: Yup.string().required('form.required')
  }), chapoTextField, {
    type: FieldType.Group,
    name: GroupFields.EDITO_HIGHLIGHT,
    labelId: getGroupLabelId(GroupFields.EDITO_HIGHLIGHT),
    fields: [{
      type: FieldType.Repeatable,
      name: Fields.EDITO_HIGHLIGHT,
      labelId: getFieldLabelId(Fields.EDITO_HIGHLIGHT),
      defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
      validation: Yup.array().max(6),
      max: 6,
      field: _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
        contentTypeNames: [DoctissimoContentTypeName.Article, DoctissimoContentTypeName.News, DoctissimoContentTypeName.Video, DoctissimoContentTypeName.Test, DoctissimoContentTypeName.Slideshow]
      })
    }]
  }]
}, seoGroupField, {
  type: FieldType.Group,
  name: GroupFields.PROPERTIES,
  labelId: getGroupLabelId(GroupFields.PROPERTIES),
  fields: [metaUrlField, {
    type: FieldType.Group,
    name: GroupFields.DATES,
    labelId: getGroupLabelId(GroupFields.DATES),
    fields: [publicationMetaDateField, createdMetaDateField, modifiedMetaDateField]
  }, {
    type: FieldType.Group,
    name: GroupFields.IMAGES,
    labelId: getGroupLabelId(GroupFields.IMAGES),
    fields: [coverImagesField, highlightedVideoField]
  }, legacyFields]
}];
export var sectionHome = {
  site: Sites.DOCTISSIMO_FR,
  name: DoctissimoContentTypeName.SectionHome,
  labelId: 'content-type.sectionHome.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  getLabel: function getLabel(contentData) {
    return contentData.title;
  },
  getPreviewImage: function getPreviewImage(contentData) {
    var _ref2;

    return (_ref2 = get(contentData, 'coverImages.default.imgUrl')) !== null && _ref2 !== void 0 ? _ref2 : null;
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  getMainSectionId: function getMainSectionId(contentData) {
    var _contentData$mainSect, _contentData$mainSect2;

    return (_contentData$mainSect = contentData === null || contentData === void 0 ? void 0 : (_contentData$mainSect2 = contentData.mainSection) === null || _contentData$mainSect2 === void 0 ? void 0 : _contentData$mainSect2[0]) !== null && _contentData$mainSect !== void 0 ? _contentData$mainSect : '';
  },
  getManualModificationDate: function getManualModificationDate() {
    return undefined;
  },
  fields: fields,
  partialDependencyFields: [Fields.TITLE, Fields.CHAPO, Fields.COVER_IMAGES, Fields.AUTHORS],
  isLinkable: true,
  isCreatable: false
};