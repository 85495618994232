import { GenericBlockType } from '@uno/common-cms';
import appendGenericBlock from '../../util/appendGenericBlock';
export var transformAffiliationToGenericAndAppend = function transformAffiliationToGenericAndAppend(block, genericBlocks, contentState) {
  var _ref = contentState.getEntity(block.getEntityAt(0)).getData(),
      affiliationId = _ref.affiliationId,
      type = _ref.type,
      title = _ref.title,
      products = _ref.products;

  var newBlock = {
    type: GenericBlockType.Affiliation,
    affiliationId: affiliationId !== null && affiliationId !== void 0 ? affiliationId : '',
    affiliationType: type,
    title: title !== null && title !== void 0 ? title : '',
    products: products
  };
  return appendGenericBlock(genericBlocks, newBlock, block);
};