import { Sites } from '@uno/common-shared';
import { Lang } from "../../../types";
import { IndexConfigId } from "../../../types/services/search";
var TEST = {
  id: Sites.TEST,
  label: 'Test',
  lang: Lang.FR_FR,
  mainIndexConfigId: IndexConfigId.TestSearch
};
export default TEST;
export * from "./hosts";
export * from "./pageConfigNames";