import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { PartialPageChunkSchema } from "../../../../../page";
import { FEED_CHUNK_IDS_DOCTISSIMO_FR as FEED_CHUNK_IDS, FeedTypeDoctissimoFr as FeedType } from "../../../../../../types";
var FeedChunkSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, PartialPageChunkSchema.properties), {}, {
    _id: {
      "enum": _toConsumableArray(Object.values(FEED_CHUNK_IDS))
    },
    _type: {
      "enum": Object.keys(FeedType)
    },
    items: {
      type: 'array'
    }
  }),
  required: _toConsumableArray(PartialPageChunkSchema.required)
};
export { FeedChunkSchema as FeedChunkSchemaDoctissimoFr };