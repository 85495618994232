import { useEffect, useState } from 'react';
import { bakoGroupsToRoles, bakoIsAllowed, bakoSiteIsAllowed, getCognitoGroupsFromToken } from '@uno/common-shared';
import client from 'services/networking/client';

var useUserRoles = function useUserRoles() {
  var _useState = useState({}),
      userRolesBySite = _useState[0],
      setUserRolesBySite = _useState[1];

  useEffect(function () {
    var clientToken = client.getToken(); //todo: see to use checkToken method

    if (null === clientToken) {
      return;
    }

    var cognitoGroups = getCognitoGroupsFromToken(clientToken);

    if (undefined === cognitoGroups) {
      return;
    }

    setUserRolesBySite(bakoGroupsToRoles(cognitoGroups));
  }, []);

  var isSiteAllowed = function isSiteAllowed(site) {
    return bakoSiteIsAllowed(site, userRolesBySite);
  };

  var isAllowed = function isAllowed(site, right) {
    return bakoIsAllowed(site, userRolesBySite, right);
  };

  return {
    isSiteAllowed: isSiteAllowed,
    isAllowed: isAllowed
  };
};

export default useUserRoles;