import { CharacterMetadata, ContentBlock, ContentState, EditorState, genKey } from 'draft-js';
import { List, Map, Repeat } from 'immutable';
import { BlockType } from '@uno/common-cms';

var insertAtomicBlock = function insertAtomicBlock(editorState, type, blockData, entityData) {
  var contentState = editorState.getCurrentContent();
  var selection = editorState.getSelection();
  var currentBlock = editorState.getCurrentContent().getBlockForKey(selection.getAnchorKey());
  var contentStateWithEntity = contentState.createEntity(type, 'IMMUTABLE', entityData);
  var entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  var charData = CharacterMetadata.create({
    entity: entityKey
  });
  var text = ' ';
  var newBlock = new ContentBlock({
    key: genKey(),
    type: BlockType.ATOMIC,
    text: text,
    characterList: List(Repeat(charData, text.length)),
    data: Map(blockData)
  });
  var blockArray = editorState.getCurrentContent().getBlocksAsArray();
  var currentBlockIndex = blockArray.findIndex(function (block) {
    return block === currentBlock;
  });
  blockArray.splice(currentBlockIndex + 1, 0, newBlock); // insert empty block after

  blockArray.push(new ContentBlock({
    key: genKey()
  }));
  var newContentState = ContentState.createFromBlockArray(blockArray, contentStateWithEntity.getEntityMap());
  return EditorState.push(editorState, newContentState, 'insert-fragment');
};

export default insertAtomicBlock;