import { Sites } from '@uno/common-shared';
import { Lang } from "../../../types";
import { IndexConfigId } from "../../../types/services/search";
var AUFEMININ_COM = {
  id: Sites.AUFEMININ_COM,
  label: 'Aufeminin',
  lang: Lang.FR_FR,
  mainIndexConfigId: IndexConfigId.AufemininComSearch
};
var ENFEMENINO_COM = {
  id: Sites.ENFEMENINO_COM,
  label: 'Enfemenino',
  lang: Lang.ES_ES,
  mainIndexConfigId: IndexConfigId.AufemininComSearch
};
var AUFEMININ = {
  AUFEMININ_COM: AUFEMININ_COM,
  ENFEMENINO_COM: ENFEMENINO_COM
};
export default AUFEMININ;
export * from "./hosts";
export * from "./pageConfigNames";