import isNil from 'lodash/isNil';

var getManualModificationDate = function getManualModificationDate(_ref) {
  var medicalValidationDate = _ref.medicalValidationDate,
      modificationDate = _ref.modificationDate;

  if (isNil(modificationDate)) {
    return medicalValidationDate !== null && medicalValidationDate !== void 0 ? medicalValidationDate : undefined;
  }

  if (isNil(medicalValidationDate)) {
    return modificationDate !== null && modificationDate !== void 0 ? modificationDate : undefined;
  }

  var modificationDateObject = new Date(modificationDate);
  var medicalValidationDateObject = new Date(medicalValidationDate);
  return modificationDateObject > medicalValidationDateObject ? modificationDate : medicalValidationDate;
};

export default getManualModificationDate;