import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var bodyTextField = {
  type: FieldType.SimpleText,
  name: Fields.BODY,
  labelId: getFieldLabelId(Fields.BODY),
  defaultValue: '',
  multiline: true,
  rows: 20
};