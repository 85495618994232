import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import AUFEMININ from "./AUFEMININ/config";
import DOCTISSIMO from "./DOCTISSIMO_FR/config";
import MARMITON from "./MARMITON_ORG/config";
import TEST from "./TEST/config";
export var getSiteConfigs = function getSiteConfigs() {
  if (process.env.STAGE !== undefined && ['dev', 'staging'].includes(process.env.STAGE)) {
    return _objectSpread(_objectSpread({
      DOCTISSIMO: DOCTISSIMO
    }, AUFEMININ), {}, {
      MARMITON: MARMITON,
      TEST: TEST
    });
  }

  return _objectSpread(_objectSpread({
    DOCTISSIMO: DOCTISSIMO
  }, AUFEMININ), {}, {
    MARMITON: MARMITON
  });
};