import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _Yup$object$shape;

import * as Yup from 'yup';
import { SponsoFieldProperties } from "../types/ContentType/fields";
var requiredField = Yup.string().required('form.required');

var requiredFieldIfIsSponsored = function requiredFieldIfIsSponsored() {
  var isUrlField = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return Yup.string().when(SponsoFieldProperties.isSponsored, {
    is: true,
    then: isUrlField ? requiredField.url('form.invalidFormat') : requiredField
  });
};

export var sponsoFieldValidation = Yup.object().shape((_Yup$object$shape = {}, _defineProperty(_Yup$object$shape, SponsoFieldProperties.isSponsored, Yup["boolean"]()), _defineProperty(_Yup$object$shape, SponsoFieldProperties.ctaLabel, requiredFieldIfIsSponsored()), _defineProperty(_Yup$object$shape, SponsoFieldProperties.legalNotice, requiredFieldIfIsSponsored()), _defineProperty(_Yup$object$shape, SponsoFieldProperties.partnerName, requiredFieldIfIsSponsored()), _defineProperty(_Yup$object$shape, SponsoFieldProperties.partnerUrl, requiredFieldIfIsSponsored(true)), _Yup$object$shape));