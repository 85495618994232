import _defineProperty from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { createStyles } from '@material-ui/core';
import { colorUsage, getSpacing, shadow, transitionDuration } from 'stylesheet';

var MuiShadowIconToggleOverrideGenerator = function MuiShadowIconToggleOverrideGenerator(isOn) {
  var borderSize = '2px';
  var color = isOn ? colorUsage.iconGradientHoverForeground : colorUsage.iconForeground;
  var commonRootStyles = {
    marginLeft: '5px',
    padding: getSpacing(1.5),
    backgroundColor: colorUsage.muiOverride.shadowIcon.background,
    boxShadow: shadow.bottom.light,
    border: "".concat(borderSize, " solid ").concat(colorUsage.muiOverride.shadowIcon.border),
    backgroundClip: 'padding-box',
    transition: "border ".concat(transitionDuration["default"]),
    '& path': {
      fill: color
    },
    '&:hover': {
      backgroundColor: colorUsage.muiOverride.shadowIcon.background
    }
  };
  var onRootStyles = {
    border: "".concat(borderSize, " solid transparent"),
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: "-".concat(borderSize),
      borderRadius: 'inherit',
      zIndex: '-1',
      background: colorUsage.buttonGradient
    }
  };
  return createStyles({
    root: isOn ? _objectSpread(_objectSpread({}, commonRootStyles), onRootStyles) : commonRootStyles
  });
};

export { MuiShadowIconToggleOverrideGenerator };