import { Sites } from '@uno/common-shared';
import { AufemininTaxonomyName } from "./taxonomyName";
var TAG_PEOPLE_FR = {
  name: AufemininTaxonomyName.TagPeopleFR,
  hierarchical: false,
  site: Sites.AUFEMININ_COM
};
var TAG_PEOPLE_ES = {
  name: AufemininTaxonomyName.TagPeopleES,
  hierarchical: false,
  site: Sites.ENFEMENINO_COM
};
var TAG_PEOPLE_IT = {
  name: AufemininTaxonomyName.TagPeopleIT,
  hierarchical: false,
  site: Sites.ALFEMMINILE_COM
};
var TAG_PEOPLE_DE = {
  name: AufemininTaxonomyName.TagPeopleDE,
  hierarchical: false,
  site: Sites.GOFEMININ_DE
};
var TAG_PEOPLE_UK = {
  name: AufemininTaxonomyName.TagPeopleUK,
  hierarchical: false,
  site: Sites.SOFEMININE_CO_UK
};
var TAG_PEOPLE_BR = {
  name: AufemininTaxonomyName.TagPeopleBR,
  hierarchical: false,
  site: Sites.TAOFEMININO_COM_BR
};
export { TAG_PEOPLE_FR, TAG_PEOPLE_ES, TAG_PEOPLE_IT, TAG_PEOPLE_DE, TAG_PEOPLE_UK, TAG_PEOPLE_BR };