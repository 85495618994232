export var AffiliationApiIds;

(function (AffiliationApiIds) {
  AffiliationApiIds["THE_CORE"] = "the_core";
})(AffiliationApiIds || (AffiliationApiIds = {}));

export var ContentAffiliationStatus;

(function (ContentAffiliationStatus) {
  ContentAffiliationStatus["WITHOUT_AFFILIATION"] = "WITHOUT_AFFILIATION";
  ContentAffiliationStatus["WITH_AFFILIATION"] = "WITH_AFFILIATION";
  ContentAffiliationStatus["WITH_OBSOLETE_AFFILIATION"] = "WITH_OBSOLETE_AFFILIATION";
})(ContentAffiliationStatus || (ContentAffiliationStatus = {}));

export var AffiliationOfferType;

(function (AffiliationOfferType) {
  AffiliationOfferType["SINGLE_OFFER"] = "SINGLE_OFFER";
  AffiliationOfferType["ALL_OFFERS"] = "ALL_OFFERS";
})(AffiliationOfferType || (AffiliationOfferType = {}));