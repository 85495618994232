import { getLinkableContentTypesBySite } from '@uno/common-cms';
var FALLBACK_CONTENT_TYPE_IF_EMPTY = 'contentType:fallBackContentTypeIfEmpty';

var getAlgoliaLinkableContentTypeFilter = function getAlgoliaLinkableContentTypeFilter(site) {
  var linkableContentTypes = getLinkableContentTypesBySite(site);
  return '(' + linkableContentTypes.map(function (contentType) {
    return "contentType:".concat(contentType.name);
  }).concat(FALLBACK_CONTENT_TYPE_IF_EMPTY).join(' OR ') + ')';
};

export default getAlgoliaLinkableContentTypeFilter;