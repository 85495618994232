import { genKey } from 'draft-js';
import { BlockType as DraftjsBlockType } from '@uno/common-cms';
import { draftjsToGenericBlockTypeMap, draftjsToGenericInlineDataTypeMap, draftjsToGenericInlineStyleTypeMap, genericToDraftjsInlineDataTypeMap, genericToDraftjsInlineStyleTypeMap } from '../types';
export var getGenericBlockTypeFromDraftjsBlock = function getGenericBlockTypeFromDraftjsBlock(block, contentState) {
  var type = block.getType();

  try {
    if (type === DraftjsBlockType.ATOMIC) {
      type = contentState.getEntity(block.getEntityAt(0)).getType();
    }
  } catch (e) {
    return undefined;
  }

  return draftjsToGenericBlockTypeMap[type];
};
export var getGenericInlineStyleRangesFromBlock = function getGenericInlineStyleRangesFromBlock(block) {
  var inlineStyleRanges = [];
  block.findStyleRanges(function (character) {
    return character.getStyle().size !== 0;
  }, function (start, end) {
    block.getInlineStyleAt(start).forEach(function (style) {
      return inlineStyleRanges.push({
        offset: start,
        length: end - start,
        type: draftjsToGenericInlineStyleTypeMap[style]
      });
    });
  });
  return inlineStyleRanges;
};
export var getRawDraftInlineStyleRangesFromGenericInlineStyleRanges = function getRawDraftInlineStyleRangesFromGenericInlineStyleRanges(genericInlineStyleRanges) {
  return genericInlineStyleRanges.map(function (_ref) {
    var length = _ref.length,
        offset = _ref.offset,
        type = _ref.type;
    return {
      style: genericToDraftjsInlineStyleTypeMap[type],
      // avoid linter error
      offset: offset,
      length: length
    };
  });
};
export var getGenericInlineDataRanges = function getGenericInlineDataRanges(block, contentState) {
  var inlineDataRanges = [];
  block.findEntityRanges(function (character) {
    return character.getEntity() !== null;
  }, function (start, end) {
    var entity = contentState.getEntity(block.getEntityAt(start));
    inlineDataRanges.push({
      offset: start,
      length: end - start,
      type: draftjsToGenericInlineDataTypeMap[entity.getType()],
      data: entity.getData()
    });
  });
  return inlineDataRanges;
};
export var getRawDraftEntityRangesAndUpdatedEntityMap = function getRawDraftEntityRangesAndUpdatedEntityMap(inlineDataRanges, entityMap) {
  var rawDraftEntityRanges = inlineDataRanges.map(function (_ref2) {
    var _Object$keys$pop;

    var data = _ref2.data,
        length = _ref2.length,
        offset = _ref2.offset,
        type = _ref2.type;
    var entityKey = parseInt((_Object$keys$pop = Object.keys(entityMap).pop()) !== null && _Object$keys$pop !== void 0 ? _Object$keys$pop : '0') + 1;
    entityMap[entityKey] = {
      data: data,
      mutability: 'MUTABLE',
      type: genericToDraftjsInlineDataTypeMap[type]
    };
    return {
      key: entityKey,
      offset: offset,
      length: length
    };
  });
  return {
    rawDraftEntityRanges: rawDraftEntityRanges,
    entityMap: entityMap
  };
};
export var addAtomicBlock = function addAtomicBlock(rawDraftContentState, type, data) {
  var _Object$keys$pop2;

  var mutability = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'IMMUTABLE';
  var entityKey = parseInt((_Object$keys$pop2 = Object.keys(rawDraftContentState.entityMap).pop()) !== null && _Object$keys$pop2 !== void 0 ? _Object$keys$pop2 : '0') + 1;
  var entityRange = {
    key: entityKey,
    length: 1,
    offset: 0
  };
  rawDraftContentState.entityMap[entityKey] = {
    data: data.entityData,
    mutability: mutability,
    type: type
  };
  rawDraftContentState.blocks.push({
    key: genKey(),
    type: DraftjsBlockType.ATOMIC,
    text: ' ',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [entityRange],
    data: data.blockData
  });
  return rawDraftContentState;
};