import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _OktaSiteNameToSites;

import { Sites } from "../../../config/sites";
export var ROLE_SEPARATOR = '_';
export var SiteRole;

(function (SiteRole) {
  SiteRole["Editor"] = "Editor";
  SiteRole["EditorAdmin"] = "EditorAdmin";
  SiteRole["EditorExternal"] = "EditorExternal";
})(SiteRole || (SiteRole = {}));

export var isSiteRole = function isSiteRole(siteRole) {
  return Object.values(SiteRole).includes(siteRole);
};
export var CrossSiteRole;

(function (CrossSiteRole) {
  CrossSiteRole["Admin"] = "Admin";
  CrossSiteRole["SEO"] = "SEO";
  CrossSiteRole["Affiliation"] = "Affiliation";
})(CrossSiteRole || (CrossSiteRole = {}));

export var isCrossSiteRole = function isCrossSiteRole(role) {
  return Object.values(CrossSiteRole).includes(role);
};
export var OktaGroupSuffix;

(function (OktaGroupSuffix) {
  OktaGroupSuffix["Redac"] = "Redac";
  OktaGroupSuffix["RedacChief"] = "RedacChief";
  OktaGroupSuffix["External"] = "External";
})(OktaGroupSuffix || (OktaGroupSuffix = {}));

export var OktaGroupSiteName;

(function (OktaGroupSiteName) {
  OktaGroupSiteName["DOCTISSIMO_FR"] = "Docti";
  OktaGroupSiteName["AUFEMININ_COM"] = "Aufem";
  OktaGroupSiteName["ENFEMENINO_COM"] = "Enfemenino";
  OktaGroupSiteName["MARMITON_ORG"] = "Marmiton";
})(OktaGroupSiteName || (OktaGroupSiteName = {}));

export var OktaSiteNameToSites = (_OktaSiteNameToSites = {}, _defineProperty(_OktaSiteNameToSites, OktaGroupSiteName.DOCTISSIMO_FR, Sites.DOCTISSIMO_FR), _defineProperty(_OktaSiteNameToSites, OktaGroupSiteName.AUFEMININ_COM, Sites.AUFEMININ_COM), _defineProperty(_OktaSiteNameToSites, OktaGroupSiteName.ENFEMENINO_COM, Sites.ENFEMENINO_COM), _defineProperty(_OktaSiteNameToSites, OktaGroupSiteName.MARMITON_ORG, Sites.MARMITON_ORG), _OktaSiteNameToSites);