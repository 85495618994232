import { Sites } from '@uno/common-shared';
import { Lang } from "../../../types";
import { IndexConfigId } from "../../../types/services/search";
var DOCTISSIMO = {
  id: Sites.DOCTISSIMO_FR,
  label: 'Doctissimo',
  lang: Lang.FR_FR,
  mainIndexConfigId: IndexConfigId.DoctissimoFrSearch
};
export default DOCTISSIMO;
export * from "./hosts";
export * from "./pageConfigNames";