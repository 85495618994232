import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var descriptionTagTextField = {
  type: FieldType.SimpleText,
  name: Fields.DESCRIPTION_TAG,
  labelId: getFieldLabelId(Fields.DESCRIPTION_TAG),
  defaultValue: '',
  multiline: true,
  displayCharactersCount: true,
  minCharactersCount: 100,
  recommendedCharactersCount: 155,
  validation: Yup.string().min(100, 'form.stringLength')
};