import jwt from 'jsonwebtoken';
import get from 'lodash/get';

var getCognitoGroupsFromToken = function getCognitoGroupsFromToken(jsonWebToken) {
  var COGNITO_GROUPS = 'cognito:groups';
  var token = jwt.decode(jsonWebToken);
  var cognitoGroups = get(token, COGNITO_GROUPS);
  return cognitoGroups;
};

export default getCognitoGroupsFromToken;