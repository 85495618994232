import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var chapoTextField = {
  type: FieldType.SimpleText,
  name: Fields.CHAPO,
  labelId: getFieldLabelId(Fields.CHAPO),
  defaultValue: '',
  multiline: true,
  validation: Yup.string().required('form.required')
};