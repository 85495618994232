export var DoctissimoContentTypeName;

(function (DoctissimoContentTypeName) {
  DoctissimoContentTypeName["Article"] = "DOCTISSIMO_FR_ARTICLE";
  DoctissimoContentTypeName["ContentTypeHome"] = "DOCTISSIMO_FR_CONTENT_TYPE_HOME";
  DoctissimoContentTypeName["Dictionary"] = "DOCTISSIMO_FR_DICTIONARY";
  DoctissimoContentTypeName["Encyclopedia"] = "DOCTISSIMO_FR_ENCYCLOPEDIA";
  DoctissimoContentTypeName["Home"] = "DOCTISSIMO_FR_HOME";
  DoctissimoContentTypeName["HtmlPage"] = "DOCTISSIMO_FR_HTML_PAGE";
  DoctissimoContentTypeName["News"] = "DOCTISSIMO_FR_NEWS";
  DoctissimoContentTypeName["Newsletter"] = "DOCTISSIMO_FR_NEWSLETTER";
  DoctissimoContentTypeName["Person"] = "DOCTISSIMO_FR_PERSON";
  DoctissimoContentTypeName["PregnancyAppAppointment"] = "DOCTISSIMO_FR_PREGNANCY_APP_APPOINTMENT";
  DoctissimoContentTypeName["PregnancyAppWeek"] = "DOCTISSIMO_FR_PREGNANCY_APP_WEEK";
  DoctissimoContentTypeName["SectionHome"] = "DOCTISSIMO_FR_SECTION_HOME";
  DoctissimoContentTypeName["Slideshow"] = "DOCTISSIMO_FR_SLIDESHOW";
  DoctissimoContentTypeName["SlideshowV2"] = "DOCTISSIMO_FR_SLIDESHOW_V2";
  DoctissimoContentTypeName["Test"] = "DOCTISSIMO_FR_TEST";
  DoctissimoContentTypeName["Video"] = "DOCTISSIMO_FR_VIDEO";
})(DoctissimoContentTypeName || (DoctissimoContentTypeName = {}));