import { useMemo } from 'react';
import { CONTENT_TYPES } from '@uno/common-cms';
import { useSiteConfig } from 'libs/common/hooks';

var getSiteContentTypes = function getSiteContentTypes(site) {
  return Object.values(CONTENT_TYPES).filter(function (contentType) {
    return contentType.site === site;
  });
};

var useContentTypes = function useContentTypes() {
  var _useSiteConfig = useSiteConfig(),
      site = _useSiteConfig.id;

  var contentTypes = useMemo(function () {
    return getSiteContentTypes(site);
  }, [site]);
  return contentTypes;
};

export default useContentTypes;