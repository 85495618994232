import { Sites } from '@uno/common-shared';
import { AufemininTaxonomyName } from "./taxonomyName";
var TAG_SEO_FR = {
  name: AufemininTaxonomyName.TagSeoFR,
  hierarchical: true,
  site: Sites.AUFEMININ_COM
};
var TAG_SEO_ES = {
  name: AufemininTaxonomyName.TagSeoES,
  hierarchical: true,
  site: Sites.ENFEMENINO_COM
};
var TAG_SEO_IT = {
  name: AufemininTaxonomyName.TagSeoIT,
  hierarchical: true,
  site: Sites.ALFEMMINILE_COM
};
var TAG_SEO_DE = {
  name: AufemininTaxonomyName.TagSeoDE,
  hierarchical: true,
  site: Sites.GOFEMININ_DE
};
var TAG_SEO_UK = {
  name: AufemininTaxonomyName.TagSeoUK,
  hierarchical: true,
  site: Sites.SOFEMININE_CO_UK
};
var TAG_SEO_BR = {
  name: AufemininTaxonomyName.TagSeoBR,
  hierarchical: true,
  site: Sites.TAOFEMININO_COM_BR
};
var TAG_SEO_BE = {
  name: AufemininTaxonomyName.TagSeoBE,
  hierarchical: true,
  site: Sites.WEWOMEN_BE
};
export { TAG_SEO_FR, TAG_SEO_ES, TAG_SEO_IT, TAG_SEO_DE, TAG_SEO_UK, TAG_SEO_BR, TAG_SEO_BE };