import { BlockType } from '@uno/common-cms';
import getTableKeyFromBlock from './getTableKeyFromBlock';
import isBlockInTableForTableKey from './isBlockInTableForTableKey';
/**
 * If it's a table, we want all table blocks
 */

var getBlockRelatives = function getBlockRelatives(block, blockMap) {
  if (block.getType() !== BlockType.TABLE) {
    return [block];
  }

  var tableKey = getTableKeyFromBlock(block);
  return blockMap.skipUntil(function (vBlock) {
    return isBlockInTableForTableKey(vBlock, tableKey);
  }).takeUntil(function (vBlock) {
    return !isBlockInTableForTableKey(vBlock, tableKey);
  }).toArray();
};

export default getBlockRelatives;