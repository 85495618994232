import { createStyles } from '@material-ui/core';
import { colorUsage } from 'stylesheet';
var MuiAccordionOverride = createStyles({
  root: {
    boxShadow: 'none',
    borderTop: "1px ".concat(colorUsage.defaultBorder, " solid")
  }
});
var MuiAccordionSummaryOverride = createStyles({
  root: {
    padding: 0
  },
  content: {
    flexDirection: 'column'
  },
  expandIcon: {
    marginRight: 0
  }
});
var MuiCoverImagesVariantButton = createStyles({
  root: {
    backgroundColor: "".concat(colorUsage.witheColor)
  }
});
export { MuiAccordionOverride, MuiAccordionSummaryOverride, MuiCoverImagesVariantButton };