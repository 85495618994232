import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { BlockType, FieldType } from "../../../../types/ContentType/fields";
export var answerRichTextField = {
  type: FieldType.RichText,
  name: Fields.ANSWER,
  enabledBlocks: [BlockType.UL, BlockType.UNSTYLED],
  labelId: getFieldLabelId(Fields.ANSWER),
  defaultValue: {
    blocks: []
  },
  validation: Yup.object()
};