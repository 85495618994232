export var cropAreaSchema = {
  type: 'object',
  properties: {
    x: {
      type: 'number'
    },
    y: {
      type: 'number'
    },
    width: {
      type: 'number'
    },
    height: {
      type: 'number'
    }
  },
  required: ['x', 'y', 'width', 'height'],
  additionalProperties: false
};
export var imageAnchorSchema = {
  type: 'object',
  properties: {
    positionX: {
      type: 'number'
    },
    positionY: {
      type: 'number'
    }
  },
  required: ['positionX', 'positionY'],
  additionalProperties: false
};
export var imageSchema = {
  type: 'object',
  properties: {
    credits: {
      type: 'string'
    },
    legend: {
      type: 'string'
    },
    imgUrl: {
      type: 'string'
    },
    _originalImage: {
      type: 'object',
      properties: {
        imgUrl: {
          type: 'string'
        },
        cropArea: cropAreaSchema
      },
      required: ['imgUrl', 'cropArea'],
      additionalProperties: false
    },
    imageAnchor: imageAnchorSchema
  },
  required: ['credits', 'legend'],
  additionalProperties: false
};
export var coverImagesSchema = {
  type: 'object',
  properties: {
    "default": imageSchema,
    facebook: imageSchema,
    homeSite: imageSchema,
    square: imageSchema,
    pinterest: imageSchema
  },
  required: ['default'],
  additionalProperties: false
};