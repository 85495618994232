import _defineProperty from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GenericBlockType } from '@uno/common-cms';
import appendGenericBlock from '../../util/appendGenericBlock';
export var transformImageToGenericAndAppend = function transformImageToGenericAndAppend(block, genericBlocks, contentState) {
  var _ref = contentState.getEntity(block.getEntityAt(0)).getData(),
      imgUrl = _ref.imgUrl,
      legend = _ref.legend,
      credits = _ref.credits,
      _originalImage = _ref._originalImage,
      imageAnchor = _ref.imageAnchor;

  var newBlock = _objectSpread(_objectSpread({
    type: GenericBlockType.Image,
    imgUrl: imgUrl,
    legend: legend,
    credits: credits
  }, _originalImage !== undefined ? {
    _originalImage: _originalImage
  } : {}), imageAnchor !== undefined ? {
    imageAnchor: imageAnchor
  } : {});

  return appendGenericBlock(genericBlocks, newBlock, block);
};