import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var legacyBOUrlReadOnlyField = {
  type: FieldType.ReadOnly,
  name: Fields.LEGACY_BO_URL,
  labelId: getFieldLabelId(Fields.LEGACY_BO_URL),
  defaultValue: '',
  validation: Yup.string()
};