import { TRUNCATE_VALUE } from 'config/constants';
export var DISPLAYABLE_LINK_LENGTH = 35;

var generateDisplayableUrl = function generateDisplayableUrl(url) {
  // strip `url` of http://, https:// and www.
  var strippedUrl = url.replace(/((^\w+:|^)\/\/)?(www\.)?/, '');

  if (strippedUrl.length <= DISPLAYABLE_LINK_LENGTH) {
    return strippedUrl;
  }

  return strippedUrl.substr(0, DISPLAYABLE_LINK_LENGTH - 3) + TRUNCATE_VALUE;
};

export default generateDisplayableUrl;