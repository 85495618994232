import { createStyles } from '@material-ui/core';
import { colorPalette } from 'stylesheet';
var MuiPaginationItemOverride = createStyles({
  page: {
    '&:hover': {
      backgroundColor: colorPalette.primary[50]
    },
    '& .MuiTouchRipple-root *': {
      backgroundColor: colorPalette.primary[500]
    }
  }
});
export default MuiPaginationItemOverride;