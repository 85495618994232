export var Services;

(function (Services) {
  Services["CONTENT"] = "content";
  Services["FORUM"] = "forum";
  Services["NAVIGATION"] = "navigation";
  Services["NOTIFICATIONS"] = "notifications";
  Services["PROFILE"] = "profile";
  Services["REVIEWS"] = "reviews";
  Services["PRIVATE_MESSAGE"] = "private_message";
  Services["TAXONOMY"] = "taxonomy";
})(Services || (Services = {}));