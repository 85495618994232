import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var titleTagTextField = {
  type: FieldType.SimpleText,
  name: Fields.TITLE_TAG,
  labelId: getFieldLabelId(Fields.TITLE_TAG),
  defaultValue: '',
  displayCharactersCount: true,
  minCharactersCount: 30,
  recommendedCharactersCount: 55,
  validation: Yup.string().min(30, 'form.stringLength')
};