import styled from 'styled-components';
import { colorUsage, getSpacing, gradientText, headerHeight, shadow, typography, zIndex } from 'stylesheet';
export var HeaderContainer = styled.header.withConfig({
  displayName: "Headerstyle__HeaderContainer",
  componentId: "sc-1bdme0z-0"
})(["", " position:sticky;top:0;z-index:", ";display:flex;flex-direction:row;flex-shrink:0;align-items:center;justify-content:center;height:", ";padding:", " ", ";background-color:", ";box-shadow:", ";"], typography.h1, zIndex.header, headerHeight, getSpacing(0.5), getSpacing(4), colorUsage.headerBackground, shadow.bottom.dark);
export var LogoLink = styled.a.withConfig({
  displayName: "Headerstyle__LogoLink",
  componentId: "sc-1bdme0z-1"
})(["display:inline-flex;"]);
export var Logo = styled.img.withConfig({
  displayName: "Headerstyle__Logo",
  componentId: "sc-1bdme0z-2"
})(["height:", ";margin-right:", ";"], getSpacing(5), getSpacing(2));
export var HeaderTitle = styled.div.withConfig({
  displayName: "Headerstyle__HeaderTitle",
  componentId: "sc-1bdme0z-3"
})(["", ""], typography.h1);
export var GradientText = styled.span.withConfig({
  displayName: "Headerstyle__GradientText",
  componentId: "sc-1bdme0z-4"
})(["", ""], gradientText);
export var LeftSide = styled.div.withConfig({
  displayName: "Headerstyle__LeftSide",
  componentId: "sc-1bdme0z-5"
})(["display:flex;flex:1 0 auto;align-items:center;margin-right:auto;"]);
export var RightSide = styled.div.withConfig({
  displayName: "Headerstyle__RightSide",
  componentId: "sc-1bdme0z-6"
})(["display:flex;align-items:center;margin-left:auto;"]);
export var HeaderItem = styled.div.withConfig({
  displayName: "Headerstyle__HeaderItem",
  componentId: "sc-1bdme0z-7"
})(["display:flex;flex:1;justify-content:center;margin:0 ", " 0 ", ";"], getSpacing(1), getSpacing(1));