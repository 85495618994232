export var isSingleOfferProduct = function isSingleOfferProduct(product) {
  return 'offer' in product;
};
export var isAllOffersProduct = function isAllOffersProduct(product) {
  return 'offers' in product;
};
export var isSingleOfferProductWithOffer = function isSingleOfferProductWithOffer(product) {
  return product !== undefined && isSingleOfferProduct(product) && product.offer !== null;
};
export var isAllOffersProductWithOffer = function isAllOffersProductWithOffer(product) {
  return product !== undefined && isAllOffersProduct(product) && product.offers.length > 0;
};