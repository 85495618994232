import { createStyles } from '@material-ui/core';
import { colorUsage } from 'stylesheet';
var CenterParentButtonOverride = createStyles(function () {
  return {
    root: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: colorUsage.centerParentButton.backgroundColor,
      '&:hover': {
        backgroundColor: colorUsage.centerParentButton.hoverBackgroundColor
      }
    }
  };
});
export default CenterParentButtonOverride;