import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _objectSpread2, _objectSpread3, _objectSpread4;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { imageSchema } from '@uno/common-shared';
import { DoctissimoContentTypeName, Fields, PregnancyPeriodOptions } from "../../../../../../config";
import { PartialContentPageChunkSchema, PartialPageChunkSchema } from "../../../../../page";
import { RepeatableLinkedContentSchema } from "../../../../../repeatableLinkedContent";
var PregnancyAppWeekChunkBaseSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, PartialContentPageChunkSchema.properties), {}, (_objectSpread2 = {
    _contentMetadata: {
      type: 'object',
      properties: {
        editorialUpdateDate: {
          type: ['string']
        }
      },
      additionalProperties: false
    },
    _contentTypeName: {
      "const": DoctissimoContentTypeName.PregnancyAppWeek
    }
  }, _defineProperty(_objectSpread2, Fields.TITLE, {
    type: 'string'
  }), _defineProperty(_objectSpread2, Fields.WEEK, {
    type: 'string'
  }), _defineProperty(_objectSpread2, Fields.PREGNANCY_PERIOD, {
    "enum": Object.values(PregnancyPeriodOptions)
  }), _defineProperty(_objectSpread2, Fields.IMAGE_FOETUS, imageSchema), _defineProperty(_objectSpread2, Fields.IMAGE_FRUIT, imageSchema), _objectSpread2)),
  required: [].concat(_toConsumableArray(PartialContentPageChunkSchema.required), ['_contentMetadata', '_contentTypeName', Fields.TITLE, Fields.WEEK, Fields.PREGNANCY_PERIOD, Fields.IMAGE_FOETUS, Fields.IMAGE_FRUIT])
};
var PregnancyAppWeekChunkSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, PregnancyAppWeekChunkBaseSchema.properties), {}, (_objectSpread3 = {}, _defineProperty(_objectSpread3, Fields.TOP_ARTICLES, RepeatableLinkedContentSchema), _defineProperty(_objectSpread3, Fields.TOP_VIDEOS, RepeatableLinkedContentSchema), _defineProperty(_objectSpread3, Fields.PREGNANCY_ARTICLE_HEALTH, RepeatableLinkedContentSchema), _defineProperty(_objectSpread3, Fields.MULTI_PREGNANCY_ARTICLE_HEALTH, RepeatableLinkedContentSchema), _defineProperty(_objectSpread3, Fields.PREGNANCY_ARTICLE_WELLNESS, RepeatableLinkedContentSchema), _defineProperty(_objectSpread3, Fields.MULTI_PREGNANCY_ARTICLE_WELLNESS, RepeatableLinkedContentSchema), _objectSpread3)),
  required: [].concat(_toConsumableArray(PregnancyAppWeekChunkBaseSchema.required), [Fields.TOP_ARTICLES, Fields.TOP_VIDEOS, Fields.PREGNANCY_ARTICLE_HEALTH, Fields.MULTI_PREGNANCY_ARTICLE_HEALTH, Fields.PREGNANCY_ARTICLE_WELLNESS, Fields.MULTI_PREGNANCY_ARTICLE_WELLNESS])
};
var GenerateUrlPregnancyAppWeekChunkSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, PartialPageChunkSchema.properties), {}, (_objectSpread4 = {}, _defineProperty(_objectSpread4, Fields.TITLE, {
    type: 'string'
  }), _defineProperty(_objectSpread4, Fields.WEEK, {
    type: 'string'
  }), _defineProperty(_objectSpread4, Fields.PREGNANCY_PERIOD, {
    "enum": Object.values(PregnancyPeriodOptions)
  }), _objectSpread4)),
  required: [].concat(_toConsumableArray(PartialPageChunkSchema.required), [Fields.TITLE, Fields.WEEK, Fields.PREGNANCY_PERIOD])
};
export { PregnancyAppWeekChunkBaseSchema as PregnancyAppWeekChunkBaseSchemaDoctissimoFr, PregnancyAppWeekChunkSchema as PregnancyAppWeekChunkSchemaDoctissimoFr, GenerateUrlPregnancyAppWeekChunkSchema as GenerateUrlPregnancyAppWeekChunkSchemaDoctissimoFr };