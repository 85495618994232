import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Services } from '@uno/common-shared';
import { CONTENT_TYPES_NAMES } from "../config";
import { ContentStatusSchema, ModelValues } from "../types";
import { UrlMetadataSchema } from "./url";
var DependencyObjectSchema = {
  type: 'object',
  properties: {
    _urls: {
      type: 'array',
      items: UrlMetadataSchema
    }
  },
  required: [],
  additionalProperties: true
};
export var DependencyMapSchema = {
  type: 'object',
  additionalProperties: DependencyObjectSchema
};
export var PartialPageChunkSchema = {
  type: 'object',
  properties: {
    _id: {
      type: 'string'
    },
    _dependencyMap: DependencyMapSchema
  },
  required: ['_id', '_dependencyMap'],
  additionalProperties: true
};
export var PartialPageChunkEnvelopeSchema = {
  type: 'object',
  properties: {
    service: {
      "enum": Object.values(Services)
    },
    model: {
      "enum": ModelValues
    },
    pageChunk: PartialPageChunkSchema
  },
  required: ['service', 'model', 'pageChunk'],
  additionalProperties: false
};
export var BuildPageInputPageChunkEnvelopeSchema = {
  type: 'object',
  properties: {
    service: {
      "enum": Object.values(Services)
    },
    model: {
      "enum": ModelValues
    },
    pageChunk: {
      type: 'object',
      properties: {
        _id: {
          type: 'string'
        }
      },
      required: ['_id'],
      additionalProperties: true
    }
  },
  required: ['service', 'model', 'pageChunk'],
  additionalProperties: false
};
export var PartialContentPageChunkSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, PartialPageChunkSchema.properties), {}, {
    _createdAt: {
      type: 'string'
    },
    _modifiedAt: {
      type: 'string'
    },
    _status: ContentStatusSchema
  }),
  required: [].concat(_toConsumableArray(PartialPageChunkSchema.required), ['_createdAt', '_modifiedAt', '_status']),
  additionalProperties: true
};
export var PartialContentPageWithContentTypeAndMainSectionChunkSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, PartialContentPageChunkSchema.properties), {}, {
    _contentTypeName: {
      "enum": CONTENT_TYPES_NAMES
    },
    mainSection: {
      type: 'array',
      items: {
        type: 'string'
      },
      minItems: 1
    }
  }),
  required: [].concat(_toConsumableArray(PartialContentPageChunkSchema.required), ['mainSection', '_contentTypeName']),
  additionalProperties: true
};