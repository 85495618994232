import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var templateSelectField = {
  type: FieldType.Select,
  name: Fields.TEMPLATE,
  labelId: getFieldLabelId(Fields.TEMPLATE),
  defaultValue: '',
  allowEmpty: false,
  validation: Yup.string().required('form.required')
};