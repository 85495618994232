import { Context, UrlCategories } from "./urlCategories";
import { getUrls } from "./urlsConfig";
import { removePrecedingSlash, removeTrailingSlash } from "./util";
export var buildUrlWithHost = function buildUrlWithHost(_ref) {
  var siteId = _ref.siteId,
      stage = _ref.stage,
      path = _ref.path,
      _ref$context = _ref.context,
      context = _ref$context === void 0 ? Context.MAIN : _ref$context,
      host = _ref.host,
      _ref$forceHttp = _ref.forceHttp,
      forceHttp = _ref$forceHttp === void 0 ? false : _ref$forceHttp;
  var protocol = forceHttp ? 'http://' : 'https://';
  var baseUrl = protocol + host + '.' + getUrls({
    siteId: siteId,
    stage: stage,
    context: context
  })[UrlCategories.DOMAIN_URL];
  return path !== '' && removePrecedingSlash(path) !== '' ? removeTrailingSlash(baseUrl) + '/' + removePrecedingSlash(path) : removeTrailingSlash(baseUrl);
};