import getBlockHighlightPosition from './getBlockHighlightPosition';
import isBlockListItem from './isBlockListItem';
var ADJUSTMENT_TOP = -20;
var LIST_ITEM_ADJUSTMENT_TOP = 8;
var HIGHLIGHT_ADJUSTMENT_TOP_DEFAULT = 8;
var HIGHLIGHT_ADJUSTMENT_TOP_FIRST_ITEM = 26;
var HIGHLIGHT_ADJUSTMENT_TOP_LAST_ITEM = 0;
var HIGHLIGHT_LIST_ITEM_ADJUSTMENT_TOP_DEFAULT = 2;
var HIGHLIGHT_LIST_ITEM_ADJUSTMENT_TOP_FIRST_ITEM = 0;

var getAdjustmentTopByHighlightPosition = function getAdjustmentTopByHighlightPosition(_ref, isList) {
  var isFirst = _ref.isFirst,
      isLast = _ref.isLast;

  switch (true) {
    case !isFirst && !isLast:
      return isList ? HIGHLIGHT_LIST_ITEM_ADJUSTMENT_TOP_DEFAULT : HIGHLIGHT_ADJUSTMENT_TOP_DEFAULT;

    case !isFirst && isLast:
      return isList ? HIGHLIGHT_LIST_ITEM_ADJUSTMENT_TOP_DEFAULT : HIGHLIGHT_ADJUSTMENT_TOP_LAST_ITEM;

    default:
      return isList ? HIGHLIGHT_LIST_ITEM_ADJUSTMENT_TOP_FIRST_ITEM : HIGHLIGHT_ADJUSTMENT_TOP_FIRST_ITEM;
  }
};

export var getNodeOffsetTop = function getNodeOffsetTop(editorState, block, node) {
  var _node$parentElement$o, _node$parentElement;

  var isList = isBlockListItem(block);
  var nodeParentTopOffset = (_node$parentElement$o = (_node$parentElement = node.parentElement) === null || _node$parentElement === void 0 ? void 0 : _node$parentElement.offsetTop) !== null && _node$parentElement$o !== void 0 ? _node$parentElement$o : 0;
  var offsetTop = isList ? node.offsetTop + nodeParentTopOffset + LIST_ITEM_ADJUSTMENT_TOP : node.offsetTop + (node.clientHeight - nodeParentTopOffset) / 2 + ADJUSTMENT_TOP;
  var highlightPosition = getBlockHighlightPosition(editorState, block);

  if (!highlightPosition) {
    return offsetTop;
  }

  return offsetTop + getAdjustmentTopByHighlightPosition(highlightPosition, isList);
};
export default getNodeOffsetTop;