import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _AUFEMININ_CONTENT_TY;

import { articleFR } from "../sites/AUFEMININ_COM/contentTypes/Article";
import { authorFR } from "../sites/AUFEMININ_COM/contentTypes/Author";
import { homeFR } from "../sites/AUFEMININ_COM/contentTypes/Home";
import { sectionHomeFR } from "../sites/AUFEMININ_COM/contentTypes/SectionHome";
import { slideshowFR } from "../sites/AUFEMININ_COM/contentTypes/Slideshow";
import { slideshowV2FR } from "../sites/AUFEMININ_COM/contentTypes/SlideshowV2";
import { tagPageFR } from "../sites/AUFEMININ_COM/contentTypes/TagPage";
import { articleES } from "../sites/ENFEMENINO_COM/contentTypes/Article";
import { authorES } from "../sites/ENFEMENINO_COM/contentTypes/Author";
import { homeES } from "../sites/ENFEMENINO_COM/contentTypes/Home";
import { sectionHomeES } from "../sites/ENFEMENINO_COM/contentTypes/SectionHome";
import { slideshowES } from "../sites/ENFEMENINO_COM/contentTypes/Slideshow";
import { slideshowV2ES } from "../sites/ENFEMENINO_COM/contentTypes/SlideshowV2";
import { tagPageES } from "../sites/ENFEMENINO_COM/contentTypes/TagPage";
import { AufemininContentTypeName } from "./ContentTypeName";
var AUFEMININ_CONTENT_TYPES = (_AUFEMININ_CONTENT_TY = {}, _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.ArticleES, articleES), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.ArticleFR, articleFR), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.AuthorES, authorES), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.AuthorFR, authorFR), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.HomeFR, homeFR), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.HomeES, homeES), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.SectionHomeFR, sectionHomeFR), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.SectionHomeES, sectionHomeES), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.SlideshowES, slideshowES), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.SlideshowFR, slideshowFR), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.SlideshowV2ES, slideshowV2ES), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.SlideshowV2FR, slideshowV2FR), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.TagPageFR, tagPageFR), _defineProperty(_AUFEMININ_CONTENT_TY, AufemininContentTypeName.TagPageES, tagPageES), _AUFEMININ_CONTENT_TY);
export default AUFEMININ_CONTENT_TYPES;
export * from "./ContentTypeName";