import { createStyles } from '@material-ui/core';
import { getSpacing } from 'stylesheet';
var MuiTableRowOverride = createStyles({
  root: {
    borderRadius: "".concat(getSpacing(1)),
    padding: "".concat(getSpacing(1), " ").concat(getSpacing(2)),
    '&:hover': {
      filter: 'brightness(0.95)'
    }
  }
});
export default MuiTableRowOverride;