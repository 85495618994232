import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _ref;

import * as Yup from 'yup';
import { BaseContentTypeName, Fields, GroupFields } from "../../../FieldNames";
import { imageTextField, legendTextField, titleTextField } from "../../../common/fieldDefinitions/simpleText";
import { ContentTypeIconName, FieldType } from "../../../../types";
import { MockFrContentTypeName } from "./ContentTypeName";
var baseLabelId = BaseContentTypeName.Slideshow;
var configuration = {
  site: 123,
  name: MockFrContentTypeName.Slideshow,
  labelId: 'content-type.slideshow.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  getLabel: function getLabel(contentData) {
    return (contentData === null || contentData === void 0 ? void 0 : contentData.title) !== undefined ? contentData === null || contentData === void 0 ? void 0 : contentData.title : '';
  },
  getAuthorId: function getAuthorId(contentData) {
    return contentData.authorId !== undefined ? contentData.authorId : '';
  },
  getManualModificationDate: function getManualModificationDate(contentData) {
    var _contentData$modifica;

    return (_contentData$modifica = contentData === null || contentData === void 0 ? void 0 : contentData.modificationDate) !== null && _contentData$modifica !== void 0 ? _contentData$modifica : undefined;
  },
  fields: [{
    type: FieldType.Group,
    name: GroupFields.SLIDESHOW,
    labelId: '',
    fields: [titleTextField, {
      type: FieldType.Number,
      name: Fields.AUTHOR_ID,
      labelId: '',
      defaultValue: '',
      validation: Yup.number()
    }, {
      type: FieldType.Repeatable,
      name: Fields.SLIDES,
      labelId: '',
      defaultValue: [(_ref = {}, _defineProperty(_ref, Fields.LEGEND, ''), _defineProperty(_ref, Fields.IMAGE, ''), _ref)],
      validation: Yup.array().min(2).required('form.required'),
      field: {
        type: FieldType.Group,
        name: GroupFields.SLIDE,
        labelId: '',
        fields: [legendTextField, imageTextField]
      }
    }, {
      type: FieldType.Repeatable,
      name: Fields.EXPERTS,
      labelId: '',
      defaultValue: [_defineProperty({}, Fields.EXPERT_ID, '')],
      field: {
        type: FieldType.LinkedContent,
        name: Fields.EXPERT_ID,
        labelId: '',
        defaultValue: '',
        validation: Yup.string()
      }
    }]
  }, {
    type: FieldType.Group,
    name: GroupFields.SEO,
    labelId: '',
    fields: []
  }]
};
export var slideshow = configuration;