import { createStyles } from '@material-ui/core';
import { colorUsage } from 'stylesheet';
import { CharactersCountStatus } from 'types/common';

var getColorFromStatus = function getColorFromStatus(status) {
  switch (status) {
    case CharactersCountStatus.Error:
      return colorUsage.textFieldStatus.error;

    case CharactersCountStatus.Warning:
      return colorUsage.textFieldStatus.warning;

    default:
      return colorUsage.textFieldStatus.valid;
  }
};

var MuiTextFieldWithStatusOverride = createStyles({
  root: function root(_ref) {
    var status = _ref.status;

    if (status === undefined) {
      return {};
    }

    var color = getColorFromStatus(status);
    return {
      '& .MuiFormLabel-root.Mui-focused': {
        color: color
      },
      '& .MuiOutlinedInput-root.Mui-focused': {
        '& fieldset': {
          borderColor: color
        },
        '& .MuiInputAdornment-root p': {
          color: color
        }
      }
    };
  }
});
export default MuiTextFieldWithStatusOverride;