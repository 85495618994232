import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
var RssFeedConfigChunkIds;

(function (RssFeedConfigChunkIds) {
  RssFeedConfigChunkIds["All"] = "rssfeed-0000-46a7-be6e-3818536af74a";
  RssFeedConfigChunkIds["Beaute"] = "rssfeed-beaute-47d8-b233-26aa5cfd2a79";
  RssFeedConfigChunkIds["Forme"] = "rssfeed-forme-421e-ab5e-278360c34a82";
  RssFeedConfigChunkIds["Grossesse"] = "rssfeed-grossesse-402a-a298-8256048de6bb";
  RssFeedConfigChunkIds["Medicaments"] = "rssfeed-medic-44b8-bf5e-b155c45c0d4d";
  RssFeedConfigChunkIds["Nutrition"] = "rssfeed-nutri-4545-9195-98d23522c377";
  RssFeedConfigChunkIds["Psychologie"] = "rssfeed-psycho-479c-b660-64a66b886419";
  RssFeedConfigChunkIds["Sante"] = "rssfeed-sante-412b-a0ff-6631381adb8f";
  RssFeedConfigChunkIds["Sexualite"] = "rssfeed-sexualite-4174-9caf-cb99921d504e";
  RssFeedConfigChunkIds["Famille"] = "rssfeed-famille-48e6-9445-a1d510c902b5";
  RssFeedConfigChunkIds["MedecineDouce"] = "rssfeed-medecineDouce-a333-ec1a2c639161";
  RssFeedConfigChunkIds["Bebe"] = "rssfeed-bebe-4b13-b8cc-7d26ee5e5954";
  RssFeedConfigChunkIds["Animaux"] = "rssfeed-animaux-e914-4b5b-8c0b-58cdf5920198";
  RssFeedConfigChunkIds["Videos"] = "rssfeed-videos-4531-9e20-ed7c204d4387";
  RssFeedConfigChunkIds["Tests"] = "rssfeed-tests-46a4-be4f-1a5163e5b801";
  RssFeedConfigChunkIds["News"] = "rssfeed-news-4da1-ba16-3e73227b06ad";
  RssFeedConfigChunkIds["Mode"] = "rssfeed-mode-469b-bb8b-a36eefb8e9b8";
  RssFeedConfigChunkIds["Diaporamas"] = "rssfeed-diaporamas-4d11-a3df-a595a891e9cb";
})(RssFeedConfigChunkIds || (RssFeedConfigChunkIds = {}));

var SiteMapNewsConfigChunkIds;

(function (SiteMapNewsConfigChunkIds) {
  SiteMapNewsConfigChunkIds["News"] = "sitemapN-0000-4b0a-9142-d089116b5bdf";
})(SiteMapNewsConfigChunkIds || (SiteMapNewsConfigChunkIds = {}));

var SiteMapWeeksConfigChunkIds;

(function (SiteMapWeeksConfigChunkIds) {
  SiteMapWeeksConfigChunkIds["Weeks"] = "sitemapW-0000-44ad-bd0b-fd9be6f75d06";
})(SiteMapWeeksConfigChunkIds || (SiteMapWeeksConfigChunkIds = {}));

var FEED_CHUNK_IDS = [].concat(_toConsumableArray(Object.values(RssFeedConfigChunkIds)), _toConsumableArray(Object.values(SiteMapNewsConfigChunkIds)), _toConsumableArray(Object.values(SiteMapWeeksConfigChunkIds)));
var FeedType;

(function (FeedType) {
  FeedType["Rss"] = "rss";
  FeedType["Sitemap"] = "sitemap";
})(FeedType || (FeedType = {}));

var SiteMapNewsFeedsNames;

(function (SiteMapNewsFeedsNames) {
  SiteMapNewsFeedsNames["News"] = "news";
})(SiteMapNewsFeedsNames || (SiteMapNewsFeedsNames = {}));

var RssFeedsNames;

(function (RssFeedsNames) {
  RssFeedsNames["All"] = "rssFeed";
  RssFeedsNames["Beaute"] = "rssFeedBeaute";
  RssFeedsNames["Forme"] = "rssFeedForme";
  RssFeedsNames["Grossesse"] = "rssFeedGrossesse";
  RssFeedsNames["Medicaments"] = "rssFeedMedicaments";
  RssFeedsNames["Nutrition"] = "rssFeedNutrition";
  RssFeedsNames["Psychologie"] = "rssFeedPsychologie";
  RssFeedsNames["Sante"] = "rssFeedSante";
  RssFeedsNames["Sexualite"] = "rssFeedSexualite";
  RssFeedsNames["Famille"] = "rssFeedFamille";
  RssFeedsNames["MedecineDouce"] = "rssFeedMedecineDouce";
  RssFeedsNames["Bebe"] = "rssFeedBebe";
  RssFeedsNames["Animaux"] = "rssFeedAnimaux";
  RssFeedsNames["Videos"] = "rssFeedVideos";
  RssFeedsNames["Tests"] = "rssFeedTests";
  RssFeedsNames["News"] = "rssFeedNews";
  RssFeedsNames["Mode"] = "rssFeedMode";
  RssFeedsNames["Diaporamas"] = "rssFeedDiaporamas";
})(RssFeedsNames || (RssFeedsNames = {}));

var SiteMapWeeksFeedsNames;

(function (SiteMapWeeksFeedsNames) {
  SiteMapWeeksFeedsNames["Weeks"] = "weeks";
})(SiteMapWeeksFeedsNames || (SiteMapWeeksFeedsNames = {}));

export { FEED_CHUNK_IDS as FEED_CHUNK_IDS_DOCTISSIMO_FR, RssFeedConfigChunkIds as RssFeedConfigChunkIdsDoctissimoFr, SiteMapNewsConfigChunkIds as SiteMapNewsConfigChunkIdsDoctissimoFr, SiteMapWeeksConfigChunkIds as SiteMapWeeksConfigChunkIdsDoctissimoFr, SiteMapNewsFeedsNames as SiteMapNewsFeedsNamesDoctissimoFr, RssFeedsNames as RssFeedsNamesDoctissimoFr, SiteMapWeeksFeedsNames as SiteMapWeeksFeedsNamesDoctissimoFr, FeedType as FeedTypeDoctissimoFr };