import _defineProperty from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

var _draftjsToGenericBloc, _draftjsToGenericInli, _genericToDraftjsInli, _draftjsToGenericInli2, _genericToDraftjsInli2;

import { AtomicBlockType as DraftjsAtomicBlockType, BlockType as DraftjsBlockType, GenericBlockType, GenericInlineDataType, GenericInlineStyleType } from '@uno/common-cms';
import { Entities as InlineEntities } from 'components/common/RichTextEditor/types';
import { StyleType } from '../../util/style';
export var draftjsToGenericBlockTypeMap = (_draftjsToGenericBloc = {}, _defineProperty(_draftjsToGenericBloc, DraftjsAtomicBlockType.AFFILIATION, GenericBlockType.Affiliation), _defineProperty(_draftjsToGenericBloc, DraftjsAtomicBlockType.BEOP, GenericBlockType.Beop), _defineProperty(_draftjsToGenericBloc, DraftjsAtomicBlockType.EMBED, GenericBlockType.Embed), _defineProperty(_draftjsToGenericBloc, DraftjsAtomicBlockType.IMAGE, GenericBlockType.Image), _defineProperty(_draftjsToGenericBloc, DraftjsAtomicBlockType.SLIDESHOW_EMBED, GenericBlockType.SlideshowEmbed), _defineProperty(_draftjsToGenericBloc, DraftjsAtomicBlockType.VIDEO, GenericBlockType.Video), _defineProperty(_draftjsToGenericBloc, DraftjsAtomicBlockType.RECIPE, GenericBlockType.Recipe), _defineProperty(_draftjsToGenericBloc, DraftjsBlockType.BLOCKQUOTE, GenericBlockType.Blockquote), _defineProperty(_draftjsToGenericBloc, DraftjsBlockType.H2, GenericBlockType.H2), _defineProperty(_draftjsToGenericBloc, DraftjsBlockType.H3, GenericBlockType.H3), _defineProperty(_draftjsToGenericBloc, DraftjsBlockType.OL, GenericBlockType.List), _defineProperty(_draftjsToGenericBloc, DraftjsBlockType.TABLE, GenericBlockType.Table), _defineProperty(_draftjsToGenericBloc, DraftjsBlockType.UL, GenericBlockType.List), _defineProperty(_draftjsToGenericBloc, DraftjsBlockType.UNSTYLED, GenericBlockType.Unstyled), _draftjsToGenericBloc);
export var draftjsToGenericInlineStyleTypeMap = (_draftjsToGenericInli = {}, _defineProperty(_draftjsToGenericInli, StyleType.BOLD, GenericInlineStyleType.Bold), _defineProperty(_draftjsToGenericInli, StyleType.ITALIC, GenericInlineStyleType.Italic), _defineProperty(_draftjsToGenericInli, StyleType.SUPERSCRIPT, GenericInlineStyleType.Superscript), _defineProperty(_draftjsToGenericInli, StyleType.UNDERLINE, GenericInlineStyleType.Underline), _draftjsToGenericInli);
export var genericToDraftjsInlineStyleTypeMap = (_genericToDraftjsInli = {}, _defineProperty(_genericToDraftjsInli, GenericInlineStyleType.Bold, StyleType.BOLD), _defineProperty(_genericToDraftjsInli, GenericInlineStyleType.Italic, StyleType.ITALIC), _defineProperty(_genericToDraftjsInli, GenericInlineStyleType.Superscript, StyleType.SUPERSCRIPT), _defineProperty(_genericToDraftjsInli, GenericInlineStyleType.Underline, StyleType.UNDERLINE), _genericToDraftjsInli);
export var draftjsToGenericInlineDataTypeMap = (_draftjsToGenericInli2 = {}, _defineProperty(_draftjsToGenericInli2, InlineEntities.AffiliationLink, GenericInlineDataType.AffiliationLink), _defineProperty(_draftjsToGenericInli2, InlineEntities.ExternalLink, GenericInlineDataType.ExternalLink), _defineProperty(_draftjsToGenericInli2, InlineEntities.InternalLink, GenericInlineDataType.InternalLink), _draftjsToGenericInli2);
export var genericToDraftjsInlineDataTypeMap = (_genericToDraftjsInli2 = {}, _defineProperty(_genericToDraftjsInli2, GenericInlineDataType.AffiliationLink, InlineEntities.AffiliationLink), _defineProperty(_genericToDraftjsInli2, GenericInlineDataType.ExternalLink, InlineEntities.ExternalLink), _defineProperty(_genericToDraftjsInli2, GenericInlineDataType.InternalLink, InlineEntities.InternalLink), _genericToDraftjsInli2);