import { createStyles } from '@material-ui/core';
import { colorUsage, getSpacing, shadow, transitionDuration } from 'stylesheet';

var MuiShadowIconButtonOverrideGenerator = function MuiShadowIconButtonOverrideGenerator(variant) {
  var padding = getSpacing(variant === 'small' ? 0.5 : 1.5);
  var borderSize = variant === 'small' ? '1px' : '2px';
  return createStyles({
    root: {
      padding: padding,
      backgroundColor: colorUsage.muiOverride.shadowIcon.background,
      boxShadow: shadow.bottom.light,
      border: "".concat(borderSize, " solid ").concat(colorUsage.muiOverride.shadowIcon.border),
      backgroundClip: 'padding-box',
      transition: "border ".concat(transitionDuration["default"]),
      '&:hover': {
        border: "".concat(borderSize, " solid transparent"),
        backgroundColor: 'white'
      },
      '&:hover:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: "-".concat(borderSize),
        borderRadius: 'inherit',
        zIndex: '-1',
        background: colorUsage.buttonGradient
      },
      '&:disabled': {
        backgroundColor: colorUsage.muiOverride.shadowIcon.disabledBackground // '#0000002e',

      }
    }
  });
};

var MuiShadowIconButtonOverride = MuiShadowIconButtonOverrideGenerator('normal');
var MuiSmallShadowIconButtonOverride = MuiShadowIconButtonOverrideGenerator('small');
export { MuiShadowIconButtonOverride, MuiSmallShadowIconButtonOverride };