import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as Yup from 'yup';
import { Sites } from '@uno/common-shared';
import { BaseContentTypeName, Fields, GroupFields } from "../../FieldNames";
import { modificationDateField } from "../../common/fieldDefinitions/date";
import { coverImagesField } from "../../common/fieldDefinitions/image";
import { createdMetaDateField, modifiedMetaDateField, publicationMetaDateField } from "../../common/fieldDefinitions/metaDate";
import { metaUrlField } from "../../common/fieldDefinitions/metaUrl";
import { bodyRichTextField } from "../../common/fieldDefinitions/richText";
import { activeSwitchField } from "../../common/fieldDefinitions/switch";
import { personTaxonomyField } from "../../common/fieldDefinitions/taxonomy";
import { getGroupLabelId } from "../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../types/ContentType";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { descriptionTagTextField, emailTextField, firstNameTextField, jobTextField, lastNameTextField, titleTagTextField, urlFacebookTextField, urlInstagramTextField, urlLinkedInTextField, urlPinterestTextField, urlTiktokTextField, urlTwitterTextField, urlWebsiteTextField, urlWikipediaTextField } from "../../common/fieldDefinitions/simpleText";
import { DoctissimoTaxonomyName } from "../taxonomy";
import { DoctissimoContentTypeName } from "./ContentTypeName";
import { getManualModificationDate, legacyFields } from "./common";
var baseLabelId = BaseContentTypeName.Person;
var fields = [{
  type: FieldType.Group,
  name: GroupFields.PERSON,
  labelId: getGroupLabelId(GroupFields.PERSON),
  fields: [firstNameTextField, lastNameTextField, jobTextField, bodyRichTextField, {
    type: FieldType.Group,
    name: GroupFields.NETWORK,
    labelId: getGroupLabelId(GroupFields.NETWORK),
    fields: [emailTextField, urlFacebookTextField, urlInstagramTextField, urlPinterestTextField, urlTwitterTextField, urlLinkedInTextField, urlTiktokTextField, urlWikipediaTextField, urlWebsiteTextField]
  }]
}, {
  type: FieldType.Group,
  name: GroupFields.SEO,
  labelId: getGroupLabelId(GroupFields.SEO),
  fields: [_objectSpread(_objectSpread({}, titleTagTextField), {}, {
    validation: Yup.string().test({
      message: 'form.stringLength',
      test: function test(string) {
        return string.length === 0 || string.length >= 30;
      }
    })
  }), _objectSpread(_objectSpread({}, descriptionTagTextField), {}, {
    validation: Yup.string().test({
      message: 'form.stringLength',
      test: function test(string) {
        return string.length === 0 || string.length >= 100;
      }
    })
  })]
}, {
  type: FieldType.Group,
  name: GroupFields.PROPERTIES,
  labelId: getGroupLabelId(GroupFields.PROPERTIES),
  fields: [metaUrlField, _objectSpread(_objectSpread({}, personTaxonomyField), {}, {
    taxonomyName: DoctissimoTaxonomyName.PersonType
  }), activeSwitchField, {
    type: FieldType.Group,
    name: GroupFields.IMAGES,
    labelId: getGroupLabelId(GroupFields.IMAGES),
    fields: [coverImagesField]
  }, modificationDateField, publicationMetaDateField, createdMetaDateField, modifiedMetaDateField, legacyFields]
}];
export var person = {
  site: Sites.DOCTISSIMO_FR,
  name: DoctissimoContentTypeName.Person,
  labelId: 'content-type.person.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Person,
  getLabel: function getLabel(contentData) {
    var _contentData$firstNam, _contentData$lastName;

    return "".concat((_contentData$firstNam = contentData === null || contentData === void 0 ? void 0 : contentData.firstName) !== null && _contentData$firstNam !== void 0 ? _contentData$firstNam : '').concat((contentData === null || contentData === void 0 ? void 0 : contentData.firstName) !== undefined && (contentData === null || contentData === void 0 ? void 0 : contentData.lastName) !== undefined ? ' ' : '').concat((_contentData$lastName = contentData === null || contentData === void 0 ? void 0 : contentData.lastName) !== null && _contentData$lastName !== void 0 ? _contentData$lastName : '');
  },
  getPreviewImage: function getPreviewImage(contentData) {
    var _contentData$coverIma, _contentData$coverIma2, _contentData$coverIma3;

    return (_contentData$coverIma = contentData === null || contentData === void 0 ? void 0 : (_contentData$coverIma2 = contentData.coverImages) === null || _contentData$coverIma2 === void 0 ? void 0 : (_contentData$coverIma3 = _contentData$coverIma2["default"]) === null || _contentData$coverIma3 === void 0 ? void 0 : _contentData$coverIma3.imgUrl) !== null && _contentData$coverIma !== void 0 ? _contentData$coverIma : null;
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  getMainSectionId: function getMainSectionId(contentData) {
    var _contentData$personTy, _contentData$personTy2;

    return (_contentData$personTy = contentData === null || contentData === void 0 ? void 0 : (_contentData$personTy2 = contentData.personType) === null || _contentData$personTy2 === void 0 ? void 0 : _contentData$personTy2[0]) !== null && _contentData$personTy !== void 0 ? _contentData$personTy : '';
  },
  getManualModificationDate: getManualModificationDate,
  fields: fields,
  partialDependencyFields: [Fields.FIRST_NAME, Fields.LAST_NAME, Fields.JOB, Fields.COVER_IMAGES],
  isLinkable: true,
  isCreatable: true
};