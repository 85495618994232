import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { formatFormErrors } from 'libs/content/utils';
var BREAK_LINE = '\n';

var useFormErrors = function useFormErrors() {
  var intl = useIntl();

  var _useSnackbar = useSnackbar(),
      enqueueSnackbar = _useSnackbar.enqueueSnackbar;

  var build = useCallback(function (_ref) {
    var fieldLabelId = _ref.fieldLabelId,
        groupLabelId = _ref.groupLabelId,
        error = _ref.error,
        position = _ref.position;
    var fieldLabel = intl.formatMessage({
      id: fieldLabelId
    });
    var groupLabel = intl.formatMessage({
      id: groupLabelId
    });

    if (Array.isArray(error)) {
      var subErrorsStr = error.map(function (subError) {
        return build(subError);
      }).join(BREAK_LINE);
      return "- ".concat(groupLabel, " : ").concat(fieldLabel).concat(BREAK_LINE).concat(subErrorsStr);
    }

    var errorMessage = intl.formatMessage({
      id: error
    });
    return "".concat(position === undefined ? "- ".concat(groupLabel, " : ") : "    - ".concat(position + 1), " ").concat(fieldLabel, " ").concat(errorMessage);
  }, [intl]);
  var showErrors = useCallback(function (errors, contentTypeName) {
    var formattedErrors = formatFormErrors(errors, contentTypeName);
    var errorStr = formattedErrors.map(function (error) {
      return build(error);
    }).join(BREAK_LINE);
    enqueueSnackbar(errorStr, {
      variant: 'error'
    });
  }, [build, enqueueSnackbar]);
  return showErrors;
};

export default useFormErrors;