import { AtomicBlockType as DraftjsAtomicBlockType } from '@uno/common-cms';
import { addAtomicBlock } from '../../util';
export var transformVideoToDraftjsAndAppend = function transformVideoToDraftjsAndAppend(_ref, rawDraftContentState) {
  var imgUrl = _ref.imgUrl,
      title = _ref.title,
      videoId = _ref.videoId,
      videoIframeUrl = _ref.videoIframeUrl;
  return addAtomicBlock(rawDraftContentState, DraftjsAtomicBlockType.VIDEO, {
    blockData: {
      openModal: false
    },
    entityData: {
      imgUrl: imgUrl,
      title: title,
      videoId: videoId,
      videoIframeUrl: videoIframeUrl
    }
  }, 'IMMUTABLE');
};