import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _fieldUpdates;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Sites } from '@uno/common-shared';
import { article } from "../../../contentTypes/Article";
import { AufemininContentTypeName } from "../../../contentTypes/ContentTypeName";
import { AufemininTaxonomyName } from "../../../taxonomy/taxonomyName";
import { Fields, GroupFields } from "../../../../FieldNames";
import { updateFieldsProperties } from "../../../../../helpers/updateFieldsProperties";
var fields = updateFieldsProperties({
  fields: article.fields,
  fieldUpdates: (_fieldUpdates = {}, _defineProperty(_fieldUpdates, "".concat(GroupFields.PROPERTIES, ".").concat(GroupFields.PROPERTIES, ".").concat(GroupFields.TAXONOMY, ".").concat(Fields.MAIN_SECTION), [{
    key: 'taxonomyName',
    value: AufemininTaxonomyName.MainSectionES
  }]), _defineProperty(_fieldUpdates, "".concat(GroupFields.PROPERTIES, ".").concat(GroupFields.PROPERTIES, ".").concat(GroupFields.TAXONOMY, ".").concat(Fields.ALT_SECTION), [{
    key: 'taxonomyName',
    value: AufemininTaxonomyName.MainSectionES
  }]), _defineProperty(_fieldUpdates, "".concat(GroupFields.PROPERTIES, ".").concat(GroupFields.PROPERTIES, ".").concat(GroupFields.TAXONOMY, ".").concat(Fields.TAG_SEO), [{
    key: 'taxonomyName',
    value: AufemininTaxonomyName.TagSeoES
  }]), _defineProperty(_fieldUpdates, "".concat(GroupFields.PROPERTIES, ".").concat(GroupFields.PROPERTIES, ".").concat(GroupFields.TAXONOMY, ".").concat(Fields.TAG_PEOPLE), [{
    key: 'taxonomyName',
    value: AufemininTaxonomyName.TagPeopleES
  }]), _defineProperty(_fieldUpdates, "".concat(GroupFields.PROPERTIES, ".").concat(GroupFields.PROPERTIES, ".").concat(GroupFields.TAXONOMY, ".").concat(Fields.TAG_OPS), [{
    key: 'taxonomyName',
    value: AufemininTaxonomyName.TagOpsES
  }]), _fieldUpdates)
});
fields = updateFieldsProperties({
  fields: article.fields,
  fieldUpdates: _defineProperty({}, "".concat(GroupFields.PROPERTIES, ".").concat(GroupFields.SPONSO, ".").concat(Fields.SPONSO), [{
    key: 'defaultValue',
    value: {
      ctaLabel: 'Más información',
      partnerUrl: '',
      legalNotice: 'En alianza con',
      partnerName: '',
      isSponsored: false
    }
  }])
});
export var articleES = _objectSpread(_objectSpread({}, article), {}, {
  site: Sites.ENFEMENINO_COM,
  name: AufemininContentTypeName.ArticleES,
  fields: fields
});