import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* eslint-disable max-lines */
import * as Yup from 'yup';
import { Sites } from '@uno/common-shared';
import { dateGroupFields, legacyGroupFields, seoGroupFields } from "./common";
import { AufemininTaxonomyName } from "../taxonomy";
import { BaseContentTypeName, Fields, GroupFields } from "../../FieldNames";
import { contentLinkedContentField } from "../../common/fieldDefinitions/linkedContent";
import { metaUrlField } from "../../common/fieldDefinitions/metaUrl";
import { paragraphRichTextField } from "../../common/fieldDefinitions/richText";
import { templateSelectField } from "../../common/fieldDefinitions/select";
import { chapoTextField, titleTextField } from "../../common/fieldDefinitions/simpleText";
import { displayOnHomepageSwitchField, seoReviewTodoSwitchField } from "../../common/fieldDefinitions/switch";
import { mainSectionField, tagNewsTaxonomyField, tagPeopleTaxonomyField, tagSeoTaxonomyField } from "../../common/fieldDefinitions/taxonomy";
import { getCoverImageFieldLabelId, getFieldLabelId, getGroupLabelId, getOptionLabelId } from "../../../helpers";
import { FieldType } from "../../../types/ContentType";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { AufemininContentTypeName } from "./ContentTypeName";
import { coverImagesField } from "./common/coverImagesField";
var baseLabelId = BaseContentTypeName.TagPage;
var seoGroupField = seoGroupFields();
export var TagPageTemplateOptions;

(function (TagPageTemplateOptions) {
  TagPageTemplateOptions["Classic"] = "classic";
  TagPageTemplateOptions["Album"] = "album";
})(TagPageTemplateOptions || (TagPageTemplateOptions = {}));

export var buildTagPageFields = function buildTagPageFields(taxonomySeo, taxonomyPeople, taxonomyNews, taxonomyMainSection, linkableTagPage) {
  var _ref;

  return (// eslint-disable-next-line max-params
    [{
      type: FieldType.Group,
      name: GroupFields.TAG_PAGE,
      labelId: getGroupLabelId(GroupFields.TAG_PAGE),
      fields: [{
        type: FieldType.Group,
        name: GroupFields.TAG,
        labelId: getGroupLabelId(GroupFields.TAG),
        fields: [_objectSpread(_objectSpread({}, tagSeoTaxonomyField), {}, {
          taxonomyName: taxonomySeo,
          multiple: false,
          validation: Yup.array().max(1)
        }), _objectSpread(_objectSpread({}, tagPeopleTaxonomyField), {}, {
          taxonomyName: taxonomyPeople,
          multiple: false,
          validation: Yup.array().max(1)
        }), _objectSpread(_objectSpread({}, tagNewsTaxonomyField), {}, {
          taxonomyName: taxonomyNews,
          multiple: false,
          validation: Yup.array().max(1)
        })]
      }, {
        type: FieldType.Group,
        name: GroupFields.TAG_PAGE,
        labelId: getGroupLabelId(GroupFields.TAG_PAGE),
        fields: [_objectSpread(_objectSpread({}, titleTextField), {}, {
          displayCharactersCount: true,
          validation: Yup.string()
        }), chapoTextField, _objectSpread(_objectSpread({}, mainSectionField), {}, {
          taxonomyName: taxonomyMainSection,
          validation: Yup.array().test({
            message: 'form.required',
            test: function test(array) {
              return array !== undefined && array.length === 1;
            }
          })
        }), {
          type: FieldType.Repeatable,
          name: Fields.LINKED_CONTENTS,
          labelId: getFieldLabelId(Fields.LINKED_CONTENTS),
          defaultValue: [(_ref = {}, _defineProperty(_ref, Fields.PARAGRAPH, {
            blocks: []
          }), _defineProperty(_ref, Fields.CONTENT, ''), _ref)],
          validation: Yup.array().max(10),
          max: 10,
          field: {
            type: FieldType.Group,
            name: GroupFields.TAG_PAGES,
            labelId: getGroupLabelId(GroupFields.TAG_PAGES),
            fields: [paragraphRichTextField, _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
              contentTypeNames: [linkableTagPage]
            })]
          }
        }]
      }]
    }, _objectSpread(_objectSpread({}, seoGroupField), {}, {
      fields: [seoReviewTodoSwitchField].concat(_toConsumableArray(seoGroupField.fields))
    }), {
      type: FieldType.Group,
      name: GroupFields.PROPERTIES,
      labelId: getGroupLabelId(GroupFields.PROPERTIES),
      fields: [{
        type: FieldType.Group,
        name: GroupFields.PARAMETERS,
        labelId: getGroupLabelId(GroupFields.PARAMETERS),
        fields: [metaUrlField, _objectSpread(_objectSpread({}, templateSelectField), {}, {
          defaultValue: TagPageTemplateOptions.Classic,
          options: Object.values(TagPageTemplateOptions).map(function (option) {
            return {
              option: option,
              labelId: getOptionLabelId(baseLabelId, Fields.TEMPLATE, option)
            };
          })
        }), displayOnHomepageSwitchField]
      }, dateGroupFields(), {
        type: FieldType.Group,
        name: GroupFields.IMAGES,
        labelId: getGroupLabelId(GroupFields.IMAGES),
        fields: [_objectSpread(_objectSpread({}, coverImagesField), {}, {
          labelId: getCoverImageFieldLabelId(Fields.COVER_IMAGES)
        })]
      }, legacyGroupFields()]
    }]
  );
};
var fields = buildTagPageFields(AufemininTaxonomyName.TagSeoFR, AufemininTaxonomyName.TagPeopleFR, AufemininTaxonomyName.TagNewsFR, AufemininTaxonomyName.MainSectionFR, AufemininContentTypeName.TagPageFR);
export var tagPage = {
  site: Sites.AUFEMININ_COM,
  name: AufemininContentTypeName.TagPageFR,
  labelId: 'content-type.tagPage.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  getLabel: function getLabel(contentData) {
    return (// TODO: See to fix "Type instantiation is excessively deep and possibly infinite"
      // @ts-ignore Sorry too many fields
      (contentData === null || contentData === void 0 ? void 0 : contentData.title) !== undefined ? contentData === null || contentData === void 0 ? void 0 : contentData.title : ''
    );
  },
  getPreviewImage: function getPreviewImage() {
    return '';
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  getMainSectionId: function getMainSectionId(contentData) {
    var _contentData$mainSect, _contentData$mainSect2;

    return (_contentData$mainSect = contentData === null || contentData === void 0 ? void 0 : (_contentData$mainSect2 = contentData.mainSection) === null || _contentData$mainSect2 === void 0 ? void 0 : _contentData$mainSect2[0]) !== null && _contentData$mainSect !== void 0 ? _contentData$mainSect : '';
  },
  getManualModificationDate: function getManualModificationDate() {
    return undefined;
  },
  fields: fields,
  partialDependencyFields: [Fields.TITLE, Fields.CHAPO],
  isLinkable: true,
  isCreatable: true
};