import { createStyles } from '@material-ui/core';
import { getSpacing } from 'stylesheet';
var MuiFormControlLabelOverride = createStyles({
  root: {
    marginLeft: 0,
    marginRight: 0
  },
  label: {
    paddingLeft: getSpacing(1)
  }
});
export default MuiFormControlLabelOverride;