export var highlightedVideoSchema = {
  type: 'object',
  properties: {
    imgUrl: {
      type: 'string'
    },
    title: {
      type: 'string'
    },
    videoId: {
      type: 'string'
    },
    videoIframeUrl: {
      type: 'string'
    }
  },
  required: ['imgUrl', 'title', 'videoId', 'videoIframeUrl'],
  additionalProperties: false
};