import { createStyles } from '@material-ui/core';
import { getSpacing } from 'stylesheet';
var MuiTabsOverride = createStyles({
  root: {
    minHeight: 'auto'
  },
  indicator: {
    display: 'none'
  },
  flexContainer: {
    padding: getSpacing(1)
  }
});
export default MuiTabsOverride;