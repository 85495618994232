import _defineProperty from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

var _groupFields;

import { GroupFields } from '@uno/common-cms';
import { Calendar, HumanValidation, Info, Picture, Tag, User } from 'theme/icons';
var groupFields = (_groupFields = {}, _defineProperty(_groupFields, GroupFields.ALLERGENS, Tag), _defineProperty(_groupFields, GroupFields.DATES, Calendar), _defineProperty(_groupFields, GroupFields.DESCRIPTION, Tag), _defineProperty(_groupFields, GroupFields.DIET, Tag), _defineProperty(_groupFields, GroupFields.IMAGES, Picture), _defineProperty(_groupFields, GroupFields.MEDICAL_VALIDATION, HumanValidation), _defineProperty(_groupFields, GroupFields.NUTRISCORE, Tag), _defineProperty(_groupFields, GroupFields.SEASON, Calendar), _defineProperty(_groupFields, GroupFields.SEE_MORE, Info), _defineProperty(_groupFields, GroupFields.SOURCES, User), _defineProperty(_groupFields, GroupFields.TAXONOMY, Tag), _defineProperty(_groupFields, GroupFields.VISUAL, Picture), _groupFields);

var getGroupIcon = function getGroupIcon(group) {
  if (groupFields[group] !== undefined) {
    return groupFields[group];
  }

  return Info;
};

export default getGroupIcon;