import { createStyles } from '@material-ui/core';
import { borderRadius, getSpacing } from 'stylesheet';
var MuiSelectOverride = createStyles({
  select: {
    minWidth: 80
  },
  outlined: {
    padding: "".concat(getSpacing(1.2), " ").concat(getSpacing(1.8)),
    '&:focus': {
      borderRadius: borderRadius.medium
    }
  },
  icon: {
    width: 16
  },
  selectMenu: {
    display: 'flex',
    alignItems: 'center'
  }
});
export default MuiSelectOverride;