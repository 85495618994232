import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _Yup$object$shape, _Yup$object$shape2, _Yup$object$shape3, _imageFieldValidation, _Yup$object$shape4, _Yup$object$shape5, _Yup$object$shape6, _Yup$object$shape7;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as Yup from 'yup';
import { ImageFieldNames, ImageFieldProperties } from '@uno/common-shared';
var imageFieldValidation = (_imageFieldValidation = {}, _defineProperty(_imageFieldValidation, ImageFieldProperties.imgUrl, Yup.string().ensure()), _defineProperty(_imageFieldValidation, ImageFieldProperties.credits, Yup.string().ensure()), _defineProperty(_imageFieldValidation, ImageFieldProperties.legend, Yup.string().ensure()), _defineProperty(_imageFieldValidation, ImageFieldProperties._originalImage, Yup.object().shape((_Yup$object$shape2 = {}, _defineProperty(_Yup$object$shape2, ImageFieldProperties.imgUrl, Yup.string().ensure()), _defineProperty(_Yup$object$shape2, ImageFieldProperties.cropArea, Yup.object().shape((_Yup$object$shape = {}, _defineProperty(_Yup$object$shape, ImageFieldProperties.x, Yup.number()), _defineProperty(_Yup$object$shape, ImageFieldProperties.y, Yup.number()), _defineProperty(_Yup$object$shape, ImageFieldProperties.width, Yup.number()), _defineProperty(_Yup$object$shape, ImageFieldProperties.height, Yup.number()), _Yup$object$shape))), _Yup$object$shape2))), _defineProperty(_imageFieldValidation, ImageFieldProperties.imageAnchor, Yup.object().shape((_Yup$object$shape3 = {}, _defineProperty(_Yup$object$shape3, ImageFieldProperties.positionX, Yup.number()), _defineProperty(_Yup$object$shape3, ImageFieldProperties.positionY, Yup.number()), _Yup$object$shape3))), _imageFieldValidation);
/** used to generate the validation schema in validateContent.ts */

export var coverImagesFieldValidation = Yup.object().shape(_objectSpread(_defineProperty({}, ImageFieldNames.DEFAULT, Yup.object().shape(imageFieldValidation)), Object.values(ImageFieldNames).reduce(function (acc, value) {
  if (value === ImageFieldNames.DEFAULT) {
    return acc;
  }

  return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, value, Yup.object().shape(imageFieldValidation)));
}, {})));
/** used to generate the validation schema in validateContent.ts */

export var coverImagesFieldValidationRequired = Yup.object().shape(_objectSpread(_defineProperty({}, ImageFieldNames.DEFAULT, Yup.object().shape((_Yup$object$shape7 = {}, _defineProperty(_Yup$object$shape7, ImageFieldProperties.imgUrl, Yup.string().ensure().required('form.imageFieldRequired')), _defineProperty(_Yup$object$shape7, ImageFieldProperties.credits, Yup.string().ensure()), _defineProperty(_Yup$object$shape7, ImageFieldProperties.legend, Yup.string().ensure()), _defineProperty(_Yup$object$shape7, ImageFieldProperties._originalImage, Yup.object().shape((_Yup$object$shape5 = {}, _defineProperty(_Yup$object$shape5, ImageFieldProperties.imgUrl, Yup.string().ensure()), _defineProperty(_Yup$object$shape5, ImageFieldProperties.cropArea, Yup.object().shape((_Yup$object$shape4 = {}, _defineProperty(_Yup$object$shape4, ImageFieldProperties.x, Yup.number()), _defineProperty(_Yup$object$shape4, ImageFieldProperties.y, Yup.number()), _defineProperty(_Yup$object$shape4, ImageFieldProperties.width, Yup.number()), _defineProperty(_Yup$object$shape4, ImageFieldProperties.height, Yup.number()), _Yup$object$shape4))), _Yup$object$shape5))), _defineProperty(_Yup$object$shape7, ImageFieldProperties.imageAnchor, Yup.object().shape((_Yup$object$shape6 = {}, _defineProperty(_Yup$object$shape6, ImageFieldProperties.positionX, Yup.number()), _defineProperty(_Yup$object$shape6, ImageFieldProperties.positionY, Yup.number()), _Yup$object$shape6))), _Yup$object$shape7))), Object.values(ImageFieldNames).reduce(function (acc, value) {
  if (value === ImageFieldNames.DEFAULT) {
    return acc;
  }

  return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, value, Yup.object().shape(imageFieldValidation)));
}, {})));