import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* eslint-disable max-lines */
import * as Yup from 'yup';
import { Sites } from '@uno/common-shared';
import { seoGroupFields } from "./common";
import { AufemininTaxonomyName } from "../taxonomy";
import { BaseContentTypeName, Fields, GroupFields } from "../../FieldNames";
import { contentLinkedContentField } from "../../common/fieldDefinitions/linkedContent";
import { titleTextField } from "../../common/fieldDefinitions/simpleText";
import { getFieldLabelId, getGroupLabelId, imageFieldValidation } from "../../../helpers";
import { FieldType } from "../../../types/ContentType";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { AufemininContentTypeName } from "./ContentTypeName";
var baseLabelId = BaseContentTypeName.Home;
var seoGroupField = seoGroupFields();
export var buildHomeFields = function buildHomeFields(linkedContentContentTypeNames, videoContentContentTypeNames, taxonomyName) {
  var _ref9, _ref10;

  return [{
    type: FieldType.Group,
    name: GroupFields.HOME,
    labelId: getGroupLabelId(GroupFields.HOME),
    fields: [{
      type: FieldType.Group,
      name: GroupFields.TOP_ARTICLES,
      labelId: getGroupLabelId(GroupFields.TOP_ARTICLES),
      fields: [{
        type: FieldType.Repeatable,
        name: Fields.TOP_ARTICLES,
        labelId: getFieldLabelId(Fields.TOP_ARTICLES),
        defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
        validation: Yup.array().max(8),
        max: 8,
        field: _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
          contentTypeNames: linkedContentContentTypeNames
        })
      }]
    }, {
      type: FieldType.Group,
      name: GroupFields.TRENDING_SECTION,
      labelId: getGroupLabelId(GroupFields.TRENDING_SECTION),
      fields: [{
        type: FieldType.Group,
        name: GroupFields.SPOTLIGHT,
        labelId: getGroupLabelId(GroupFields.SPOTLIGHT),
        fields: [{
          type: FieldType.Repeatable,
          name: Fields.SPOTLIGHT_MACRO_SECTION_TOP_CONTENT,
          labelId: getFieldLabelId(Fields.SPOTLIGHT_MACRO_SECTION_TOP_CONTENT),
          defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
          validation: Yup.array().max(7),
          max: 7,
          field: _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
            contentTypeNames: linkedContentContentTypeNames
          })
        }]
      }, {
        type: FieldType.Group,
        name: GroupFields.MACRO_SECTION_1,
        labelId: getGroupLabelId(GroupFields.MACRO_SECTION_1),
        fields: [{
          type: FieldType.Taxonomy,
          name: Fields.MACRO_SECTION_1_MAIN_SECTION,
          taxonomyName: taxonomyName,
          labelId: getFieldLabelId(Fields.MACRO_SECTION_1_MAIN_SECTION),
          defaultValue: [],
          multiple: false,
          validation: Yup.array().test({
            message: 'form.required',
            test: function test(array) {
              return array !== undefined && array.length === 1;
            }
          })
        }, {
          type: FieldType.Repeatable,
          name: Fields.MACRO_SECTION_1_TOP_CONTENT,
          labelId: getFieldLabelId(Fields.MACRO_SECTION_1_TOP_CONTENT),
          defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
          validation: Yup.array().max(7),
          max: 7,
          field: _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
            contentTypeNames: linkedContentContentTypeNames
          })
        }]
      }, {
        type: FieldType.Group,
        name: GroupFields.MACRO_SECTION_2,
        labelId: getGroupLabelId(GroupFields.MACRO_SECTION_2),
        fields: [{
          type: FieldType.Taxonomy,
          name: Fields.MACRO_SECTION_2_MAIN_SECTION,
          taxonomyName: taxonomyName,
          labelId: getFieldLabelId(Fields.MACRO_SECTION_2_MAIN_SECTION),
          defaultValue: [],
          multiple: false,
          validation: Yup.array().test({
            message: 'form.required',
            test: function test(array) {
              return array !== undefined && array.length === 1;
            }
          })
        }, {
          type: FieldType.Repeatable,
          name: Fields.MACRO_SECTION_2_TOP_CONTENT,
          labelId: getFieldLabelId(Fields.MACRO_SECTION_2_TOP_CONTENT),
          defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
          validation: Yup.array().max(7),
          max: 7,
          field: _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
            contentTypeNames: linkedContentContentTypeNames
          })
        }]
      }, {
        type: FieldType.Group,
        name: GroupFields.MACRO_SECTION_3,
        labelId: getGroupLabelId(GroupFields.MACRO_SECTION_3),
        fields: [{
          type: FieldType.Taxonomy,
          name: Fields.MACRO_SECTION_3_MAIN_SECTION,
          taxonomyName: taxonomyName,
          labelId: getFieldLabelId(Fields.MACRO_SECTION_3_MAIN_SECTION),
          defaultValue: [],
          multiple: false,
          validation: Yup.array().test({
            message: 'form.required',
            test: function test(array) {
              return array !== undefined && array.length === 1;
            }
          })
        }, {
          type: FieldType.Repeatable,
          name: Fields.MACRO_SECTION_3_TOP_CONTENT,
          labelId: getFieldLabelId(Fields.MACRO_SECTION_3_TOP_CONTENT),
          defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
          validation: Yup.array().max(7),
          max: 7,
          field: _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
            contentTypeNames: linkedContentContentTypeNames
          })
        }]
      }, {
        type: FieldType.Group,
        name: GroupFields.MACRO_SECTION_4,
        labelId: getGroupLabelId(GroupFields.MACRO_SECTION_4),
        fields: [{
          type: FieldType.Taxonomy,
          name: Fields.MACRO_SECTION_4_MAIN_SECTION,
          taxonomyName: taxonomyName,
          labelId: getFieldLabelId(Fields.MACRO_SECTION_4_MAIN_SECTION),
          defaultValue: [],
          multiple: false,
          validation: Yup.array().test({
            message: 'form.required',
            test: function test(array) {
              return array !== undefined && array.length === 1;
            }
          })
        }, {
          type: FieldType.Repeatable,
          name: Fields.MACRO_SECTION_4_TOP_CONTENT,
          labelId: getFieldLabelId(Fields.MACRO_SECTION_4_TOP_CONTENT),
          defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
          validation: Yup.array().max(7),
          max: 7,
          field: _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
            contentTypeNames: linkedContentContentTypeNames
          })
        }]
      }, {
        type: FieldType.Group,
        name: GroupFields.MACRO_SECTION_5,
        labelId: getGroupLabelId(GroupFields.MACRO_SECTION_5),
        fields: [{
          type: FieldType.Taxonomy,
          name: Fields.MACRO_SECTION_5_MAIN_SECTION,
          taxonomyName: taxonomyName,
          labelId: getFieldLabelId(Fields.MACRO_SECTION_5_MAIN_SECTION),
          defaultValue: [],
          multiple: false,
          validation: Yup.array().test({
            message: 'form.required',
            test: function test(array) {
              return array !== undefined && array.length === 1;
            }
          })
        }, {
          type: FieldType.Repeatable,
          name: Fields.MACRO_SECTION_5_TOP_CONTENT,
          labelId: getFieldLabelId(Fields.MACRO_SECTION_5_TOP_CONTENT),
          defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
          validation: Yup.array().max(7),
          max: 7,
          field: _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
            contentTypeNames: linkedContentContentTypeNames
          })
        }]
      }]
    }, {
      type: FieldType.Group,
      name: GroupFields.VIDEO,
      labelId: getGroupLabelId(GroupFields.VIDEO),
      fields: [{
        type: FieldType.Repeatable,
        name: Fields.TOP_VIDEOS,
        labelId: getFieldLabelId(Fields.TOP_VIDEOS),
        defaultValue: [_defineProperty({}, Fields.CONTENT, '')],
        validation: Yup.array().max(5),
        max: 5,
        field: _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
          contentTypeNames: [videoContentContentTypeNames]
        })
      }]
    }, {
      type: FieldType.Group,
      name: GroupFields.PARTNER,
      labelId: getGroupLabelId(GroupFields.PARTNER),
      fields: [{
        type: FieldType.Image,
        name: Fields.PARTNER_IMAGE,
        labelId: getFieldLabelId(Fields.PARTNER_IMAGE),
        defaultValue: {
          imgUrl: undefined,
          legend: '',
          credits: ''
        },
        ratio: {
          width: 3,
          height: 2
        },
        constraints: {
          maxWidth: 3000
        },
        validation: imageFieldValidation
      }, {
        type: FieldType.Repeatable,
        name: Fields.PARTNER_CONTENTS,
        labelId: getFieldLabelId(Fields.PARTNER_CONTENTS),
        defaultValue: [(_ref9 = {}, _defineProperty(_ref9, Fields.CONTENT, ''), _defineProperty(_ref9, Fields.TITLE, ''), _ref9)],
        validation: Yup.array().max(3),
        max: 3,
        field: {
          type: FieldType.Group,
          name: GroupFields.PARTNER_CONTENT,
          labelId: getGroupLabelId(GroupFields.PARTNER_CONTENT),
          fields: [_objectSpread(_objectSpread({}, titleTextField), {}, {
            displayCharactersCount: true,
            maxCharactersCount: 110,
            validation: Yup.string().max(110, 'form.stringLength').required('form.required')
          }), _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
            contentTypeNames: linkedContentContentTypeNames
          })]
        }
      }]
    }, {
      type: FieldType.Group,
      name: GroupFields.AFFILIATION,
      labelId: getGroupLabelId(GroupFields.AFFILIATION),
      fields: [{
        type: FieldType.Repeatable,
        name: Fields.AFFILIATION_CONTENTS,
        labelId: getFieldLabelId(Fields.AFFILIATION_CONTENTS),
        defaultValue: [(_ref10 = {}, _defineProperty(_ref10, Fields.CONTENT, ''), _defineProperty(_ref10, Fields.TITLE, ''), _ref10)],
        validation: Yup.array().max(10),
        max: 10,
        field: {
          type: FieldType.Group,
          name: GroupFields.AFFILIATION_CONTENT,
          labelId: getGroupLabelId(GroupFields.AFFILIATION_CONTENT),
          fields: [_objectSpread(_objectSpread({}, titleTextField), {}, {
            displayCharactersCount: true,
            maxCharactersCount: 110,
            validation: Yup.string().max(110, 'form.stringLength')
          }), _objectSpread(_objectSpread({}, contentLinkedContentField), {}, {
            contentTypeNames: linkedContentContentTypeNames
          })]
        }
      }]
    }]
  }, seoGroupField, {
    type: FieldType.Group,
    name: GroupFields.PROPERTIES,
    labelId: getGroupLabelId(GroupFields.PROPERTIES),
    fields: []
  }];
};
var fields = buildHomeFields([AufemininContentTypeName.ArticleFR, AufemininContentTypeName.SlideshowFR, AufemininContentTypeName.TagPageFR], AufemininContentTypeName.ArticleFR, //TODO Replace with VideoFR
AufemininTaxonomyName.MainSectionFR);
export var home = {
  site: Sites.AUFEMININ_COM,
  name: AufemininContentTypeName.HomeFR,
  labelId: 'content-type.home.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  getLabel: function getLabel() {
    return '';
  },
  getPreviewImage: function getPreviewImage() {
    return '';
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  getMainSectionId: function getMainSectionId() {
    return '';
  },
  getManualModificationDate: function getManualModificationDate() {
    return undefined;
  },
  fields: fields,
  partialDependencyFields: [],
  isLinkable: false,
  isCreatable: false
}; // TODO: See to fix "Type instantiation is excessively deep and possibly infinite"
// @ts-ignore Sorry too many fields