import * as Yup from 'yup';
import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { FieldType } from "../../../../types/ContentType/fields";
export var keywordsTagTextField = {
  type: FieldType.SimpleText,
  name: Fields.KEYWORDS_TAG,
  labelId: getFieldLabelId(Fields.KEYWORDS_TAG),
  defaultValue: '',
  multiline: true,
  validation: Yup.string().test({
    message: 'form.lineBreak',
    test: function test(value) {
      return false === /[\r\n]+/.test(value);
    }
  })
};