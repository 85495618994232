import { BlockType } from '@uno/common-cms';
import getTableKeyFromBlock from './getTableKeyFromBlock';
import isBlockInTableForTableKey from './isBlockInTableForTableKey';
/**
 * Get blocks after
 *
 * If block is a table, we skip all table blocks for this table
 */

var getBlocksAfter = function getBlocksAfter(block, blockMap) {
  if (block.getType() === BlockType.TABLE) {
    return blockMap.skipUntil(function (vBlock) {
      return (vBlock === null || vBlock === void 0 ? void 0 : vBlock.getKey()) === block.getKey();
    }).skipUntil(function (vBlock) {
      return !isBlockInTableForTableKey(vBlock, getTableKeyFromBlock(block));
    });
  }

  return blockMap.skipUntil(function (vBlock) {
    return (vBlock === null || vBlock === void 0 ? void 0 : vBlock.getKey()) === block.getKey();
  }).rest();
};

export default getBlocksAfter;