import { createSlice } from '@reduxjs/toolkit';
import assignIn from 'lodash/assignIn';
export var contentInitialState = {
  firstLoadContent: {
    value: undefined,
    loading: false
  },
  validateSchema: true
};
var contentSlice = createSlice({
  name: 'content',
  initialState: contentInitialState,
  reducers: {
    updateFirstLoadContent: function updateFirstLoadContent(state, action) {
      assignIn(state.firstLoadContent, action.payload);
    },
    updateRawContent: function updateRawContent(state, action) {
      assignIn(state.firstLoadContent.value, action.payload);
    },
    setValidateSchema: function setValidateSchema(state, action) {
      state.validateSchema = action.payload;
    }
  }
});
var _contentSlice$actions = contentSlice.actions,
    updateFirstLoadContent = _contentSlice$actions.updateFirstLoadContent,
    updateRawContent = _contentSlice$actions.updateRawContent,
    setValidateSchema = _contentSlice$actions.setValidateSchema;
export { updateFirstLoadContent, updateRawContent, setValidateSchema };
export default contentSlice.reducer;