import { genKey } from 'draft-js';
import { BlockType } from '@uno/common-cms';
export var transformH2ToDraftjsAndAppend = function transformH2ToDraftjsAndAppend(genericBlock, rawDraftContentState) {
  var _genericBlock$text;

  rawDraftContentState.blocks.push({
    key: genKey(),
    type: BlockType.H2,
    text: (_genericBlock$text = genericBlock.text) !== null && _genericBlock$text !== void 0 ? _genericBlock$text : '',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: []
  });
  return rawDraftContentState;
};