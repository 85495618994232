import { genKey } from 'draft-js';
import { getRawDraftEntityRangesAndUpdatedEntityMap, getRawDraftInlineStyleRangesFromGenericInlineStyleRanges } from '../../util';
export var transformListToDraftjsAndAppend = function transformListToDraftjsAndAppend(genericBlock, rawDraftContentState) {
  genericBlock.items.forEach(function (_ref) {
    var depth = _ref.depth,
        inlineDataRanges = _ref.inlineDataRanges,
        inlineStyleRanges = _ref.inlineStyleRanges,
        text = _ref.text,
        type = _ref.type;

    var _getRawDraftEntityRan = getRawDraftEntityRangesAndUpdatedEntityMap(inlineDataRanges, rawDraftContentState.entityMap),
        rawDraftEntityRanges = _getRawDraftEntityRan.rawDraftEntityRanges,
        entityMap = _getRawDraftEntityRan.entityMap;

    rawDraftContentState.blocks.push({
      key: genKey(),
      type: type,
      text: text !== null && text !== void 0 ? text : '',
      depth: depth,
      inlineStyleRanges: getRawDraftInlineStyleRangesFromGenericInlineStyleRanges(inlineStyleRanges),
      entityRanges: rawDraftEntityRanges
    });
    rawDraftContentState.entityMap = entityMap;
  });
  return rawDraftContentState;
};