import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _objectSpread2;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { coverImagesSchema } from '@uno/common-shared';
import { DOCTISSIMO_FR_CONTENT_TYPE_HOME_CONTENT_TYPES, DoctissimoContentTypeName, Fields } from "../../../../../../config";
import { PartialContentPageChunkSchema } from "../../../../../page";
var ContentTypeHomeChunkSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, PartialContentPageChunkSchema.properties), {}, (_objectSpread2 = {
    _contentTypeName: {
      "const": DoctissimoContentTypeName.ContentTypeHome
    }
  }, _defineProperty(_objectSpread2, Fields.MAIN_SECTION, {
    type: 'array',
    items: {
      type: 'string'
    }
  }), _defineProperty(_objectSpread2, Fields.CONTENT_TYPE, {
    "enum": DOCTISSIMO_FR_CONTENT_TYPE_HOME_CONTENT_TYPES
  }), _defineProperty(_objectSpread2, Fields.TITLE, {
    type: 'string'
  }), _defineProperty(_objectSpread2, Fields.COVER_IMAGES, coverImagesSchema), _objectSpread2)),
  required: [].concat(_toConsumableArray(PartialContentPageChunkSchema.required), ['_contentTypeName', Fields.CONTENT_TYPE, Fields.TITLE, Fields.COVER_IMAGES])
};
export { ContentTypeHomeChunkSchema as ContentTypeHomeChunkSchemaDoctissimoFr };