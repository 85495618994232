import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _fieldUpdates;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Sites } from '@uno/common-shared';
import { AufemininContentTypeName } from "../../../contentTypes/ContentTypeName";
import { buildSectionHomeFields, SECTION_HOME_ALLOWED_CONTENT_TYPES, sectionHome } from "../../../contentTypes/SectionHome";
import { AufemininTaxonomyName } from "../../../taxonomy";
import { Fields, GroupFields } from "../../../../FieldNames";
import { updateFieldsProperties } from "../../../../../helpers/updateFieldsProperties";
var commonFields = buildSectionHomeFields(SECTION_HOME_ALLOWED_CONTENT_TYPES[Sites.ENFEMENINO_COM]);
var fields = updateFieldsProperties({
  fields: commonFields,
  fieldUpdates: (_fieldUpdates = {}, _defineProperty(_fieldUpdates, "".concat(GroupFields.SECTION_HOME, ".").concat(GroupFields.TAXONOMY, ".").concat(Fields.MAIN_SECTION), [{
    key: 'taxonomyName',
    value: AufemininTaxonomyName.MainSectionES
  }]), _defineProperty(_fieldUpdates, "".concat(GroupFields.SECTION_HOME, ".").concat(GroupFields.TAXONOMY, ".").concat(Fields.ALT_SECTION), [{
    key: 'taxonomyName',
    value: AufemininTaxonomyName.MainSectionES
  }]), _fieldUpdates)
});
export var sectionHomeES = _objectSpread(_objectSpread({}, sectionHome), {}, {
  site: Sites.ENFEMENINO_COM,
  name: AufemininContentTypeName.SectionHomeES,
  fields: fields
});