import React from "react";
var __jsx = React.createElement;
import { useIntl } from 'react-intl';
import { AtomicBlockType } from '@uno/common-cms';
import { Picture } from 'theme/icons';
import { useInsertAtomicBlockCallback } from '../../../shared/hooks';
import { ImageBlockToggle } from './BlockToolbarComponent.style';

var BlockToolbarComponent = function BlockToolbarComponent(_ref) {
  var setEditorState = _ref.setEditorState;
  var handleBlockToggle = useInsertAtomicBlockCallback(setEditorState);
  var intl = useIntl();
  var label = intl.formatMessage({
    id: 'content.labels.block-toolbar.image'
  });
  return __jsx(ImageBlockToggle, {
    onMouseDown: function onMouseDown() {
      return handleBlockToggle(AtomicBlockType.IMAGE, {
        openModal: true
      }, {});
    },
    title: label,
    "aria-label": label
  }, __jsx(Picture, null));
};

export default BlockToolbarComponent;