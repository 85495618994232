import { GenericBlockType } from '@uno/common-cms';
import appendGenericBlock from '../../util/appendGenericBlock';
export var transformSlideshowEmbedToGenericAndAppend = function transformSlideshowEmbedToGenericAndAppend(block, genericBlocks, contentState) {
  var _ref = contentState.getEntity(block.getEntityAt(0)).getData(),
      contentId = _ref.contentId;

  var newBlock = {
    type: GenericBlockType.SlideshowEmbed,
    contentId: contentId
  };
  return appendGenericBlock(genericBlocks, newBlock, block);
};