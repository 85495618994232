/** Search index ids, contained in the index name.
 *
 * Must be lowercase for ElasticSearch compatibility.
 */
export var IndexConfigId;

(function (IndexConfigId) {
  IndexConfigId["AufemininComSearch"] = "aufeminin_com_search";
  IndexConfigId["DoctissimoFrSearch"] = "doctissimo_fr_search";
  IndexConfigId["MarmitonOrgSearch"] = "marmiton_org_search";
  IndexConfigId["TestSearch"] = "test_search";
})(IndexConfigId || (IndexConfigId = {}));

/**
 * Search configurations to convert API queries to search engine query
 */
export var SearchQueryResolverId;

(function (SearchQueryResolverId) {
  SearchQueryResolverId["CmsBackend"] = "cms_backend-a60190ad-b8ff-4b78-979e-40ebde2af2aa";
  SearchQueryResolverId["CmsFrontendAufemininCom"] = "cms_frontend_aufeminin_com";
  SearchQueryResolverId["CmsFrontendDoctissimoFr"] = "cms_frontend_doctissimo_fr";
  SearchQueryResolverId["CmsFrontendAufemininComFeed"] = "cms_frontend_aufeminin_com_feed";
  SearchQueryResolverId["TestSearch"] = "test_search_resolver";
})(SearchQueryResolverId || (SearchQueryResolverId = {}));

export var INDEXATION_METHOD;

(function (INDEXATION_METHOD) {
  INDEXATION_METHOD["ID"] = "id";
  INDEXATION_METHOD["QUERY"] = "query";
})(INDEXATION_METHOD || (INDEXATION_METHOD = {}));

export var SortOrder;

(function (SortOrder) {
  SortOrder["DESCENDING"] = "desc";
  SortOrder["ASCENDING"] = "asc";
})(SortOrder || (SortOrder = {}));

export var RangeOperand;

(function (RangeOperand) {
  RangeOperand["GreaterThan"] = "gt";
  RangeOperand["GreaterThanOrEqualTo"] = "gte";
  RangeOperand["LowerThan"] = "lt";
  RangeOperand["LowerThanOrEqualTo"] = "lte";
})(RangeOperand || (RangeOperand = {}));