var __jsx = React.createElement;
import React from 'react';
import { GradientButton } from 'components/common/material-ui/custom-components';
import { Container, HelperList, PageContent, Title } from './AppCrashFallback.style';
/**
 * Error page inspiration https://medium.com/design-ideas-thoughts/designing-error-pages-8d82e16e3472
 */

// TODO : use NewRelic Browser dialog
var reportDialog = function reportDialog(eventId) {
  return function () {
    return console.log({
      eventId: eventId
    });
  };
};

var AppCrashFallback = function AppCrashFallback(_ref) {
  var eventId = _ref.eventId;
  return __jsx("main", null, __jsx(Container, null, __jsx(PageContent, null, __jsx(Title, null, "Sorry, this is not working properly."), __jsx("br", null), __jsx("p", null, "We know about this issue and are working to fix it."), __jsx("br", null), __jsx("p", null, "In the meantime, here is what you can do:"), __jsx(HelperList, null, __jsx("li", null, "Refresh the page (sometimes it helps)."), __jsx("li", null, "Try again in 30 minutes."), __jsx("li", null, __jsx(GradientButton, {
    onClick: reportDialog(eventId)
  }, "Tell us what happened"))))));
};

export default AppCrashFallback;