import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _properties;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { AufemininContentTypeName } from "../../config";
import { Fields } from "../../config/FieldNames";
import { basePartialDocumentSchema, baseSearchParamsSchema } from "../schemas";
export var partialDocumentSchema = {
  type: 'object',
  properties: _objectSpread(_objectSpread({}, basePartialDocumentSchema.properties), {}, {
    contentTypeName: {
      "enum": Object.values(AufemininContentTypeName)
    },
    // override basePartialDocumentSchema schema
    content: {
      type: 'object',
      properties: (_properties = {}, _defineProperty(_properties, Fields.IS_GOOGLE_NEWS, {
        type: 'boolean'
      }), _defineProperty(_properties, Fields.DISPLAY_ON_HOMEPAGE, {
        type: 'boolean'
      }), _defineProperty(_properties, "isVideo", {
        type: 'boolean'
      }), _defineProperty(_properties, Fields.TAG_SEO, {
        type: 'string'
      }), _defineProperty(_properties, Fields.TAG_PEOPLE, {
        type: 'string'
      }), _defineProperty(_properties, Fields.TAG_OPS, {
        type: 'string'
      }), _defineProperty(_properties, Fields.TAG_NEWS, {
        type: 'string'
      }), _properties),
      required: []
    }
  }),
  required: []
};
export var partialDocumentSchemaWithRequiredUrl = {
  type: 'object',
  properties: partialDocumentSchema.properties,
  required: ['absoluteUrl']
};
export var searchParamsSchema = baseSearchParamsSchema;