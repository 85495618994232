import get from 'lodash/get';
import * as Yup from 'yup';
import { Sites } from '@uno/common-shared';
import { BaseContentTypeName, Fields, GroupFields } from "../../FieldNames";
import { createdMetaDateField, modifiedMetaDateField, publicationMetaDateField } from "../../common/fieldDefinitions/metaDate";
import { metaUrlField } from "../../common/fieldDefinitions/metaUrl";
import { legacyBOUrlReadOnlyField, legacyIdReadOnlyField, legacyPathLabelReadOnlyField } from "../../common/fieldDefinitions/readOnly";
import { getFieldLabelId, getGroupLabelId, imageFieldValidation } from "../../../helpers";
import { FieldType } from "../../../types";
import { ContentTypeIconName } from "../../../types/ContentType/icons";
import { MarmitonContentTypeName } from "./ContentTypeName";
var baseLabelId = BaseContentTypeName.Utensil;
var fields = [{
  type: FieldType.Group,
  name: GroupFields.UTENSIL,
  labelId: getGroupLabelId(GroupFields.UTENSIL),
  fields: [{
    type: FieldType.SimpleText,
    name: Fields.NAME,
    labelId: getFieldLabelId(Fields.NAME),
    defaultValue: '',
    validation: Yup.string().required('form.required')
  }, {
    type: FieldType.SimpleText,
    name: Fields.PLURAL_NAME,
    labelId: getFieldLabelId(Fields.PLURAL_NAME),
    defaultValue: '',
    validation: Yup.string()
  }, {
    type: FieldType.SimpleText,
    name: Fields.KEYWORD,
    labelId: getFieldLabelId(Fields.KEYWORD),
    defaultValue: '',
    validation: Yup.string().required('form.required')
  }, {
    type: FieldType.Image,
    name: Fields.VISUAL,
    labelId: getFieldLabelId(Fields.VISUAL),
    defaultValue: {
      imgUrl: undefined,
      legend: '',
      credits: ''
    },
    ratio: {
      width: 1,
      height: 1
    },
    constraints: {
      maxWidth: 3000
    },
    validation: imageFieldValidation
  }]
}, {
  type: FieldType.Group,
  name: GroupFields.PROPERTIES,
  labelId: getGroupLabelId(GroupFields.PROPERTIES),
  fields: [metaUrlField, {
    type: FieldType.Group,
    name: GroupFields.DATES,
    labelId: getGroupLabelId(GroupFields.DATES),
    fields: [publicationMetaDateField, createdMetaDateField, modifiedMetaDateField]
  }, {
    type: FieldType.Group,
    name: GroupFields.LEGACY,
    labelId: getGroupLabelId(GroupFields.LEGACY),
    fields: [legacyIdReadOnlyField, legacyPathLabelReadOnlyField, legacyBOUrlReadOnlyField]
  }]
}];
export var utensil = {
  site: Sites.MARMITON_ORG,
  name: MarmitonContentTypeName.Utensil,
  labelId: 'content-type.utensil.name',
  baseLabelId: baseLabelId,
  icon: ContentTypeIconName.Article,
  getLabel: function getLabel(contentData) {
    return contentData.name !== undefined ? contentData.name : '';
  },
  getPreviewImage: function getPreviewImage(contentData) {
    var _ref;

    return (_ref = get(contentData, 'image.imgUrl')) !== null && _ref !== void 0 ? _ref : null;
  },
  getSubLabel: function getSubLabel() {
    return null;
  },
  getAuthorIds: function getAuthorIds() {
    return [];
  },
  getMainSectionId: function getMainSectionId() {
    return '';
  },
  getManualModificationDate: function getManualModificationDate() {
    return undefined;
  },
  fields: fields,
  partialDependencyFields: [Fields.NAME],
  isLinkable: false,
  isCreatable: true
};