import { AtomicBlockType as DraftjsAtomicBlockType } from '@uno/common-cms';
import { addAtomicBlock } from '../../util';
export var transformSlideshowEmbedToDraftjsAndAppend = function transformSlideshowEmbedToDraftjsAndAppend(_ref, rawDraftContentState) {
  var contentId = _ref.contentId;
  return addAtomicBlock(rawDraftContentState, DraftjsAtomicBlockType.SLIDESHOW_EMBED, {
    blockData: {
      openModal: false
    },
    entityData: {
      contentId: contentId
    }
  }, 'IMMUTABLE');
};