import _toConsumableArray from "/builds/unifygroup/uno/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray";
import { GenericBlockType } from '@uno/common-cms';
import { isHighlightedBlock } from 'components/common/RichTextEditor/util/block';

var appendGenericBlock = function appendGenericBlock(genericBlocks, newBlock, draftBlock) {
  var _genericBlocks, _highlightedBlock$blo;

  var isHighlighted = isHighlightedBlock(draftBlock);

  if (!isHighlighted) {
    return [].concat(_toConsumableArray(genericBlocks), [newBlock]);
  }

  var highlightedBlock = {
    type: GenericBlockType.Highlighted,
    blocks: []
  };

  if (((_genericBlocks = genericBlocks[genericBlocks.length - 1]) === null || _genericBlocks === void 0 ? void 0 : _genericBlocks.type) === GenericBlockType.Highlighted) {
    var lastGenericBlock = genericBlocks.pop();
    highlightedBlock.blocks = lastGenericBlock.blocks;
  }

  if (((_highlightedBlock$blo = highlightedBlock.blocks[highlightedBlock.blocks.length - 1]) === null || _highlightedBlock$blo === void 0 ? void 0 : _highlightedBlock$blo.type) === GenericBlockType.List && newBlock.type === GenericBlockType.List) {
    var _list$items;

    var list = highlightedBlock.blocks.pop();

    (_list$items = list.items).push.apply(_list$items, _toConsumableArray(newBlock.items));

    highlightedBlock.blocks.push(list);
  } else {
    highlightedBlock.blocks.push(newBlock);
  }

  return [].concat(_toConsumableArray(genericBlocks), [highlightedBlock]);
};

export default appendGenericBlock;