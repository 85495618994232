import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _TEST_CONTENT_TYPES;

import { article } from "./Article";
import { TestContentTypeName } from "./ContentTypeName";
import { person } from "./Person";
import { withNoBuildPage } from "./WithNoBuildPage";
var TEST_CONTENT_TYPES = (_TEST_CONTENT_TYPES = {}, _defineProperty(_TEST_CONTENT_TYPES, TestContentTypeName.Article, article), _defineProperty(_TEST_CONTENT_TYPES, TestContentTypeName.Person, person), _defineProperty(_TEST_CONTENT_TYPES, TestContentTypeName.WithNoBuildPage, withNoBuildPage), _TEST_CONTENT_TYPES);
export default TEST_CONTENT_TYPES;
export * from "./ContentTypeName";