import { createMuiTheme } from '@material-ui/core';
import { fontFamily } from 'stylesheet';
import { MuiAutocompleteOverride, MuiBackdropOverride, MuiButtonOverrides, MuiCheckboxOverride, MuiDialogOverride, MuiFormControlLabelOverride, MuiIconButtonOverride, MuiInputLabelOverride, MuiOutlinedInputOverride, MuiPaginationItemOverride, MuiSelectOverride, MuiTableBodyOverride, MuiTableCellOverride, MuiTableHeadOverride, MuiTableRowOverride, MuiTableSortLabelOverride, MuiTabOverride, MuiTabsOverride, paletteOverride } from './overrides';
var theme = createMuiTheme({
  typography: {
    fontFamily: fontFamily.main
  },
  palette: paletteOverride,
  overrides: {
    MuiAutocomplete: MuiAutocompleteOverride,
    MuiBackdrop: MuiBackdropOverride,
    MuiButton: MuiButtonOverrides,
    MuiCheckbox: MuiCheckboxOverride,
    MuiDialog: MuiDialogOverride,
    MuiFormControlLabel: MuiFormControlLabelOverride,
    MuiIconButton: MuiIconButtonOverride,
    MuiInputLabel: MuiInputLabelOverride,
    MuiOutlinedInput: MuiOutlinedInputOverride,
    MuiPaginationItem: MuiPaginationItemOverride,
    MuiSelect: MuiSelectOverride,
    MuiTab: MuiTabOverride,
    MuiTabs: MuiTabsOverride,
    MuiTableCell: MuiTableCellOverride,
    MuiTableSortLabel: MuiTableSortLabelOverride,
    MuiTableBody: MuiTableBodyOverride,
    MuiTableHead: MuiTableHeadOverride,
    MuiTableRow: MuiTableRowOverride
  },
  props: {}
});
export default theme;