import { Sites } from '@uno/common-shared';
import { AufemininTaxonomyName } from "./taxonomyName";
var TAG_OPS_FR = {
  name: AufemininTaxonomyName.TagOpsFR,
  hierarchical: false,
  site: Sites.AUFEMININ_COM
};
var TAG_OPS_ES = {
  name: AufemininTaxonomyName.TagOpsES,
  hierarchical: false,
  site: Sites.ENFEMENINO_COM
};
var TAG_OPS_IT = {
  name: AufemininTaxonomyName.TagOpsIT,
  hierarchical: false,
  site: Sites.ALFEMMINILE_COM
};
var TAG_OPS_DE = {
  name: AufemininTaxonomyName.TagOpsDE,
  hierarchical: false,
  site: Sites.GOFEMININ_DE
};
var TAG_OPS_UK = {
  name: AufemininTaxonomyName.TagOpsUK,
  hierarchical: false,
  site: Sites.SOFEMININE_CO_UK
};
var TAG_OPS_BR = {
  name: AufemininTaxonomyName.TagOpsBR,
  hierarchical: false,
  site: Sites.TAOFEMININO_COM_BR
};
var TAG_OPS_BE = {
  name: AufemininTaxonomyName.TagOpsBE,
  hierarchical: false,
  site: Sites.WEWOMEN_BE
};
export { TAG_OPS_FR, TAG_OPS_ES, TAG_OPS_IT, TAG_OPS_DE, TAG_OPS_UK, TAG_OPS_BR, TAG_OPS_BE };