import { Fields } from "../../../FieldNames";
import { getFieldLabelId } from "../../../../helpers/contentTypeLabelId";
import { dateFieldAgainstPublishedAtValidation } from "../../../../helpers/dateFieldAgainstPublishedAtValidation";
import { FieldType } from "../../../../types/ContentType/fields";
var withTime = false;
export var medicalValidationDateField = {
  type: FieldType.Date,
  name: Fields.MEDICAL_VALIDATION_DATE,
  labelId: getFieldLabelId(Fields.MEDICAL_VALIDATION_DATE),
  defaultValue: null,
  withTime: withTime,
  validateAgainstPublishedAt: true,
  validation: dateFieldAgainstPublishedAtValidation(Fields.MEDICAL_VALIDATION_DATE, withTime)
};