import { BAKO_RIGHT_TO_ROLES } from "../rights";

var isAllowed = function isAllowed(site, group, right) {
  var userSiteRoles = group[site];

  if (undefined === userSiteRoles) {
    return false;
  }

  return userSiteRoles.some(function (role) {
    return BAKO_RIGHT_TO_ROLES[right].includes(role);
  });
};

export default isAllowed;