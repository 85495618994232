import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

/* eslint-disable max-lines */

/**
 * Each type need to have a field description in frontend/sites/bako/src/components/FormBuilder
 * It will be validated by backend/services/content/libs/validation/technical/validateContent.ts using a validator given in backend/services/content/libs/validation/technical/FieldTypeValidation.ts
 */
export var FieldType;

(function (FieldType) {
  FieldType["CoverImages"] = "coverImages";
  FieldType["Date"] = "date";
  FieldType["Group"] = "group";
  FieldType["Image"] = "image";
  FieldType["LinkedContent"] = "linkedContent";
  FieldType["MetaDate"] = "metaDate";
  FieldType["MetaPublicationDate"] = "metaPublicationDate";
  FieldType["MetaUrl"] = "metaUrl";
  FieldType["Number"] = "number";
  FieldType["ReadOnly"] = "readOnly";
  FieldType["Repeatable"] = "repeatable";
  FieldType["RichText"] = "rich-text";
  FieldType["Select"] = "select";
  FieldType["SemanticScore"] = "semanticScore";
  FieldType["SimpleText"] = "simple-text";
  FieldType["Sponso"] = "sponso";
  FieldType["Switch"] = "switch";
  FieldType["Taxonomy"] = "taxonomy";
  FieldType["Video"] = "video";
})(FieldType || (FieldType = {}));

// order here is important for RTE toolbars when field has "enabledBlocks: ALL_BLOCKS"
export var BlockType; // order here is important for RTE toolbars when field has "enabledBlocks: ALL_BLOCKS"

(function (BlockType) {
  BlockType["UNSTYLED"] = "unstyled";
  BlockType["PARAGRAPH"] = "paragraph";
  BlockType["H1"] = "header-one";
  BlockType["H2"] = "header-two";
  BlockType["H3"] = "header-three";
  BlockType["H4"] = "header-four";
  BlockType["H5"] = "header-five";
  BlockType["H6"] = "header-six";
  BlockType["UL"] = "unordered-list-item";
  BlockType["OL"] = "ordered-list-item";
  BlockType["BLOCKQUOTE"] = "blockquote";
  BlockType["CODE"] = "code-block";
  BlockType["ATOMIC"] = "atomic";
  BlockType["TABLE"] = "table";
})(BlockType || (BlockType = {}));

export var AtomicBlockType;

(function (AtomicBlockType) {
  AtomicBlockType["AFFILIATION"] = "affiliation";
  AtomicBlockType["BEOP"] = "beop";
  AtomicBlockType["EMBED"] = "embed";
  AtomicBlockType["IMAGE"] = "image";
  AtomicBlockType["SLIDESHOW_EMBED"] = "slideshow-embed";
  AtomicBlockType["VIDEO"] = "video";
  AtomicBlockType["RECIPE"] = "recipe";
})(AtomicBlockType || (AtomicBlockType = {}));

export var ALL_BLOCKS = [].concat(_toConsumableArray(Object.values(AtomicBlockType)), _toConsumableArray(Object.values(BlockType)));
export var DependencyScope;

(function (DependencyScope) {
  DependencyScope["IdOnly"] = "IdOnly";
  DependencyScope["Partial"] = "Partial";
  DependencyScope["Full"] = "Full";
})(DependencyScope || (DependencyScope = {}));

export var VideoFieldProperties;

(function (VideoFieldProperties) {
  VideoFieldProperties["imgUrl"] = "imgUrl";
  VideoFieldProperties["title"] = "title";
  VideoFieldProperties["videoId"] = "videoId";
  VideoFieldProperties["videoIframeUrl"] = "videoIframeUrl";
})(VideoFieldProperties || (VideoFieldProperties = {}));

export var DateMetaData;

(function (DateMetaData) {
  DateMetaData["Created"] = "created";
  DateMetaData["Modified"] = "modified";
})(DateMetaData || (DateMetaData = {}));

export var META_URL_FIELD = 'url';
"";
export var SponsoFieldProperties;

(function (SponsoFieldProperties) {
  SponsoFieldProperties["ctaLabel"] = "ctaLabel";
  SponsoFieldProperties["partnerUrl"] = "partnerUrl";
  SponsoFieldProperties["legalNotice"] = "legalNotice";
  SponsoFieldProperties["partnerName"] = "partnerName";
  SponsoFieldProperties["isSponsored"] = "isSponsored";
})(SponsoFieldProperties || (SponsoFieldProperties = {}));