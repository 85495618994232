import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _TEST_HOSTS;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Stages } from '@uno/common-shared';
export var TEST_HOST_IDS;

(function (TEST_HOST_IDS) {
  TEST_HOST_IDS["TEST"] = "test";
})(TEST_HOST_IDS || (TEST_HOST_IDS = {}));

var commonTestHosts = _defineProperty({}, TEST_HOST_IDS.TEST, 'test');

export var TEST_HOSTS = (_TEST_HOSTS = {}, _defineProperty(_TEST_HOSTS, Stages.DEV, _objectSpread({}, commonTestHosts)), _defineProperty(_TEST_HOSTS, Stages.STAGING, _objectSpread({}, commonTestHosts)), _defineProperty(_TEST_HOSTS, Stages.PRODUCTION, _objectSpread({}, commonTestHosts)), _defineProperty(_TEST_HOSTS, Stages.PRODUCTION_LEGACY, _objectSpread({}, commonTestHosts)), _TEST_HOSTS);